import ToggleButton from "@mui/material/ToggleButton";

export const PassesToggle = ({ cell }) => {
  let currentPasses; 

  try{
    if(typeof JSON.parse(cell.row.submission_status) === "object")
    currentPasses = JSON.parse(cell.row.submission_status);
  } catch(e){
    // console.log(e)
    // console.log("current passes is undefined!")
  }
    
  return (
    <ToggleButton
      value="check"
      style={{
        backgroundColor:
          currentPasses?.passes < 1 || !currentPasses?.passes
            ? "red"
            : currentPasses?.passes < cell.row.pass_num &&
              currentPasses?.passes >= 1
            ? "orange"
            : "green",
        cursor: 'default'
      }}
    />
  );
};
