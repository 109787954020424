import { useState, useEffect, createContext } from 'react';

const CurrentEventContext = createContext({
    currentEvent: ""
});

export const CurrentEventContextProvider = (props) => {
    const [currentEvent, setCurrentEvent] = useState({ currentEvent: "" });

    useEffect(() => {
        fetch("https://youngstartup.io/api/cwebsite/get_current_event")
            .then(res => res.json())
            .then(data => setCurrentEvent(data.slug))
    }, []);

    return (
        <CurrentEventContext.Provider
            value={{ currentEvent: currentEvent }}
        >
            {props.children}
        </CurrentEventContext.Provider>
    );
};

export default CurrentEventContext;