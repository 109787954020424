import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Tooltip } from "@mui/material";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";

import { unsetLocalTok, validateTok } from "../utils/funcs";

export const Landing = () => {
  const [displayName, setDisplayName] = useState(null);

  async function getData(url) {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json();
  }

  useEffect(() => {
    getData("https://youngstartup.io/api/db/users/current").then((data) =>
      setDisplayName(`${data.first_name} ${data.last_name}`)
    );
  });

  if (!localStorage.tok) {
    window.location.href = "/";
    return <div>please log in</div>;
  }

  validateTok().then((data) => {
    // console.log(data);
    if (!data.success) {
      console.log("token not valid! please log in");
      unsetLocalTok();
      window.location.href = "/";
    }
  });

  return (
    <Container
      style={{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "85vh",
      }}
      fluid
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "5%",
        }}
      >
        <Tooltip title="Logout">
          <Fab
            color="secondary"
            className="add-circle-icon settings-icon filter-icon"
            style={{ position: "absolute", right: "10vw", top: "5vh" }}
            onClick={() => {
              unsetLocalTok(localStorage.tok);
              window.location.href = "/login";
            }}
          >
            <ExitToAppIcon>Logout</ExitToAppIcon>
          </Fab>
        </Tooltip>
        <Stack>
          <img
            src="https://youngstartup.com/assets/media/trns-logo.webp"
            style={{ maxWidth: "350px", margin: "0% auto" }}
            alt=""
          />
          <h3 style={{ color: "white" }}>Welcome {displayName}</h3>
        </Stack>
        <Container className="landing-container" fluid>
          {/* <a href="/contacts" className="landing-btn">
          Contacts
        </a>
        <a href="/deals" className="landing-btn">
          Deals
        </a>
        <a href="/companies" className="landing-btn">
          Companies
        </a>
        <a href="/recruiters" className="landing-btn">
          Recruiters
        </a>
        <a href="/wishlist" className="landing-btn">
          Wish List
        </a>
        <a href="/dash" className="landing-btn">
          Dashboard
        </a>
       
        <a href="/speakers" className="landing-btn">
         Featured Speakers
        </a> */}

          <Link to="/attendees" className="landing-btn">
            Attendees
          </Link>
          <Link to="/speakers" className="landing-btn">
            Featured Speakers
          </Link>
          <Link to="/presenters" className="landing-btn">
            Presenters
          </Link>
          <Link to="/agenda" className="landing-btn">
            Agenda
          </Link>
          <Link to="/sponsors" className="landing-btn">
            Sponsors
          </Link>

          {/* <a href="/lists" className="landing-btn">
          Lists
        </a> */}
        </Container>
      </div>
    </Container>
  );
};
