import { useDrag } from 'react-dnd';
import { ItemTypes } from '../index.js';

const style = {
  border: 'none',
  backgroundColor: '#303265',
  color: 'white',
  borderRadius:'25px',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  float: 'left',
}

export const Box = ({ name }) => {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { name },
      end(item, monitor) {
        const dropResult = monitor.getDropResult()
        if (item && dropResult) {
          let alertMessage = ''
            const isCopyAction = dropResult.dropEffect === 'copy'
            const actionName = isCopyAction ? 'copied' : 'moved'
            alertMessage = `You ${actionName} ${item.name} into ${dropResult.name}!`
          alert(alertMessage)
        }
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [name],
  )
  return (
    <div ref={drag} style={{ ...style, opacity }}>
      {name}
    </div>
  )
}
