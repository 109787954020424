import {
  NavBar,
  OldRecruiterTable,
  RecruiterTable,
  NestedTable,
  RecruiterTableFiltered,
  ToRecruitTable,
  AlreadyRecruitedTable
} from "../components/index";

import { Tab, Tabs } from "react-bootstrap";

export const Recruiters = () => {
  if (!localStorage.tok) {
    window.location.href = "/";
    return <div>Please log in</div>;
  }
  return (
    <>
      <NavBar />
      <br />
      <Tabs
        defaultActiveKey="companies"
        id="uncontrolled-tab-example"
        className="mb-3"
        style={{
          color: "white !important",
          fontFamily: "Roboto, sans-serif !important",
        }}
      >
        <Tab eventKey="companies" title="Companies">
          <RecruiterTableFiltered style={{marginTop:'1rem'}}/>
        </Tab>
        <Tab eventKey="toRecruit" title="Companies to Recruit">
          <ToRecruitTable />
        </Tab>
        <Tab eventKey="recruiting" title="In process of recruiting">
          <AlreadyRecruitedTable />
        </Tab>
      </Tabs>
      
    </>
  );
};
