import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
  usePagination,
} from "react-table";
import toast from "react-hot-toast";
import { Offcanvas,Tab, Tabs } from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import FilterListIcon from "@mui/icons-material/FilterList";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import AllInboxIcon from '@mui/icons-material/AllInbox';
import CloseIcon from "@mui/icons-material/Close";

import { ColumnFilter, GlobalFilter2, Checkbox,NavBar, NestedTable,EmailWindowForm  } from "../index";
import { COMPANY_COLUMNS } from "../columns";
import { CompanyForm } from "../Forms/CompanyForm";
import "../../components/Table.css";

export const AlreadyRecruitedTable = () => {
  //CHECKS TO SEE IF THERE IS A VALID TOKEN
  if (
    localStorage.tok == null ||
    localStorage.tok == undefined ||
    !localStorage.tok
  ) {
    window.location.href = "/";
  }
  const [dataArr, setDataArr] = useState([]);
  const columns = useMemo(() => COMPANY_COLUMNS, []);
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState("none");
  const [showEmailForm, setShowEmailForm] = useState("none");
  const [emailTo, setEmailTo] = useState("");
  const [showFilters, setShowFilters] = useState("none");
  const [loader, setLoader] = useState("none");
  const [hideBtns, setHideBtns] = useState("inline-block");
  const [openedArr, setOpenedArr] = useState([]);
  const [filtered1, setFiltered1] = useState([]);
  const [filtered2, setFiltered2] = useState([]);
  const [filtered3, setFiltered3] = useState([]);
  const [filtered4, setFiltered4] = useState([]);
  const [select1, setSelect1] = useState(undefined);
  const [select2, setSelect2] = useState(undefined);
  const [select3, setSelect3] = useState(undefined);
  const [select4, setSelect4] = useState(undefined);
  const [selectedArr, setSelectedArr] = useState([]); 
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [emailList, setEmailList] = useState('none')
  const handleCloseSide = () => setShow(false);
  const handleShowSide = () => setShow(true);
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
  }) => {
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onBlur = () => {
      updateMyData(index, id, value);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        className="editable-cell"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        style={{ color: "white !important" }}
        onKeyPress={(e) => {
          if (e.code == "NumpadEnter" || e.code == "Enter") {
            e.target.blur();
          }
        }}
      />
    );
  };

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
      Cell: EditableCell,
    };
  }, []);

  ////////////////////////////////////////////////////////
  //FILTER FUNCTION

  const onFilteredChange1 = (value, accessor) => {
    let insertNewFilter = 1;
    if (filtered1.length) {
      filtered1.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered1.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered1.push({ id: accessor, value: value });
    }

    setFiltered1(filtered1);
  };

  const onFilteredChange2 = (value, accessor) => {
    let insertNewFilter = 1;
    if (filtered2.length) {
      filtered2.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered2.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered2.push({ id: accessor, value: value });
    }

    setFiltered2(filtered2);
  };

  const onFilteredChange3 = (value, accessor) => {
    let insertNewFilter = 1;
    if (filtered3.length) {
      filtered3.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered3.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered3.push({ id: accessor, value: value });
    }

    setFiltered3(filtered3);
  };
  /////////////////////////////////
  const onFilteredChange4 = (value, accessor) => {
    let insertNewFilter = 1;
    if (filtered4.length) {
      filtered4.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered4.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered4.push({ id: accessor, value: value });
    }

    setFiltered4(filtered4);
  };

  //////////////////////////////////////////////////////

  const updateMyData = (rowIndex, columnId, value) => {
    // console.log(
    //   "rowIndex= " + rowIndex,
    //   "colIndex= " + columnId,
    //   "value = " + value
    // );
    columnId = columnId.split(".").pop();
    setSkipPageReset(true);
    let currentRow = dataArr.filter((row, index) => {
      if (index === rowIndex) {
        //console.log(row);
        return row;
      }
    });
    currentRow = currentRow[0];
    let newObj = {
      properties: {
        [columnId]: value,
      },
    };
    console.log(newObj);
    editCompany(
      "https://youngstartup.io/api/hs/companies/update/" + currentRow.id,
      newObj
    ).then((data) => {
      //console.log(data);
      // TODO reset the component so you get the fresh info from the API
    });
  };

  React.useEffect(() => {
    setSkipPageReset(false);
  }, [dataArr]);

  async function deleteCompany(url) {
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return;
  }

  async function editCompany(url, data) {
    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  const handleAddContact = (e) => {
    e.preventDefault();
    setShowForm("block");
    setHideBtns("none");
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShowForm("none");
    setHideBtns("inline-block");
  };

  const toggleRowOpen = (id) => {
    if (openedArr.includes(id)) {
      setOpenedArr((old) => old.filter((item) => item !== id));
    } else {
      setOpenedArr((old) => [...old, id]);
    }
  };

  async function getData(url) {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json();
  }

  async function postFavorite(url, input) {
    const reqBody = { body: input };
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(reqBody),
    });

    return response.json();
  }

  async function deleteFavorite(url, input) {
    const myBody = { body: input };
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(myBody),
    });
    return;
  }

  async function deleteContact(url) {
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return;
  }

  const openEmailWindow = () => {
    setShowEmailForm("block");
    console.log(selectedArr)
    const emailArr = selectedArr.map(contact=>contact.properties.email)
    console.log(emailArr)
    let emailStr = ''
    for(let i=0; i<emailArr.length; i++){
      emailStr = emailStr + emailArr[i] + ";"
    }
    console.log(emailStr)    
    setEmailTo(emailStr);
  }

  useEffect(() => {
    setLoader("block");
    getData(`https://youngstartup.io/api/hs-lists/getcontactlistcompanies/2`).then((data) => {
      setLoader("none");
      setDataArr(data.results);
    });
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    page,
    setGlobalFilter,
    nextPage,
    selectedFlatRows,
    getToggleHideAllColumnsProps,
    allColumns,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: dataArr,
      defaultColumn,
      initialState: { pageSize: 50 },
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <>
                <div>Select</div>
                <Checkbox {...getToggleAllRowsSelectedProps()} />
              </>
            ),
            Cell: ({ row, data }) => (
              <>
                <div className="select-container">
                  <Checkbox
                    {...row.getToggleRowSelectedProps()}
                    style={{ marginRight: "1rem", marginTop: "11.5%" }}
                    className="checkbox"
                  />
                  <Tooltip title="Delete company">
                    <Fab
                      color="secondary"
                      onClick={() => {
                        //console.log(data[row.index].id);
                        deleteCompany(
                          "https://youngstartup.io/api/hs/companies/delete/" +
                            data[row.index].id
                        ).then(() =>
                          getData(
                            `https://youngstartup.io/api/hs/companies/all`
                          ).then((data) => {
                            setDataArr(data.results);
                          })
                        );
                      }}
                      className="delete-icon"
                    >
                      <DeleteIcon
                        style={{ transform: "scale(1.5)" }}
                        key={data[row.index].id}
                      />
                    </Fab>
                  </Tooltip>
                  <div className="divider"></div>
                  {data[row.index].properties.wishlist == false && (
                    <Tooltip title="Save">
                      <Fab
                        color="secondary"
                        onClick={() => {
                          const obj = {
                            object_type: "companies",
                            object_id: data[row.index].id,
                          };
                          postFavorite(
                            "https://youngstartup.io/api/db/wishlist/add",
                            obj
                          )
                            // .then((data) => console.log(data))
                            .then(() => {
                              getData(
                                `https://youngstartup.io/api/hs/companies/all`
                              ).then((data) => {
                                setLoader("none");
                                setDataArr(data.results);
                              });
                            });
                        }}
                        style={{ transform: "scale(.7)" }}
                      >
                        <FavoriteIcon style={{ transform: "scale(1.3)" }} />
                      </Fab>
                    </Tooltip>
                  )}
                  {data[row.index].properties.wishlist == true && (
                    <Tooltip title="Unsave">
                      <Fab
                        color="secondary"
                        style={{ transform: "scale(.7)" }}
                        onClick={() => {
                          deleteContact(
                            "https://youngstartup.io/api/db/wishlist/delete/companies/" +
                              data[row.index].id
                          ).then(() => {
                            getData(
                              `https://youngstartup.io/api/hs/companies/all`
                            )
                              .then((data) => {
                                setDataArr(data.results);
                              })
                              .then(() => refreshTableData());
                          });
                        }}
                      >
                        <FavoriteBorderOutlinedIcon
                          style={{ transform: "scale(1.3)" }}
                        />
                      </Fab>
                    </Tooltip>
                  )}
                </div>
              </>
            ),
          },
          ...columns,
          [],
        ];
      });
    }
  );

  const { globalFilter, pageIndex, pageSize } = state;

  const multiIdArr = selectedFlatRows.map((x) => {
    return { id: `${x.original.id}` };
  });

  const multiDeleteArr = { inputs: multiIdArr };

  async function deleteMulti(
    url = "https://youngstartup.io/api/hs/companies/delmulti"
  ) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(multiDeleteArr),
    });
    return;
  }

  const refreshTableData = () => {
    let myPromise = getData(
      `https://youngstartup.io/api/hs/companies/all`
    ).then((data) => {
      setDataArr(data.results);
    });

    toast.promise(
      myPromise,
      {
        loading: "Refreshing...",
        success: (data) => `Refreshed!`,
        error: (err) => `This just happened: ${err.toString()}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 1000,
        },
      }
    );
  };

  //console.log(selectedArr)

  const MyTable = () => {
    return (
      <>
        <table {...getTableProps()}>
          <img
            style={{
              display: loader,
              position: "absolute",
              top: "50%",
              left: "45%",
            }}
            src="pulse.gif"
            alt=""
            />
          <thead>
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.headers.length + "_hfrag"}>
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <th>Row Number</th>
                  <th>Expand</th>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/*<div>{column.canFilter ? column.render("Filter") : null}</div>*/}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "⬆️"
                            : "⬇️"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    style={{ color: "white !important" }}
                  >
                    {
                      <td>
                        <span>{parseInt(row.id) + 1}</span>
                      </td>
                    }
                    <td style={{ color: "white" }}>
                      <span id={row.id} onClick={() => toggleRowOpen(row.id)}>
                        {openedArr.includes(row.id) ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </span>
                    </td>
                    {row.cells.map((cell) => {
                      if (cell !== row.cells[row.cells.length - 1])
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ color: "white !important" }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                    })}
                  </tr>
                    {openedArr.includes(row.id) && (
                <tr colSpan={7}>
                  <td colSpan={7}>
                    <NestedTable key={row.id} row={row} setShowEmailForm={setShowEmailForm} setEmailTo={setEmailTo} selectedArr={selectedArr} setSelectedArr={setSelectedArr} emailList={emailList} setEmailList={setEmailList}/>
                  </td>
                </tr>
              )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div style={{ textAlign: "center", padding: "0", margin: "0" }}>
        <span style={{ color: "white", marginRight: "1rem", backgroundColor: "#212529"}}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <span style={{ color: "white" }}>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
                gotoPage(pageNumber);
              }}
              className="next-btn page-num"
            />
          </span>
          <select
            style={{
              backgroundColor: "inherit",
              color: "white",
              border: "1px solid white",
              borderRadius: "15px",
              marginTop: "1vh",
            }}
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50, 100].map((pageSize) => (
              <option
                key={pageSize}
                style={{ backgroundColor: "#212529", color: "white" }}
                value={pageSize}
              >
                Show {pageSize}
              </option>
            ))}
          </select>
          {!canPreviousPage && (
            <button className="next-btn" disabled onClick={() => gotoPage(0)}>
              {"<<"}
            </button>
          )}
          {canPreviousPage && (
            <button className="next-btn" onClick={() => gotoPage(0)}>
              {"<<"}
            </button>
          )}
          {!canPreviousPage && (
            <button
              className="next-btn"
              disabled
              onClick={() => previousPage()}
            >
              Previous
            </button>
          )}
          {canPreviousPage && (
            <button className="next-btn" onClick={() => previousPage()}>
              Previous
            </button>
          )}
          {!canNextPage && (
            <button className="next-btn" disabled onClick={() => nextPage()}>
              Next
            </button>
          )}
          {canNextPage && (
            <button className="next-btn" onClick={() => nextPage()}>
              Next
            </button>
          )}
          {canNextPage && (
            <button
              className="next-btn"
              onClick={() => gotoPage(pageCount - 1)}
            >
              {">>"}
            </button>
          )}
          {!canNextPage && (
            <button
              className="next-btn"
              disabled
              onClick={() => gotoPage(pageCount - 1)}
            >
              {">>"}
            </button>
          )}
        </div>{" "}
      </>
    );
  };

  const listItems = selectedArr.map(item=> <li key={item.properties.id} style={{color:'black', listStyleType:'none'}}>{item.properties.firstname} {item.properties.lastname} - {item.properties.email}</li>)
  //console.log(listItems)

  if (dataArr.length <= 0) {
    getData(`https://youngstartup.io/api/hs-lists/getcontactlistcompanies/2`).then((data) => {
      setDataArr(data.results);
    });
  }

  return (
    <>
      <CompanyForm
        showForm={showForm}
        setShowForm={setShowForm}
        handleClose={handleClose}
        getData={getData}
        setDataArr={setDataArr}
        dataArr={dataArr}
      /><EmailWindowForm showEmailForm={showEmailForm} setShowEmailForm={setShowEmailForm} emailTo={emailTo} />
      <Tooltip title="Add association">
       <Fab
        color="primary"
        onClick={handleAddContact}
        className="add-circle-icon"
        aria-label="add"
      >
        <AddIcon />
      </Fab>
      </Tooltip>
      <GlobalFilter2 filter={globalFilter} setFilter={setGlobalFilter} />
      <Tooltip title="Column settings">
        <Fab
          color="primary"
          style={{ display: hideBtns }}
          className="add-circle-icon settings-icon"
          aria-label="add"
          onClick={handleShowSide}
        >
          <SettingsIcon  />
        </Fab>
      </Tooltip>
      <Offcanvas
        show={show}
        onHide={handleCloseSide}
        style={{ width: "fit-content", backgroundColor: "#212529" }}
      >
        <Offcanvas.Header closeButton>Column Settings</Offcanvas.Header>
        <div className="column-display" style={{ fontSize: "1.2rem" }}>
          Display Columns:
          <br />
          <span style={{ color: "white", marginRight: "1rem" }}>
            <Checkbox {...getToggleHideAllColumnsProps()} />
            Toggle All
          </span>
          {allColumns.map((column) => {
            if (column.id)
              return (
                <div key={column.id} style={{ display: "block" }}>
                  <label style={{ color: "white" }}>
                    <input type="checkbox" {...column.getToggleHiddenProps()} />
                    {typeof column.Header === "string"
                      ? column.Header
                      : "Select"}
                  </label>
                </div>
              );
          })}
        </div>
      </Offcanvas>
      <Tooltip title="Refresh page">
        <Fab
          color="primary"
          style={{ marginRight: "2rem", marginLeft: "2rem", display: hideBtns }}
          aria-label="add"
          onClick={() => {
            let myPromise = getData(
              `https://youngstartup.io/api/hs/companies/all`
            ).then((data) => {
              setDataArr(data.results);
            });

            toast.promise(
              myPromise,
              {
                loading: "Loading",
                success: (data) => `Refreshed!`,
                error: (err) => `This just happened: ${err.toString()}`,
              },
              {
                style: {
                  minWidth: "250px",
                },
                success: {
                  duration: 1000,
                },
              }
            );
          }}
        >
          <RefreshIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Filter options">
        <Fab
          color="primary"
          onClick={() => {
            showFilters == "none"
              ? setShowFilters("block")
              : setShowFilters("none");
          }}
          style={{ display: hideBtns }}
          className="add-circle-icon settings-icon filter-icon"
          aria-label="add"
        >
          <FilterListIcon />
        </Fab>
      </Tooltip>
      {selectedFlatRows.length > 0 && (
        <Tooltip title="Delete selected">
          <Fab
            color="secondary"
            style={{ display: hideBtns }}
            className="add-circle-icon settings-icon"
            aria-label="add"
            onClick={() => {
              let myPromise = deleteMulti();

              toast.promise(
                myPromise,
                {
                  loading: "Loading",
                  success: (data) => {
                    refreshTableData();
                    return `Successfully deleted!!`;
                  },
                  error: (err) => `This just happened: ${err.toString()}`,
                },
                {
                  style: {
                    minWidth: "250px",
                  },
                  success: {
                    duration: 5000,
                  },
                }
              );
            }}
          >
            <DeleteForeverIcon
              
              className="refresh-btn"
              variant="danger"
            />
          </Fab>
        </Tooltip>
      )}
      {
        selectedArr.length > 0 && 
          <Tooltip title="Email multiple">
            <Fab 
            color="secondary" 
            style={{ display: hideBtns }}
            className="add-circle-icon settings-icon"
            onClick={()=>{openEmailWindow();{/*setEmailList('none')*/}}}
            >
              <AllInboxIcon />
            </Fab>
          </Tooltip>

      }
      <div className="filters" style={{ display: showFilters }}>
        <h4 style={{ textAlign: "center", color: "white", marginTop: "1rem" }}>
          Filter Options:
        </h4>
        <div style={{ display: "flex", margin: "1rem" }}>
          <label style={{ margin: "1rem", color: "white" }}>Deal Stage</label>
          <CreatableSelect
            style={{
              width: "10% !important",
              marginBottom: "20px",
              margin: "2rem",
              display: "flex",
              color: "black !important",
            }}
            className="advanced-filter-input"
            /*onCreateOption={(old,entry)=>setFiltered([...old, entry])} */
            onChange={(deals) => {
              setSelect1(deals);
              onFilteredChange1(
                deals.map((entry) => {
                  return entry.value;
                })
              );
              setDataArr(
                dataArr.filter((x, i) => {
                  for (let j = 0; j < deals.length; j++) {
                    if (x.properties.name == deals[j].value) return dataArr;
                  }
                })
              );
            }}
            value={select1}
            isMulti
            options={dataArr.map((contact, i) => {
              return {
                id: i,
                value: contact.properties.name,
                label: contact.properties.name,
              };
            })}
          />
          <label style={{ margin: "1rem", color: "white" }}>Gender</label>
          <CreatableSelect
            style={{
              width: "10% !important",
              marginBottom: "20px",
              margin: "2rem",
              display: "flex",
              color: "black",
            }}
            className="advanced-filter-input"
            //onCreateOption={(old,entry)=>setFiltered([...old, entry])}
            onChange={(entries) => {
              setSelect1(entries);
              onFilteredChange2(
                entries.map((entry) => {
                  return entry.value;
                })
              );
              setDataArr(
                dataArr.filter((x, i) => {
                  for (let j = 0; j < entries.length; j++) {
                    if (x.properties.gender == entries[j].value) return dataArr;
                  }
                })
              );
            }}
            value={select2}
            isMulti
            options={[
              { id: 1, value: "Male", label: "Male" },
              { id: 2, value: "Female", label: "Female" },
            ]}
          />
          <label style={{ margin: "1rem", color: "white" }}>Industry</label>
          <CreatableSelect
            style={{
              width: "10% !important",
              marginBottom: "20px",
              margin: "2rem",
              display: "flex",
            }}
            className="advanced-filter-input"
            //onCreateOption={(old,entry)=>setFiltered([...old, entry])}
            onChange={(entries) => {
              setSelect3(entries);
              onFilteredChange3(
                entries.map((entry) => {
                  return entry.value;
                })
              );
              setDataArr(
                dataArr.filter((x, i) => {
                  for (let j = 0; j < entries.length; j++) {
                    if (x.properties.firstname == entries[j].value)
                      return dataArr;
                  }
                })
              );
            }}
            value={select3}
            isMulti
            options={[
              { id: 1, value: "Fintech", label: "Fintech" },
              { id: 1, value: "Medtech", label: "Medtech" },
            ]}
          />
          <label style={{ margin: "1rem", color: "white" }}>Regions</label>
          <CreatableSelect
            style={{
              width: "10% !important",
              marginBottom: "20px",
              margin: "2rem",
              display: "flex",
            }}
            className="advanced-filter-input"
            //onCreateOption={(old,entry)=>setFiltered([...old, entry])}
            onChange={(entries) => {
              setSelect4(entries);
              onFilteredChange4(
                entries.map((entry) => {
                  return entry.value;
                })
              );
              setDataArr(
                dataArr.filter((x, i) => {
                  for (let j = 0; j < entries.length; j++) {
                    if (x.properties.region == entries[j].value) return dataArr;
                  }
                })
              );
            }}
            value={select4}
            isMulti
            options={[
              { id: 1, value: "East Coast", label: "East Coast" },
              { id: 2, value: "West Coast", label: "West Coast" },
            ]}
          />
        </div>
      </div>
      <div className="investor-count">
        Currently displaying{" "}
        <span
          style={{
            color: "red",
            fontSize: "1.5rem",
            marginRight: ".5rem",
            marginLeft: ".5rem",
          }}
        >
          {dataArr.length}
        </span>{" "}
        companies
      </div>
      <div style={{backgroundColor:'white', width:'25vw', position:'fixed', top:'25%', height:'auto', right:'15vw', borderRadius:'10px', display:emailList, padding:"1rem"}}>
        <CloseIcon onClick={()=>setEmailList('none')}/>
        <h4 style={{textAlign:'center'}}>Email list</h4>
        <ul>
          {listItems}
        </ul>
      </div>
          <MyTable />
    </>
  );
};
