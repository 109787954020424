import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
  updateMyData,
  usePagination,
} from "react-table";
import { ColumnFilter } from "../index";
import { DEAL_COLUMNS } from "../columns";
import { Checkbox, DealForm } from "../index";
import "../../components/Table.css";
import { Offcanvas } from "react-bootstrap";
import { GlobalFilter2 } from "../GlobalFilter2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import TextField from "@mui/material/TextField";
import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import { capitalizeFirstLetter } from "../../utils/funcs";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

export const DealTable = () => {
  const [dataArr, setDataArr] = useState([]);
  const columns = useMemo(() => DEAL_COLUMNS, []);
  const [originalData] = useState(dataArr);
  const [loader, setLoader] = useState("none");
  const [hideBtns, setHideBtns] = useState("inline-block");
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState("none");
  const [skipPageReset, setSkipPageReset] = useState(false);
  const handleCloseSide = () => setShow(false);
  const handleShowSide = () => setShow(true);
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
  }) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onBlur = () => {
      updateMyData(index, id, value);
    };

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        className="editable-cell"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={(e) => {
          if (e.code == "NumpadEnter" || e.code == "Enter") {
            e.target.blur();
          }
        }}
        style={{ flexShrink: "-moz-initial" }}
      />
    );
  };

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
      Cell: EditableCell,
    };
  }, []);

  const updateMyData = (rowIndex, columnId, value) => {
    console.log(
      "rowIndex= " + rowIndex,
      "colIndex= " + columnId,
      "value = " + value
    );
    columnId = columnId.split(".").pop();
    setSkipPageReset(true);
    let currentRow = dataArr.filter((row, index) => {
      if (index === rowIndex) {
        console.log(row);
        return row;
      }
    });
    currentRow = currentRow[0];
    let newObj = {
      properties: {
        [columnId]: value,
      },
    };

    editDeal(
      "https://youngstartup.io/api/hs/deals/update/" + currentRow.id,
      newObj
    ).then((data) => {
      console.log(data);
      // TODO reset the component so you get the fresh info from the API
    });
  };

  React.useEffect(() => {
    setSkipPageReset(false);
  }, [dataArr]);

  async function deleteDeal(url) {
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return;
  }

  async function deleteDeal(url) {
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return;
  }

  async function editDeal(url, data) {
    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  const handleAddContact = (e) => {
    e.preventDefault();
    setShowForm("block");
    setHideBtns("none");
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShowForm("none");
    setHideBtns("inline-block");
  };

  async function getData(url) {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json();
  }

  async function postFavorite(url, input) {
    const reqBody = { body: input };
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(reqBody),
    });

    return response.json();
  }

  async function deleteContact(url) {
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return;
  }

  async function deleteAssociateDeal(url) {
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });

    return response.json();
  }

  useEffect(() => {
    setLoader("block");
    getData(`https://youngstartup.io/api/hs/deals/all`).then((data) => {
      setLoader("none");
      setDataArr(data.results);
    });
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    page,
    setGlobalFilter,
    nextPage,
    selectedFlatRows,
    getToggleHideAllColumnsProps,
    allColumns,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: dataArr,
      defaultColumn,
      initialState: { pageSize: 50 },
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <>
                <div>Select</div>
                <Checkbox {...getToggleAllRowsSelectedProps()} />
              </>
            ),
            Cell: ({ row, data }) => (
              <>
                <div className="select-container">
                  <Checkbox
                    {...row.getToggleRowSelectedProps()}
                    style={{ marginRight: "1rem", marginTop: "10%" }}
                    className="checkbox"
                  />
                  <Tooltip title="Delete">
                    <Fab color="secondary" className="delete-icon">
                      <DeleteIcon
                        style={{ transform: "scale(1.5)" }}
                        key={data[row.index].id}
                        onClick={() => {
                          let item = data[row.index];
                          let id = item.id;
                          // console.log(data[row.index]);
                          const myPromise = deleteDeal(
                            "https://youngstartup.io/api/hs/deals/delete/" +
                              data[row.index].id
                          ).then(() =>
                            getData(
                              `https://youngstartup.io/api/hs/deals/all`
                            ).then((data) => {
                              setDataArr(data.results);
                            })
                          );

                          toast.promise(
                            myPromise,
                            {
                              loading: "Loading",
                              success: (data) =>
                                `Successfully deleted id: ${capitalizeFirstLetter(
                                  item.properties.firstname
                                )} ${capitalizeFirstLetter(
                                  item.properties.lastname
                                )}`,
                              error: (err) => `error: ${err.toString()}`,
                            },
                            {
                              style: {
                                minWidth: "250px",
                              },
                              success: {
                                duration: 5000,
                                // icon: '🔥',
                              },
                            }
                          );
                        }}
                      />
                    </Fab>
                  </Tooltip>
                  <div className="divider"></div>
                  {data[row.index].properties.wishlist == false && (
                    <Tooltip title="Save">
                      <Fab
                        color="secondary"
                        onClick={() => {
                          const obj = {
                            object_type: "deals",
                            object_id: data[row.index].id,
                          };
                          postFavorite(
                            "https://youngstartup.io/api/db/wishlist/add",
                            obj
                          ).then((data) => console.log(data));
                        }}
                        style={{ transform: "scale(.7)" }}
                      >
                        <FavoriteIcon style={{ transform: "scale(1.3)" }} />
                      </Fab>
                    </Tooltip>
                  )}
                  {data[row.index].properties.wishlist == true && (
                    <Tooltip title="Unsave">
                      <Fab
                        color="secondary"
                        style={{ transform: "scale(.7)" }}
                        onClick={() => {
                          deleteContact(
                            "https://youngstartup.io/api/db/wishlist/delete/deals/" +
                              data[row.index].id
                          ).then(() => {
                            getData(
                              `https://youngstartup.io/api/hs/deals/all`
                            ).then((data) => {
                              setDataArr(data.results);
                            });
                          });
                        }}
                      >
                        <FavoriteBorderOutlinedIcon
                          style={{ transform: "scale(1.3)" }}
                        />
                      </Fab>
                    </Tooltip>
                  )}
                  {
                    (data[row.index].properties.companyId !== 0) && 
                  <Tooltip title="Unassign">
                  <Fab 
                  color="secondary" 
                  className='delete-icon'
                  key={data[row.index].id}
                  onClick={() => {
                    console.log( `https://youngstartup.io/api/hs/companies/${data[row.index].properties.companyId}/assoc/deals/${data[row.index].id}/company_to_deal`)
                    //ADD TO URL THE COMPANY ID ASSOCIATED TO THE DEAL
                    const myPromise = deleteAssociateDeal(
                      `https://youngstartup.io/api/hs/companies/${data[row.index].properties.companyId}/assoc/deals/${data[row.index].id}/company_to_deal`
                    )

                    toast.promise(
                      myPromise,
                      {
                        loading: "Loading",
                        success: (data) =>
                          `Successfully unassociated ${data[row.index].properties.companyId} from ${data[row.index].id}!`,
                        error: (err) => `This just happened: ${err.toString()}`,
                      },
                      {
                        style: {
                          minWidth: "250px",
                        },
                        success: {
                          duration: 2500,
                        },
                      }
                    );
                    setTimeout(()=>window.location.reload(), 2000)
                    // setTimeout(()=>window.location.reload(), 3000)
                  }}
                  >
                    <AssignmentOutlinedIcon  />
                  </Fab>
                  </Tooltip>
          }
                </div>
              </>
            ),
          },
          ...columns,
          [],
        ];
      });
    }
  );

  const { globalFilter, pageIndex, pageSize } = state;

  const multiIdArr = selectedFlatRows.map((x) => {
    return { id: `${x.original.id}` };
  });

  const multiDeleteArr = { inputs: multiIdArr };

  async function deleteMulti(
    url = "https://youngstartup.io/api/hs/deals/delmulti"
  ) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(multiDeleteArr),
    });
    return;
  }

  const refreshTableData = () => {
    let myPromise = getData(`https://youngstartup.io/api/hs/deals/all`).then(
      (data) => {
        setDataArr(data.results);
      }
    );

    toast.promise(
      myPromise,
      {
        loading: "Refreshing...",
        success: (data) => `Refreshed!`,
        error: (err) => `This just happened: ${err.toString()}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 1000,
        },
      }
    );
  };

  console.log(dataArr);

  return (
    <>
      <DealForm
        showForm={showForm}
        setShowForm={setShowForm}
        handleClose={handleClose}
        getData={getData}
        setDataArr={setDataArr}
        dataArr={dataArr}
      />
      <Tooltip title="Add deal">
        <Fab
          color="primary"
          onClick={handleAddContact}
          className="add-circle-icon"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <GlobalFilter2 filter={globalFilter} setFilter={setGlobalFilter} />
      <Tooltip title="Column settings">
        <Fab
          color="primary"
          style={{ display: hideBtns }}
          className="add-circle-icon settings-icon"
          aria-label="add"
          onClick={handleShowSide}
        >
          <SettingsIcon />
        </Fab>
      </Tooltip>
      <Offcanvas
        show={show}
        onHide={handleCloseSide}
        style={{ width: "fit-content", backgroundColor: "#212529" }}
      >
        <Offcanvas.Header closeButton>Column Settings</Offcanvas.Header>
        <div className="column-display" style={{ fontSize: "1.2rem" }}>
          Display Columns:
          <br />
          <span style={{ color: "white", marginRight: "1rem" }}>
            <Checkbox {...getToggleHideAllColumnsProps()} />
            Toggle All
          </span>
          {allColumns.map((column) => {
            if (column.id)
              return (
                <div key={column.id} style={{ display: "block" }}>
                  <label style={{ color: "white" }}>
                    <input type="checkbox" {...column.getToggleHiddenProps()} />
                    {typeof column.Header === "string"
                      ? column.Header
                      : "Select"}
                  </label>
                </div>
              );
          })}
        </div>
      </Offcanvas>
      <Tooltip title="Refresh deals">
        <Fab
          color="primary"
          style={{ marginRight: "2rem", marginLeft: "2rem", display: hideBtns }}
          aria-label="add"
          onClick={() => {
            let myPromise = getData(
              `https://youngstartup.io/api/hs/contacts/all`
            ).then((data) => {
              setDataArr(data.results);
            });

            toast.promise(
              myPromise,
              {
                loading: "Loading",
                success: (data) => `Refreshed!`,
                error: (err) => `This just happened: ${err.toString()}`,
              },
              {
                style: {
                  minWidth: "250px",
                },
                success: {
                  duration: 1000,
                },
              }
            );
          }}
        >
          <RefreshIcon />
        </Fab>
      </Tooltip>
      {selectedFlatRows.length >= 1 && (
        <Tooltip title="Delete selected">
          <Fab
            color="secondary"
            style={{ display: hideBtns }}
            className="add-circle-icon settings-icon"
            aria-label="add"
            onClick={() => {
              let myPromise = deleteMulti();

              toast.promise(
                myPromise,
                {
                  loading: "Loading",
                  success: (data) => {
                    refreshTableData();
                    return `Successfully deleted!!`;
                  },
                  error: (err) => `This just happened: ${err.toString()}`,
                },
                {
                  style: {
                    minWidth: "250px",
                  },
                  success: {
                    duration: 5000,
                  },
                }
              );
            }}
          >
            <DeleteForeverIcon className="refresh-btn" variant="danger" />
          </Fab>
        </Tooltip>
      )}
      <div className="investor-count">
        Currently displaying{" "}
        <span
          style={{
            color: "red",
            fontSize: "1.5rem",
            marginRight: ".5rem",
            marginLeft: ".5rem",
          }}
        >
          {dataArr.length}
        </span>{" "}
        deals
      </div>
      <table {...getTableProps()}>
        <img
          style={{
            display: loader,
            position: "absolute",
            top: "50%",
            left: "45%",
          }}
          src="pulse.gif"
          alt=""
          />
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th>Row Number</th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/*<div>{column.canFilter ? column.render("Filter") : null}</div>*/}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? "⬆️" : "⬇️") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {
                  <td>
                    <span>{parseInt(row.id) + 1}</span>
                  </td>
                }
                {row.cells.map((cell) => {
                  if (cell !== row.cells[row.cells.length - 1])
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                })}
              </tr>
            );
          })}
        </tbody>
        {/*<tfoot>
          {footerGroups.map((footerGroup) => (
            <tr {...footerGroup.getFooterGroupProps()}>
              {footerGroup.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
              </tfoot>*/}
      </table>
      <div style={{ textAlign: "center", padding: "0", margin: "0" }}>
        <span
          style={{
            color: "white",
            marginRight: "1rem",
            backgroundColor: "#212529",
          }}
        >
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <span style={{ color: "white" }}>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            className="next-btn page-num"
          />
        </span>
        <select
          style={{
            backgroundColor: "inherit",
            color: "white",
            border: "1px solid white",
            borderRadius: "15px",
            marginTop: "1vh",
          }}
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 25, 50, 100].map((pageSize) => (
            <option
              key={pageSize}
              style={{ backgroundColor: "#212529", color: "white" }}
              value={pageSize}
            >
              Show {pageSize}
            </option>
          ))}
        </select>
        {!canPreviousPage && (
          <button className="next-btn" disabled onClick={() => gotoPage(0)}>
            {"<<"}
          </button>
        )}
        {canPreviousPage && (
          <button className="next-btn" onClick={() => gotoPage(0)}>
            {"<<"}
          </button>
        )}
        {!canPreviousPage && (
          <button className="next-btn" disabled onClick={() => previousPage()}>
            Previous
          </button>
        )}
        {canPreviousPage && (
          <button className="next-btn" onClick={() => previousPage()}>
            Previous
          </button>
        )}
        {!canNextPage && (
          <button className="next-btn" disabled onClick={() => nextPage()}>
            Next
          </button>
        )}
        {canNextPage && (
          <button className="next-btn" onClick={() => nextPage()}>
            Next
          </button>
        )}
        {canNextPage && (
          <button className="next-btn" onClick={() => gotoPage(pageCount - 1)}>
            {">>"}
          </button>
        )}
        {!canNextPage && (
          <button
            className="next-btn"
            disabled
            onClick={() => gotoPage(pageCount - 1)}
          >
            {">>"}
          </button>
        )}
      </div>
    </>
  );
};
