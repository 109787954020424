import { useContext, useEffect, useState } from "react";

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
    Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, InputBase, Paper, Stack, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow
} from "@mui/material";

import { HoverButton } from "../HoverButton";
import { getData, patchData, postData } from "../../api/apiCalls";
import CurrentEventContext from "../../utils/current_event_context";
import styles from './SelectOldPresenters.module.css';

const SelectTrack = ({ open, handleCloseSelectors, trackOptions, presenter, currentEvent, handleSuccessMsg, handleErrMsg }) => {
    const handleAddStatus = (value) => {
        let body = {
            body: {
                event: currentEvent,
                final_presenting_track: value,
                id: presenter.id,
                rivka_stage: "selected",
                submitted: true
            },
        };
        patchData(
            `https://youngstartup.io/api/db-a/presenters/updateapplication`,
            body
        ).then((data) => {
            if (data.success) {
                handleSuccessMsg();
                handleCloseSelectors();
                // refreshStats()
                // getData(
                //     `https://youngstartup.io/api/db/presenters/getallapplications?stage=${cell.row.rivka_stage
                //     }&company_stage=${cell.row.company_stage === "Seeking Angel Round" ? "seed" : "top"
                //     }`
                // ).then((data) => setData(data));
            } else {
                handleErrMsg();
            }
        });
    };

    return (
        <Dialog open={open} onClose={handleCloseSelectors}>
            <Stack className={styles["select-track-container"]}
                style={{
                    display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap",
                    alignItems: "stretch",
                }} spacing={2}>
                <div className={styles["select-header"]}>
                    <div className={styles["select-header-text"]}>Select Track</div>
                    <div className={styles["confirm-btn-container"]}>
                        <HoverButton
                            initialUrl={"/assets/Property 1=Default (4).png"}
                            hoverUrl={"/assets/Property 1=Variant2 (4).png"}
                            className={styles["status-icon"]}
                            onClick={handleCloseSelectors}
                        />
                    </div>
                </div>
                {trackOptions?.map((track, i) => {
                    return (
                        <div
                            key={track.value}
                            className={styles["track-btn"]}
                            onClick={() => handleAddStatus(track.option_value)}
                            style={{ margin: "10px" }}
                        >
                            {track.option_display}
                        </div>
                    );
                })}
            </Stack>
        </Dialog>
    );
};

export const SelectOldPresenters = ({ open, handleClose, handleCloseActions, handleSuccessMsg, handleErrMsg }) => {
    const [searchInput, setSearchInput] = useState("");
    const [data, setData] = useState([]);
    const [currentPresenter, setCurrentPresenter] = useState({});
    const [trackOptions, setTrackOptions] = useState([]);
    const [showSelector, setShowSelector] = useState(false);

    const handleCloseSelectors = () => {
        setShowSelector(false)
    }

    let currentEvent = useContext(CurrentEventContext).currentEvent;

    useEffect(() => {
        getData(`https://youngstartup.io/api/db-info/property-options-dd/presenters`)
            .then((data) => {
                setTrackOptions(data.final_presenting_track);
            });
    }, []);

    const handleSearch = () => {
        postData("https://youngstartup.io/api/db/presenters/search_all_accounts", { body: { query: searchInput } })
            .then(data => setData(data))
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"xl"}
            sx={{
                '& .MuiDialog-paper': {
                    backgroundColor: '#303265',
                    color: "white",
                    border: "2px solid white"
                },
            }}
        >
            <div style={{ position: "sticky", top: "0" }}>
                <DialogTitle align="center">Select Old Presenters
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute', right: 8, top: 8, borderRadius: "0", border: "2px solid white", padding: "3px"
                        }}
                    >
                        <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                </DialogTitle>

                <DialogContent >
                    <DialogContentText>
                        <p>Search for <span style={{ color: "#F0AD4E" }}>full name, email, or company name</span></p>
                        <p>Note: if there are no results, try searching for just
                            <span style={{ color: "#F0AD4E" }}> "@company.com"</span>
                            , if no results, desired presenter must create an account
                        </p>
                    </DialogContentText>

                    <Paper
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "75%" }}
                        style={{ backgroundColor: "black", marginLeft: "auto", marginRight: "auto" }}
                    >

                        <InputBase
                            sx={{ flex: 1 }}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                            style={{ color: "white" }}
                            onChange={e => setSearchInput(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") { e.preventDefault(); handleSearch(); };
                            }}
                        />
                        <IconButton type="button"
                            onClick={handleSearch}
                            sx={{
                                p: '10px',
                                '& .MuiSvgIcon-root': {
                                    color: "#A19191",
                                },
                            }}
                            aria-label="search"
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </DialogContent>
            </div>

            <DialogContent style={{
                paddingTop: "0",
                marginTop: "20px"
            }}>
                {data.length > 0 &&
                    <TableContainer component={Paper}
                        style={{
                            backgroundColor: '#303265',
                            overflowX: "visible"
                        }}
                    >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table"  >
                            <TableHead style={{
                                backgroundColor: '#303265',
                                position: "sticky",
                                top: "0",
                            }}>
                                <TableRow>
                                    <TableCell style={{ color: "#F0AD4E" }}>First Name</TableCell>
                                    <TableCell style={{ color: "#F0AD4E" }}>Last Name</TableCell>
                                    <TableCell style={{ color: "#F0AD4E" }}>Email</TableCell>
                                    <TableCell style={{ color: "#F0AD4E" }}>Company Name</TableCell>
                                    <TableCell style={{ color: "#F0AD4E" }}>Rivka Stage</TableCell>
                                    <TableCell style={{ color: "#F0AD4E" }}> </TableCell>
                                    <TableCell style={{ color: "#F0AD4E" }}> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((presenter) => (
                                    < TableRow
                                        key={presenter.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell style={{ color: "white" }}>{presenter.firstname}</TableCell>
                                        <TableCell style={{ color: "white" }}>{presenter.lastname}</TableCell>
                                        <TableCell style={{ color: "white" }}>{presenter.email}</TableCell>
                                        <TableCell style={{ color: "white" }}>{presenter.company_name}</TableCell>
                                        <TableCell style={{ color: "white" }}>{presenter.rivka_stage}</TableCell>
                                        <TableCell>
                                            <Button
                                                style={{ color: "white", backgroundColor: "#05861A", border: "2px solid white", position: "static" }}
                                                onClick={() => { setCurrentPresenter(presenter); setShowSelector(true); }}
                                            >Select</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                style={{ color: "white", backgroundColor: "#05861A", border: "2px solid white", position: "static" }}
                                                onClick={() => {
                                                    let body = {
                                                        body: {
                                                            id: presenter.id,
                                                            // rivka_stage: "so_inclusion"
                                                            so_inclusion: "yes"
                                                        },
                                                    };
                                                    patchData(`https://youngstartup.io/api/db-a/presenters/updateapplication`, body)
                                                        .then((data) => {
                                                            if (data.success) {
                                                                handleSuccessMsg();
                                                                handleClose();
                                                                handleCloseActions();
                                                            }
                                                        });
                                                }}
                                            >SO</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }

                <SelectTrack open={showSelector} handleCloseSelectors={handleCloseSelectors} trackOptions={trackOptions}
                    currentEvent={currentEvent} presenter={currentPresenter} handleSuccessMsg={handleSuccessMsg} handleErrMsg={handleErrMsg} />
            </DialogContent>
        </Dialog >
    )
}
