import { Form } from "react-bootstrap";
import Button from '@mui/material/Button'
import { useState } from "react";
import toast from "react-hot-toast";
import Input from "@mui/material/Input";
import CloseIcon from '@mui/icons-material/Close';
import Fab from '@mui/material/Fab'
import InputLabel from '@mui/material/InputLabel';


export const ContactForm = (props) => {
  const [contactInputs, setContactInputs] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
  });
  
  const [companyInputs, setCompanyInputs] = useState({
    name: "",
    website: ""
  })
  const [emailVal, setEmailVal] = useState('none');
  const [siteVal, setSiteVal] = useState('none');
  const [firstVal, setFirstVal] = useState('none');
  const [lastVal, setLastVal] = useState('none');
  const [phoneVal, setPhoneVal] = useState('none');

  const formData = {
    contact: {
      properties: contactInputs
    },
    company: {
      properties: companyInputs
    }
  };

  const submitBtn = (
  <button type="submit" class="form-btn btn btn-primary">Add Contact</button>
  );

  const handleContactChange = (e) => {
    setContactInputs({
      ...contactInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleCompanyChange = (e) => {
    setCompanyInputs({
      ...companyInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const re = /\S+@\S+\.\S+/;

    if(re.test(contactInputs.email) == false){
      setEmailVal('block');
      setTimeout(()=>{ setEmailVal('none'); }, 2000);
      // setTimeout(()=>{ setEmailVal('none'); }, 6000);

      return
    }

    async function postData(url) {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          tok: localStorage.tok,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(formData),
      });

      return response.json();
    }

    const myPromise = postData(
      `https://youngstartup.io/api/hs/contacts/addnewcontactwithcompany`,
      formData
    )
      .then((data) => {
        console.log(data);
        props.setShowForm("none");
        return data;
      })
      .then((data) => {
        if (data.status && data.status == "error") {
          console.error(data.message);

          throw new Error(data.message);
        }
        // update the state with new set
        props.setDataArr([data, ...props.dataArr]);
      })
      .then(() => {
        // empty inputs of form
        let emptyInputs = Object.fromEntries(Object.keys(contactInputs).map((key) => [key, '']));
        setContactInputs(emptyInputs)
        let emptyCompanyInputs = Object.fromEntries(Object.keys(companyInputs).map((key) => [key, '']));
        setCompanyInputs(emptyCompanyInputs)
      }).then(()=> props.setHideBtns('inline'))

    toast.promise(
      myPromise,
      {
        loading: "Loading",
        success: () => `Successfully added!!`,
        error: (err) => `This just happened: ${err.toString()}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
        },
      }
    );
    setTimeout(()=>props.setHideBtns('inline-block'),2000)
  };

  //console.log(siteVal)

  return (
    <>
      <Form
        style={{ display: `${props.showForm}` }}
        onSubmit={handleSubmit}
        onKeyDown={(e)=>{if (e.code == "NumpadEnter" || e.code == "Enter") {
          e.preventDefault();
        }}}
        encType="multipart/form-data"
        className="contact-form"
      >
        <a
          onClick={props.handleClose}
          style={{
            marginLeft: "80%",
            marginBottom: "2rem !important",
            cursor: "pointer",
          }}
          className="close-btn"
        >
          <CloseIcon />
        </a>
        <div style={{border:'1px solid white', borderRadius:'10px', padding:'1rem'}}>
        <Form.Group className="mb-3">
        <InputLabel>First Name</InputLabel>
          <Input
            name="firstname"
            value={contactInputs.firstname}
            onChange={handleContactChange}
            type="text"
          />
          {/* <div style={{color:'red', textAlign:'center', position:'absolute', display:firstVal}}>please enter a valid first name</div> */}
        </Form.Group>

        <Form.Group className="mb-3">
        <InputLabel>Last Name</InputLabel>
          <Input
            name="lastname"
            value={contactInputs.lastname}
            onChange={handleContactChange}
            type="text"
          />
           {/* <div style={{color:'red', textAlign:'center', position:'absolute', display:lastVal}}>please enter a valid last name</div> */}
        </Form.Group>

        <Form.Group className="mb-3">
        <InputLabel>Email</InputLabel>
          <Input
            name="email"
            value={contactInputs.email}
            onChange={handleContactChange}
            type="text"
          />
          <div style={{color:'red', textAlign:'center', position:'absolute', display:emailVal, top:'43%'}}>please enter a valid email</div>
        </Form.Group>

        <Form.Group className="mb-3">
        <InputLabel>Phone Number</InputLabel>
          <Input

            name="phone"
            value={contactInputs.phone}
            onChange={handleContactChange}
            type="text"
          />
          {/* <div style={{color:'red', textAlign:'center', position:'absolute', display:phoneVal}}>please enter a valid phone number</div> */}
        </Form.Group>

        {/* <Form.Group className="mb-3">
        <InputLabel>Website URL</InputLabel>
          <Input
            name="website"
            value={contactInputs.website}
            onChange={handleChange}
            type="text"          
          />
          <div style={{color:'red', textAlign:'center', position:'absolute', display:siteVal}}>please enter a valid website</div> 
        </Form.Group> */}
        </div>
        <div style={{border:'1px solid white', borderRadius:'10px', padding:'1rem', marginTop:'1rem'}}>
        <Form.Group className="mb-3">
        <InputLabel>Company name</InputLabel>
          <Input
            name="name"
            value={companyInputs.name}
            onChange={handleCompanyChange}
            type="text"
          />
        </Form.Group>
        <Form.Group className="mb-3">
        <InputLabel>Company website</InputLabel>
          <Input
            name="website"
            value={companyInputs.website}
            onChange={handleCompanyChange}
            type="text"
          />
        </Form.Group>
        </div>
        <div className="form-btn-container">
        <Button
      color="primary"
      variant="contained"
      className="form-btn"
      onClick={handleSubmit}
      style={{marginTop:'.5rem'}}
      type="submit"
    >
      Add Contact
    </Button>
        </div>
      </Form>
    </>
  );
};
