//REACT IMPORTS
import { useEffect, useState } from "react";

//MUI IMPORTS
import Popover from "@mui/material/Popover";
import ToggleButton from "@mui/material/ToggleButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { patchData, getData } from "../../api/apiCalls";
import { HoverButton, AddOnToggle, AddOns } from "../index";

export const ConfirmOptionSelect = ({
  cell,
  setData,
  handleErrMsg,
  handleSuccessMsg,
  fetchData,
  fetchUrl
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [optionValue, setOptionValue] = useState("");
  const [stage, setStage] = useState("");
  const [content, setContent] = useState(null);
  const [selected2, setSelected2] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const SelectMenu = () => (
    <Stack spacing={1}>
      <div
        className="option-select-btn bg-green"
        style={{ width: "200px", padding: "2.5%" }}
        onClick={(e) => {
          setContent(
            <AddOns
              cell={cell}
              SelectMenu={SelectMenu}
              setData={setData}
              setContent={setContent}
              fetchData={fetchData}
              handleClose={handleClose}
              handleErrMsg={handleErrMsg}
              handleSuccessMsg={handleSuccessMsg}
              fetchUrl={fetchUrl}
            />
          );
          // let body = {
          //   body:{
          //     id: cell.row.id,
          //     rivka_stage: "confirmed"
          //   }
          // }
          // patchData(`https://youngstartup.io/api/db-a/presenters/updateapplication`, body)
          // .then(data => console.log(data))
        }}
      >
        Confirmed & Paid
      </div>
      <div
        style={{ width: "200px", padding: "2.5%" }}
        className="option-select-btn bg-red"
        onClick={(e) => {
          console.log("q")

          let body = {
            body: {
              id: cell.row.id,
              rivka_stage: "not going ahead",
            },
          };
          patchData(
            `https://youngstartup.io/api/db-a/presenters/updateapplication`,
            body
          ).then((data) => {
            if (data.success) {
              handleSuccessMsg();
              handleClose();
              // getData(
              //   `https://youngstartup.io/api/db/presenters/getallapplications?stage=${cell.row.rivka_stage
              //   }&company_stage=${cell.row.company_stage === "Seeking Angel Round"
              //     ? "seed"
              //     : "top"
              //   }`
              // )
              // .then((data) => setData(data));
            }
          });
        }}
      >
        Not Going Ahead
      </div>
    </Stack>
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // if (cell.row.rivka_stage === "confirmed") {
      setContent(
        <AddOns
          cell={cell}
          SelectMenu={SelectMenu}
          setData={setData}
          setContent={setContent}
          fetchData={fetchData}
          handleClose={handleClose}
          handleErrMsg={handleErrMsg}
          handleSuccessMsg={handleSuccessMsg}
          fetchUrl={fetchUrl}
        />
      );
    // } else {
    //   setContent(<SelectMenu />);
    // }
  }, []);

  return (
    <div>
      <HoverButton
        initialUrl={"assets/Property 1=Default (7).png"}
        hoverUrl={"/assets/Property 1=Variant2 (7).png"}
        className="option-btn"
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div className="popover-contents">{content}</div>
      </Popover>
    </div>
  );
};
