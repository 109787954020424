import { NavBar, 
  // ContactTable  
} from "../components/index";

export const Contacts = () => {
  if(!localStorage.tok){
    window.location.href='/'
    return(<div>Please log in</div>)
  }
  return (
    <div>
      <NavBar />
      {/* <ContactTable /> */}
    </div>
  );
}
