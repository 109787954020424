import CloseIcon from "@mui/icons-material/Close";

export const AttendeeIndex = (props) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        width: "20vw",
        height: "20vh",
        display: props.showIndex,
        position: "absolute",
        right: "5%",
        top: "15%",
        borderRadius:'5px'
      }}
    >
      <CloseIcon onClick={() => props.setShowIndex("none")} />
      <div style={{ textAlign: "center" }}>
        <h5>Attendee Index</h5>
        <div style={{display:'flex', flexDirection:'row',textAlign:'center',justifyContent:'center'}}>
            <div style={{width:"3vw", height:'3vh',backgroundColor:'#BE82FF',marginRight:'2rem'}}></div>
            <div style={{marginLeft:'2rem'}}>Duplicate</div>
        </div>
      </div>
    </div>
  );
};
