import { NavBar, DealTable } from "../components/index";

export const Deals = () => {
  if(!localStorage.tok){
    window.location.href='/'
    return(<div>Please log in</div>)
  }
  return (
    <div>
      <NavBar />
      <DealTable />
    </div>
  );
};
