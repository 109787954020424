import { useEffect, useState } from "react";

// MUI imports
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, IconButton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import ToggleButton from '@mui/material/ToggleButton';
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// utils
import toast, { Toaster } from "react-hot-toast";
import XlsExport from "xlsexport";

import {
  deleteData,
  getData,
  postData,
  putData
} from "../api/apiCalls";
import { MuiAppBar } from "../components/index";
import { formatDateForBizza, parseDateForDBFormat } from "../utils/funcs";

// builds a table (runs once for each day)
export const CreateAgendaTable = ({ rows, setRefresh, eventData, timezone }) => {
  const handleCellFocusOut = (cell, event) => {
    // collect row data
    if (!cell.row) {
      [cell.row] = rows.filter((i) => i.id == cell.id);
    }
    // check if any edits were made
    if (cell.row[cell.field] != cell.value) {
      let obj = { id: cell.row.id };

      // fix date format (for dates and duration fields)
      if (cell.field == "datetime_start") {
        let date_value = new Date(cell.value);
        let event_day_date = new Date(eventData.date_start);
        event_day_date.setDate(event_day_date.getDate() + (cell.row.day - 1));
        obj.datetime_start = parseDateForDBFormat(
          undefined,
          event_day_date.toLocaleDateString(),
          date_value.toLocaleTimeString("it-IT")
        );
        obj.datetime_end = parseDateForDBFormat(
          new Date(
            new Date(obj.datetime_start).getTime() +
            cell.row.item_duration * 60000
          )
        );
      } else if (cell.field == "item_duration") {
        let date_value = new Date(cell.row.datetime_start);
        date_value = new Date(date_value.getTime() + cell.value * 60000);
        let event_day_date = new Date(eventData.date_start);
        event_day_date.setDate(event_day_date.getDate() + (cell.row.day - 1));
        obj.datetime_end = parseDateForDBFormat(
          undefined,
          event_day_date.toLocaleDateString(),
          date_value.toLocaleTimeString("it-IT")
        );
        obj.item_duration = cell.value;
      } else if (cell.field == "day") {
        obj.day = cell.value;
        let event_day_date = new Date(eventData.date_start);
        event_day_date.setDate(event_day_date.getDate() + (cell.value - 1));
        let start_time = new Date(cell.row.datetime_start);
        obj.datetime_start = parseDateForDBFormat(
          undefined,
          event_day_date.toLocaleDateString(),
          start_time.toLocaleTimeString("it-IT")
        );
        let end_time = new Date(
          start_time.getTime() + cell.row.item_duration * 60000
        );
        obj.datetime_end = parseDateForDBFormat(
          undefined,
          event_day_date.toLocaleDateString(),
          end_time.toLocaleTimeString("it-IT")
        );
      } else {
        obj[cell.field] = cell.value;
      }

      try {
        putData("https://youngstartup.io/api/dbt/agenda/update", {
          body: obj,
        }).then((data) => {
          if (data.success == true) {
            toast.success("Successfully updated!");
          } else {
            toast.error("Error while updateing.");
          }
          setRefresh((old) => old + 1);
        });
      } catch (err) {
        console.error(err);
        toast.error("Error while updateing.");
      }
    }
  };

  // columns of table
  const columns = [
    {
      field: "delete",
      headerName: "",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
      flex: 0.25,
      renderCell: (cell) => (
        <IconButton
          aria-label="delete"
          size="small"
          color="secondary"
          onClick={(e) => {
            toast.promise(
              deleteData(
                "https://youngstartup.io/api/dbt/agenda/remove/" + cell.row.id
              ).then((data) => {
                setRefresh((i) => i + 1);
              }),
              {
                loading: "Deleting row...",
                success: <b>Successfully deleted!</b>,
                error: <b>Could not delete.</b>,
              }
            );
          }}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      ),
    },
    {
      field: "status",
      headerName: "Display on Site",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
      width: 80,
      renderCell: (cell) => (
        <DisplaySwitch cell={cell} setRefresh={setRefresh} />
      ),
    },
    {
      field: "day",
      headerName: "Day",
      headerAlign: "center",
      width: 55,
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable: false,
      width: 60,
    },
    {
      field: "datetime_start",
      headerName: "Start Time",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable: false,
      type: "dateTime",
      width: 110,
      renderCell: (cell) => {
        let date = new Date(cell.row.datetime_start).addHours(eventData.timezone === timezone ? 0 : (timezone == "ET" ? 3 : -3));
        let displayTime = date.toLocaleTimeString("it-IT");
        return <span>{displayTime.slice(0, 5)}</span>;
      },
    },
    {
      field: "datetime_end",
      headerName: "End Time",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      type: "dateTime",
      sortable: false,
      width: 110,
      renderCell: (cell) => {
        let date = new Date(cell.row.datetime_end).addHours(eventData.timezone === timezone ? 0 : (timezone == "ET" ? 3 : -3));
        let displayTime = date.toLocaleTimeString("it-IT");
        return <span>{displayTime.slice(0, 5)}</span>;
      },
    },
    {
      field: "item_duration",
      headerName: "Duration (min)",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      sortable: false,
      editable: true,
      width: 90,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "left",
      headerClassName: "super-app-theme--header",
      sortable: false,
      editable: true,
      flex: 5,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "center",
      align: "left",
      headerClassName: "super-app-theme--header",
      sortable: false,
      editable: true,
      flex: 5,
    },
    {
      field: "internal_name",
      headerName: "Internal Name",
      headerAlign: "center",
      align: "left",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 140,
      renderCell: (cell) => {
        return cell.row.is_presenting_track ? <span style={{ color: "red" }}>{cell.row.internal_name + " Track"}</span> : (cell.row.internal_name == "other" ? <span style={{ color: "orange" }}>{cell.row.internal_name}</span> : cell.row.internal_name);
      },
    },
    {
      field: "moderator",
      headerName: "Moderator",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (cell) => (
        <div>
          <a
            target={"_blank"}
            href={
              cell.row.moderator.length > 0 ? "https://manager.youngstartup.io/speakers?confirmed=true&q=" + cell.row.moderator[0].firstname + " " + cell.row.moderator[0].lastname : ""
            }
            style={{ color: "yellow" }}
          >
            {cell.row.moderator.length > 0 ? cell.row.moderator[0].firstname + " " + cell.row.moderator[0].lastname : ""}
          </a>
        </div>
      ),
    },
    {
      field: "panelists_count",
      headerName: "Panelists",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
      renderCell: (cell) => (
        <div>
          <a
            target={"_blank"}
            href={
              "https://manager.youngstartup.io/speakers?confirmed=true&panel=" +
              cell.row.internal_name +
              ";"
            }
            style={{ color: "yellow" }}
          >
            {cell.row.panelists_count} {cell.row.moderator.length > 0 ? "+ M" : ""}
          </a>
        </div>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      headerAlign: "center",
      align: "left",
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable: false,
      flex: 2,
    },
    {
      field: "zoom_link",
      headerName: "Zoom Link",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable: false,
      flex: 1,
    },
    // {
    //   field: "location",
    //   headerName: "Location",
    //   headerAlign: "center",
    //   align: "center",
    //   headerClassName: "super-app-theme--header",
    //   editable: true,
    //   sortable: true,
    //   hidden: true,
    //   flex: 1,
    // },
    {
      field: "pdf",
      headerName: "PDF of Panelists",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      sortable: false,
      width: 40,
      renderCell: (cell) => (
        <Button
          variant="contained"
          size="small"
          onClick={(e) => {
            toast.promise(
              postData(
                "https://youngstartup.io/api/db-a/agenda/createPanelistspdf",
                { body: { id: cell.row.id } }
              ).then((data) => {
                if (data.success == true) {
                  toast(
                    <span>
                      Link to PDF:{" "}
                      <a target={"_blank"} href={data.link}>
                        Click Here
                      </a>
                    </span>
                  );
                } else {
                  toast.error("Error while creating PDF. " + data.message);
                }
                setRefresh((old) => old + 1);
              }),
              {
                loading: "Creating PDF...",
                success: <b>Successfully created!</b>,
                error: <b>Could not save.</b>,
              }
            );
          }}
        >
          Open
        </Button>
      ),
    },
    {
      field: "bizzabo_id",
      headerName: "Bizzabo ID",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <>
      <div style={{ width: "100%" }}>
        <DataGrid
          disableSelectionOnClick
          rows={rows}
          columns={columns}
          rowHeight={50}
          onCellEditCommit={handleCellFocusOut}
          autoHeight
          hideFooter={true}
          disableColumnFilter
          sx={{
            color: "white !important",
            border: "none",
            backgroundColor: "#000120",
            fontFamily: "Manrope, sans-serif",
          }}

        // need to inisilize the visable col  - https://mui.com/components/data-grid/columns/#initialize-the-visible-columns
        />
      </div>
    </>
  );
};

// MAIN Component
export const Agenda = () => {
  // states
  const [data, setData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [event, setEvent] = useState("");
  const [timezone, setTimezone] = useState("");
  const [refresh, setRefresh] = useState(1);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({ speaker_panel_contact: [], final_presenting_track: [], other: [] });
  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const [formPostData, setFormPostData] = useState({});
  const [inputValue, setInputValue] = useState("");

  const MuiToggle = ({ selected, setFormPostData, day }) => {
    return (
      <ToggleButton
        value="check"
        selected={selected}
        sx={{ backgroundColor: selected ? 'green !important' : "", border: '1px solid white' }}
        onChange={() => {
          setFormPostData({ ...formPostData, day })
        }}
      >
      </ToggleButton>
    )
  }


  Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
  }


  const handleChange = (event) => {
    setTimezone(event.target.checked ? "ET" : "PT");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormPostData({})
    setOpen(false);
  };
  // use effects
  useEffect(() => {
    getData(`https://youngstartup.io/api/cwebsite/get_current_event`).then(
      (data) => {
        setEvent(data.slug);
        setEventData(data);
        setTimezone(data.timezone);
        getData(
          `https://youngstartup.io/api/db/agenda/getAll?event=${data.slug}`
        ).then((data) => {
          setData(data);
        });
      }
    );

    getData(
      "https://youngstartup.io/api/db-info/property-options-dd/agenda"
    ).then((data) => setOptions(data));
  }, [refresh]);

  return (
    <>
      <MuiAppBar />
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            color: "white",
            marginRight: "5px",
          }}
        >
          <label style={{ color: "white" }}>PT</label>
          <Switch
            checked={timezone == "ET" ? true : false}
            color="warning"
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <label style={{ color: "white" }}>ET</label>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogContent sx={{ height: "58vh", backgroundColor: '#030120', color: 'white' }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>Add Agenda Item</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <button onClick={handleClose}>X</button>
            </div>
          </div>
          <div
            style={{
              justifyContent: "center",
              borderBottom: "1px solid gray",
              paddingBottom: "5vh",
              paddingTop: "2vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "3vh",
              }}
            >

              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(e, newValue) => {
                  if (newValue.includes(" Track")) {
                    newValue = newValue.replace(" Track", "").toLowerCase()
                    setFormPostData({ ...formPostData, internal_name: newValue, is_presenting_track: 1 })
                    return
                  }
                  setFormPostData({ ...formPostData, internal_name: newValue })
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={[
                  // remove all panels that exist already
                  ...options.speaker_panel_contact.filter(option => { let arr = []; data?.map((i) => arr = [...arr, ...i]); arr = arr.map(i => i.internal_name); return !arr.includes(option.option_value); }).map((option) => option.option_display_short).sort(),
                  ...options.final_presenting_track.map((option) => option?.option_display_short).sort(),
                  ...options.other.map((option) => option?.option_display_short).sort()
                ]}
                sx={{
                  width: "30%", svg: { fill: "#030120" }, input: { color: "white", fontSize: "1rem" },
                  textarea: { color: "white" },
                  "&": {
                    color: "white !important",
                    backgroundColor: "#303265",
                    borderRadius: "5px",
                    minWidth: "fit-content",
                  },
                  "& label": {
                    color: "white",
                  },
                  "& label.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "white",
                    color: "white",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "green",
                    color: "white",
                  },
                  "& .MuiInputBase-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                    "& .MuiSvgIcon-root-MuiSelect-icon": {
                      fill: "white !important",
                    },
                    "& .MuiPaper-root": {
                      backgroundColor: "lightblue !important",
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{
                      sx: {
                        // set the color of the label when not shrinked
                        color: "white",
                      }
                    }}
                    {...params} label="Add Agenda Items..." />
                )}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <TextField
                label="Full Name"
                value={formPostData.name}
                onChange={(e) => {
                  setFormPostData({ ...formPostData, name: e.target.value })
                }}
                sx={{
                  color: "white", input: { color: "white" }, "&": {
                    color: "white",
                    backgroundColor: "#303265",
                    borderRadius: "5px",
                    minWidth: "fit-content",
                    width: "50%",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    // set the color of the label when not shrinked
                    color: "white",
                  }
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextField
                label="Description"
                value={formPostData.description}
                onChange={(e) => {
                  setFormPostData({ ...formPostData, description: e.target.value })
                }}
                multiline
                sx={{
                  color: "white !important", input: { color: "white !important" },
                  "& .MuiInputBase-input": {
                    color: "white"
                  },
                  "&": {
                    color: "white !important",
                    backgroundColor: "#303265",
                    borderRadius: "5px",
                    minWidth: "fit-content",
                    width: "80%",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    // set the color of the label when not shrinked
                    color: "white",
                  }
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", paddingTop: '1.5vh' }}>
            <span>Times are in {eventData.timezone}</span>
          </div>

          <div style={{ display: "flex", paddingTop: '2vh', justifyContent: 'center', alignItems: 'center', gap: '5%' }}>
            <div style={{ display: "flex", margin: 'auto', flex: 1, gap: '5%', flexDirection: "column" }}>

              <LocalizationProvider dateAdapter={AdapterDateFns} style={{ color: "white" }}>
                <Stack spacing={3}>
                  <TimePicker
                    label="Start Time"
                    value={formPostData.start_time ? formPostData.start_time : ""}
                    onChange={(newValue) => {
                      setFormPostData({ ...formPostData, start_time: newValue })
                    }}
                    renderInput={(params) => <TextField {...params}
                      sx={{
                        "& .MuiFormLabel-root, .MuiInputBase-inputAdornedEnd": {
                          color: "white",
                        },
                        "&": {
                          color: "white",
                          backgroundColor: "#303265",
                          borderRadius: "5px",
                          minWidth: "fit-content",
                        }
                      }}
                    />}
                  />
                </Stack>
              </LocalizationProvider>
              <br />
              <LocalizationProvider dateAdapter={AdapterDateFns} style={{ color: "white" }}>
                <Stack spacing={3}>
                  <TimePicker
                    label="End Time"
                    value={formPostData.end_time ? formPostData.end_time : ""}
                    onChange={(newValue) => {
                      let date_value = new Date(formPostData.start_time);
                      let temp = Math.abs((new Date(newValue) - date_value) / 60000)
                      setFormPostData({ ...formPostData, end_time: newValue, item_duration: Math.ceil(temp) })
                    }}
                    renderInput={(params) => <TextField {...params}
                      sx={{
                        "& .MuiFormLabel-root, .MuiInputBase-inputAdornedEnd": {
                          color: "white",
                        },
                        "&": {
                          color: "white",
                          backgroundColor: "#303265",
                          borderRadius: "5px",
                          minWidth: "fit-content",
                        }
                      }}
                    />}
                  />
                </Stack>
              </LocalizationProvider>
              <br />
              <TextField
                label="Duration (min)"
                type="number"
                fullWidth
                value={formPostData.item_duration}
                onChange={(e) => {
                  let date_value = new Date(formPostData.start_time);
                  date_value = new Date(date_value.getTime() + e.target.value * 60000);
                  setFormPostData({ ...formPostData, item_duration: e.target.value, end_time: date_value })
                }}
                sx={{
                  color: "white", input: { color: "white" }, "&": {
                    color: "white",
                    backgroundColor: "#303265",
                    borderRadius: "5px",
                  },
                }}
                InputLabelProps={{
                  shrink: formPostData.item_duration ? true : false,
                  sx: {
                    // set the color of the label when not shrinked
                    color: "white",
                  }
                }}
              />

            </div>
            <Divider orientation="vertical" style={{ height: '150px', borderColor: "white" }} flexItem />
            <Stack spacing={2} style={{ margin: 'auto', flex: 1, textAlign: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center' }}><MuiToggle selected={formPostData.day && formPostData.day == 1 ? true : false} day={1} setFormPostData={setFormPostData} /><div>Day 1</div></div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center' }}><MuiToggle selected={formPostData.day && formPostData.day == 2 ? true : false} day={2} setFormPostData={setFormPostData} /><div>Day 2</div></div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '15px', justifyContent: 'center' }}><MuiToggle selected={formPostData.day && formPostData.day == 3 ? true : false} day={3} setFormPostData={setFormPostData} /><div>Day 3</div></div>
              {/* {data.map((x, i)=><div style={{display:'flex', flexDirection:'row', gap:'15px', justifyContent:'center'}}><MuiToggle /><div key={i} >{x[0].datetime_start.slice(8,10)}</div></div>)} */}
            </Stack>
          </div>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: '#030120' }}>
          <Button variant="contained" onClick={() => {
            // validate data
            if (
              !formPostData.name ||
              !formPostData.day ||
              !formPostData.start_time ||
              !formPostData.end_time ||
              !formPostData.item_duration
            ) {
              alert("some of the values are missing!")
            } else {
              // fix up the data
              let tempObj = {
                day: formPostData.day,
                name: formPostData.name,
                event,
                item_duration: formPostData.item_duration,
                description: formPostData.description,
              };

              // panel name
              let all_options = [...options.speaker_panel_contact, ...options.final_presenting_track, ...options.other];
              let [panel_obj] = all_options.filter((option) => option?.option_display_short === formPostData.internal_name)
              tempObj.short_name = panel_obj?.option_display_short
              tempObj.internal_name = panel_obj?.option_value

              tempObj.is_presenting_track = options.final_presenting_track.filter(i => i?.option_display_short === formPostData.internal_name).length > 0 ? 1 : 0;

              // time start
              let date_value = new Date(formPostData.start_time);
              let event_day_date = new Date(eventData.date_start);
              event_day_date.setDate(event_day_date.getDate() + (formPostData.day - 1));
              let temp = parseDateForDBFormat(
                undefined,
                event_day_date.toLocaleDateString(),
                date_value.toLocaleTimeString("it-IT")
              )
              tempObj = { ...tempObj, datetime_start: temp }

              // time end
              let date_value2 = new Date(formPostData.end_time);
              let temp2 = parseDateForDBFormat(
                undefined,
                event_day_date.toLocaleDateString(),
                date_value2.toLocaleTimeString("it-IT")
              )
              tempObj = { ...tempObj, datetime_end: temp2 }

              // post data to server
              postData("https://youngstartup.io/api/dbt/agenda/add", { body: tempObj })
                .then(data => {
                  if (data.success) {
                    toast.success("The item was added successfuly!")
                  } else {
                    toast.error("An error has occurred!")
                  }
                  setRefresh(i => (i + 1))
                })

              handleClose()
            }
          }}>Save</Button>
        </DialogActions>
      </Dialog>
      <div>
        <Toaster />
      </div>
      <div
        style={{
          display: "flex",
          gap: "15px",
          paddingLeft: "5%",
        }}
      >
        <button className="filterBtn" onClick={handleClickOpen}>
          <AddIcon />
        </button>
        <button
          onClick={e => {
            let tempData = [];
            data?.map((i) => tempData = [...tempData, ...i]);
            tempData = tempData.map(i => {
              let panelists = "";
              panelists += i.moderator.length > 0 ? `(${i.moderator[0].email}, Moderator) ` : "";
              panelists += i.panelists.length > 0 ? i.panelists.sort((a, b) => a.lastname.localeCompare(b.lastname)).map(item => `(${item.email}, Speaker) `).join("") : "";
              let obj = {
                "Unique ID": i.bizzabo_id,
                "Session Title": i.name,
                "Description": i.description,
                "Description HTML": "",
                "Start Date & Time": formatDateForBizza(i.datetime_start),
                "End Date & Time": formatDateForBizza(i.datetime_end),
                "Visible / Hidden session": i.status == "final" ? "Visible" : "Hidden",
                "Speakers": panelists,
              };
              return obj;
            })

            let xls = new XlsExport(tempData, "exprtedtable");
            // xls.exportToCSV(eventData.slug + " Agenda.csv");
            xls.exportToXLS(eventData.slug + " Agenda.xls");
          }}
          className="filterBtn">
          <FileDownloadIcon />
        </button>
        <button onClick={() => {
          toast.success("Refreshing...")
          setRefresh(i => (i + 1))
        }}
          className="filterBtn">
          <RefreshIcon

          />
        </button>
      </div>
      {/* <div style={{width: '100%', color: "white" }}>
               <p> Timezone: {eventData.timezone}</p>
               <p> Event: {event}</p>
            </div> */}
      {/* loop throw each day */}
      {data.map((item, index) => (
        <div key={index}>
          <div style={{ color: "white", height: "5vh" }}>Day {index + 1}</div>
          <CreateAgendaTable
            rows={item}
            setRefresh={setRefresh}
            eventData={eventData}
            timezone={timezone}
          />
        </div>
      ))}
    </>
  );
};

export const DisplaySwitch = ({ cell, setRefresh }) => (
  <Switch
    sx={{
      "& .MuiSwitch-switchBase": {
        color: `${cell.row.status == "final" ? "green !important" : "red !important"
          }`,
      },
      "& .MuiSwitch-track": {
        backgroundColor: "white",
      },
    }}
    checked={cell.row.status == "final" ? true : false}
    onClick={(e) => {
      if (!e.target.checked) {
        cell.row.status = "pending";
        e.target.checked = false;
        try {
          putData("https://youngstartup.io/api/dbt/agenda/update", {
            body: { id: cell.row.id, status: "pending" },
          }).then((data) => {
            setRefresh((old) => old + 1);
          });
          toast.success("Removed from website!");
        } catch (err) {
          console.error(err);
          toast.error("Error while removeing form website.");
        }
      } else {
        cell.row.status = "final";
        e.target.checked = true;
        try {
          putData("https://youngstartup.io/api/dbt/agenda/update", {
            body: { id: cell.row.id, status: "final" },
          }).then((data) => {
            setRefresh((old) => old + 1);
          });
          toast.success("Added to website!");
        } catch (err) {
          console.error(err);
          toast.error("Error while adding to website.");
        }
      }
    }}
  />
);
