import { useEffect, useState } from "react";

import ToggleButton from "@mui/material/ToggleButton";

import { getData, patchData } from "../../api/apiCalls";
import { parseDateForDBFormat } from "../../utils/funcs";

export const PresenterToggle = ({
  cell,
  id,
  property,
  newValue,
  prop,
  handleSuccessMsg,
  handleErrMsg,
  setData,
}) => {
  const [selected, setSelected] = useState(
    prop === "opted_in_coaching" && property === "yes"
      ? true
      : property && prop !== "opted_in_coaching"
      ? true
      : property 
      ? true 
      : false

  );

  useEffect(() => {
    // console.log(property);
  }, []);

  return (
    <ToggleButton
      value="check"
      selected={selected}
      style={{ backgroundColor: selected ? "green" : "red", cursor: prop === "email_confirmed_2" || prop === "opted_in_coaching" ? "pointer" : "default" }}
      onChange={() => {
        if (prop === "email_confirmed_2") {
          setSelected(!selected);
          let body = {
            body: {
              id: id,
              [prop]: selected
                ? null
                : parseDateForDBFormat(new Date(Date.now())),
            },
          };
          console.log(body);
          patchData(
            `https://youngstartup.io/api/db-a/presenters/updateapplication`,
            body
          ).then((data) => {
            if (data.success) {
              handleSuccessMsg();
              getData(
                `https://youngstartup.io/api/db/presenters/getallapplications?stage=${
                  cell.row.rivka_stage
                }&company_stage=${
                  cell.row.company_stage === "Seeking Angel Round"
                    ? "seed"
                    : "top"
                }`
              ).then((data) => setData(data.data));
              // ).then((data) => setData(data));
            } else {
              handleErrMsg();
            }
          });
        } else if (prop === "opted_in_coaching") {
          setSelected(!selected);
          let body = {
            body: {
              id: id,
              [prop]: selected ? "no" : "yes",
            },
          };
          console.log(body);
          patchData(
            `https://youngstartup.io/api/db-a/presenters/updateapplication`,
            body
          ).then((data) => {
            if (data.success) {
              handleSuccessMsg();
              getData(
                `https://youngstartup.io/api/db/presenters/getallapplications?stage=${
                  cell.row.rivka_stage
                }&company_stage=${
                  cell.row.company_stage === "Seeking Angel Round"
                    ? "seed"
                    : "top"
                }`
              ).then((data) => setData(data.data));
              // ).then((data) => setData(data));
            } else {
              handleErrMsg();
            }
          });
        }
      }}
    />
  );
};
