// import { Fab } from "@mui/material";
import Fab from '@mui/material/Fab';
import DeleteIcon from "@mui/icons-material/Delete";
import { format } from "date-fns";
import React, { useState } from "react";
import { ColumnFilter } from "./index";
import Select from "react-select";
import { patchData } from "../api/apiCalls";

const stageValues = ["presentationscheduled", "appointmentscheduled"];

export const ATTENDEE_COLUMNS = [
  {
    Header: "Source",
    Footer: "Source",
    accessor: "source",
    filter: "includesSome",
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
    filter: "includesSome",
  },

  {
    Header: "First Name",
    Footer: "First Name",
    accessor: "firstname",
    filter: "includesSome",
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "lastname",
    filter: "includesSome",
  },

  {
    Header: "Company",
    Footer: "Company",
    accessor: "company",
    filter: "includesSome",
  },
  {
    Header: "Role",
    Footer: "Role",
    accessor: "role",
    filter: "includesSome",
  },
  {
    Header: "Bio",
    Footer: "Bio",
    accessor: "bio",
    filter: "includesSome",
  },
  {
    Header: "Headshot",
    Footer: "Headshot",
    accessor: "headshot_url",
    filter: "includesSome",
  },
  // {
  //     Header: 'Company ID',
  //     Footer: 'Company ID',
  //     accessor: 'company_id',
  //     filter: 'includesSome',

  // },
  {
    Header: "Speaker Panel Contact",
    Footer: "Speaker Panel Contact",
    accessor: "speaker_panel_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);
      // console.log(row.original.id);
      let options = [
        "Venture Roadmap",
        "Coaching Panel",
        "Fund of Funds",
        "Family Offices",
        "Due Diligence",
        "Opening Panel",
        "Angel",
        "Startup to IPO",
        "Fin Tech",
        "Medtech Panel",
        "Term Sheets",
        "Fundable Deals",
        "Where's the Exit",
        "Corporate VC",
        "Accelerators",
        "Founders turned VCs",
        "Clean Tech",
        "LS/HC Checkup",
        "Female Funders",
        "European Investors",
        "Ed Tech",
        "Food Tech",
        "Alternative Capital",
        "workshop1",
        "workshop2",
        "workshop3",
        "workshop4",
        "workshop5",
        "workshop6",
        "workshop7",
        "workshop8",
        "workshop9",
        "workshop10",
        "workshop11",
        "workshop12",
        "workshop13",
        "workshop14",
        "workshop15",
      ];
      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }

        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  speaker_panel_contact: e.target.value,
                },
              };
              patchData(
                "https://youngstartup.io/api/db/attendees/update",
                editBody
              ).then((data) => console.log(data));
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              console.log(newValue);
              const editBody = {
                body: {
                  id: row.original.id,
                  speaker_panel_contact: e.target.value,
                },
              };
              patchData(
                "https://youngstartup.io/api/db/attendees/update",
                editBody
              ).then((data) => console.log(data));
            }}
          >
            <option value={""}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Final Judging Track",
    Footer: "Final Judging Track",
    accessor: "final_judging_track_placement_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = [
        "Tech",
        "Life Sciences/Healthcare",
        "Clean Tech",
        "Fin Tech",
        "Seed",
        "Medtech",
        "Consumer Products",
      ];

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  final_judging_track_placement_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  final_judging_track_placement_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={""}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Coaching Contact",
    Footer: "Final Coaching Contact",
    accessor: "final_coaching_track_placement_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = ["Tech", "Life Sciences/Healthcare", "Clean Tech", "Fin Tech", "Medtech", "Consumer Products"]

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  final_coaching_track_placement_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  final_coaching_track_placement_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={""}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Presenting Track",
    Footer: "Final Presenting Track",
    accessor: "final_presenting_track",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = ["Tech", "Life Sciences/Healthcare", "Clean Tech", "Fin Tech", "Medtech", "Consumer Products", "Seed"];

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  final_presenting_track: e.target.value,
                },
              };
              console.log(editBody);
              // patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data=>console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  final_presenting_track: e.target.value,
                },
              };
              console.log(editBody);
              // patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data=>console.log(data))
            }}
          >
            <option value={""}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Presenting Round",
    Footer: "Presenting Round",
    accessor: "presenting_round",
    filter: "includesSome",
  },
  {
    Header: "Presenting Order Slot",
    Footer: "Presenting Order Slot",
    accessor: "presenting_order_slot",
    filter: "includesSome",
  },
  {
    Header: "Coaching Room",
    Footer: "Coaching Room Number",
    accessor: "coaching_room_number",
    filter: "includesSome",
  },
  {
    Header: "Code",
    Footer: "Code",
    accessor: "code",
    filter: "includesSome",
  },
  {
    Header: "Meetmatch Role",
    Footer: "Meetmatch Role",
    accessor: "meetmatch_role",
    filter: "includesSome",
  },
  // {
  //     Header: 'Meetmatch Matchmaking',
  //     Footer: 'Meetmatch Matchmaking',
  //     accessor: 'meetmatch_matchmaking',
  //     filter: 'includesSome',
  // },
  {
    Header: "Matchmaking Type",
    Footer: "Matchmaking Type",
    accessor: "matchmaking_type",
    filter: "includesSome",
  },
  {
    Header: "Matchmaking Signup",
    Footer: "Matchmaking Signup",
    accessor: "matchmaking_signup",
    filter: "includesSome",
  },
  {
    Header: "Email Confirmed",
    Footer: "Email Confirmed",
    accessor: "email_confirmed",
  },
  {
    Header: "Opened Dashboard",
    Footer: "Opened Dashboard",
    accessor: "opened_dashboard",
  },
  {
    Header: "Emails Sent (count)",
    Footer: "Emails Sent (count)",
    accessor: "emails_sent",
  },
];

export const SPEAKERS_COLUMNS = [
  {
    Header: "Notes",
    Footer: "Notes",
    accessor: "notes",
  },
  {
    Header: "Display on Website",
    Footer: "Display on Website",
    accessor: "speaker_display",
  },
  {
    Header: "Role",
    Footer: "Role",
    accessor: "speaker_role",
    filter: "includesSome",
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
    filter: "includesSome",
  },

  {
    Header: "First Name",
    Footer: "First Name",
    accessor: "firstname",
    filter: "includesSome",
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "lastname",
    filter: "includesSome",
  },
  {
    Header: "Job Title",
    Footer: "Job Title",
    accessor: "jobtitle",
    filter: "includesSome",
  },
  {
    Header: "Company",
    Footer: "Company",
    accessor: "company",
    filter: "includesSome",
  },
  {
    Header: "Company Website",
    Footer: "Company Website",
    accessor: "website",
    filter: "includesSome",
  },
  {
    Header: "Bio",
    Footer: "Bio",
    accessor: "bio",
  },
  {
    Header: "Headshot",
    Footer: "Headshot",
    accessor: "headshot_url",
  },
  {
    Header: "Panel Choice 1",
    Footer: "Panel Choice 1",
    accessor: "panel_choice_1_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = ["Coaching Panel", "Opening Panel", "Angel", "Medtech Panel", "Clean Tech", "Fundable Deals", "Term Sheets", "Fin Tech", "Corporate VC", "LS/HC Checkup", "Where's the Exit", "Any", "Venture Roadmap", "Female Funders", "Fund of Funds", "Family Offices", "Due Diligence", "Startup to IPO", "Accelerators", "Founders turned VCs", "European Investors", "Ed Tech", "Food Tech", "Alternative Capital"]

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  panel_choice_1_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  panel_choice_1_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={""}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },

  },
  {
    Header: "Panel Choice 2",
    Footer: "Panel Choice 2",
    accessor: "panel_choice_2_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = ["Coaching Panel", "Opening Panel", "Angel", "Medtech Panel", "Clean Tech", "Fundable Deals", "Term Sheets", "Fin Tech", "Corporate VC", "LS/HC Checkup", "Where's the Exit", "Any", "Venture Roadmap", "Female Funders", "Fund of Funds", "Family Offices", "Due Diligence", "Startup to IPO", "Accelerators", "Founders turned VCs", "European Investors", "Ed Tech", "Food Tech", "Alternative Capital"]

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  panel_choice_2_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  panel_choice_2_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={""}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Panel Choice 3",
    Footer: "Panel Choice 3",
    accessor: "panel_choice_3_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = ["Coaching Panel", "Opening Panel", "Angel", "Medtech Panel", "Clean Tech", "Fundable Deals", "Term Sheets", "Fin Tech", "Corporate VC", "LS/HC Checkup", "Where's the Exit", "Any", "Venture Roadmap", "Female Funders", "Fund of Funds", "Family Offices", "Due Diligence", "Startup to IPO", "Accelerators", "Founders turned VCs", "European Investors", "Ed Tech", "Food Tech", "Alternative Capital"]

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  panel_choice_3_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  panel_choice_3_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={""}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },

  },
  {
    Header: "Final Speaker Panel",
    Footer: "Final Speaker Panel",
    accessor: "speaker_panel_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);
      // console.log(row.original.id);
      let options = [
        "Venture Roadmap",
        "Coaching Panel",
        "Fund of Funds",
        "Family Offices",
        "Due Diligence",
        "Opening Panel",
        "Angel",
        "Startup to IPO",
        "Fin Tech",
        "Medtech Panel",
        "Term Sheets",
        "Fundable Deals",
        "Where's the Exit",
        "Corporate VC",
        "Accelerators",
        "Founders turned VCs",
        "Clean Tech",
        "LS/HC Checkup",
        "Female Funders",
        "European Investors",
        "Ed Tech",
        "Food Tech",
        "Alternative Capital",
        "workshop1",
        "workshop2",
        "workshop3",
        "workshop4",
        "workshop5",
        "workshop6",
        "workshop7",
        "workshop8",
        "workshop9",
        "workshop10",
        "workshop11",
        "workshop12",
        "workshop13",
        "workshop14",
        "workshop15",
      ];
      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }

        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  speaker_panel_contact: e.target.value,
                },
              };
              patchData(
                "https://youngstartup.io/api/db/attendees/update",
                editBody
              ).then((data) => console.log(data));
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              console.log(newValue);
              const editBody = {
                body: {
                  id: row.original.id,
                  speaker_panel_contact: e.target.value,
                },
              };
              patchData(
                "https://youngstartup.io/api/db/attendees/update",
                editBody
              ).then((data) => console.log(data));
            }}
          >
            <option value={""}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Final Judging Track",
    Footer: "Final Judging Track",
    accessor: "final_judging_track_placement_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = [
        "Tech",
        "Life Sciences/Healthcare",
        "Clean Tech",
        "Fin Tech",
        "Seed",
        "Medtech",
        "Consumer Products",
      ];

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  final_judging_track_placement_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  final_judging_track_placement_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={""}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Final Coaching Track",
    Footer: "Final Coaching Track",
    accessor: "final_coaching_track_placement_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = ["Tech", "Life Sciences/Healthcare", "Clean Tech", "Fin Tech", "Medtech", "Consumer Products"]

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  final_coaching_track_placement_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  final_coaching_track_placement_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={""}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Judging Choice 1",
    Footer: "Judging Choice 1",
    accessor: "judging_track_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = ["Tech", "Life Sciences/Healthcare", "Clean Tech", "Fin Tech", "Ed Tech", "Seed", "Medtech", "Consumer Products"]

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  judging_track_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  judging_track_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={"--None--"}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Judging Choice 2",
    Footer: "Judging Choice 2",
    accessor: "judging_track_2_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = ["Tech", "Life Sciences/Healthcare", "Clean Tech", "Fin Tech", "Ed Tech", "Seed", "Medtech", "Consumer Products"]

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  judging_track_2_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  judging_track_2_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={"--None--"}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Coaching Choice 1",
    Footer: "Coaching Choice 1",
    accessor: "coaching_track_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = ["Coaching Panel", "Opening Panel", "Angel", "Medtech Panel", "Clean Tech", "Fundable Deals", "Term Sheets", "Fin Tech", "Corporate VC", "LS/HC Checkup", "Where's the Exit", "Any", "Venture Roadmap", "Female Funders", "Fund of Funds", "Family Offices", "Due Diligence", "Startup to IPO", "Accelerators", "Founders turned VCs", "European Investors", "Ed Tech", "Food Tech", "Alternative Capital"]

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  coaching_track_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  coaching_track_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={"--None--"}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Coaching Choice 2",
    Footer: "Coaching Choice 2",
    accessor: "coaching_track_2_contact",
    filter: "includesSome",
    Cell: ({ value, row }) => {
      const [newValue, setNewValue] = useState(value);

      let options = ["Coaching Panel", "Opening Panel", "Angel", "Medtech Panel", "Clean Tech", "Fundable Deals", "Term Sheets", "Fin Tech", "Corporate VC", "LS/HC Checkup", "Where's the Exit", "Any", "Venture Roadmap", "Female Funders", "Fund of Funds", "Family Offices", "Due Diligence", "Startup to IPO", "Accelerators", "Founders turned VCs", "European Investors", "Ed Tech", "Food Tech", "Alternative Capital"]

      if (value !== null) {
        for (let i = 0; i < options.length; i++) {
          if (value == options[i]) {
            delete options[i];
          }
        }
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);
              const editBody = {
                body: {
                  id: row.original.id,
                  coaching_track_2_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={value}>{value}</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      } else {
        return (
          <select
            value={newValue}
            onChange={(e) => {
              setNewValue(e.target.value);

              const editBody = {
                body: {
                  id: row.original.id,
                  coaching_track_2_contact: e.target.value,
                },
              };
              console.log(editBody);
              patchData("https://youngstartup.io/api/db/attendees/update", editBody).then(data => console.log(data))
            }}
          >
            <option value={"--None--"}>Select...</option>
            {options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    },
  },
  {
    Header: "Region",
    Footer: "Region",
    accessor: "vc_investment_location_s_",
    filter: "includesSome",
  },
  {
    Header: "Region that they say",
    Footer: "Region that they say",
    accessor: "vc_company_region__provided_by_the_investor_themselves_",
    filter: "includesSome",
  },
  {
    Header: "Coaching Room",
    Footer: "Coaching Room Number",
    accessor: "coaching_room_number",
    filter: "includesSome",
  },
  {
    Header: "Brella Code",
    Footer: "Brella Code",
    accessor: "code",
    filter: "includesSome",
  },
  {
    Header: "Matchmaking Signup",
    Footer: "Matchmaking Signup",
    accessor: "matchmaking_signup",
    filter: "includesSome",
  },
  {
    Header: "Email Confirmed",
    Footer: "Email Confirmed",
    accessor: "email_confirmed",
  },
  {
    Header: "Opened Dashboard",
    Footer: "Opened Dashboard",
    accessor: "opened_dashboard",
  },
  {
    Header: "Emails Sent (count)",
    Footer: "Emails Sent (count)",
    accessor: "emails_sent",
  },
  {
    Header: "Hubspot Contact ID",
    Footer: "Hubspot Contact ID",
    accessor: "hs_id",
  }
];

export const CONTACT_COLUMNS = [
  {
    Header: "First Name",
    Footer: "First Name",
    accessor: "properties.firstname",
    filter: "includesSome",
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "properties.lastname",
    filter: "includesSome",
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "properties.email",
    filter: "includesSome",
  },
  {
    Header: "Company",
    Footer: "Company",
    accessor: "properties.company",
    filter: "includesSome",
  },
  {
    Header: "ID",
    Footer: "ID",
    accessor: "id",
    Cell: ({ value }) => value,
    filter: "includesSome",
  },
  {
    Header: "Date Joined",
    Footer: "Date Joined",
    accessor: "properties.createdate",
    filter: "includesSome",
    Cell: ({ value }) => {
      return (
        <div style={{ textAlign: "center" }}>
          {format(new Date(value), "dd/MM/yyyy")}
        </div>
      );
    },
  },
];

export const WISH_COLUMNS = [
  {
    Header: "First Name",
    Footer: "First Name",
    filter: "includesSome",
    accessor: "properties.firstname",
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "properties.lastname",
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "properties.email",
  },
  // {
  //     Header: 'Company',
  //     Footer: 'Company',
  //     accessor: 'properties.company',

  // },
  // {
  //     Header: 'ID',
  //     Footer: 'ID',
  //     accessor: 'id',
  //     Cell: ({value}) => value

  // },
  {
    Header: "Date Joined",
    Footer: "Date Joined",
    accessor: "properties.createdate",
    Cell: ({ value }) => {
      return (
        <div style={{ textAlign: "center" }}>
          {format(new Date(value), "dd/MM/yyyy")}
        </div>
      );
    },
  },
];

export const NESTED_COLUMNS = [
  {
    Header: "First Name",
    Footer: "First Name",
    accessor: "properties.firstname",
    Cell: ({ value }) => value,
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "properties.lastname",
    Cell: ({ value }) => value,
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "properties.email",
    Cell: ({ value }) => value,
  },
  {
    Header: "ID",
    Footer: "ID",
    accessor: "id",
    Cell: ({ value }) => value,
  },
  {
    Header: "Date Joined",
    Footer: "Date Joined",
    accessor: "properties.createdate",
    Cell: ({ value }) => {
      return (
        <div style={{ textAlign: "center" }}>
          {format(new Date(value), "dd/MM/yyyy")}
        </div>
      );
    },
  },
];

export const GROUPED_COLUMNS = [
  {
    Header: "Name",
    Footer: "Name",
    columns: [
      {
        Header: "First Name",
        Footer: "First Name",
        accessor: "properties.firstname",
      },
      {
        Header: "Last Name",
        Footer: "Last Name",
        accessor: "properties.lastname",
      },
    ],
  },
  {
    Header: "Info",
    Footer: "Info",
    columns: [
      {
        Header: "Email",
        Footer: "Email",
        accessor: "properties.email",
      },
      {
        Header: "Date Joined",
        Footer: "Date Joined",
        accessor: "properties.createdate",
        Cell: ({ value }) => {
          return format(new Date(value), "dd/MM/yyyy");
        },
      },
      {
        Header: "ID",
        Footer: "ID",
        accessor: "id",
      },
    ],
  },
];

export const COMPANY_COLUMNS = [
  {
    Header: "Name",
    Footer: "Name",
    accessor: "properties.name",
  },
  {
    Header: "Domain",
    Footer: "Domain",
    accessor: "properties.website",
  },
  {
    Header: "Associated Contacts",
    Footer: "Addociated Contacts",
    accessor: "properties.num_associated_contacts",
  },
  {
    Header: "ID",
    Footer: "ID",
    accessor: "id",
  },
  {
    Header: "Date Joined",
    Footer: "Date Joined",
    accessor: "properties.createdate",
    Cell: ({ value }) => {
      return (
        <div style={{ textAlign: "center" }}>
          {format(new Date(value), "dd/MM/yyyy")}
        </div>
      );
    },
  },
];

export const DEAL_COLUMNS = [
  {
    Header: "Name",
    Footer: "Name",
    accessor: "properties.dealname",
  },
  {
    Header: "Stage",
    Footer: "Stage",
    accessor: "properties.dealstage",
    Cell: ({ value }) => {
      return (
        <select style={{ backgroundColor: "inherit", color: "white" }}>
          {stageValues.map((stage) => {
            let optionStr;
            switch (stage) {
              case "presentationscheduled":
                optionStr = "Presentation Scheduled";
                break;
              case "appointmentscheduled":
                optionStr = "Appointment Scheduled";
                break;
            }
            if (value == stage) {
              return (
                <option
                  selected
                  style={{ backgroundColor: "#212529" }}
                  value={value}
                >
                  {optionStr}
                </option>
              );
            } else {
              return (
                <option style={{ backgroundColor: "#212529" }} value={value}>
                  {optionStr}
                </option>
              );
            }
          })}
        </select>
      );
    },
  },
  {
    Header: "Amount",
    Footer: "Amount",
    accessor: "properties.amount",
    Cell: ({ value }) => {
      return `$${value}`;
    },
  },
  {
    Header: "ID",
    Footer: "ID",
    accessor: "id",
  },
  {
    Header: "Date Created",
    Footer: "Date Joined",
    accessor: "properties.createdate",
    Cell: ({ value }) => {
      return (
        <div style={{ textAlign: "center" }}>
          {format(new Date(value), "dd/MM/yyyy")}
        </div>
      );
    },
  },
];

export const COMPANY_LIST_COLUMNS = [
  {
    Header: "Name",
    Footer: "Name",
    accessor: "name",
  },
  {
    Header: "Size",
    Footer: "Size",
    accessor: "size",
  },
  {
    Header: "Type",
    Footer: "Type",
    accessor: "type",
  },
  {
    Header: "Last Updated",
    Footer: "Last Updated",
    accessor: "last_updated",
  },
  {
    Header: "Creator",
    Footer: "Creator",
    accessor: "creator",
  },
  {
    Header: "Folder",
    Footer: "Folder",
    accessor: "Folder",
  },
];
