import { Form } from "react-bootstrap";
import Button from '@mui/material/Button'
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Input from "@mui/material/Input";
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';



export const RecruiterContactForm = props => {
    const [dataArr, setDataArr] = useState([]);
  const [contactInputs, setContactInputs] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
  });
  const [emailVal, setEmailVal] = useState('none');

  const formData = {
    contact: {
      properties: contactInputs
    },
    company: {
      id: props.companyId
    }
  };

  console.log(formData)

  const submitBtn = (
  <button type="submit" class="form-btn btn btn-primary">Add Contact</button>
  );

  const handleContactChange = (e) => {
    setContactInputs({
      ...contactInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const re = /\S+@\S+\.\S+/;
    const rePhone = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    const reName = /^[a-z ,.'-]+$/i;
    const reUrl = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ig;

    if(re.test(contactInputs.email) == false){
      setEmailVal('block');
      setTimeout(()=>{ setEmailVal('none'); }, 2000);
      // setTimeout(()=>{ setEmailVal('none'); }, 6000);

    // }
    // if(reUrl.test(inputs.website) == false){
    //   setSiteVal('block');
    //   setTimeout(()=>{ setSiteVal('none'); }, 6000);
    // }
    // if(reName.test(inputs.firstname) == false){
    //   setFirstVal('block');
    //   setTimeout(()=>{ setFirstVal('none'); }, 6000);
    // }
    // if(reName.test(inputs.lastname) == false){
    //   setLastVal('block');
    //   setTimeout(()=>{ setLastVal('none'); }, 6000);
    // }
    // if(rePhone.test(inputs.phone) == false){
    //   setPhoneVal('block');
    //   setTimeout(()=>{ setPhoneVal('none'); }, 6000);
      return
    }

    async function postData(url) {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          tok: localStorage.tok,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(formData),
      });

      return response.json();
    }

    const myPromise = postData(
      `https://youngstartup.io/api/hs/contacts/addnewcontactwithcompany`,
      formData
    )
      .then((data) => {
        console.log(data);
        props.setContactForm("none");
        return data;
      })
      .then((data) => {
        console.log(data)
        if (data.status && data.status == "error") {
          console.error(data.message);

          throw new Error(data.message);
        }
        // update the state with new set
        
      })
      
      .then(() => {
        // empty inputs of form
        let emptyInputs = Object.fromEntries(Object.keys(contactInputs).map((key) => [key, '']));
        setContactInputs(emptyInputs)
      }).then(()=> props.setHideBtns('inline'))

    toast.promise(
      myPromise,
      {
        loading: "Loading",
        success: () => `Successfully added!!`,
        error: (err) => `This just happened: ${err.toString()}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
        },
      }
    ).then(()=>props.getData(
      `https://youngstartup.io/api/hs-lists/getcontactlistcompanies/1?getall=true`
    ))
    .then((data) => {
      props.setDataArr(data.results);
    })
    setTimeout(()=>props.setHideBtns('inline-block'),2000)
  };

  //console.log(siteVal)

  async function getData(url) {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json();
  }

  useEffect(()=>{
    getData(`https://youngstartup.io/api/hs/companies/all`)
    .then(data=>setDataArr(data.results))
  }, [])

  console.log(dataArr)

  const itemArr = []
  dataArr.map((x,i)=>{
    const newObj = {}
    newObj.id=x.id;
    newObj.name=x.properties.name
    itemArr.push(newObj)
  })

  console.log(itemArr)

  return (
    <>
      <Form
        style={{ display: `${props.contactForm}` }}
        onSubmit={handleSubmit}
        onKeyDown={(e)=>{if (e.code == "NumpadEnter" || e.code == "Enter") {
          e.preventDefault();
        }}}
        encType="multipart/form-data"
        className="contact-form"
      >
        <a
          onClick={()=>{props.setContactForm('none');props.setHideBtns('inline-block')}}
          style={{
            marginLeft: "80%",
            marginBottom: "2rem !important",
            cursor: "pointer",
          }}
          className="close-btn"
        >
          <CloseIcon />
        </a>
        <div style={{border:'1px solid white', padding:'1rem', borderRadius:'10px'}}>
        <Form.Group className="mb-3">
        <InputLabel>First Name</InputLabel>
          <Input
            name="firstname"
            value={contactInputs.firstname}
            onChange={handleContactChange}
            type="text"
          />
          {/* <div style={{color:'red', textAlign:'center', position:'absolute', display:firstVal}}>please enter a valid first name</div> */}
        </Form.Group>

        <Form.Group className="mb-3">
        <InputLabel>Last Name</InputLabel>
          <Input
            name="lastname"
            value={contactInputs.lastname}
            onChange={handleContactChange}
            type="text"
          />
           {/* <div style={{color:'red', textAlign:'center', position:'absolute', display:lastVal}}>please enter a valid last name</div> */}
        </Form.Group>

        <Form.Group className="mb-3">
        <InputLabel>Email</InputLabel>
          <Input
            name="email"
            value={contactInputs.email}
            onChange={handleContactChange}
            type="text"
          />
          <div style={{color:'red', textAlign:'center', position:'absolute', display:emailVal, top:'43%'}}>please enter a valid email</div>
        </Form.Group>

        <Form.Group className="mb-3">
        <InputLabel>Phone Number</InputLabel>
          <Input

            name="phone"
            value={contactInputs.phone}
            onChange={handleContactChange}
            type="text"
          />
          {/* <div style={{color:'red', textAlign:'center', position:'absolute', display:phoneVal}}>please enter a valid phone number</div> */}
        </Form.Group>
        {/* <Form.Group className="mb-3">
        <InputLabel>Website URL</InputLabel>
          <Input
            name="website"
            value={contactInputs.website}
            onChange={handleChange}
            type="text"          
          />
          <div style={{color:'red', textAlign:'center', position:'absolute', display:siteVal}}>please enter a valid website</div> 
        </Form.Group> */}
        </div>
        <div className="form-btn-container">
        <Button
      color="primary"
      variant="contained"
      style={{marginTop:'4rem'}}
      className="form-btn"
      onClick={handleSubmit}
      type="submit"
    >
      Add Contact
    </Button>
        </div>
      </Form>
    </>
  );
};
