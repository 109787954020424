import jwt_decode from "jwt-decode";

// capitilize first letter of each word
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


// localstorage token functions
export const setLocalTok = (tok) => {
  localStorage.setItem("tok", tok);
  let decoded = jwt_decode(tok);
  localStorage.expires = decoded.exp * 1000;
}

export const unsetLocalTok = (tok) => {
  localStorage.removeItem("tok");
}

export const getLocalTok = (tok) => {
  return localStorage.tok || "";
}

export const validateTok = async () => {
  const response = await fetch(`https://youngstartup.io/api/veifytok`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      tok: localStorage.tok,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
  return response.json() || "";
}


// fetch functions
export const getArrDataAsync = async (arr) => {

  for (let i = 0; i < arr.length; i++) {
    let data = await fetch(arr[i].url, {
      method: arr[i].method,
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    data = await data.json();
    // setDataArr(old => [...old, ...data]);
  }
}

// export const getDataAsync = async (arr) => {

//      let data = await fetch(arr[i].url, {
//             method: arr[i].method,
//             mode: "cors",
//             headers: {
//               "Content-Type": "application/json",
//               tok: localStorage.tok,
//             },
//             redirect: "follow",
//             referrerPolicy: "no-referrer",
//           });
//       return await data.json();
// }

// update params of URL
export const addOrUpdateQueryParam = (key, value) => {
  let searchParams = new URLSearchParams(window.location.search);
  if (!value) {
    searchParams.delete(key);
  } else {
    searchParams.set(key, value);
  }
  let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
  window.history.pushState({ path: newurl }, '', newurl);
}

// gets params from URL
export const getQueryParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}


export const debounce = (func, delay) => {
  let debounceTimer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(debounceTimer)
    debounceTimer
      = setTimeout(() => func.apply(context, args), delay)
  }
}
export const applyCustomOrder = (arr, desiredOrder) => {
  const orderForIndexVals = desiredOrder.slice(0).reverse();
  arr.sort((a, b) => {
    const aIndex = -orderForIndexVals.indexOf(a);
    const bIndex = -orderForIndexVals.indexOf(b);
    return aIndex - bIndex;
  });
}


export const parseDateForDBFormat = (dateObj = false, dateStr = false, timeStr = false) => {
  if (!dateObj && (dateStr && timeStr)) {
    dateObj = new Date(Date.parse(dateStr + " " + timeStr))
  }
  return dateObj.getFullYear() + "-" + Number(dateObj.getMonth() + 1) + "-" + dateObj.getDate() + " " + dateObj.toLocaleTimeString('it-IT');
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
}

export function getDates(startDate, stopDate) {
  var dateArray = [];
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
}

export function formatDateForBizza(dateVal) {
  var newDate = new Date(dateVal);

  var sMonth = padValue(newDate.getMonth() + 1);
  var sDay = padValue(newDate.getDate());
  var sYear = newDate.getFullYear();
  var sHour = newDate.getHours();
  var sMinute = padValue(newDate.getMinutes());
  var sAMPM = "AM";

  var iHourCheck = parseInt(sHour);

  if (iHourCheck >= 12) {
    sAMPM = "PM";
    if (iHourCheck > 12) {
      sHour = iHourCheck - 12;
    }
  }
  else if (iHourCheck === 0) {
    sHour = "12";
  }

  sHour = padValue(sHour);

  return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
}

function padValue(value) {
  return (value < 10) ? "0" + value : value;
}

// export function abbreviateNumber(value) {
//   var newValue = value;
//   if (value >= 1000) {
//       var suffixes = ["", "k", "m", "b","t"];
//       var suffixNum = Math.floor( ("" + value).length / 3 );
//       var shortValue = '';
//       for (var precision = 2; precision >= 1; precision--) {
//           shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
//           var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
//           if (dotLessShortValue.length <= 2) { break; }
//       }
//       if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
//       newValue = shortValue+suffixes[suffixNum];
//   }
//   return newValue;
// }

let ranges = [
  { divider: 1e18, suffix: 'E' },
  { divider: 1e15, suffix: 'P' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'K' }
];

export function abbreviateNumber(n) {
  if (isNaN(n) || n === null) {
    return " ";
  }
  for (let i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      const shortNum = Number((n / ranges[i].divider).toFixed(2));
      const response = Number.isInteger(shortNum) ? parseInt(shortNum) : shortNum;
      return response + " " + ranges[i].suffix;
      //returns e.g. "1.5 M"
    }
  }
  return n.toString();
}