import { set } from 'date-fns';
import { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import TextField from '@mui/material/TextField';

export const GlobalFilter2 = ({ filter, setFilter }) => {
    const [value, setValue] = useState(filter);

    const onChange = useAsyncDebounce(value=>{
        setFilter(value || undefined)
    }, 500);

        return (
            <span style={{position:'relative'}}>
                <span>
                    <TextField value={value || ''}
                    id="outlined-basic" 
                    label="Search" 
                    variant="outlined"
                    style={{color:'white !important'}}
                    placeholder='Search'
                    onChange={e=>{
                    setValue(e.target.value)
                    onChange(e.target.value.trim())}}
                    />
                </span>
            </span>
        )
}