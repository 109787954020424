import {
  WishContactTable,
  NavBar,
  WishCompanyTable,
  WishDealTable,
} from "../components/index";
import { Tabs, Tab } from "react-bootstrap";

export const WishList = () => {
  return (
    <>
      <NavBar />
      <Tabs
        defaultActiveKey="contacts"
        className="mb-3"
        style={{ color: "white !important" }}
      >
        <Tab eventKey="contacts" title="Contacts">
          <WishContactTable />
        </Tab>
        <Tab eventKey="companies" title="Companies">
          <WishCompanyTable />
        </Tab>
        <Tab eventKey="deals" title="Deals">
          <WishDealTable />
        </Tab>
      </Tabs>
    </>
  );
};
