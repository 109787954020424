//REACT IMPORTS
import { useCallback, useEffect, useRef, useState } from "react";

// MUI IMPORTS
import AddIcon from "@mui/icons-material/Add";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { DialogActions } from "@mui/material";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/system";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";

//REACT-DND IMPORTS
import update from "immutability-helper";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { v4 as uuidv4 } from "uuid";

// LOCAL IMPORTS
import { HoverButton, MuiAppBar } from "../components/index";
import { getData, postData, putData } from "../api/apiCalls";

const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaders": { display: "none" },
  "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
  "& .MuiDataGrid": { border: "none" },
  "& .MuiDataGrid-cell": { border: "none" },
  border: "none",
}));

const styles = {
  color: "white",
  minWidth: "fit-content",
  maxWidth: "100%",
  input: { color: "white", fontSize: "1rem" },
  textarea: { color: "white" },
  "&": {
    color: "white !important",
    backgroundColor: "#303265",
    borderRadius: "5px",
    minWidth: "fit-content",
    // position:'absolute'
  },
  "& label": {
    color: "transparent",
  },
  "& label.Mui-focused": {
    color: "transparent",
  },
  "& .MuiInputBase-root": {
    "& fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
      color: "white",
    },
  },
};

const styles2 = {
  color: "white",
  minWidth: "fit-content",

  maxWidth: "100%",
  input: { color: "white", fontSize: ".9rem" },
  textarea: { color: "white" },
  "&": {
    color: "white !important",
    backgroundColor: "#000120",
    borderRadius: "5px",
    minWidth: "fit-content",
  },
  "& label": {
    color: "transparent",
  },
  "& label.Mui-focused": {
    color: "transparent",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "white",
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    color: "white",
  },
  "& .MuiInputBase-root": {
    "& fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
      color: "white",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "& .MuiSvgIcon-root-MuiSelect-icon": {
      fill: "white",
    },
    "& .MuiPaper-root": {
      backgroundColor: "lightblue !important",
    },
  },
};

const MuiToggle = ({ cell }) => {
  const [selected, setSelected] = useState(
    cell.row.display_status == "yes" ? true : false
  );

  return (
    <ToggleButton
      value="check"
      selected={selected}
      sx={{ bgcolor: selected ? "green !important" : "red !important" }}
      onChange={() => {
        setSelected(!selected);
        let body = {
          body: {
            id: cell.row.id,
            display_status: selected ? "no" : "yes",
          },
        };
        putData(`https://youngstartup.io/api/dbt/sponsors/update`, body).then(
          (data) => console.log(data)
        );
      }}
    />
  );
};

export const Sponsors = () => {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [newSponsor, setNewSponsor] = useState({});
  const [modalSponsors, setModalSponsors] = useState([]);
  const [sponsorList, setSponsorList] = useState([]);
  const [companyLogo, setCompanyLogo] = useState("");
  const [headshot, setHeadshot] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [currentSponsorId, setCurrentSponsorId] = useState(null);
  const [currentSponsorDataId, setCurrentSponsorDataId] = useState(null);
  const [currentHeadshotUrl, setCurrentHeadshotUrl] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [currentSponsor, setCurrentSponsor] = useState(false);
  const [currentSponsorType, setCurrentSponsorType] = useState("");
  const [error, setError] = useState(false);
  const [companyInputs, setCompanyInputs] = useState({
    logo_url: "",
    name: "",
    sponsor_type: "",
    display_status: "",
    order_slot: "",
    team_members: "",
    description: "",
    notes: "",
  });
  const [teamMembers, setTeamMembers] = useState([]);
  const [keys, setKeys] = useState([]);
  const [ddOptions, setDdOptions] = useState({});
  const [expanded, setExpanded] = useState({
    description: false,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentSponsor(false);
    setCompanyLogo("");
    setExpanded({
      description: false,
    });
    setTeamMembers([]);
    // setTeamMembers([
    //   {
    //     firstname: "",
    //     lastname: "",
    //     jobtitle: "",
    //     headshot_url: "",
    //     id: uuidv4(),
    //   },
    // ]);
    setCompanyInputs({
      logo_url: "",
      name: "",
      website: "",
      sponsor_type: "",
      display_status: "",
      description: "",
      order_slot: "",
      team_members: "",
      notes: "",
    });
    refreshData();
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
    refreshData();
  };

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
    refreshData();
  };

  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
    refreshData();
  };

  const handleClickOpen5 = () => {
    setOpen5(true);
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  const handleClickOpen6 = () => {
    setOpen6(true);
  };

  const handleClose6 = () => {
    setOpen6(false);
  };

  const handleCompanyInputChange = (e) => {
    setCompanyInputs({
      ...companyInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = teamMembers.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setTeamMembers(newInputFields);
  };

  const handleAddFields = () => {
    setTeamMembers([
      ...teamMembers,
      {
        id: uuidv4(),
        firstName: "",
        lastName: "",
        jobtitle: "",
        linkedin: "",
        order_slot: teamMembers.length,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...teamMembers];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setTeamMembers(values);
  };

  const uploadLogo = async (e) => {
    console.log(e.target);
    if (e.target.files[0].size > 4194304) {
      alert("File is too big!");
      e.target.value = "";
      return;
    }
    const file = e.target.files[0];
    if (file) {
      const base64 = await convertBase64(file);
      let body = {
        body: {
          file_name: companyInputs.name
            .replaceAll(/[^A-Z|a-z|0-9]/g, "_")
            .toLowerCase(),
          path: "/sponsors/logos/",
          upload_file: base64,
        },
      };
      console.log(body);
      postData(
        `https://youngstartup.io/api/db-a/files/uploadfile_base_64`,
        body
      ).then((data) => {
        setCompanyLogo(data.link);
      });
    }
  };

  const uploadHeadshot = async (e) => {
    console.log(e.target);
    if (e.target.files[0]?.size > 4194304) {
      alert("File is too big!");
      e.target.value = "";
      return;
    }

    const file = e.target.files[0];

    if (file) {
      const base64 = await convertBase64(file);
      let body = {
        body: {
          file_name: "",
          path: "/sponsors/headshots",
          upload_file: base64,
        },
      };

      postData(
        `https://youngstartup.io/api/db-a/files/uploadfile_base_64`,
        body
      )
        .then((data) => setHeadshot(data.link))
        .then(() => console.log(headshot));
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const refreshData = () => {
    getData("https://youngstartup.io/api/db/sponsors/get_by_type")
      .then((data) => {
        console.log(data);
        setData(data);
        return data;
      })
      .then((data) => {
        setKeys(Object.keys(data));
      })
      .then(() => console.log(keys));

    getData(`https://youngstartup.io/api/dbt/sponsor_types/all`).then(
      (data) => {
        setDdOptions(data);
        setModalSponsors(data.sort((a, b) => a.order_slot - b.order_slot));
      }
    );
  };

  useEffect(() => {
    getData("https://youngstartup.io/api/db/sponsors/get_by_type")
      .then((data) => {
        console.log(data);
        setData(data);
        return data;
      })
      .then((data) => {
        console.log(data);
        setKeys(Object.keys(data));
      })
      .then(() => console.log(keys));

    getData(`https://youngstartup.io/api/dbt/sponsor_types/all`).then(
      (data) => {
        console.log(data);
        setDdOptions(data);
        setModalSponsors(data?.sort((a, b) => a.order_slot - b.order_slot));
      }
    );
  }, []);

  // useEffect(() => {
  //   if(!teamMembers.length){
  //     setTeamMembers((old) => [
  //       ...old,
  //       {
  //         firstname: "",
  //         lastname: "",
  //         jobtitle: "",
  //         headshot_url: "",
  //         id: uuidv4(),
  //       },
  //     ]);
  //   }
  // }, [teamMembers]);

  const ItemTypes = {
    CARD: "card",
  };

  const Card = ({ id, text, index, moveCard }) => {
    const [value, setValue] = useState(text);

    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
      accept: ItemTypes.CARD,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        moveCard(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: () => {
        return { id, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
      <div
        ref={ref}
        style={{
          display: "flex",
          backgroundColor: "#303265",
          padding: "2.5%",
          alignItems: "center",
          gap: "5%",
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
          width: "500px",
        }}
      >
        <img src={"assets/Vector (7).png"} style={{ width: "25px" }} alt="" />

        {open2 ? (
          <input
            value={value}
            style={{
              fontSize: "2rem",
              width: "100%",
              borderRadius: "25px",
              backgroundColor: "black",
              border: "none",
              color: "white",
              padding: "1%",
            }}
            onChange={(e) => {
              setValue(e.target.value);

              let modalSponsorsCopy = modalSponsors;

              modalSponsorsCopy.forEach((sponsor, i) => {
                if (index + 1 === sponsor.order_slot) {
                  sponsor.display_name = e.target.value;
                  sponsor.internal_name = e.target.value
                    .replaceAll(/[^A-Z|a-z|0-9]/g, "_")
                    .toLowerCase();
                  return;
                }
              });

              setModalSponsors(modalSponsorsCopy);
            }}
          />
        ) : (
          <h4 style={{ whiteSpace: "nowrap", fontSize: "2rem" }}>{text}</h4>
        )}
        {/* <div
          style={{ display: "flex", justifyContent: "right", width: "100%" }}
        >
          {
            <Fab
              sx={{ backgroundColor: "red !important" }}
              size="small"
              onClick={(e) => {
                deleteData(
                  open2
                    ? `https://youngstartup.io/api/dbt/sponsor_types/remove/${id}`
                    : `https://youngstartup.io/api/dbt/sponsors/remove/${id}`
                ).then((data) => {
                  if (data.success) {
                    setError(false);
                    handleClickOpen6();
                  } else {
                    setError(true);
                    handleClickOpen6();
                  }
                  if (data.success && open2) {
                    let modalSponsorsCopy = modalSponsors;
                    setModalSponsors(
                      modalSponsorsCopy.filter(
                        (sponsor, i) => sponsor.id !== id
                      )
                    );
                  } else if (data.success && open3) {
                    let sponsorListCopy = sponsorList;
                    setSponsorList(
                      sponsorListCopy.filter((sponsor, i) => sponsor.id !== id)
                    );
                  }

                  setTimeout(() => {
                    handleClose6();
                  }, 3000);
                });
              }}
            >
              <RemoveIcon />
            </Fab>
          }
        </div> */}
      </div>
    );
  };

  const SingleSponsorCard = ({ id, index, card, moveCard }) => {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
      accept: ItemTypes.CARD,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        moveCard(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: () => {
        return { id, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
      <div
        ref={ref}
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          width: "100%",
          gap: "10%",
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: "#303265",
          paddingLeft: "5%",
          marginTop: "2.5vh",
        }}
      >
        <img src={card.headshot_url} style={{ maxWidth: "100px" }} alt=""
        />
        <h1 style={{ color: "white", textAlign: "left" }}>
          {card.firstname} {card.lastname}
        </h1>
      </div>
    );
  };

  const IndividualContainer = () => {
    {
      const moveCard = useCallback((dragIndex, hoverIndex) => {
        setTeamMembers((prevCards) =>
          update(prevCards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevCards[dragIndex]],
            ],
          })
        );
      }, []);
      const renderCard = useCallback((card, index) => {
        return (
          <SingleSponsorCard
            key={card.id}
            index={index}
            id={card.id}
            card={card}
            moveCard={moveCard}
          />
        );
      }, []);
      return (
        <>
          <Stack spacing={2}>
            {teamMembers.map((card, i) => renderCard(card, i))}
          </Stack>
        </>
      );
    }
  };

  const Container = () => {
    {
      const moveCard = useCallback((dragIndex, hoverIndex) => {
        setModalSponsors((prevCards) =>
          update(prevCards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevCards[dragIndex]],
            ],
          })
        );
      }, []);

      const renderCard = useCallback((card, index) => {
        return (
          <Card
            key={card.id}
            index={index}
            id={card.id}
            text={card.display_name}
            moveCard={moveCard}
          />
        );
      }, []);

      return (
        <>
          <Stack spacing={2}>
            {modalSponsors.map((card, i) => renderCard(card, i))}
          </Stack>
        </>
      );
    }
  };

  const ContainerSponsor = () => {
    {
      const moveCard = useCallback((dragIndex, hoverIndex) => {
        setSponsorList((prevCards) =>
          update(prevCards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, prevCards[dragIndex]],
            ],
          })
        );
      }, []);

      const renderCard = useCallback((card, index) => {
        return (
          <Card
            key={card.id}
            index={index}
            id={card.id}
            text={card.name}
            moveCard={moveCard}
          />
        );
      }, []);

      return (
        <>
          <Stack spacing={2}>
            {sponsorList.map((card, i) => renderCard(card, i))}
          </Stack>
        </>
      );
    }
  };

  const MuiSelect = () => {
    const [value, setValue] = useState("");

    return (
      <Select
        value={
          companyInputs.sponsor_type
            ? companyInputs.sponsor_type
            : "Select Item"
        }
        sx={{
          "&": {
            color: "white !important",
            backgroundColor: "#303265",
            borderRadius: "5px",
            minWidth: "fit-content",
            fontSize: ".9rem",
            width: "70%",
          },
        }}
        onChange={handleCompanyInputChange}
        name={"sponsor_type"}

      // renderValue={(value) => <div style={{ color: "white" }}>{value}</div>}
      >
        <MenuItem value={"Select Item"} disabled>
          Select Item
        </MenuItem>
        {ddOptions?.map((option, i) => (
          <MenuItem key={i} value={option.internal_name}>
            {option.display_name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const columns = [
    {
      field: "logo_url",
      headerName: "Logo",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      flex: 1,
      customHeadRender: () => null,
      renderCell: (cell) => {
        return (
          <div style={{ display: "flex", gap: "5%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={"assets/Vector (7).png"}
                style={{ width: "15px", cursor: "pointer" }}
                onClick={() => {
                  setCurrentSponsorType(cell.row.sponsor_type);
                  setCurrentId(cell.row.id);
                  setSponsorList(
                    [...data[cell.row.sponsor_type]].sort(
                      (a, b) => a.order_slot - b.order_slot
                    )
                  );
                  handleClickOpen3();
                }}
                alt=""
              />
            </div>
            {cell.row.logo_url ? (
              <img
                src={cell.row.logo_url}
                onClick={(e) => {
                  console.log(cell.row);
                  setCurrentSponsorType(cell.row.sponsor_type);
                  setCurrentSponsor(true);
                  setCurrentSponsorId(cell.row.id);
                  setCompanyLogo(cell.row.logo_url);
                  setCompanyInputs({
                    logo_url: cell.row.logo_url,
                    name: cell.row.name,
                    id: cell.row.id ? cell.row.id : uuidv4(),
                    website: cell.row.website,
                    description: cell.row.description,
                    sponsor_type: cell.row.sponsor_type,
                    display_status: cell.row.display_status,
                    order_slot: cell.row.order_slot,
                    team_members: cell.row.team_members,
                    notes: cell.row.notes,
                  });
                  cell.row.team_members &&
                    setTeamMembers(JSON.parse(cell.row.team_members));
                  handleClickOpen();
                }}
                style={{
                  objectFit: "scale-down",
                  width: "300px",
                  height: "100px",
                  cursor: "pointer",
                }}
                alt=""
              />
            ) : (
              <div
                style={{
                  height: "100px",
                  width: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "gray",
                }}
                onClick={(e) => {
                  console.log(cell.row);
                  setCurrentSponsor(cell.row);
                  setCompanyLogo(cell.row.logo_url);
                  setCompanyInputs({
                    logo_url: cell.row.logo_url,
                    name: cell.row.name,
                    id: cell.row.id ? cell.row.id : uuidv4(),
                    website: cell.row.website,
                    sponsor_type: cell.row.sponsor_type,
                    display_status: cell.row.display_status,
                    description: cell.row.description,
                    order_slot: cell.row.order_slot,
                    team_members: cell.row.team_members,
                    notes: cell.row.notes,
                  });

                  handleClickOpen();
                }}
              >
                <InsertPhotoIcon sx={{ fill: "black", fontSize: "5rem" }} />
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      //   editable: true,
    },
    {
      field: "sponsor_type",
      headerName: "Type",
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      //   editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      flex: 1,
      renderCell: (cell) => {
        return <MuiToggle cell={cell} />;
      },
      //   editable: true,
    },
    {
      field: "notes",
      headerName: "Notes",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      flex: 1,
      //   editable: true,
    },
  ];

  const headerNames = ["Logo", "Name", "Type", "Status", "Notes"];

  const options = {
    customHeadRender: () => null,
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <MuiAppBar />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2.5%",
            padding: "2.5%",
          }}
        >
          <HoverButton
            initialUrl={"/assets/Property 1=Default.png"}
            hoverUrl={"/assets/Property 1=Variant2.png"}
            style={{ width: "50px", height: "50px", cursor: "pointer" }}
            onClick={handleClickOpen}
          />

          <HoverButton
            initialUrl={"/assets/Property 1=Default (1).png"}
            hoverUrl={"/assets/Property 1=Variant2 (1).png"}
            style={{ width: "50px", height: "50px", cursor: "pointer" }}
          />

          <HoverButton
            initialUrl={"/assets/Property 1=Default (2).png"}
            hoverUrl={"/assets/Property 1=Variant2 (2).png"}
            style={{ width: "50px", height: "50px", cursor: "pointer" }}
            onClick={(e) => {
              refreshData();
            }}
          />

          <div
            style={{
              border: "2px solid white",
              padding: ".75%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "white",
            }}
            onClick={handleClickOpen2}
          >
            Sponsor Types
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            paddingLeft: "1.5%",
          }}
        >
          {headerNames.map((name, i) => {
            return (
              <h2 style={{ textAlign: "center", color: "#F0AD4E" }}>{name}</h2>
            );
          })}
        </div>
        <div style={{ width: "100%", borderBottom: "1px solid #F0AD4E" }}></div>
        {keys.map((key, i) => {
          if (data[key]?.length > 0)
            return (
              <div
                style={{
                  borderTop: i > 0 && "1px solid white",
                  margin: "2.5vh 0vh",
                  padding: "2.5vh 0vh",
                }}
              >
                <DataGrid
                  key={i}
                  columns={columns}
                  rows={[...data[key]]?.sort(
                    (a, b) => a.order_slot - b.order_slot
                  )}
                  sx={{ color: "white" }}
                  autoHeight
                  options={options}
                  rowHeight={100}
                  disableSelectionOnClick
                  hideFooter
                />
              </div>
            );
        })}

        <Dialog
          open={open}
          maxWidth={"xl"}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            sx={{
              backgroundColor: "#020120",
              color: "white",
              width: "1200px !important",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "2.5vh",
                borderBottom: "1px solid gray",
              }}
            >
              <h2
                style={{
                  whiteSpace: "nowrap",
                  display: "flex",
                  alignItems: "center",
                  gap: "5%",
                }}
              >
                Add <MuiSelect /> Sponsor
              </h2>
              <div
                style={{ display: "flex", alignItems: "center", gap: "15%" }}
              >
                <HoverButton
                  initialUrl={"/assets/Property 1=Default (4).png"}
                  hoverUrl={"/assets/Property 1=Variant2 (4).png"}
                  onClick={handleClose}
                  style={{ width: "50px", height: "50px", cursor: "pointer" }}
                />
                <HoverButton
                  initialUrl={"/assets/Property 1=Default (5).png"}
                  hoverUrl={"/assets/Property 1=Variant2 (5).png"}
                  style={{ width: "50px", height: "50px", cursor: "pointer" }}
                  onClick={(e) => {
                    console.log(teamMembers);
                    console.log(teamMembers.length);
                    if (
                      companyInputs.name.trim() == "" ||
                      companyInputs.website.trim() == "" ||
                      (!companyLogo && !companyInputs.logo_url) ||
                      companyInputs.sponsor_type.trim() == ""
                    ) {
                      setError(true);
                      handleClickOpen6();
                      setTimeout(() => {
                        handleClose6();
                      }, 2000);
                      // }, 3000);
                      return;
                    }

                    teamMembers.forEach((member, i) => {
                      if (member.id == currentId && headshot) {
                        member.headshot_url = headshot;
                        setCurrentId(teamMembers[teamMembers.length - 1].id);
                        // console.log(currentId)
                        console.log(teamMembers);
                        setHeadshot("");
                        return;
                      }
                    });

                    let updatedInputs = {
                      ...companyInputs,
                      logo_url: companyLogo,
                      team_members: JSON.stringify(teamMembers),
                      id: companyInputs.id
                        ? companyInputs.id
                        : currentSponsorId,
                    };
                    let body = {
                      body: updatedInputs,
                    };

                    currentSponsor
                      ? putData(
                        `https://youngstartup.io/api/dbt/sponsors/update`,
                        body
                      ).then((data) => {
                        console.log(data);
                        if (data.success) {
                          //////////////
                          getData(
                            "https://youngstartup.io/api/db/sponsors/get_by_type"
                          ).then((data) => {
                            console.log(data);
                            setError(false);
                            handleClickOpen6();
                            setTimeout(() => {
                              handleClose6();
                            }, 2000);
                            // }, 4000);
                            data[`${currentSponsorType}`]?.forEach(
                              (sponsor, i) => {
                                if (sponsor.id === currentSponsorId) {
                                  setTeamMembers(
                                    JSON.parse(sponsor.team_members)
                                  );
                                  return;
                                }
                              }
                            );
                          });
                        } else {
                          setError(true);
                          handleClickOpen6();
                          setTimeout(() => {
                            handleClose6();
                          }, 2000);
                          // }, 4000);
                        }
                      })
                      : postData(
                        `https://youngstartup.io/api/dbt/sponsors/add`,
                        body
                      ).then((data) => {
                        console.log(data);
                        if (data.success) {
                          setError(false);
                          setCurrentSponsorId(data.data_id);
                          refreshData();
                          setCurrentSponsor(true);
                          handleClickOpen6();
                          console.log(currentSponsorDataId);
                          data[`${companyInputs.sponsor_type}`]?.forEach(
                            (sponsor, i) => {
                              console.log(sponsor.data_id);
                              if (sponsor.id == data.data_id) {
                                console.log("team members set!");
                                setCurrentSponsorId(data.data_id);
                                setTeamMembers(
                                  JSON.parse(sponsor.team_members)
                                );
                                setTimeout(() => {
                                  handleClose6();
                                }, 2000);
                                // }, 3000);
                              }
                            }
                          );
                        } else {
                          setError(true);
                          handleClickOpen6();
                          setTimeout(() => {
                            handleClose6();
                          }, 2000);
                          // }, 4000);
                        }
                      });
                    console.log(teamMembers);
                  }}
                />
                {/* <img
                  src={"/Save Button.png"}
                  onClick={(e) => {
                    console.log(teamMembers);
                    console.log(teamMembers.length)
                    if (teamMembers.length > 0 &&
                      (!teamMembers[teamMembers.length - 1]?.firstname ||
                      !teamMembers[teamMembers.length - 1]?.lastname ||
                      !teamMembers[teamMembers.length - 1]?.jobtitle ||
                      !companyInputs.name ||
                      !companyInputs.website ||
                      (!companyLogo && !companyInputs.logo_url) ||
                      !companyInputs.sponsor_type)
                    ) {
                      setError(true);
                      handleClickOpen6();
                      setTimeout(() => {
                        handleClose6();
                      }, 3000);
                      return;
                    }

                    teamMembers.forEach((member, i) => {
                      if (member.id == currentId && headshot) {
                        member.headshot_url = headshot;
                        setCurrentId(teamMembers[teamMembers.length - 1].id);
                        // console.log(currentId)
                        console.log(teamMembers);
                        setHeadshot("");
                        return;
                      }
                    });

                    let updatedInputs = {
                      ...companyInputs,
                      logo_url: companyLogo,
                      team_members: JSON.stringify(teamMembers),
                      id: companyInputs.id
                        ? companyInputs.id
                        : currentSponsorId,
                    };
                    let body = {
                      body: updatedInputs,
                    };

                    currentSponsor
                      ? putData(
                          `https://youngstartup.io/api/dbt/sponsors/update`,
                          body
                        ).then((data) => {
                          console.log(data);
                          if (data.success) {
                            //////////////
                            getData(
                              "https://youngstartup.io/api/db/sponsors/get_by_type"
                            ).then((data) => {
                              console.log(data);
                              setError(false);
                              handleClickOpen6();
                              setTimeout(() => {
                                handleClose6();
                              }, 4000);
                              data[`${currentSponsorType}`]?.forEach(
                                (sponsor, i) => {
                                  if (sponsor.id === currentSponsorId) {
                                    setTeamMembers(
                                      JSON.parse(sponsor.team_members)
                                    );
                                    return;
                                  }
                                }
                              );
                            });
                          } else {
                            setError(true);
                            handleClickOpen6();
                            setTimeout(() => {
                              handleClose6();
                            }, 4000);
                          }
                        })
                      : postData(
                          `https://youngstartup.io/api/dbt/sponsors/add`,
                          body
                        ).then((data) => {
                          console.log(data);
                          if (data.success) {
                            setError(false);
                            setCurrentSponsorId(data.data_id);
                            refreshData();
                            setCurrentSponsor(true);
                            handleClickOpen6();
                            console.log(currentSponsorDataId);
                            data[`${companyInputs.sponsor_type}`]?.forEach(
                              (sponsor, i) => {
                                console.log(sponsor.data_id);
                                if (sponsor.id == data.data_id) {
                                  console.log("team members set!");
                                  setCurrentSponsorId(data.data_id);
                                  setTeamMembers(
                                    JSON.parse(sponsor.team_members)
                                  );
                                  setTimeout(() => {
                                    handleClose6();
                                  }, 3000);
                                }
                              }
                            );
                          } else {
                            setError(true);
                            handleClickOpen6();
                            setTimeout(() => {
                              handleClose6();
                            }, 4000);
                          }
                        });
                    console.log(teamMembers);
                  }}
                  style={{ width: "50px", height: "50px", cursor: "pointer" }}
                /> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "5vh",
                gap: "5%",
                height: "250px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "1%" }}>
                <h2>Logo</h2>
                <div
                  style={{
                    position: "relative",
                    height: "auto",
                    width: "auto",
                    display: "flex",
                  }}
                >
                  <HoverButton
                    initialUrl={"assets/Add (1).png"}
                    hoverUrl={"/Add (2).png"}
                    style={{ cursor: "pointer", width: "50px", height: "50px", position: 'absolute', right: '15px', top: '-25px' }}
                  />
                  <img
                    src={
                      companyLogo
                        ? companyLogo
                        : companyInputs.logo_url
                          ? companyInputs.logo_url
                          : "/Add Logo Button.png"
                    }
                    style={{
                      height: "100px",
                      width: "250px",
                      cursor: "pointer",
                    }}
                    alt=""
                  />
                  <input
                    type="file"
                    style={{
                      opacity: "0",
                      transform: "scale(2)",
                      position: "absolute",
                      top: "0",
                      right: "7.5%",
                      width: "30px",
                      pointer: "cursor",
                    }}
                    name="company_logo"
                    accept="image/png, image/jpeg"
                    // onClick={(e) => {
                    //   if (
                    //     companyInputs.name == false ||
                    //     companyInputs.name === ""
                    //   ) {
                    //     handleClickOpen4();
                    //     e.target.disabled = true;
                    //   } else {
                    //     e.target.disabled = false;
                    //   }
                    // }}
                    onChange={(e) => {
                      uploadLogo(e);
                    }}
                  />
                </div>
              </div>
              <Stack spacing={2} sx={{ mb: 5, width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <div style={{ color: "#F0AD4E", width: "35%" }}>
                    Company Name*
                  </div>{" "}
                  <TextField
                    label="Outlined"
                    variant="outlined"
                    size="small"
                    name={"name"}
                    fullWidth
                    value={companyInputs.name}
                    sx={styles}
                    onChange={handleCompanyInputChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    marginTop: expanded.description && "25px",
                  }}
                >
                  <div style={{ color: "#F0AD4E", width: "35%" }}>
                    Description*
                  </div>
                  <TextField
                    name="description"
                    size="small"
                    fullWidth
                    value={companyInputs.description}
                    onChange={handleCompanyInputChange}
                    onDoubleClick={(e) =>
                      expanded.description
                        ? setExpanded({ ...expanded, description: false })
                        : setExpanded({ ...expanded, description: true })
                    }
                    label="Outlined"
                    variant="outlined"
                    sx={{
                      color: "white",
                      minWidth: "fit-content",
                      border: expanded.description && "1px solid white",
                      maxWidth: expanded.description ? "562.5px" : "100%",
                      top: "0",
                      right: "0",
                      zIndex: "10000",
                      position: expanded.description && "absolute",
                      input: { color: "white", fontSize: "1rem" },
                      textarea: { color: "white" },
                      "&": {
                        color: "white !important",
                        backgroundColor: "#303265",
                        borderRadius: "5px",
                        minWidth: "fit-content",
                        // position:'absolute'
                      },
                      "& label": {
                        color: "transparent",
                      },
                      "& label.Mui-focused": {
                        color: "transparent",
                      },
                      "& .MuiInputBase-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                          color: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                          color: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                          color: "white",
                        },
                      },
                    }}
                    multiline={expanded.description}
                    rows={expanded.description && 8}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: expanded.description && "23.5px",
                  }}
                >
                  <div style={{ color: "#F0AD4E", width: "35%" }}>
                    Website Url*
                  </div>
                  <TextField
                    fullWidth
                    size="small"
                    name="website"
                    onChange={handleCompanyInputChange}
                    value={companyInputs.website}
                    label="Outlined"
                    variant="outlined"
                    sx={styles}
                    multiline={expanded.website}
                    rows={expanded.website && 8}
                  />
                </div>
              </Stack>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "2.5%" }}>
              <h4 style={{ color: "#F0AD4E" }}>People</h4>
              <HoverButton
                initialUrl={"assets/Add (1).png"}
                hoverUrl={"/Add (2).png"}
                style={{ cursor: "pointer", width: "50px", height: "50px" }}
                onClick={() => {
                  if (
                    teamMembers.length &&
                    !teamMembers[teamMembers.length - 1]?.headshot_url
                  ) {
                    return;
                  }

                  setTeamMembers((old) => [
                    ...old,
                    {
                      firstname: "",
                      lastname: "",
                      jobtitle: "",
                      headshot_url: "",
                      id: uuidv4(),
                    },
                  ]);
                  console.log(teamMembers);

                  refreshData();
                }}
              />

              {/* <img
                    style={{ cursor: "pointer", width:'50px' }}
                    src={"assets/Add (1).png"}
                    onClick={() => {
                      if (teamMembers.length && !teamMembers[teamMembers.length - 1]?.headshot_url) {
                        return;
                      }

                      setTeamMembers((old) => [
                        ...old,
                        {
                          firstname: "",
                          lastname: "",
                          jobtitle: "",
                          headshot_url: "",
                          id: uuidv4(),
                        },
                      ]);
                      console.log(teamMembers);

                      refreshData();
                    }}
                  /> */}
            </div>
            {/* <IndividualContainer /> */}
            {teamMembers.map((member, i) => {
              return (
                <div
                  key={i}
                  onClick={(e) => {
                    setCurrentId(member.id);
                    setCurrentHeadshotUrl(member.headshot_url);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    gap: "5%",
                    backgroundColor: "#303265",
                    marginTop: "2.5vh",
                    padding: "1% 2.5%",
                  }}
                >
                  <img
                    src={"assets/Vector (7).png"}
                    style={{ width: "25px", height: "40px", cursor: "pointer" }}
                    onClick={handleClickOpen5}
                    alt=""
                  />
                  <div style={{ position: "relative" }}>
                    {/* <Fab
                      sx={{
                       
                      }}
                      color="primary"
                    > */}
                    <HoverButton
                      initialUrl={"assets/Add (1).png"}
                      hoverUrl={"/Add (2).png"}
                      style={{
                        width: "50px",
                        position: "absolute",
                        right: "0",
                        cursor: "pointer",
                        zIndex: "11000",
                      }}
                    />
                    {/* <img src="/assets/Add (1).png" style={{width:'50px', position: "absolute",
                        right: "0",
                        cursor: "pointer",}} /> */}
                    {/* <EditIcon /> */}
                    <input
                      type="file"
                      style={{
                        opacity: "0",
                        transform: "scale(3)",
                        position: "absolute",
                        right: "0",
                        cursor: "pointer",
                        width: "25px",
                        zIndex: '12000'
                      }}
                      name="headshot"
                      accept="image/png, image/jpeg"
                      // onClick={(e) => {
                      //     e.target.disabled = false;
                      // }}
                      onChange={(e) => {
                        currentId
                          ? currentId == member.id && uploadHeadshot(e)
                          : uploadHeadshot(e);
                      }}
                    />
                    {/* </Fab> */}

                    <img
                      src={
                        member.headshot_url &&
                          member.headshot_url !== currentHeadshotUrl
                          ? member.headshot_url
                          : headshot
                            ? headshot
                            : member.headshot_url
                              ? member.headshot_url
                              : "/Group 130.png"
                      }
                      style={{
                        width: "175px",
                        height: "175px",
                        borderRadius: "100%",
                      }}
                      alt=""
                    />
                  </div>
                  <table style={{ borderSpacing: "5px", width: "100%" }}>
                    <tr>
                      <td style={{ color: "#F0AD4E" }}>First Name*</td>
                      <td>
                        <TextField
                          label="Outlined"
                          variant="outlined"
                          size="small"
                          value={member.firstname}
                          sx={styles2}
                          name="firstname"
                          onChange={(e) => handleChangeInput(member.id, e)}
                        />
                      </td>
                      <td style={{ color: "#F0AD4E" }}>Last Name*</td>
                      <td>
                        <TextField
                          label="Outlined"
                          variant="outlined"
                          size="small"
                          value={member.lastname}
                          sx={styles2}
                          name="lastname"
                          onChange={(e) => handleChangeInput(member.id, e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ color: "#F0AD4E" }}>Job Title*</td>
                      <td>
                        <TextField
                          size="small"
                          name="jobtitle"
                          label="Outlined"
                          value={member.jobtitle}
                          variant="outlined"
                          onChange={(e) => handleChangeInput(member.id, e)}
                          sx={styles2}
                        />
                      </td>
                      <td style={{ color: "#F0AD4E" }}>LinkedIn</td>
                      <td>
                        <TextField
                          size="small"
                          label="Outlined"
                          value={member.linkedin}
                          name="linkedin"
                          variant="outlined"
                          onChange={(e) => handleChangeInput(member.id, e)}
                          sx={styles2}
                        />
                      </td>
                    </tr>
                  </table>
                  {/* <Stack spacing={1} sx={{ p: 5 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "22.5%", color: "#F0AD4E" }}>
                        First Name*
                      </div>{" "}
                      <div style={{ display: "flex", gap: "2.5%" }}>
                        <TextField
                          label="Outlined"
                          variant="outlined"
                          size="small"
                          value={member.firstname}
                          sx={styles2}
                          name="firstname"
                          onChange={(e) => handleChangeInput(member.id, e)}
                        />
                        <TextField
                          label="Outlined"
                          variant="outlined"
                          size="small"
                          value={member.lastname}
                          sx={styles2}
                          name="lastname"
                          onChange={(e) => handleChangeInput(member.id, e)}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "25%", color: "#F0AD4E" }}>
                        Job Title*
                      </div>{" "}
                      <TextField
                        fullWidth
                        size="small"
                        name="jobtitle"
                        label="Outlined"
                        value={member.jobtitle}
                        variant="outlined"
                        onChange={(e) => handleChangeInput(member.id, e)}
                        sx={styles2}
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "25%", color: "#F0AD4E" }}>
                        LinkedIn
                      </div>{" "}
                      <TextField
                        size="small"
                        fullWidth
                        label="Outlined"
                        value={member.linkedin}
                        name="linkedin"
                        variant="outlined"
                        onChange={(e) => handleChangeInput(member.id, e)}
                        sx={styles2}
                      />
                    </div>
                  </Stack> */}
                  <HoverButton
                    initialUrl={"/assets/Property 1=Default (3).png"}
                    hoverUrl={"/assets/Property 1=Variant2 (3).png"}
                    style={{ width: "50px", cursor: "pointer" }}
                    onClick={(e) => {
                      let teamMembersCopy = teamMembers;
                      let userId = member.id;
                      teamMembersCopy.forEach((member, i) => {
                        if (member.id == userId) {
                          teamMembersCopy.pop(i);
                        }
                      });
                      console.log(teamMembersCopy);
                      let body = {
                        body: {
                          id: companyInputs.id,
                          team_members: JSON.stringify(teamMembersCopy),
                        },
                      };
                      putData(
                        "https://youngstartup.io/api/dbt/sponsors/update",
                        body
                      ).then((data) => {
                        if (data.success) {
                          setError(false);
                          handleClickOpen6();
                          refreshData();
                          getData(
                            "https://youngstartup.io/api/db/sponsors/get_by_type"
                          ).then((data) => {
                            data[`${currentSponsorType}`]?.forEach(
                              (sponsor, i) => {
                                if (sponsor.id === currentSponsorId) {
                                  setTeamMembers(
                                    JSON.parse(sponsor.team_members)
                                  );
                                  return;
                                }
                              }
                            );
                          });
                          //  if(!teamMembers.length){
                          //   setTeamMembers((old) => [
                          //     ...old,
                          //     {
                          //       firstname: "",
                          //       lastname: "",
                          //       jobtitle: "",
                          //       headshot_url: "",
                          //       id: uuidv4(),
                          //     },
                          //   ]);
                          //  }
                          setTimeout(() => {
                            handleClose6();
                          }, 2000);
                          // }, 3000);
                        }
                      });
                    }}
                  />
                </div>
              );
            })}

            {/* {
            people.map((person, i)=>{
              return(
                <SponsorPerson key={i} person={person} />
              )
            })
          } */}
          </DialogContent>
        </Dialog>
        <Dialog
          open={open2}
          maxWidth={"md"}
          onClose={handleClose2}
          sx={{ overflowX: "hidden !important" }}
        >
          <DialogContent sx={{ backgroundColor: "#020120", color: "white" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h1>Sponsor Types</h1>
              <div style={{ display: "flex", gap: "15%" }}>
                <img
                  src={"/Close.png"}
                  style={{ width: "50px", cursor: "pointer" }}
                  onClick={handleClose2}
                />
                <img
                  src={"/Save Button.png"}
                  style={{ width: "50px", cursor: "pointer" }}
                  onClick={(e) => {
                    console.log(modalSponsors);
                    modalSponsors.forEach((sponsor, i) => {
                      sponsor.order_slot = i + 1;
                    });
                    console.log(modalSponsors);
                    let body = {
                      body: {
                        data: modalSponsors,
                      },
                    };
                    postData(
                      `https://youngstartup.io/api/dbt/sponsor_types/updatemulti_diff_val`,
                      body
                    ).then((data) => {
                      if (data.faild_count < 1) {
                        setError(false);
                        setOpen6(true);
                        refreshData();
                      } else {
                        setError(true);
                        setOpen6(true);
                      }
                    });
                  }}
                  alt=""
                />
              </div>
            </div>

            <Stack sx={{ marginTop: "2.5vh" }} spacing={2}>
              <Container keys={keys} />
              <div
                style={{
                  display: "flex",
                  width: "500px",
                  backgroundColor: "#303265",
                  padding: "2.5%",
                  alignItems: "center",
                  gap: "5%",
                  cursor: "move",
                }}
              >
                <img src={"assets/Vector (7).png"} style={{ width: "25px" }} alt=""
                />
                <form
                  style={{
                    display: "flex",
                    gap: "5%",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {" "}
                  <input
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      fontSize: "2rem",
                      border: "none",
                      width: "350px",
                      borderRadius: "25px",
                      padding: "1%",
                    }}
                    onChange={(e) => {
                      setNewSponsor({
                        order_slot: modalSponsors.length + 1,
                        display_name: e.target.value,
                        internal_name: e.target.value
                          .replaceAll(/[^A-Z|a-z|0-9]/g, "_")
                          .toLowerCase(),
                      });
                    }}
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Fab
                      type="click"
                      color="primary"
                      size="small"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={(e) => {
                        e.preventDefault();
                        console.log(newSponsor.display_name);
                        if (
                          newSponsor?.display_name?.trim() == "" ||
                          !newSponsor?.display_name
                        ) {
                          setError(true);
                          handleClickOpen6();
                          setTimeout(() => {
                            handleClose6();
                            return;
                          }, 2000);
                          // }, 4000);
                          return;
                        } else {
                          let body = { body: newSponsor };
                          postData(
                            `https://youngstartup.io/api/dbt/sponsor_types/add`,
                            body
                          )
                            .then((data) => {
                              if (data.success) {
                                setError(false);
                                handleClickOpen6();
                                setTimeout(() => handleClose6(), 2000);
                                // setTimeout(() => handleClose6(), 3000);
                              } else {
                                setError(true);
                                handleClickOpen6();
                                setTimeout(() => handleClose6(), 2000);
                                // setTimeout(() => handleClose6(), 3000);
                              }
                            })
                            .then(() => {
                              refreshData();
                            });
                        }
                      }}
                    >
                      <AddIcon />
                    </Fab>
                  </div>
                </form>
              </div>
              {/* { keys.map((key, i)=>{
             return <div style={{display: "flex", backgroundColor:'#303265', padding:"2.5%", alignItems:'center', gap:"5%"}}>
                <img src={"assets/Vector (7).png"} style={{width:'25px'}} />
                <h1>{key}</h1>
              </div>
             })} */}
            </Stack>
          </DialogContent>
        </Dialog>
        <Dialog
          open={open3}
          maxWidth={"md"}
          onClose={handleClose3}
          sx={{ overflowX: "hidden !important" }}
        >
          <DialogActions
            sx={{
              backgroundColor: "#020120",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "2.5%",
                width: "100%",
                justifyContent: "right",
              }}
            >
              <img
                src={"/Close.png"}
                onClick={handleClose3}
                style={{ width: "50px", height: "50px", cursor: "pointer" }}
                alt=""
              />
              <img
                src={"/Save Button.png"}
                onClick={(e) => {
                  sponsorList.forEach((sponsor, i) => {
                    sponsor.order_slot = i + 1;
                  });
                  let body = {
                    body: {
                      data: sponsorList,
                    },
                  };
                  console.log(sponsorList);
                  postData(
                    `https://youngstartup.io/api/dbt/sponsors/updatemulti_diff_val`,
                    body
                  )
                    .then((data) => console.log(data))
                    .then(() => {
                      setError(false);
                      handleClickOpen6();
                      refreshData();
                      setTimeout(() => {
                        handleClose6();
                      }, 2000);
                      // }, 3000);

                      // getData(
                      //   "https://youngstartup.io/api/db/sponsors/get_by_type"
                      // ).then((data) => {
                      //   setSponsorList(data[currentSponsorType]);
                      // });
                    })
                    .catch((e) => {
                      setError(true);
                      handleClickOpen6();
                      setTimeout(() => {
                        handleClose6();
                      });
                    });
                }}
                style={{ width: "50px", height: "50px", cursor: "pointer" }}
                alt=""
              />
            </div>
          </DialogActions>
          <DialogContent sx={{ backgroundColor: "#020120", color: "white" }}>
            <ContainerSponsor />
          </DialogContent>
        </Dialog>
        <Dialog open={open4} onClose={handleClose4} maxWidth={"2000px"}>
          <DialogContent>
            <Alert severity="error">
              Error - you must fill out company name before uploading company
              logo.
            </Alert>
          </DialogContent>
        </Dialog>
        <Dialog open={open5} onClose={handleClose5} maxWidth={"2000px"}>
          <DialogActions
            sx={{ backgroundColor: "#020120", justifyContent: "right" }}
          >
            <img
              src={"/Close.png"}
              style={{ width: "50px", cursor: "pointer" }}
              onClick={handleClose5}
            />
            <img
              src={"/Save Button.png"}
              onClick={(e) => {
                let body = {
                  body: {
                    id: companyInputs.id,
                    team_members: JSON.stringify(teamMembers),
                  },
                };
                putData(
                  "https://youngstartup.io/api/dbt/sponsors/update",
                  body
                ).then((data) => {
                  if (data.success) {
                    setError(false);
                    handleClickOpen6();
                    setTimeout(() => {
                      handleClose6();
                    }, 2000);
                    // }, 3000);
                  }
                });
              }}
              style={{ width: "50px", height: "50px", cursor: "pointer" }}
              alt=""
            />
          </DialogActions>
          <DialogContent sx={{ backgroundColor: "#020120" }}>
            <IndividualContainer />
          </DialogContent>
        </Dialog>
        <Dialog open={open6} onClose={handleClose6} fullWidth>
          <DialogActions
            sx={{
              justifyContent: "right",
              backgroundColor: error ? "#D71226" : "#10861B",
            }}
          >
            <img
              src={"/Close.png"}
              style={{ width: "50px", cursor: "pointer" }}
              onClick={handleClose6}
              alt=""
            />
          </DialogActions>
          <DialogContent
            sx={{ backgroundColor: error ? "#D71226" : "#10861B" }}
          >
            <h4
              style={{
                color: "white",
                textAlign: "center",
                marginBottom: "3rem",
              }}
            >
              {error ? "AN ERROR HAS OCCURED!" : "CHANGE SUCCUSSFUL!"}
            </h4>
          </DialogContent>
        </Dialog>
      </div>
    </DndProvider>
  );
};
