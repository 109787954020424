import { useState, useEffect } from "react";

import ToggleButton from "@mui/material/ToggleButton";
import { patchData, getData } from "../../api/apiCalls";

export const AddOnToggle = ({ cell, setAddOns, addOns, setData, handleSuccessMsg }) => {
  const [selected, setSelected] = useState(
    cell.row.so_inclusion === "yes" ? true : false
  );

  return (
    <ToggleButton
      value="check"
      selected={selected}
      style={{ backgroundColor: selected ? "green" : "red" }}
      onChange={() => {
        setSelected(!selected);
        if (addOns) {
          setAddOns({ ...addOns, so_inclusion: selected ? "" : "yes" });
        } else {
          let body = {
            body: {
              id: cell.row.id,
              rivka_stage: "confirmed",
              so_inclusion: selected ? "" : "yes",
            },
          };
          patchData(
            `https://youngstartup.io/api/db-a/presenters/updateapplication`,
            body
          ).then((data) => {
            if (data.success) {
              handleSuccessMsg();
              getData(
                `https://youngstartup.io/api/db/presenters/getallapplications?stage=${
                  cell.row.rivka_stage
                }&company_stage=${
                  cell.row.company_stage === "Seeking Angel Round"
                    ? "seed"
                    : "top"
                }`
              ).then((data) => setData(data.data));
              // ).then((data) => setData(data));
            }
          });
        }
      }}
    />
  );
};
