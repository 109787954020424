import { useState } from "react";

import Popover from "@mui/material/Popover";

import { HoverButton } from "../index";
import { getData, patchData } from "../../api/apiCalls";

export const PresentingVirtualPicker = ({ cell, fetchUrl, setData, handleErrMsg, handleSuccessMsg }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const cellId = cell.row.id;
  const companyStage = cell.row.company_stage === "Seeking Angel Round" ? "seed" : "top";
  const rivkaStage = cell.row.rivka_stage;

  // console.log(`https://youngstartup.io/api/db/presenters/getallapplications?stage=${rivkaStage}&company_stage=${companyStage}`)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateStatus = (status, id) => {
    let body = {
      body: {
        id: id,
        presenting_virtually: status,
      },
    };
    patchData(
      `https://youngstartup.io/api/db-a/presenters/updateapplication`,
      body
    )
      .then((data) => {
        if (data.success) {
          handleSuccessMsg();
          handleClose();
          getData(
            `https://youngstartup.io/api/db/presenters/getallapplications?stage=${rivkaStage}&company_stage=${companyStage}`
          ).then((data) => {
            setData(data.data);
            // setData(data);
          });
        } else {
          handleErrMsg()
        }

      })

  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{ cursor: 'pointer' }}>
      {cell.row.presenting_virtually ?
        <img
          onClick={handleClick}
          src={
            cell.row.presenting_virtually == "yes"
              ? "/assets/Property 1=Default (9).png"
              : "/assets/Property 1=Default (8).png"
          }
          className="status-icon"
          alt=""
          /> : <span onClick={handleClick}>?</span>}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="status-picker">
          <HoverButton
            initialUrl={"/assets/Property 1=Default (8).png"}
            hoverUrl={"/assets/Property 1=Variant2 (8).png"}
            className="status-picker-icon"
            onClick={() => {
              handleUpdateStatus("no", cellId);
            }}
          />
          <HoverButton
            initialUrl={"/assets/Property 1=Default (9).png"}
            hoverUrl={"/assets/Property 1=Variant2 (9).png"}
            className="status-picker-icon"
            onClick={() => handleUpdateStatus("yes", cellId)}
          />
        </div>
      </Popover>
    </div>
  );
};
