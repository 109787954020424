import { useState, useEffect, useContext } from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { HoverButton, AddOnToggle } from "../index";
import { patchData, getData } from "../../api/apiCalls";
import TicketTypesContext from "../../utils/ticketTypesContext";

export const AddOns = ({
  cell,
  setContent,
  SelectMenu,
  setData,
  handleSuccessMsg,
  handleErrMsg,
  handleClose,
  fetchData,
  fetchUrl

}) => {
  const [addOns, setAddOns] = useState({
    dinner_tickets: cell.row.dinner_tickets,
    hard_copy_event_guide: cell.row.hard_copy_event_guide,
    so_inclusion: cell.row.so_inclusion,
    extra_passes: cell.row.extra_passes,
    ticket_type: cell.row?.ticket_type,
  });
  // console.log("raw", cell.row)
  // console.log("state", addOns)

  const handleChangeAddOns = (e) => {
    // console.log(e.target.value);
    setAddOns({
      ...addOns,
      [e.target.name]: e.target.value,
    });
  };
  const ctx = useContext(TicketTypesContext);

  // const queryString = window.location.search || "?stage=applied&company_stage=top";
  // const fetchUrl = `https://youngstartup.io/api/db/presenters/getallapplications${queryString}`;

  // console.log("q3", fetchUrl)
  // debugger
  return (
    <Stack>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "25px",
          color: "white",
        }}
      >
        <h5>Select Add-ons</h5>
        <div style={{ display: "flex", gap: "7.5px" }}>
          <HoverButton
            initialUrl={"/assets/Property 1=Default (4).png"}
            hoverUrl={"/assets/Property 1=Variant2 (4).png"}
            className="status-icon"
            onClick={(e) => {
              cell.row.rivka_stage === "confirmed"
                ? handleClose()
                : setContent(<SelectMenu />);
            }}
          />
          <HoverButton
            initialUrl={"/assets/Property 1=Default (5).png"}
            hoverUrl={"/assets/Property 1=Variant2 (5).png"}
            className="status-icon"
            onClick={(e) => {
              let body = {
                body: {
                  id: cell.row.id,
                  // rivka_stage: "confirmed",
                  dinner_tickets: Number(addOns.dinner_tickets),
                  hard_copy_event_guide: addOns.hard_copy_event_guide,
                  so_inclusion: addOns.so_inclusion,
                  extra_passes: Number(addOns.extra_passes),
                  ticket_type: addOns.ticket_type
                },
              };
              console.log(body);
              patchData(`https://youngstartup.io/api/db-a/presenters/updateapplication`, body)
                .then((data) => {
                  if (data.success) {
                    handleSuccessMsg();
                    handleClose()
                    getData(
                      `https://youngstartup.io/api/db/presenters/getallapplications?stage=${cell.row.rivka_stage
                      }&company_stage=${cell.row.company_stage === "Seeking Angel Round"
                        ? "seed"
                        : "top"
                      }`
                    )
                      .then((data) => setData(data.data));
                  }
                });
            }}
          />
        </div>
      </div>
      <Stack sx={{ color: "white" }} spacing={2}>
        <div style={{ display: "flex", alignItems: "center", gap: "5%" }}>
          <input
            type="number"
            style={{
              width: "25%",
              backgroundColor: "#000120",
              border: "none",
              borderRadius: "15px",
              color: "white",
              textAlign: "center",
            }}
            value={addOns.extra_passes}
            name="extra_passes"
            onChange={handleChangeAddOns}
          />
          <div>Extra Tickets</div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5%" }}>
          <input
            type="number"
            style={{
              width: "25%",
              backgroundColor: "#000120",
              border: "none",
              borderRadius: "15px",
              color: "white",
              textAlign: "center",
            }}
            name="dinner_tickets"
            value={addOns.dinner_tickets}
            onChange={handleChangeAddOns}
          />
          <div>Dinner Tickets</div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5%" }}>
          <input
            type="number"
            style={{
              width: "25%",
              backgroundColor: "#000120",
              border: "none",
              borderRadius: "15px",
              color: "white",
              textAlign: "center",
            }}
            name="hard_copy_event_guide"
            value={addOns.hard_copy_event_guide}
            onChange={handleChangeAddOns}
          />
          <div>Hard Copy Event Guide</div>
        </div>
        {/* {console.log("ticket_type", addOns.ticket_type)} */}
        <select
          name="ticket_type"
          value={addOns.ticket_type}
          onChange={handleChangeAddOns}
        >
          <option key={"i"} value="">--Please choose an option--</option>
          {ctx?.ticketTypes.map((e, i) => {
            return <option key={i} value={e.option_value}>{e.option_display_short}</option>
          })}
        </select>

        {cell.row.final_presenting_track === "Seed" &&
          ["confirmed", "selected"].includes(cell.row.rivka_stage) && (
            <div style={{ display: "flex", alignItems: "center", gap: "5%" }}>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AddOnToggle
                  cell={cell}
                  addOns={addOns}
                  setAddOns={setAddOns}
                />
              </div>
              <div>Summary Outline Inclusion</div>
            </div>
          )}

        <div
          style={{ width: "200px", padding: "2.5%" }}
          className="option-select-btn bg-red"
          onClick={(e) => {
            // debugger
            // console.log("q", fetchUrl)
            // fetchData();
            // debugger
            let body = {
              body: {
                id: cell.row.id,
                rivka_stage: "not going ahead",
              },
            };
            patchData(
              `https://youngstartup.io/api/db-a/presenters/updateapplication`,
              body
            )
              .then((data) => {
                if (data.success) {
                  handleSuccessMsg();
                  handleClose();
                  // debugger
                  fetchData();

                  // window.location.reload();
                  // getData(
                  //   `https://youngstartup.io/api/db/presenters/getallapplications?stage=${cell.row.rivka_stage
                  //   }&company_stage=${cell.row.company_stage === "Seeking Angel Round"
                  //     ? "seed"
                  //     : "top"
                  //   }`
                  // )
                  // .then((data) => {
                  //   setData(data); 
                  // });
                  // getData(fetchUrl)
                  // .then((data) => {
                  //   setData(data); 
                  // });
                }
              });
          }}
        >
          Not Going Ahead
        </div>

        <div
          style={{ width: "200px", padding: "2.5%" }}
          className="option-select-btn bg-green"
          onClick={(e) => {
            // debugger
            // console.log("q2", fetchUrl)
            fetchData();
            let body = {
              body: {
                id: cell.row.id,
                rivka_stage: "confirmed",
                dinner_tickets: Number(addOns.dinner_tickets),
                hard_copy_event_guide: addOns.hard_copy_event_guide,
                so_inclusion: addOns.so_inclusion,
                extra_passes: Number(addOns.extra_passes),
                ticket_type: addOns.ticket_type
              },
            };
            console.log(body);
            patchData(
              `https://youngstartup.io/api/db-a/presenters/updateapplication`,
              body
            )
              .then((data) => {
                if (data.success) {
                  handleSuccessMsg();
                  handleClose();
                }
              });
          }}
        >
          Confirmed & Paid
        </div>
      </Stack>
    </Stack>
  );
};
