import { useEffect, useState } from "react";

// MUI imports
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RefreshIcon from "@mui/icons-material/Refresh";
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Popper from "@mui/material/Popper";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import XlsExport from "xlsexport";



//COMPONENT IMPORTS
import { MuiAppBar } from "../index";

// utils
import {
  deleteObject,
  getData,
  postData,
  putData,
  patchData,
  deleteData,
  createAttendee,
} from "../../api/apiCalls";
import {
  addOrUpdateQueryParam,
  getQueryParameterByName,
} from "../../utils/funcs";
import toast, { Toaster } from "react-hot-toast";
import { InputLabel, Typography } from "@mui/material";

// SUPPORTING COMPONENTS

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const attendeeTagOptions = [
  "Coach",
  "Judge",
  "Panelist",
  "Moderator",
  "Investor",
  "Sponsor",
];

const investmentLocationOptions = [
  "United States",
  "Canada",
  "UK",
  "Israel",
  "Specific Region Only",
  "Europe",
];

const industryFocusOptions = [
  "Tech",
  "CleanTech",
  "EdTech",
  "FinTech",
  "MedTech",
  "Lifesciences/Healthcare",
  "Healthcare IT",
  "Mobile/Communication",
  "Consumer Products",
  "Software",
  "FoodTech",
  "Seed Stage",
];

const judgingOptions = [
  { value: "Tech", label: "Tech" },
  { value: "Life Sciences/Healthcare", label: "Life Sciences/Healthcare" },
  { value: "Clean Tech", label: "Clean Tech" },
  { value: "Fin Tech", label: "Fin Tech" },
  { value: "Ed Tech", label: "Ed Tech" },
  { value: "Seed", label: "Seed" },
  { value: "Medtech", label: "Medtech" },
  { value: "Consumer Products", label: "Consumer Products" },
  { value: "--None--", label: "--None--" },
];

const coachingOptions = [
  {
    label: "Tech",
    value: "Tech",
  },
  {
    label: "Life Sciences/Healthcare",
    value: "Life Sciences/Healthcare",
  },
  {
    label: "Clean Tech",
    value: "Clean Tech",
  },
  {
    label: "Fin Tech",
    value: "Fin Tech",
  },
  {
    label: "Medtech",
    value: "Medtech",
  },
  {
    label: "Consumer Products",
    value: "Consumer Products",
  },
];

const speakerOptions = [
  { value: "Venture Roadmap", label: "Venture Roadmap" },
  {
    value: "Coaching Panel",
    label: "Coaching Panel - Getting your Company Funded",
  },
  { value: "Fund of Funds", label: "Fund of Funds" },
  { value: "Family Offices", label: "Family Offices" },
  { value: "Due Diligence", label: "Due Diligence" },
  { value: "Opening Panel", label: "Opening Panel - Changing Venture World" },
  { value: "Angel", label: "Raising Money from Angel Investors" },
  { value: "Startup to IPO", label: "Startup to IPO or SPAC" },
  { value: "Fin Tech", label: "Banking on Fintech Innovation" },
  { value: "Medtech Panel", label: "Medtech Panel" },
  { value: "Term Sheets", label: "Negotiating a Winning Term Sheet" },
  { value: "Fundable Deals", label: "Fundable Deals - Getting VCs Hooked" },
  { value: "Where's the Exit", label: "Where's the Exit" },
  {
    value: "Corporate VC",
    label: "Corporate Venture Funding: All You Need To Know",
  },
  { value: "Accelerators", label: "Accelerators" },
  {
    value: "Founders turned VCs",
    label: "Both sides of the Table - Founders turned VCs",
  },
  { value: "Clean Tech", label: "Green Dollars for Cleantech Innovators" },
  {
    value: "LS/HC Checkup",
    label:
      "The Future of Innovation for Life Sciences and Healthcare Startups",
  },
  {
    value: "Female Funders",
    label: "Female Advantage: Why Female Founders make Great Leaders",
  },
  {
    value: "European Investors",
    label: "Scaling your Startup Internationally",
  },
  { value: "Ed Tech", label: "NA - Ed Tech" },
  { value: "Food Tech", label: "NA - Food Tech" },
  {
    value: "Alternative Capital",
    label: "Alternative Capital",
  } /*{"value":"workshop1","label":"workshop1"},{"value":"workshop2","label":"workshop2"},{"value":"workshop3","label":"workshop3"},{"value":"workshop4","label":"workshop4"},{"value":"workshop5","label":"workshop5"},{"value":"workshop6","label":"workshop6"},{"value":"workshop7","label":"workshop7"},{"value":"workshop8","label":"workshop8"},{"value":"workshop9","label":"workshop9"},{"value":"workshop10","label":"workshop10"},{"value":"workshop11","label":"workshop11"},{"value":"workshop12","label":"workshop12"},{"value":"workshop13","label":"workshop13"},{"value":"workshop14","label":"workshop14"},{"value":"workshop15","label":"workshop15"}*/,
  ,
  { value: "", label: "" },
];



const MuiToggleStats = ({ stat, cell }) => {
  const [color, setColor] = useState(null)
  // console.log(stat);
  // console.log(cell.row.pass_num)
  useEffect(() => {
    if (typeof stat == "number") {

    }
    if (stat == 0) {
      setColor('red')
    } else if (stat > 0 && stat !== cell.row.pass_num) {
      setColor("orange")
    } else if (!stat) {
      setColor("red")
    } else {
      setColor("green")
    }
  }, [])
  return (
    <ToggleButton
      // value="check"
      selected={stat}
      sx={{
        backgroundColor: typeof stat == "number" ? `${color} !important` : stat ? "green !important" : "red !important",
        border: "1px solid white",
      }}
    // onChange={() => {
    //   setSelected(!selected);
    //   console.log(stat);
    // }}
    ></ToggleButton>
  );
};

const MuiSelect = ({ cell, options }) => {
  const [value, setValue] = useState(cell.formattedValue);
  console.log(options)
  return (
    <Select
      value={value}
      sx={{
        "&": {
          color: "white !important",
          backgroundColor: "#303265",
          borderRadius: "5px",
          minWidth: "fit-content",
          fontSize: '.9rem'
        },
      }}
      label="Age"
      onChange={(e) => {
        setValue(e.target.value)
        let body = {
          body: {
            id: cell.id ? cell.id : cell.row.id,
            [cell.field ? cell.field : "coaching_room_number"]: e.target.value
          }
        }
        // console.log(body)
        patchData(`https://youngstartup.io/api/db/attendees/update`, body)
          .then(data => console.log(data))
      }}
    // renderValue={(value) => <div style={{ color: "white" }}>{value}</div>}
    >
      {options.map((option) => (
        <MenuItem value={option.option_value ? option.option_value : option}>{option.option_display_short ? option.option_display_short : option}</MenuItem>
      ))}
    </Select>
  );
};



//Input styles

const styles = {
  color: "white",
  minWidth: "fit-content",

  maxWidth: "100%",
  input: { color: "white", fontSize: ".7rem" },
  textarea: { color: "white" },
  "&": {
    color: "white !important",
    backgroundColor: "#303265",
    borderRadius: "5px",
    minWidth: "fit-content",
  },
  "& label": {
    color: "transparent",
  },
  "& label.Mui-focused": {
    color: "transparent",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "white",
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    color: "white",
  },
  "& .MuiInputBase-root": {
    "& fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
      color: "white",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
      color: "white",
    },
    "& .MuiSvgIcon-root-MuiSelect-icon": {
      fill: "white",
    },
    "& .MuiPaper-root": {
      backgroundColor: "lightblue !important",
    },
  },
};


const dropdownStyles = {
  "& .MuiSelect-outlined": {
    color: "white",
    fontSize: "10px",
    backgroundColor: "#303265",
    p: 2,
    mb: 5,
    height: "auto",
    display: "flex",
  },
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

// MAIN Component

export const PresentersTable = () => {
  // return (<div>Hello!!</div>)

  // states
  const [data, setData] = useState([]);
  const [backupData, setBackupData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [event, setEvent] = useState("");
  const [refresh, setRefresh] = useState(1);
  const [filterObj, setFilterObj] = useState({});
  const [ddOptions, setDdOptions] = useState({});
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [saveText, setSaveText] = useState("Save");
  const [speakerStats, setSpeakerStats] = useState({
    panels: [],
    judging_tracks: [],
    coaching_tracks: [],
    total_speakers: 0,
    total_judges: 0,
    total_coaches: 0,
    displayed_on_website: 0,
  });
  const [confirm, setConfirm] = useState(false);
  const [btnColor, setBtnColor] = useState("primary");
  const [popperContent, setPopperContent] = useState({
    title: "",
    content: "",
  });
  const [options, setOptions] = useState({
    attendee_tag: [],
    speaker_display: [],
    coaching_track_contact: [],
    judging_track_contact: [],
    speaker_panel_contact: [],
    vc_investment_location_s_: [],
    final_panel_status: [],
    final_judging_track_status: [],
    final_coaching_track_status: [],
    final_presenting_track: [],
    opted_in_coaching: [],
  });
  const [modalInfo, setModalInfo] = useState({
    id: "",
    speaker_display: "",
    headshot_url: "assets/placeholder.jpeg",
    headshot: "",
    attendee_tag: "",
    firstname: "",
    lastname: "",
    jobtitle: "",
    website: "",
    company: "",
    mobilephone: "",
    email: "",
    source: "",
    vc_investment_location_s_: "",
    vc_s_industry_focuses: "",
    judging_track_contact: "",
    judging_track_2_contact: "",
    final_judging_track_placement_contact: "",
    panel_choice_1_contact: "",
    panel_choice_2_contact: "",
    panel_choice_3_contact: "",
    speaker_panel_contact: "",
    coaching_track_contact: "",
    coaching_track_2_contact: "",
    final_coaching_track_placement_contact: "",
    notes: "",
    bio: "",
    pass_num: ""
  });
  const [showInput, setShowInput] = useState(false);
  const [confirmDialogDisplay, setConfirmDialogDisplay] = useState(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);

  const MuiToggle = ({ cell }) => {
    const [selected, setSelected] = useState(cell.row.opted_in_coaching == "yes" ? true : false);
    // console.log(selected)
    return (
      <ToggleButton
        selected={selected ? true : false}
        sx={{
          backgroundColor: selected ? "green !important" : "red !important",
          border: "1px solid white",
        }}
        onChange={(e) => {
          setSelected(!selected);
          // console.log(selected);
          let body = {
            body: {
              id: cell.id,
              opted_in_coaching: selected ? "no" : "yes"
            }
          }
          console.log(body)
          patchData(`https://youngstartup.io/api/db/attendees/update`, body)
            .then(data => console.log(data))
          // .then(()=> getData(
          //   `https://youngstartup.io/api/db/attendees/all?filterby=source&val=presenters`
          // ).then((data) => {
          //   setData(data);
          // }))

        }}
      ></ToggleButton>
    );
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setModalInfo({ ...modalInfo, attendee_tag: value.join(";") });
    // console.log(modalInfo);
  };

  const uploadHeadshot = async (e) => {
    if (e.target.files[0].size > 4194304) {
      alert("File is too big!");
      e.target.value = "";
      return;
    }
    const file = e.target.files[0];
    if (file) {
      const base64 = await convertBase64(file);
      setModalInfo({ ...modalInfo, headshot: base64 });
    }

    // console.log(modalInfo);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const MuiToggleButton = ({ cell }) => {
    const [alignment, setAlignment] = useState(
      cell.formattedValue ? cell.formattedValue : cell.speaker_display
    );

    return (
      <Paper sx={{ width: "fit-content" }}>
        <StyledToggleButtonGroup
          value={alignment}
          exclusive
          onChange={(event, newAlignment) => {
            if (newAlignment !== null) {
              setAlignment(newAlignment);
              setModalInfo({ ...modalInfo, speaker_display: newAlignment });
            }
            if (open == false) {
              // console.log(alignment);
              let body = {
                body: {
                  id: cell.id,
                  speaker_display: newAlignment,
                },
              };
              patchData(
                "https://youngstartup.io/api/db/attendees/update",
                body
              )
                // .then(() =>
                //   getData(
                //     `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                //   )
                //   .then((data) => {
                //     setData(data);
                //   })
                // );
            }
          }}
          aria-label="text alignment"
        >
          {/* not displayed */}
          <ToggleButton
            sx={{
              p: 0,
              filter: alignment == "yes" ? "grayscale(0)" : "grayscale(.9)",
              "&:hover": {
                filter: "grayscale(0)",
                transition: "1s",
              },
            }}
            value={"yes"}
          >
            <SquareRoundedIcon color="success" />
          </ToggleButton>

          {/* pending */}
          <ToggleButton
            sx={{
              p: 0,
              filter: alignment == "pending" ? "grayscale(0)" : "grayscale(1)",
              "&:hover": {
                filter: "grayscale(0)",
                transition: "1s",
              },
            }}
            value={"pending"}
          >
            <SquareRoundedIcon color="warning" />
          </ToggleButton>
          <ToggleButton
            sx={{
              p: 0,
              filter: alignment == "no" ? "grayscale(0)" : "grayscale(1)",
              width: "100%",
              "&:hover": {
                filter: "grayscale(0)",
                transition: "1s",
              },
            }}
            value={"no"}
          >
            <SquareRoundedIcon color="error" />
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    );
  };

  const stageOptions = [
    {
      value: "Confirmed Participation ( JDG )",
      label: "Confirmed Participation ( JDG )",
    },
    { value: "Cancelled", label: "Cancelled" },
    {
      value: "Cancelled, sent replacement",
      label: "Cancelled, sent replacement",
    },
    { value: "Never invite", label: "Never invite" },
    { value: "Never invite company", label: "Never invite company" },
  ];

  // filter section

  const filterProperties = [
    "email",
    "firstname",
    "lastname",
    "company",
    "final_presenting_track",
    "notes",
    "coaching_room_number",
    "brella_code",
    "email_confirmed",
    "opened_dashboard",
    "matchmaking_signup",
    "submission_status",
    "opted_in_coaching",
    "emails_sent",
    "status",
    "hs_id",
  ];

  const filterRows = (
    val,
    props = filterProperties,
    localData = backupData,
    operator = "contains",
    multiOptionOperator = "OR"
  ) => {
    val = val.trim();
    let valArr = [val];
    if (val.includes(";")) {
      valArr = val.split(";");
    } else if (val.includes(" ")) {
      valArr = val.split(" ");
    }
    let res = [];
    valArr.map((searchItem) => {
      searchItem = searchItem.toLowerCase(); //.replace(" ", "")
      let temp = localData.filter((item) => {
        let flag = false;
        for (let i = 0; i < props.length; i++) {
          if (typeof item[props[i]] !== "string" && Array.isArray(item[props[i]]) && item[props[i]].includes(Number(searchItem))) {
            flag = true;
            break;
          }
          if (
            searchItem &&
            item[props[i]]
            && typeof item[props[i]] === "string"
            && (item[props[i]].toLowerCase() == searchItem ||
              (operator == "contains" &&
                (item[props[i]].toLowerCase().includes(searchItem) ||
                  item[props[i]]
                    .toLowerCase()
                    .replace(" ", "")
                    .includes(searchItem))))
          ) {
            flag = true;
            break;
          }
          if (
            searchItem &&
            item[props[i]]
            && typeof item[props[i]] === "number"
            && (item[props[i]] == searchItem ||
              (operator == "contains" &&
                (String(item[props[i]]).includes(searchItem))))
          ) {
            flag = true;
            break;
          }
        }
        return flag;
      });
      if (multiOptionOperator === "AND") {
        localData = temp;
        res = temp;
        return;
      }
      res = [...res, ...temp];
    });
    return [...new Set(res)];
  };

  const filterData = (filtersObj, data = backupData) => {
    let tempData = data;

    if (
      filterObj.final_presenting_track &&
      filterObj.final_presenting_track != false &&
      typeof filterObj.final_presenting_track === "string"
    ) {
      tempData = filterRows(
        filterObj.final_presenting_track,
        ["final_presenting_track"],
        tempData,
        "="
      );
    }
    if (
      filterObj.submission_status &&
      filterObj.submission_status != false &&
      typeof filterObj.submission_status === "string"
    ) {
      tempData = filterRows(
        filterObj.submission_status,
        ["submission_status"],
        tempData,
        "="
      );
    }
    if (
      filterObj.emails_sent &&
      filterObj.emails_sent != false &&
      typeof filterObj.emails_sent === "string"
    ) {
      tempData = filterRows(
        filterObj.emails_sent,
        ["emails_sent"],
        tempData,
        "="
      );
    }
    if (
      filterObj.q &&
      filterObj.q != false &&
      typeof filterObj.q === "string"
    ) {
      tempData = filterRows(
        filterObj.q,
        undefined,
        tempData,
        "contains",
        "AND"
      );
    }

    updateQueryParams();
    return tempData;
  };

  const updateQueryParams = (filtersObj = filterObj) => {
    for (const param in filterObj) {
      if (Object.hasOwnProperty.call(filterObj, param)) {
        if (param) {
          addOrUpdateQueryParam(String(param), filterObj[param]);
        }
      }
    }
  };

  const updateFiltersfromQuery = () => {
    let queryOptions = [
      "q",
      "final_presenting_track",
      "submission_status",
      "emails_sent",
      "opted_in_coaching",
    ];
    let temp;
    let obj = {};
    queryOptions.map((option) => {
      if ((temp = getQueryParameterByName(option))) {
        if (temp === "false") {
          temp = false;
        }
        obj[option] = temp;
      }
    });
    setFilterObj(obj);
  };
  // end of filter section


  //DIALOG FUNCTIONS
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setModalInfo({
      id: "",
      speaker_display: "",
      headshot_url: "assets/placeholder.jpeg",
      headshot: "",
      attendee_tag: "",
      firstname: "",
      lastname: "",
      jobtitle: "",
      website: "",
      company: "",
      mobilephone: "",
      email: "",
      source: "",
      vc_investment_location_s_: "",
      vc_s_industry_focuses: "",
      judging_track_contact: "",
      judging_track_2_contact: "",
      final_judging_track_placement_contact: "",
      panel_choice_1_contact: "",
      panel_choice_2_contact: "",
      panel_choice_3_contact: "",
      speaker_panel_contact: "",
      coaching_track_contact: "",
      coaching_track_2_contact: "",
      final_coaching_track_placement_contact: "",
      notes: "",
      bio: "",
    })
  };

  // use effects
  useEffect(() => {
    getData(`https://youngstartup.io/api/cwebsite/get_current_event`).then(
      (data) => {
        setEvent(data.slug);
        setEventData(data);
        getData(
          `https://youngstartup.io/api/db/attendees/all?filterby=source&val=presenters`
        ).then((data) => {
          console.log(data);
          setData(data);
          setBackupData(data);
          updateFiltersfromQuery();
        })
          .then(() => {
            if (refresh > 1) {
              toast.success("Refreshed data!");
            }
          })
      }
    );
    // console.log(filterObj)
  }, [refresh]);

  useEffect(() => {
    getData(
      `https://youngstartup.io/api/db-info/property-options-dd/presenters`
    ).then((data) => {
      console.log(data)
      setDdOptions(data);
    });

    // getData(
    //   "https://youngstartup.io/api/db-info/property-options-dd/presenters"
    // ).then((data) => {
    //   console.log(data)
    //   setOptions({
    //     final_presenting_track: data.final_presenting_track,
    //     opted_in_coaching: data.opted_in_coaching,
    //   })
    // }).then(()=>console.log(options))


  }, []);

  useEffect(() => {
    setData(filterData(filterObj));
  }, [filterObj]);

  // handel edit
  const handleCellFocusOut = (cell, event) => {
    // collect row data
    if (!cell.row) {
      [cell.row] = data.filter((i) => i.id == cell.id);
    }

    // check if any edits were made
    if (cell.row[cell.field] != cell.value) {
      let obj = { id: cell.row.id };
      obj[cell.field] = cell.value;
      try {
        putData("https://youngstartup.io/api/dbt/all_attendees/update", {
          body: obj,
        }).then((data) => {
          if (data.success == true) {
            toast.success("Successfully updated!");
          } else {
            toast.error("Error while updateing.");
          }
          setRefresh((old) => old + 1);
        });
      } catch (err) {
        console.error(err);
        toast.error("Error while updateing.");
      }
    }
  };

  const columns = [
    {
      field: "lastname",
      headerName: "Featured Presenter",
      headerAlign: "center",
      align: "left",
      headerClassName: "super-app-theme--header",
      editable: false,
      flex: 2,
      renderCell: (cell) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Stack>
            <img
              style={{ width: "100px", height: "80%", objectFit: "contain" }}
              src={cell.row.plane_headshot_url}
            // onClick={() => {
            //     setModalInfo({
            //       id: cell.row.id,
            //       date_created: cell.row.date_created,
            //       vc_s_industry_focuses: cell.row.vc_s_industry_focuses,
            //       speaker_display: cell.row.speaker_display,
            //       current_vc_stage: cell.row.current_vc_stage,
            //       headshot_url: cell.row.plane_headshot_url,
            //       source: cell.row.source,
            //       attendee_tag:
            //         cell.row.attendee_tag === null ? "" : cell.row.attendee_tag,
            //       firstname: cell.row.firstname,
            //       lastname: cell.row.lastname,
            //       jobtitle: cell.row.jobtitle,
            //       website: cell.row.website,
            //       mobilephone: cell.row.mobilephone,
            //       company: cell.row.company,
            //       email: cell.row.email,
            //       vc_investment_location_s_: cell.row.vc_investment_location_s_,
            //       judging_track_contact: cell.row.judging_track_contact,
            //       judging_track_2_contact: cell.row.judging_track_2_contact,
            //       final_judging_track_placement_contact:
            //         cell.row.final_judging_track_placement_contact,
            //       panel_choice_1_contact: cell.row.panel_choice_1_contact,
            //       panel_choice_2_contact: cell.row.panel_choice_2_contact,
            //       panel_choice_3_contact: cell.row.panel_choice_3_contact,
            //       speaker_panel_contact: cell.row.speaker_panel_contact,
            //       coaching_track_contact: cell.row.coaching_track_contact,
            //       coaching_track_2_contact: cell.row.coaching_track_2_contact,
            //       final_coaching_track_placement_contact:
            //         cell.row.final_coaching_track_placement_contact,
            //       notes: cell.row.notes,
            //       bio: cell.row.bio,
            //       submission_status: cell.row.submission_status
            //     })

            //     //SO FORM DATA
            //     getData(`https://youngstartup.io/api/db-a/attendees/getpresenterdetails/${cell.row.id}`)
            //     .then(data => console.log(data))
            //     setOpen(true);
            // }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "20%",
              }}
            >
              <img
                style={{ width: "50px", objectFit: "contain" }}
                src={cell.row.logo_url}
                alt=""
              />
            </div>
          </Stack>
          <Stack spacing={3}>
            <span style={{ color: "#F0AD4E" }}>
              {cell.row.firstname} {cell.row.lastname}
            </span>
            <span>
              <a
                style={{ color: "white" }}
                target={"_blank"}
                href={cell.row.website}
              >
                {cell.row.company}
              </a>
            </span>
          </Stack>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Stats",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (cell) => {
        // console.log(cell.row.submission_status['passes'])
        return (
          <Stack spacing={1}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                gap: '5px'
              }}
            >
              <div>Confirmed</div>
              <MuiToggleStats stat={cell.row.email_confirmed ? true : false} cell={cell} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div>Logged In</div>
              <MuiToggleStats stat={cell.row.opened_dashboard ? true : false} cell={cell} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div>Passes</div>
              <MuiToggleStats stat={(cell.row.submission_status['passes'])} cell={cell} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div>Images</div>
              <MuiToggleStats stat={(cell.row.plane_headshot_url && cell.row.logo_url) ? true : false} cell={cell} />
            </div>
          </Stack>
        );
      },
    },
    {
      field: "emails_sent",
      headerName: "Emails Sent",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (cell) => {
        let emails = cell.value;
        if (emails) {
          emails = emails.map(item => ddOptions.emails_sent?.filter(i => i.option_value == item)[0]?.option_display_short)
        }
        return (
          <Stack>
            {Array.isArray(emails) ? emails.map((email, i) => (<div key={i} style={{ display: "block" }}>{email}, &nbsp;</div>)) : emails}
          </Stack>
        );
      },
    },
    {
      field: "final_presenting_track",
      headerName: "Presenting Track",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      flex: 1,
      type: "singleSelect",
      renderCell: (cell) => {
        const options = ddOptions.final_presenting_track
        return <MuiSelect cell={cell} options={options} />;
      },
    },
    {
      field: "presenting_round",
      headerName: "Presenting Round",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      type: "number",
      hide: true,
      flex: 1,
      renderCell: (cell) => {
        // console.log(cell);
        return <MuiSelect cell={cell} />;
      },
    },
    {
      field: "presenting_order_slot",
      headerName: "Presenting Order Slot",
      headerAlign: "center",
      align: "center",
      hide: true,
      headerClassName: "super-app-theme--header",
      type: "number",
      editable: true,
      flex: 1,
    },
    {
      field: "passes_deadline",
      headerName: "Passes Deadline",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      flex: 1,
    },
    {
      field: "so_submission_deadline",
      headerName: "SO Deadline",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      flex: 1,
    },
    {
      field: "coaching_deadline",
      headerName: "Coaching Deadline",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      flex: 1,
    },
    {
      field: "coaching_room_number",
      headerName: "Coaching",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (cell) => {

        return (
          <Stack spacing={1}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: '5px'
              }}
            >
              <div>Opt-in</div>
              <MuiToggle cell={cell} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {cell.row.opted_in_coaching == "yes" &&
                <><div>Room:</div>
                  <div>
                    <MuiSelect /*cell={{formattedValue: cell.row.coaching_room_number}} */ cell={cell} options={ddOptions.coaching_room_number} />
                  </div></>}
            </div>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title" sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:"15px"}}>
          <img width={125} src={
              modalInfo.headshot_url == ""
                ? "assets/placeholder.jpeg"
                : modalInfo.headshot_url
            } /> <div><div style={{fontSize:'1.5rem'}}>{modalInfo.firstname} {modalInfo.lastname}</div><div style={{textTransform:'capitalize', fontSize:'1rem'}}>{modalInfo.attendee_tag}</div></div> 
        </DialogTitle> */}
        <DialogActions sx={{ backgroundColor: "#030120" }}>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <CloseIcon sx={{ color: "white" }} onClick={handleClose} />
          </div>
        </DialogActions>
        <DialogContent
          className="dialog"
          sx={{ backgroundColor: "#000120", color: "white" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{ display: "flex", flexDirection: "row", gap: "25px" }}
            >
              <div>
                <div>
                  <Select
                    size="small"
                    sx={dropdownStyles}
                    fullWidth
                    value={modalInfo.current_vc_stage}
                    onChange={(e) =>
                      setModalInfo({
                        ...modalInfo,
                        current_vc_stage: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={modalInfo.current_vc_stage}>
                      {modalInfo.current_vc_stage}
                    </MenuItem>
                    {stageOptions.map((option) => {
                      // console.log(stageOptions);
                      return (
                        <MenuItem value={option.value}>
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>

                <div style={{ position: "relative" }}>
                  <Fab
                    color="primary"
                    style={{
                      position: "absolute",
                      top: "17.5%",
                      right: "8%",
                      transform: "scale(.7)",
                    }}
                    onClick={() =>
                      showInput == true
                        ? setShowInput(false)
                        : setShowInput(true)
                    }
                  >
                    <EditIcon sx={{ position: "absolute" }} />
                    <input
                      style={{ opacity: "0" }}
                      accept="image/*"
                      id="raised-button-file"
                      multiple
                      type="file"
                      onChange={(e) => {
                        // console.log(e.target.value);
                        uploadHeadshot(e).then(() =>
                          console.log(modalInfo.headshot_url)
                        );
                      }}
                    />
                  </Fab>
                  {modalInfo.headshot_url !== "" ? (
                    <img
                      width="200px"
                      style={{ marginTop: "5vh" }}
                      src={
                        modalInfo.headshot
                          ? modalInfo.headshot
                          : modalInfo.headshot_url
                      }
                      alt=""
                      />
                  ) : (
                    <img
                      width="200px"
                      style={{ marginTop: "5vh" }}
                      src={"assets/placeholder.jpeg"}
                      alt=""
                      />
                  )}
                  <div><div>{modalInfo.pass_num}</div></div>
                </div>
              </div>

              <div>
                <Stack spacing={0.5} sx={{ display: "relative" }}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2.5px",
                    }}
                  >
                    <div style={{ width: "80px" }}>Role:</div>
                    <Select
                      sx={dropdownStyles}
                      label="Role"
                      multiple
                      value={modalInfo.attendee_tag
                        .split(";")
                        .filter((i) => i)}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => {
                        // console.log(selected);
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                            }}
                          >
                            {selected.map((item, i) => (
                              <div
                                style={{
                                  backgroundColor: "#000120",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        );
                      }}
                      MenuProps={MenuProps}
                    >
                      {attendeeTagOptions.map((name) => {
                        return (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                modalInfo.attendee_tag
                                  .split(";")
                                  .indexOf(name) > -1
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {/* <select
                        onChange={(e) => {
                          let options = optionsState;
                          options.push(e.target.value);
                          let uniqueArr = options.filter(function (item, pos) {
                            return options.indexOf(item) == pos;
                          });
                        }}
                        multiple
                        style={{ width: "250px" }}
                      >
                        <option value={modalInfo.attendee_tag}>
                          {modalInfo.attendee_tag}
                        </option>
                        {attendeeTagOptions.map((tag) => {
                          let options = optionsState;
                          if (
                            modalInfo.attendee_tag.includes(`${tag}`) == true &&
                            tag !== ""
                          ) {
                            options.push(tag);
                            return (
                              
                              <option
                                value={tag}
                                selected={true}
                                onClick={(e) => {
                                  console.log(e);
                                  let options = optionsState;
                                  console.log(e.target.value);
                                  options.push(e.target.value);
                                  console.log(options);
                                  let uniqueChars = [...new Set(options)];
                                  console.log(uniqueChars);
                                }}
                              >
                                {tag == "" ? "None" : tag}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                value={tag}
                                selected={false}
                                onClick={(e) => {
                                  console.log(e);
                                  let options = optionsState;
                                  console.log(e.target.value);
                                  options.push(e.target.value);
                                  console.log(options);
                                  let uniqueChars = [...new Set(options)];
                                  console.log(uniqueChars);
                                }}
                              >
                                {tag == "" ? "None" : tag}
                              </option>
                            );
                          }
                        })}
                      </select> */}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div style={{ width: "80px" }}>Name:</div>
                    <TextField
                      inputProps={{ readOnly: modalInfo.id ? true : false }}
                      size="small"
                      sx={styles}
                      label="First Name"
                      style={{ width: "25%" }}
                      value={modalInfo.firstname}
                      onChange={(e) =>
                        setModalInfo({
                          ...modalInfo,
                          firstname: e.target.value,
                        })
                      }
                    />{" "}
                    <TextField
                      size="small"
                      sx={styles}
                      inputProps={{ readOnly: modalInfo.id ? true : false }}
                      label="Last Name"
                      style={{ width: "25%" }}
                      value={modalInfo.lastname}
                      onChange={(e) =>
                        setModalInfo({
                          ...modalInfo,
                          lastname: e.target.value,
                        })
                      }
                    />
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div style={{ width: "80px" }}>Title:</div>
                    <TextField
                      size="small"
                      sx={styles}
                      inputProps={{ readOnly: modalInfo.id ? true : false }}
                      label="Title"
                      value={modalInfo.jobtitle}
                      onChange={(e) =>
                        setModalInfo({
                          ...modalInfo,
                          jobtitle: e.target.value,
                        })
                      }
                    />{" "}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div style={{ width: "80px" }}>Company:</div>
                    <TextField
                      size="small"
                      sx={styles}
                      inputProps={{ readOnly: modalInfo.id ? true : false }}
                      label="Company"
                      value={modalInfo.company}
                      onChange={(e) =>
                        setModalInfo({
                          ...modalInfo,
                          company: e.target.value,
                        })
                      }
                    />{" "}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div style={{ width: "80px" }}>Web:</div>
                    <TextField
                      size="small"
                      sx={styles}
                      inputProps={{ readOnly: modalInfo.id ? true : false }}
                      label="Website"
                      value={modalInfo.website}
                      onChange={(e) =>
                        setModalInfo({
                          ...modalInfo,
                          website: e.target.value,
                        })
                      }
                    />{" "}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div style={{ width: "80px" }}>Phone:</div>
                    <TextField
                      size="small"
                      sx={styles}
                      label="Phone"
                      inputProps={{ readOnly: modalInfo.id ? true : false }}
                      value={modalInfo.mobilephone}
                      onChange={(e) =>
                        setModalInfo({
                          ...modalInfo,
                          mobilephone: e.target.value,
                        })
                      }
                    />{" "}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div style={{ width: "80px" }}>Email:</div>
                    <TextField
                      size="small"
                      sx={styles}
                      label="Email"
                      inputProps={{ readOnly: modalInfo.id ? true : false }}
                      required
                      value={modalInfo.email}
                      onChange={(e) =>
                        setModalInfo({
                          ...modalInfo,
                          email: e.target.value,
                        })
                      }
                    />{" "}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div style={{ width: "80px" }}>$ Raised:</div>
                    <TextField
                      inputProps={{ readOnly: modalInfo.id ? true : false }}
                      size="small"
                      sx={styles}
                      label="Date Created"
                      value={modalInfo.date_created}
                    />{" "}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div style={{ width: "80px" }}>$ Sought:</div>
                    <TextField
                      inputProps={{ readOnly: modalInfo.id ? true : false }}
                      size="small"
                      sx={styles}
                      label="Date Created"
                      value={modalInfo.date_created}
                    />{" "}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    Regions:{" "}
                    <Select
                      sx={dropdownStyles}
                      multiple
                      value={
                        modalInfo.vc_investment_location_s_
                          ? modalInfo.vc_investment_location_s_
                            .split(";")
                            .filter((i) => i)
                          : []
                      }
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        setModalInfo({
                          ...modalInfo,
                          vc_investment_location_s_: value.join(";"),
                        });
                        // console.log(modalInfo);
                      }}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => {
                        // console.log(selected);
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                            }}
                          >
                            {selected.map((item, i) => (
                              <div
                                style={{
                                  backgroundColor: "#000120",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        );
                      }}
                      MenuProps={MenuProps}
                    >
                      {investmentLocationOptions.map((name) => {
                        return (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                modalInfo.vc_investment_location_s_ &&
                                modalInfo.vc_investment_location_s_
                                  .split(";")
                                  .indexOf(name) > -1
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </span>
                  <span>
                    Industry Focus(es):{" "}
                    <Select
                      sx={dropdownStyles}
                      multiple
                      value={
                        modalInfo.vc_s_industry_focuses
                          ? modalInfo.vc_s_industry_focuses
                            .split(";")
                            .filter((i) => i)
                          : []
                      }
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        setModalInfo({
                          ...modalInfo,
                          vc_s_industry_focuses: value.join(";"),
                        });
                        // console.log(modalInfo);
                      }}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => {
                        // console.log(selected);
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                            }}
                          >
                            {selected.map((item, i) => (
                              <div
                                style={{
                                  backgroundColor: "#000120",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        );
                      }}
                      MenuProps={MenuProps}
                    >
                      {industryFocusOptions.map((name) => {
                        return (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                modalInfo.vc_s_industry_focuses
                                  ? modalInfo.vc_s_industry_focuses
                                    .split(";")
                                    .indexOf(name) > -1
                                  : []
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </span>
                </Stack>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "5px",
              }}
            >
              <table
                style={{
                  textAlign: "center",
                  fontSize: ".9rem !important",
                }}
              >
                <tr>
                  <th></th>
                  <th>Choice 1</th>
                  <th>Choice 2</th>
                  <th>Final</th>
                </tr>
                <tr>
                  <td style={{ color: "#F0AD4E" }}>Judging:</td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#303265",
                        borderRadius: "5px",
                        fontSize: "12px",
                        padding: "5px",
                      }}
                    >
                      {modalInfo.judging_track_contact}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#303265",
                        borderRadius: "5px",
                        fontSize: "12px",
                        padding: "5px",
                      }}
                    >
                      {modalInfo.judging_track_2_contact}
                    </div>
                  </td>
                  <td>
                    <Select
                      sx={dropdownStyles}
                      value={
                        modalInfo.final_judging_track_placement_contact
                      }
                      onChange={(e) =>
                        setModalInfo({
                          ...modalInfo,
                          final_judging_track_placement_contact:
                            e.target.value,
                        })
                      }
                    >
                      <MenuItem
                        value={
                          modalInfo.final_judging_track_placement_contact
                        }
                      >
                        {modalInfo.final_judging_track_placement_contact}
                      </MenuItem>
                      {judgingOptions.map((option) => {
                        if (
                          option.value !==
                          modalInfo.final_judging_track_placement_contact
                        )
                          return (
                            <MenuItem value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                      })}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td style={{ color: "#F0AD4E" }}>Coaching:</td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#303265",
                        borderRadius: "5px",
                        fontSize: "12px",
                        padding: "5px",
                      }}
                    >
                      {modalInfo.coaching_track_contact}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#303265",
                        borderRadius: "5px",
                        fontSize: "12px",
                        padding: "5px",
                      }}
                    >
                      {modalInfo.coaching_track_2_contact}
                    </div>
                  </td>
                  <td>
                    {" "}
                    <Select
                      sx={dropdownStyles}
                      value={
                        modalInfo.final_coaching_track_placement_contact
                      }
                      onChange={(e) =>
                        setModalInfo({
                          ...modalInfo,
                          final_coaching_track_placement_contact:
                            e.target.value,
                        })
                      }
                    >
                      <MenuItem
                        value={
                          modalInfo.final_coaching_track_placement_contact
                        }
                      >
                        {modalInfo.final_coaching_track_placement_contact}
                      </MenuItem>
                      {coachingOptions.map((option) => {
                        if (
                          option.value !==
                          modalInfo.final_coaching_track_placement_contact
                        )
                          return (
                            <MenuItem value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                      })}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th>Choice 1</th>
                  <th>Choice 2</th>
                  <th>Choice 3</th>
                  <th>Final</th>
                </tr>
                <tr>
                  <td style={{ color: "#F0AD4E" }}>Speaking:</td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#303265",
                        borderRadius: "5px",
                        fontSize: "12px",
                        padding: "5px",
                      }}
                    >
                      {modalInfo.panel_choice_1_contact}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#303265",
                        borderRadius: "5px",
                        fontSize: "12px",
                        padding: "5px",
                      }}
                    >
                      {modalInfo.panel_choice_2_contact}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        backgroundColor: "#303265",
                        borderRadius: "5px",
                        fontSize: "12px",
                        padding: "5px",
                      }}
                    >
                      {modalInfo.panel_choice_3_contact}
                    </div>
                  </td>
                  <td>
                    {" "}
                    <Select
                      sx={dropdownStyles}
                      value={modalInfo.speaker_panel_contact}
                      onChange={(e) =>
                        setModalInfo({
                          ...modalInfo,
                          speaker_panel_contact: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={modalInfo.speaker_panel_contact}>
                        {modalInfo.speaker_panel_contact}
                      </MenuItem>
                      {speakerOptions.map((option) => {
                        if (
                          option.value !== modalInfo.speaker_panel_contact
                        )
                          return (
                            <MenuItem value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                      })}
                    </Select>
                  </td>
                </tr>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "5vh",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                Notes:{" "}
                <TextField
                  sx={styles}
                  label="Notes"
                  multiline
                  rows={7}
                  inputProps={{ readOnly: modalInfo.id ? true : false }}
                  value={modalInfo.notes}
                  onChange={(e) => {
                    setModalInfo({ ...modalInfo, notes: e.target.value });
                  }}
                >
                  {modalInfo.notes}
                </TextField>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                }}
              >
                Bio:{" "}
                <TextField
                  sx={styles}
                  inputProps={{ readOnly: modalInfo.id ? true : false }}
                  label="Bio"
                  multiline
                  rows={7}
                  onChange={(e) => {
                    setModalInfo({ ...modalInfo, bio: e.target.value });
                  }}
                  value={modalInfo.bio}
                >
                  {modalInfo.bio}
                </TextField>
              </div>
            </div>
          </div>

          {/* <DialogContentText id="alert-dialog-description" sx={{textAlign:'center', fontSize:'.75rem'}}>
            
          </DialogContentText> */}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#000120" }}>
          <Dialog open={confirm} onClose={() => setConfirm(false)}>
            <DialogContent>
              Delete {modalInfo.firstname} {modalInfo.lastname}?
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  setConfirm(false);
                }}
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                color="error"
                endIcon={<DeleteIcon />}
                onClick={() => {
                  if (modalInfo.id) {
                    deleteData(
                      `https://youngstartup.io/api/db/attendees/remove/${modalInfo.id}`
                    )
                      // .then((data) => console.log(data))
                      .then(() =>
                        getData(
                          `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                        ).then((data) => {
                          setData(data);
                          setConfirm(false);
                          handleClose();
                        })
                      );
                  }
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Button
            variant="contained"
            color="warning"
            endIcon={<DeleteIcon />}
            onClick={() => {
              // console.log(modalInfo.id);
              setConfirm(true);
            }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color={btnColor}
            endIcon={
              saveText == "Saved!" ? (
                <CheckCircleOutlineIcon />
              ) : (
                <SaveIcon />
              )
            }
            onClick={() => {
              setSaveText("Saving...");
              let body;
              modalInfo.id
                ? (body = {
                  body: {
                    id: modalInfo.id,
                    current_vc_stage: modalInfo.current_vc_stage,
                    attendee_tag: modalInfo.attendee_tag,
                    firstname: modalInfo.firstname,
                    lastname: modalInfo.lastname,
                    company: modalInfo.company,
                    jobtitle: modalInfo.jobtitle,
                    website: modalInfo.website,
                    source: modalInfo.source,
                    mobilephone: modalInfo.mobilephone,
                    email: modalInfo.email,
                    vc_investment_location_s_:
                      modalInfo.vc_investment_location_s_,
                    judging_track_contact:
                      modalInfo.judging_track_contact,
                    judging_track_2_contact:
                      modalInfo.judging_track_2_contact,
                    final_judging_track_placement_contact:
                      modalInfo.final_judging_track_placement_contact,
                    panel_choice_1_contact:
                      modalInfo.panel_choice_1_contact,
                    panel_choice_2_contact:
                      modalInfo.panel_choice_2_contact,
                    panel_choice_3_contact:
                      modalInfo.panel_choice_3_contact,
                    speaker_panel_contact:
                      modalInfo.speaker_panel_contact,
                    coaching_track_contact:
                      modalInfo.coaching_track_contact,
                    coaching_track_2_contact:
                      modalInfo.coaching_track_2_contact,
                    final_coaching_track_placement_contact:
                      modalInfo.final_coaching_track_placement_contact,
                    notes: modalInfo.notes,
                    speaker_display: modalInfo.speaker_display,
                    bio: modalInfo.bio,
                    vc_s_industry_focuses:
                      modalInfo.vc_s_industry_focuses,
                  },
                })
                : (body = {
                  body: {
                    attendee_tag: modalInfo.attendee_tag,
                    firstname: modalInfo.firstname,
                    lastname: modalInfo.lastname,
                    company: modalInfo.company,
                    current_vc_stage: modalInfo.current_vc_stage,
                    jobtitle: modalInfo.jobtitle,
                    source: modalInfo.source,
                    website: modalInfo.website,
                    mobilephone: modalInfo.mobilephone,
                    email: modalInfo.email,
                    vc_investment_location_s_:
                      modalInfo.vc_investment_location_s_,
                    judging_track_contact:
                      modalInfo.judging_track_contact,
                    judging_track_2_contact:
                      modalInfo.judging_track_2_contact,
                    final_judging_track_placement_contact:
                      modalInfo.final_judging_track_placement_contact,
                    panel_choice_1_contact:
                      modalInfo.panel_choice_1_contact,
                    panel_choice_2_contact:
                      modalInfo.panel_choice_2_contact,
                    panel_choice_3_contact:
                      modalInfo.panel_choice_3_contact,
                    speaker_panel_contact:
                      modalInfo.speaker_panel_contact,
                    coaching_track_contact:
                      modalInfo.coaching_track_contact,
                    coaching_track_2_contact:
                      modalInfo.coaching_track_2_contact,
                    final_coaching_track_placement_contact:
                      modalInfo.final_coaching_track_placement_contact,
                    notes: modalInfo.notes,
                    speaker_display: modalInfo.speaker_display,
                    bio: modalInfo.bio,
                    vc_s_industry_focuses:
                      modalInfo.vc_s_industry_focuses,
                  },
                });
              // console.log(body);

              modalInfo.id
                ? patchData(
                  "https://youngstartup.io/api/db/attendees/update",
                  body
                )
                  // .then((data) => console.log(data))
                  .then(() =>
                    getData(
                      `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                    ).then((data) => {
                      setData(data);
                      // console.log(data);
                    })
                  )
                  .then(() => {
                    getData(
                      `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
                    ).then((data) => {
                      // console.log(data);
                      setSpeakerStats(data);
                      // console.log(speakerStats);
                    });
                  })
                : createAttendee(
                  "https://youngstartup.io/api/db/attendees/add",
                  body
                )
                  // .then((data) => console.log(data))
                  .then(() =>
                    getData(
                      `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                    ).then((data) => {
                      setData(data);
                      // console.log(data);
                    })
                  )
                  .then(() => {
                    getData(
                      `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
                    ).then((data) => {
                      // console.log(data);
                      setSpeakerStats(data);
                      // console.log(speakerStats);
                    });
                  });

              let headshotBody = {
                body: {
                  id: modalInfo.id,
                  headshot: modalInfo.headshot,
                },
              };
              // console.log(headshotBody);
              // console.log(modalInfo.headshot);
              if (modalInfo.headshot) {
                fetch(
                  "https://youngstartup.io/api/db-a/attendees/uploadspeakerheadshot",
                  {
                    method: "POST",
                    mode: "cors",
                    headers: {
                      "Content-Type": "application/json",
                      tok: localStorage.tok,
                    },
                    redirect: "follow",
                    referrerPolicy: "no-referrer",
                    body: JSON.stringify(headshotBody),
                  }
                )
                  // .then((data) => console.log(data))
                  .then(() => {
                    setSaveText("Saved!");
                    setBtnColor("success");
                    setTimeout(() => {
                      setBtnColor("primary");
                      setSaveText("Save");
                    }, 750);
                    // }, 4000);
                  });
              } else {
                setSaveText("Saved!");
                setBtnColor("success");
                setTimeout(() => {
                  setBtnColor("primary");
                  setSaveText("Save");
                }, 750);
                // }, 4000);
              }
            }}
          >
            {saveText}
          </Button>
        </DialogActions>
      </Dialog>
      <MuiAppBar setFilterObj={setFilterObj} filterObj={filterObj} />
      <div
        style={{
          display: "flex",
          justifyContent: 'center',
          marginTop: '2.5vh',
        }}
      >
        <div style={{ display: 'flex', gap: '15px', alignItems: 'center', flex: 1, paddingLeft: '5vw' }}>
          <button className="filterBtn" onClick={handleClickOpen}>
            <AddIcon />
          </button>
          <button
            onClick={e => {
              let tempData = data;
              tempData = tempData.map(i => { i.submission_status = JSON.stringify(i.submission_status); return i; });
              let xls = new XlsExport(tempData, "exprtedtable");
              // xls.exportToCSV("presenters.csv");
              xls.exportToXLS("presenters.xls");
            }}
            className="filterBtn"
          >
            <FileDownloadIcon />
          </button>
          <button
            className="filterBtn"
            onClick={(e) => {
              setRefresh((old) => old + 1);
            }}
          >
            <RefreshIcon />
          </button>
          <button
            className="filterBtn"
            onClick={e => {
              getData("https://youngstartup.io/api/db-a/attendees/updatepresstats")
                .then(data => {
                  if (data.success) {
                    toast.success("Statuses updated!")
                    setRefresh((old) => old + 1);
                  } else {
                    toast.error("Something went wrong!")
                  }
                });
            }}
          >
            Update Statuses
          </button>
          <Select
            sx={{ minWidth: 120 }}
            labelId="demo-simple-select-label"
            className="filterBtn"
            label="Send Email"
            renderValue={value => <div style={{ color: 'white' }}>{value}</div>}
            //  value="0"
            defaultValue={"Send Email"}
            onChange={(e) => {
              if (selectionModel.length < 1) {
                toast.error("Please select first the people to send the email to!")
                return;
              }
              setConfirmDialogProps({ emailId: e.target.value })
              setConfirmDialogDisplay(true)
            }}
          >
            <MenuItem value={"Send Email"} disabled style={{ fontStyle: 'italic' }}>Send Email</MenuItem>
            {ddOptions.emails_sent?.map(i => <MenuItem value={i.option_value}>{i.option_display_short}</MenuItem>)}
          </Select>
          <Dialog props={confirmDialogProps} open={confirmDialogDisplay} onClose={() => { setConfirmDialogDisplay(false) }}>
            <DialogTitle>Confirm</DialogTitle>
            <Typography
              sx={{ color: "black", margin: "35px" }}
            >
              Confirm send <b>{ddOptions.emails_sent?.filter(i => i.option_value == confirmDialogProps.emailId)[0]?.option_display_short}</b> email to <b>{selectionModel.length}</b> people?
            </Typography>
            <Button
              onClick={e => {
                postData("https://youngstartup.io/api/db-a/attendees/enrollhswf", {
                  body: {
                    ids: selectionModel,
                    wf_id: confirmDialogProps.emailId
                  }
                })
                  .then(data => {
                    if (data.success) {
                      toast.success("Email was sent!")
                    } else {
                      toast.error("something went wrong!")
                    }
                  })
                setConfirmDialogDisplay(false)
              }}
            >Send!</Button>
          </Dialog>
        </div>
        <div style={{ display: 'flex', color: 'white', alignItems: 'center', gap: '25px', flex: 2 }}>
          <Stack>
            <h5 style={{ textAlign: 'center' }}>Top Innovator</h5>
            <div style={{ display: 'flex', gap: '7.5px' }}>
              <button className="presenter-btn">
                Applied to Present
              </button>
              <button className="presenter-btn">
                Selected
              </button>
              <button className="presenter-btn">
                Confirmed & Paid
              </button>
            </div>
          </Stack>
          <Divider orientation="vertical" sx={{ height: '75px !important' }} />
          <Stack>
            <h5 style={{ textAlign: 'center' }}>Seed Stage</h5>
            <div style={{ display: 'flex', gap: '7.5px' }}>
              <button className="presenter-btn">
                Applied to Present
              </button>
              <button className="presenter-btn">
                Selected
              </button>
              <button className="presenter-btn">
                Confirmed & Paid
              </button>
            </div>
          </Stack>

        </div>
      </div>
      <div>
        <Toaster />
      </div>
      {/* <div style={{ width: "100%", color: "white" }}>
        <p> Event: {event}</p>
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1.75%",
          alignItems: "center",
          width: "100%",
          justifyContent: "left",
          color: 'white',
          marginTop: '1vh',
          marginBottom: '1vh'
        }}
      >
        <div style={{ marginLeft: "5%" }}>Filters:</div>
        <button
          className="filterBtn"
          style={{
            color: filterObj.final_presenting_track ? "#f0ad4e" : "white",
          }}
          onClick={(e) => {
            anchorEl == null
              ? setAnchorEl(e.currentTarget)
              : setAnchorEl(null);
            setPopperContent({
              title: "Track",
            });
          }}
        >
          Track
        </button>
        <button
          className="filterBtn"
          style={{ color: filterObj.opted_in_coaching ? "#f0ad4e" : "white" }}
          onClick={(e) => {
            anchorEl == null
              ? setAnchorEl(e.currentTarget)
              : setAnchorEl(null);
            setPopperContent({
              title: "Coaching",
            });
          }}
        >
          Coaching
        </button>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          sx={{ backgroundColor: "#000120" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <ClickAwayListener onClickAway={(e) => setAnchorEl(null)}>
            <div
              style={{
                border: "1px solid grey",
                color: "white",
                padding: "1rem",
                backgroundColor: "#000120",
                display: "flex",
                flexDirection: "column",
                maxWidth: "200px !important",
              }}
            >
              <div style={{ fontSize: ".9rem" }}>
                {popperContent.title}
              </div>
              {popperContent.title !== "Rejected/Cancelled" &&
                popperContent.title !== "Featured" &&
                (popperContent.title == "Track" ? (
                  <Autocomplete
                    multiple
                    sx={styles}
                    value={
                      filterObj.final_presenting_track
                        ? filterObj.final_presenting_track.split(";").filter((i) => i)
                        : []
                    }
                    id="checkboxes-tags-demo"
                    onChange={(e, value) => {
                      // console.log(value);
                      let filterValue =
                        value.length > 1
                          ? value.join(";")
                          : value[0]
                            ? value[0] + ";"
                            : "";
                      setFilterObj({ ...filterObj, final_presenting_track: filterValue });
                    }}
                    options={ddOptions.final_presenting_track.map(
                      (i) => i.option_value
                    )}
                    disableCloseOnSelect
                    getOptionLabel={(option) => {
                      // console.log(option);
                      return option;
                    }}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8, color: "black" }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      );
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Checkboxes"
                        placeholder="Tracks..."
                        autoFocus
                      />
                    )}
                  />
                ) : popperContent.title == "Emails" ? (
                  <Autocomplete
                    multiple
                    sx={styles}
                    value={filterObj.emails_sent
                      ? ddOptions.emails_sent?.filter(i => filterObj.emails_sent.split(";").filter((i) => i).includes(i.option_value))
                      : []}
                    id="checkboxes-tags-demo"
                    onChange={(e, value) => {
                      let filterValue =
                        value.length > 1
                          ? value.map(i => i.option_value).join(";")
                          : value[0]
                            ? value[0].option_value + ";"
                            : "";
                      setFilterObj({
                        ...filterObj,
                        emails_sent: filterValue,
                      })
                    }}
                    options={
                      ddOptions.emails_sent
                    }
                    disableCloseOnSelect
                    getOptionLabel={(option) => {
                      // console.log(option);
                      return option.option_display_short;
                    }}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8, color: "black" }}
                            checked={selected}
                          />
                          {option.option_display_short}
                        </li>
                      );
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Checkboxes"
                        autoFocus
                        placeholder={
                          popperContent.title == "Coaching"
                            ? "Coaching..."
                            : ""
                        }
                      />
                    )}
                  />
                ) : "")}
              {popperContent.title == "Featured" && (
                <Autocomplete
                  multiple
                  sx={styles}
                  value={
                    filterObj.speaker_display
                      ? filterObj.speaker_display
                        .split(";")
                        .filter((i) => i)
                      : []
                  }
                  id="checkboxes-tags-demo"
                  onChange={(e, value) => {
                    // console.log(value);
                    let filterValue =
                      value.length > 1
                        ? value.join(";")
                        : value[0]
                          ? value[0] + ";"
                          : "";
                    setFilterObj({
                      ...filterObj,
                      speaker_display: filterValue,
                    });
                  }}
                  options={options.speaker_display.map(
                    (i) => i.option_display_short
                  )}
                  disableCloseOnSelect
                  getOptionLabel={(option) => {
                    // console.log(option);
                    return option;
                  }}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8, color: "black" }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Checkboxes"
                      placeholder="Featured"
                      autoFocus
                    />
                  )}
                />
              )}
            </div>
          </ClickAwayListener>
        </Popper>
        <button
          className="filterBtn"
          style={{ color: filterObj.judging_track ? "#f0ad4e" : "white" }}
          onClick={(e) => {
            anchorEl == null
              ? setAnchorEl(e.currentTarget)
              : setAnchorEl(null);
            setPopperContent({
              title: "Stats",
            });
          }}
        >
          Stats
        </button>
        <button
          className="filterBtn"
          style={{
            color: filterObj.coaching_track ? "#f0ad4e" : "white",
          }}
          onClick={(e) => {
            anchorEl == null
              ? setAnchorEl(e.currentTarget)
              : setAnchorEl(null);
            setPopperContent({
              title: "Emails",
            });
          }}
        >
          Emails
        </button>

        {/* <Divider
                orientation="vertical"
                flexItem
                sx={{ height: "75px !important" }}
              /> */}
        <button
          className="filterBtn"
          style={{
            border: "1px solid #F0AD4E",
            color: "#F0AD4E",
            display: "flex",
            flexDirection: "row",
          }}
          onClick={(e) => {
            let newurl =
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname;
            window.history.pushState({ path: newurl }, "", newurl);
            let temp = filterObj;
            for (const prop in temp) {
              temp[prop] = "";
            }
            setFilterObj({ ...temp });
            setData(backupData);
          }}
        >
          Clear Filters
        </button>
      </div>
      <div style={{ height: "60vh" }}>
        <div style={{ width: "100%", height: "100%" }}>
          <DataGrid
            checkboxSelection={true}
            disableSelectionOnClick
            rows={data}
            columns={columns}
            rowHeight={140}
            onCellEditCommit={handleCellFocusOut}
            sx={{
              color: "white !important",
              border: "none",
              backgroundColor: "#000120",
              fontFamily: "Manrope, sans-serif",
            }}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
          />
        </div>
      </div>
    </>
  );
};
