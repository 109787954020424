import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

export const AttendeeAddForm = (props) => {
  return (
    <form
      style={{
        display: props.attendeeForm,
        backgroundColor: "gray",
        width: "fit-content",
        padding: "1rem",
        height: "fit-content",
        borderRadius: "5px",
        zIndex: "1",
        position: "absolute",
        top: "30%",
        right: "20%",
      }}
    >
      <CloseIcon onClick={() => props.setAttendeeForm("none")} />
      <Stack spacing={3}>
        <TextField id="outlined-basic" label="First Name" variant="outlined" />
        <TextField id="outlined-basic" label="Last Name" variant="outlined" />
        <TextField id="outlined-basic" label="Email" variant="outlined" />
        <TextField id="outlined-basic" label="Company" variant="outlined" />
      </Stack>
      <Stack spacing={3}>
        <TextField id="outlined-basic" label="Company ID" variant="outlined" />
        <TextField id="outlined-basic" label="Speaker Panel Contact" variant="outlined" />
        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
      </Stack>
      <Stack spacing={3}>
        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
      </Stack>
      <div style={{display:'inline-block',margin:'5%'}}>
        <Button variant="contained" color="primary">Add Attendee</Button>
      </div>
    </form>
  );
};
