//REACT IMPORTS
import { useEffect, useState } from "react";

//MUI IMPORTS
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
// import Select from "@mui/material/Select";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";

//OTHER IMPORTS
import toast from "react-hot-toast";

//LOCAL IMPORTS
import {
  HoverButton, MuiAppBar, StatsDisplay, PresenterGrid, Schedule, SelectOldPresenters, DetailsPopup,
  presenterColumns
} from "../components/index";

import { getData, patchData, postData } from "../api/apiCalls";
import { addOrUpdateQueryParam } from "../utils/funcs";

export const Presenters = () => {
  const [data, setData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [searchedData, setSearchedData] = useState({});
  const [searchedFilteredData, setSearchedFilteredData] = useState({});
  const [checked, setChecked] = useState({
    email_confirmed: false,
    opened_dashboard: false,
    submission_status: false,
  });
  const [checked2, setChecked2] = useState({
    email_confirmed: false,
    opened_dashboard: false,
    submission_status: false,
  });
  const [buttons, setButtons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog3, setOpenDialog3] = useState(false);
  const [openDialog4, setOpenDialog4] = useState(false);
  const [openDialog5, setOpenDialog5] = useState(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState({});
  const [confirmDialogDisplay, setConfirmDialogDisplay] = useState(false);
  const [stats, setStats] = useState({});
  const [colors, setColors] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [currentCell, setCurrentCell] = useState(null);
  const [err, setErr] = useState(false);
  const [clearSelection, setClearSelection] = useState(0);
  const [secondaryContact, setSecondaryContact] = useState([]);
  const [deadlinesStructure, setDeadlinesStructure] = useState([]);
  const [startupInputs, setStartupInputs] = useState({
    id: "",
    rivka_stage: "",
    revenue_positive: "",
    funding_raised: "",
    funding_sought: "",
    company_name: "",
    firstname: "",
    lastname: "",
    website: "",
    phone_number: "",
    email: "",
    primary_industry: "",
    industry_sub_category: "",
    street: "",
    city: "",
    state: "",
    country: "",
    company_stage: "",
    description: "",
    milestones: "",
    use_of_proceeds: "",
    management_team: "",
    current_investors: "",
    secondary_contacts: [],
    headshot_url: "",
    logo_url: "",
    one_liner: "",
    target_markets: "",
    competition: "",
    law_firm: "",
    bank: "",
    accounting_firm: "",
    presentation_link: "",
  });
  const [contactInputs, setContactInputs] = useState({
    name: "",
    email: "",
    id: "",
  });
  const [ddOptions, setDdOptions] = useState();
  const [trackOptions, setTrackOptions] = useState([]);
  const [multipleSelect, setMultipleSelect] = useState([]);
  const [attendeeIdArr, setAttendeeIdArr] = useState([]);
  const queryString = window.location.search || "?stage=applied&company_stage=top";
  // console.log("q5", queryString)
  // console.count("q6")
  const [fetchUrl, setFetchUrl] = useState(`https://youngstartup.io/api/db/presenters/getallapplications${queryString}`);
  // console.log("q7", fetchUrl)


  const [ticketsDetails, setTicketsDetails] = useState([]);

  let params = new URLSearchParams(document.location.search);
  let urlStage = params.get("stage");
  // let urlCompanyStage = params.get("company_stage");

  const [searchInput, setSearchInput] = useState("");
  const [dashboardStatus, setDashboardStatus] = useState("off");

  useEffect(() => {
    if (urlStage === "confirmed") {
      getData("https://youngstartup.io/api/db-info/property-options-dd/all_attendees")
        .then(data => {
          const dlArray = data.pres_deadlines;
          setDeadlinesStructure(dlArray);
        })
    }
  }, []);

  const handleClickOpen = (cell) => {
    setSecondaryContact(JSON.parse(cell.row.secondary_contacts));
    setCurrentCell(cell);
    if (Array.isArray(JSON.parse(cell.row.secondary_contacts))) {
      setContactInputs({
        name: JSON.parse(cell.row.secondary_contacts)[0]?.name,
        email: JSON.parse(cell.row.secondary_contacts)[0]?.email,
        id: cell.row.id,
      });
    } else {
      setContactInputs({
        name: "",
        email: "",
        id: cell.row.id,
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen2 = (cell) => {
    setOpenDialog2(true);
  };

  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };

  const handleClickOpen3 = (cell) => {
    console.log(cell)
    setOpenDialog3(true);
  };

  // const handleCloseDialog3 = () => {
  //   setOpenDialog3(false);
  // };

  const handleClickOpen4 = (cell) => {
    const attendeeId = cell.row.attendee_id;
    getData("https://youngstartup.io/api/db-a/attendees/getpresenterdetails/" + attendeeId)
      .then((data) => {
        setTicketsDetails(data.passes);
      })
    setOpenDialog4(true);
    setCurrentCell(cell);
    setStartupInputs({
      rivka_stage: cell.row.rivka_stage ? cell.row.rivka_stage : "applied",
      revenue_positive: cell.row.revenue_positive,
      funding_raised: cell.row.funding_raised,
      funding_sought: cell.row.funding_sought,
      company_name: cell.row.company_name,
      firstname: cell.row.firstname,
      lastname: cell.row.lastname,
      website: cell.row.website,
      phone_number: cell.row.phone_number,
      email: cell.row.email,
      primary_industry: cell.row.primary_industry,
      street: cell.row.street,
      city: cell.row.city,
      state: cell.row.state,
      industry_sub_category: cell.row.industry_sub_category,
      country: cell.row.country,
      company_stage: cell.row.company_stage,
      id: cell.row.id,
      description: cell.row.description,
      milestones: cell.row.milestones,
      use_of_proceeds: cell.row.use_of_proceeds,
      management_team: cell.row.management_team,
      current_investors: cell.row.current_investors,
      secondary_contacts: JSON.parse(cell.row.secondary_contacts) || [],
      headshot_url: cell.row.headshot_url,
      logo_url: cell.row.logo_url,
      one_liner: cell.row.one_liner,
      target_markets: cell.row.target_markets,
      competition: cell.row.competition,
      law_firm: cell.row.law_firm,
      bank: cell.row.bank,
      accounting_firm: cell.row.accounting_firm,
      presentation_link: cell.row.presentation_link,
    });
  };

  const handleCloseDialog4 = () => {
    setOpenDialog4(false);
    setTicketsDetails([]);
    // setTimeout(
    //   () =>
    //     setStartupInputs({
    //       rivka_stage: "",
    //       revenue_positive: "",
    //       funding_raised: "",
    //       funding_sought: "",
    //       company_name: "",
    //       firstname: "",
    //       lastname: "",
    //       website: "",
    //       phone_number: "",
    //       email: "",
    //       primary_industry: "",
    //       industry_sub_category: "",
    //       country: "",
    //       company_stage: "",
    //     }),
    //   2000
    // );
  };

  const handleClickOpen5 = () => {
    setOpenDialog5(true);
  };

  const handleCloseDialog5 = () => {
    setOpenDialog5(false);
    handleCloseActions();
  };

  const changeStartupInputs = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    // let name2;
    // let value2;
    // console.log(name, value)
    // console.log("debug", startupInputs.secondary_contacts)
    // debugger
    // if (name === "secondary_contacts_name") {
    //   if (startupInputs.secondary_contacts.length < 1) {
    //     name2 = "secondary_contacts";
    //     value2 = [{ name: e.target.value, email: "" }]
    //   } else {
    //     name2 = "secondary_contacts";
    //     value2 = [{ name: e.target.value, email: startupInputs.secondary_contacts.email }]
    //   }
    // } else if (name === "secondary_contacts_email") {
    //   if (startupInputs.secondary_contacts.length < 1) {
    //     name2 = "secondary_contacts";
    //     value2 = [{ name: "", email: e.target.value }]
    //   } else {
    //     name2 = "secondary_contacts";
    //     value2 = [{ name: startupInputs.secondary_contacts.email, email: e.target.value }]
    //   }
    // }

    // if (name2) {
    //   setStartupInputs({
    //     ...startupInputs,
    //     [name2]: value2,
    //   });
    //   return;
    // }
    setStartupInputs({
      ...startupInputs,
      [name]: value,
    });
  };

  const handleFilterChange = async (e) => {
    const name = e.target.name;
    const value = e.target.checked;

    //THIS SETS OFF THE "CHECKED" USEEFFECT
    setChecked({
      ...checked,
      [name]: value,
    });
  };

  const handleFilterChange2 = async (e) => {
    const name = e.target.name;
    const value = e.target.checked;

    //THIS SETS OFF THE "CHECKED" USEEFFECT
    setChecked2({
      ...checked,
      [name]: value,
    });
  };

  const handleErrMsg = () => {
    setErr(true);
    handleClickOpen2();
    setTimeout(() => {
      handleCloseDialog2();
    }, 2000);
  };

  const handleSuccessMsg = () => {
    setErr(false);
    handleClickOpen2();
    setTimeout(() => {
      handleCloseDialog2();
      handleCloseDialog4();
    }, 750);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // console.count("default")

  const removeUser = (id) => {
    // alert(id)
    let data1;
    // console.log("test", filteredData)
    if (Array.isArray(filteredData) && !fetchUrl.includes("applied")) {
      // console.log("t1", filteredData)

      data1 = filteredData.filter(x => x.id !== id);
      // setFilteredData(() => ([]));
      // setSearchedFilteredData(() => ([]));

      // data1 = structuredClone(temp);
      // console.log("g1", data1)
    } else if (!fetchUrl.includes("applied")) {
      // console.log("t2", filteredData)

      let res = {};
      for (let obj in filteredData) {
        let temp = filteredData[obj].filter(x => x.id !== id);
        // console.log("temp", temp)
        res[obj] = temp;
      }
      data1 = res;
    } else {
      // console.log("t3", filteredData)
    }
    // console.log("data11", data1)

    setFilteredData(() => data1);
    setSearchedFilteredData(data1);
  }

  const fetchData = async () => {
    // console.log("test1")
    setLoading(true);
    // console.log("fetchUrl", fetchUrl)
    // const queryString = window.location.search || "?stage=applied&company_stage=top";
    // const [fetchUrl, setFetchUrl] = useState(`https://youngstartup.io/api/db/presenters/getallapplications${queryString}`);

    let data1 = await getData(fetchUrl);
    data1 = data1.data;

    //sort data by status change, newest first
    if (Array.isArray(data1) && !fetchUrl.includes("applied")) {
      // console.log("test2")

      data1.sort((a, b) => {
        return JSON.parse(b.rivka_status).at(-1).id - JSON.parse(a.rivka_status).at(-1).id;
        //for applied
        // return b.rivka_last_highest_status?.id - a.rivka_last_highest_status?.id;
      })
    } else if (!fetchUrl.includes("applied")) {
      // console.log("test3")

      let res = {};
      for (let obj in data1) {
        // data1[obj].sort((a, b) => {
        //   return JSON.parse(b.rivka_status).at(-1).id - JSON.parse(a.rivka_status).at(-1).id;
        //   //for applied
        //   // return  b.rivka_last_highest_status?.id - a.rivka_last_highest_status?.id;
        // })
        res[obj] = data1[obj];
      }
      data1 = res;
    }
    else {
      // console.log("test4")
    }
    setData(data1);
    setFilteredData(data1);
    // console.log("data", data1)
    setLoading(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let q = params.get("q")

    if (!q) {
      setSearchedData(data);
      setSearchedFilteredData(filteredData);
    }
    else {
      // q = q.replace("%40", "@");
      setSearchInput(q)
      handleSearch({ target: { value: q } })
    }

  }, [filteredData, deadlinesStructure])

  const handleSearch = (ev) => {
    const rawValue = ev.target.value;
    setSearchInput(rawValue);
    const value = rawValue.toLowerCase();
    addOrUpdateQueryParam("q", value);

    let results;
    if (Array.isArray(data)) {
      results = data.filter((el) => {
        return (
          el.firstname?.toLowerCase().includes(value) ||
          el.lastname?.toLowerCase().includes(value) ||
          el.email?.toLowerCase().includes(value) ||
          el.company_name?.toLowerCase().includes(value) ||
          el.country?.toLowerCase().includes(value) ||
          el.website?.toLowerCase().includes(value)
        )
      })
      setSearchedData(results);
    }
    else {
      let res = {};
      for (let obj in filteredData) {
        results = filteredData[obj].filter((el) => {
          return (
            el.firstname.toLowerCase().includes(value) ||
            el.lastname.toLowerCase().includes(value) ||
            el.email.toLowerCase().includes(value) ||
            el.company_name.toLowerCase().includes(value) ||
            el.country.toLowerCase().includes(value) ||
            el.website.toLowerCase().includes(value)
          )
        })
        if (results.length) {
          res[obj] = results;
        }
      }
      setSearchedFilteredData(res);
    }
  }

  const refreshStats = () => {
    getData(
      `https://youngstartup.io/api/db/presenters/get_application_stats`
    ).then((data) => setStats(data));
  };

  useEffect(() => {
    const dataCopy = data;

    Object.entries(dataCopy)?.map(([track, value]) => {
      // console.log(checked);
      let filters = Object.entries(checked).filter(([key, value]) => value);
      //THIS FUNCTION SHOULD SET OFF THE USEEFFECT BELOW AND CONSOLE LOG FILTERED DATA - BUT IT DOESN'T
      let temp = value.filter((row) => {
        for (let i = 0; i < filters.length; i++) {
          // console.log(filters[i][0]);
          // console.log(row[filters[i][0]]);
          Object.entries(row).map(([key, value]) => { });
          return row[filters[i][0]] ? true : false;
        }
      });

      // console.log(temp);

      setFilteredData((old) => ({
        ...old,
        [track]: temp,
      }));
    });
  }, [checked]);

  useEffect(() => {
    const isEmpty = Object.keys(searchedFilteredData).length === 0;
    const dataCopy = isEmpty ? data : searchedFilteredData;

    // const isEmpty = Object.keys(filteredData).length === 0;
    // const dataCopy = isEmpty ? data : filteredData;

    Object.entries(dataCopy).map(([track, value]) => {
      // console.log(checked2);
      let filters = Object.entries(checked2).filter(([key, value]) => value);
      // console.log(filters);
      // console.log(track);
      //THIS FUNCTION SHOULD SET OFF THE USEEFFECT BELOW AND CONSOLE LOG FILTERED DATA - BUT IT DOESN'T
      let temp = value.filter((row) => {
        for (let i = 0; i < filters.length; i++) {
          // console.log(row[filters[i][0]])
          return row[filters[i][0]] ? false : true;
        }
      });

      // console.log(temp);

      setFilteredData((old) => ({
        ...old,
        [track]: temp,
      }));
    });
  }, [checked2]);

  // useEffect(() => {
  //   console.log("urlstage1", urlStage)
  //   console.log("urlstage2", fetchUrl)
  // }, [urlStage, fetchUrl])

  useEffect(() => {
    getData(`https://youngstartup.io/api/db/presenters/get_application_stats`)
      .then((data) => setStats(data));
    setLoading(true);
    getData(`https://youngstartup.io/api/db-info/property-options-dd/presenters`)
      .then((data) => {
        setLoading(false);
        setDdOptions(data);
        setButtons(data.rivka_stage);
        setTrackOptions(data.final_presenting_track);
        setColors(data.rivka_color);
      });

    let params = new URLSearchParams(document.location.search);
    let urlStage = params.get("stage");

    if (
      urlStage === "selected" ||
      urlStage === "not going ahead" ||
      urlStage === "pitch" ||
      urlStage === "so_inclusion"
    ) {
      setCurrentColumn(columnsLevel2);
    } else if (
      urlStage === "applied" ||
      urlStage === null
    ) {
      setCurrentColumn(columnsLevel1);
    } else if (
      urlStage === "maybe" ||
      urlStage === "rejected"
    ) {
      setCurrentColumn(columnsLevel4);
    } else {
      setCurrentColumn(columnsLevel3);
    }

    fetchData();

    //set dashboard access on or off
    fetch("https://youngstartup.io/api/cwebsite/get_current_event")
      .then(res => res.json())
      .then(data => setDashboardStatus((data.presenter_dash_access === "on") ? "on" : "off"))
  }, []);

  useEffect(() => {
    // console.log("test")
    setLoading(true);
    fetchData();
    // refreshStats();
  }, [fetchUrl]);

  // useEffect(() => {
  //   console.log(multipleSelect);
  // }, [multipleSelect]);

  // useEffect(() => {
  //   console.log("data changed");
  // }, [data]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { columnsLevel1, columnsLevel2, columnsLevel3, columnsLevel4 } = presenterColumns(
    handleErrMsg, handleSuccessMsg, setData, setStats, refreshStats, handleClickOpen4, fetchUrl, colors,
    fetchData, handleClickOpen, ddOptions, setStartupInputs, deadlinesStructure, fetchData);

  const [currentColumn, setCurrentColumn] = useState();
  // const [currentColumn, setCurrentColumn] = useState(columnsLevel1);

  const [openActions, setOpenActions] = useState(false);
  const [anchorElActions, setAnchorElActions] = useState(null);
  const handleClickActions = (e) => {
    setOpenActions(true);
    setAnchorElActions(e.currentTarget);
  }
  const handleCloseActions = () => {
    setOpenActions(false);
    setAnchorElActions(null);
  }

  const [showOldPresenters, setShowOldPresenters] = useState(false);
  const handleClickOldPresenters = () => {
    setShowOldPresenters(true)
  }
  const handleCloseOldPresenters = () => {
    setShowOldPresenters(false);
    handleCloseActions();
  }

  const [openEmails, setOpenEmails] = useState(false);
  const [anchorElEmails, setAnchorElEmails] = useState(null);
  const [openReports, setOpenReports] = useState(false);
  const [anchorElReports, setAnchorElReports] = useState(null);
  const handleClickEmails = (e) => {
    setOpenEmails(true);
    setAnchorElEmails(e.currentTarget);

  }
  const handleCloseEmails = () => {
    setOpenEmails(false);
    setAnchorElEmails(null);
    handleCloseActions();
  }
  const handleClickReports = (e) => {
    setOpenReports(true);
    setAnchorElReports(e.currentTarget);

  }
  const handleCloseReports = () => {
    setOpenReports(false);
    setAnchorElReports(null);
    handleCloseActions();
  }
  const [openAccounts, setOpenAccounts] = useState(false);
  const handleClickAccounts = () => {
    setOpenAccounts(true);
  }
  const handleCloseAccounts = () => {
    setOpenAccounts(false);
    handleCloseActions();
  }

  //toggles dashboard access status
  const handleToggleAccounts = () => {
    postData("https://youngstartup.io/api/db-a/presenters/change_dash_status", { body: { status: (dashboardStatus === "on") ? "off" : "on" } })
      .then(res => {
        if (res.success) {
          handleCloseAccounts();
          setDashboardStatus((dashboardStatus === "on") ? "off" : "on");
        }
      })
  }

  return (
    <div style={{ backgroundColor: "#000120" }}>
      <div >
        <MuiAppBar />
        <section className="action-bar">
          <div
            className="action-btn-container"
            style={{
              flexDirection: "column",
              gap: "15px",
              paddingLeft: "2.5%",
            }}
          >
            <div style={{ display: "flex", gap: "15px" }}>
              {/* <HoverButton
              initialUrl={"/assets/Property 1=Default (1).png"}
              hoverUrl={"/assets/Property 1=Variant2 (1).png"}
              className={"presenter-action-btn"}
            />
            <HoverButton
              initialUrl={"/assets/Property 1=Default (2).png"}
              hoverUrl={"/assets/Property 1=Variant2 (2).png"}
              className={"presenter-action-btn"}
              onClick={(e) => {
                getData(fetchUrl).then((data) => {
                  console.log(data);
                });
              }}
            /> */}

              <Button variant="outlined" style={{ color: "white", border: "white solid " }}
                onClick={handleClickActions}
              >
                Actions
              </Button>
              <Popover
                open={openActions}
                onClose={handleCloseActions}
                style={{ border: "2px solid white" }}
                anchorEl={anchorElActions}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              // transformOrigin={{
              //   vertical: "top",
              //   horizontal: "left",
              // }}

              >
                <div className="popover-contents" style={{ width: "auto" }}>
                  <Stack spacing={0.5} style={{ color: "white", width: "auto" }}>

                    <div
                      onClick={handleClickOpen3}
                      style={{ cursor: "pointer" }}
                    >
                      <Badge badgeContent={stats.missing_deadlines} color="error" anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}><span>Set Deadlines</span>
                      </Badge>
                      <span style={{ color: "#F0AD4E", float: "right" }}>&#11208;</span>
                    </div>

                    <div
                      onClick={handleClickOpen5}
                      style={{ cursor: "pointer" }}
                    >
                      <Badge badgeContent={stats.missing_in_schedule} color="error">Set Schedule</Badge> <span style={{ color: "#F0AD4E", float: "right" }}>&#11208;</span>
                    </div>

                    <div
                      onClick={handleClickEmails}
                      style={{ cursor: "pointer" }}
                    >
                      Send e-mails <span style={{ color: "#F0AD4E", float: "right" }}>&#11208;</span>
                    </div>
                    <Popover
                      open={openEmails}
                      anchorEl={anchorElEmails}
                      onClose={handleCloseEmails}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {ddOptions?.emails_sent?.map((e, i) => (
                        <MenuItem key={i} value={e.option_value} onClick={() => {
                          setConfirmDialogDisplay(true);
                          setConfirmDialogProps({ ...confirmDialogProps, emailId: e.option_value })
                        }}>
                          {e.option_display_short}
                        </MenuItem>
                      ))}
                    </Popover>

                    <div
                      onClick={handleClickAccounts}
                      style={{ cursor: "pointer" }}
                    >
                      Turn {dashboardStatus === "on" ? "off" : "on"} Accounts <span style={{ color: "#F0AD4E", float: "right" }}>&#11208;</span>
                    </div>

                    <Dialog open={openAccounts} onClose={handleCloseAccounts}
                      PaperProps={{
                        style: {
                          background: "#303265",
                          color: "white",
                          border: "2px solid white",
                          width: "300px",
                          textAlign: "center",
                          padding: "15px"
                        }
                      }}
                    >
                      <DialogTitle>Are You Sure?</DialogTitle>
                      <DialogContent>
                        <DialogContentText style={{ color: "white" }}>
                          Note: You are turning on all presenter accounts for all users that have deadlines
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions style={{ justifyContent: "space-around" }}>
                        <Button style={{ backgroundColor: "#05861A", color: "white", border: "2px solid white", }} onClick={handleToggleAccounts}>Yes</Button>
                        <Button style={{ backgroundColor: "#D70026", color: "white", border: "2px solid white" }} onClick={handleCloseAccounts}>No</Button>
                      </DialogActions>
                    </Dialog>

                    <div
                      onClick={handleClickOldPresenters}
                      style={{ cursor: "pointer" }}
                    >
                      Select old presenters <span style={{ color: "#F0AD4E", float: "right", paddingLeft: "20px" }}>&#11208;</span>
                    </div>

                    <div
                      onClick={handleClickReports}
                      style={{ cursor: "pointer" }}
                    >
                      Download Reports <span style={{ color: "#F0AD4E", float: "right" }}>&#11208;</span>
                    </div>
                    <Popover
                      open={openReports}
                      anchorEl={anchorElReports}
                      onClose={handleCloseReports}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {ddOptions?.reports?.map((e, i) => (
                        <MenuItem key={i} value={e.option_value} onClick={() => {
                          // console.log(e.option_link)
                          // getFile(e.option_link, "GET");
                        }}>
                          <a style={{ color: "white" }} href={e.option_link + "?refresh_cache=" + Date.now()} download>{e.option_display_short}</a>
                          {/* {e.option_display_short} */}
                        </MenuItem>
                      ))}
                    </Popover>
                  </Stack>

                </div>
              </Popover>

              {/* <Button variant="outlined" onClick={handleClickOldPresenters} style={{ backgroundColor: "white" }}>Old Presenters</Button> */}
              <SelectOldPresenters open={showOldPresenters} handleClose={handleCloseOldPresenters} handleCloseActions={handleCloseActions} handleSuccessMsg={handleSuccessMsg} handleErrMsg={handleErrMsg} />

              <div style={{ position: "relative" }}>
                <TextField
                  id="outlined-basic"
                  label={<span>
                    <SearchIcon style={{ color: "#A19191", verticalAlign: "middle" }} />
                    Search this table only
                  </span>}

                  autoComplete="off"
                  variant="outlined"
                  sx={{
                    ml: 5,
                    color: "white",
                    minWidth: "300px",
                    input: { color: "white" },
                    textarea: { color: "white" },
                    "&": {
                      color: "white !important",
                      backgroundColor: "#303265",
                      borderRadius: "5px",
                    },
                    "& label": {
                      color: "lightgray",
                    },
                    "& label.Mui-focused": {
                      color: "lightgray",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white",
                      color: "white",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "green",
                      color: "white",
                    },
                    "& .MuiInputBase-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                        color: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                        color: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                        color: "white",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                        color: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                        color: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                        color: "white",
                      },
                    },
                  }}
                  onChange={handleSearch}
                  // onChange={(e) => { setSearchInput(e.target.value); handleSearch(e.target.value) }}
                  value={searchInput}
                />
              </div>
              {fetchUrl.includes("confirmed") && (
                <>
                  <HoverButton
                    initialUrl={"/assets/Property 1=Default (13).png"}
                    hoverUrl={"/assets/Property 1=Variant2 (12).png"}
                    onClick={handleClick}
                    className={"presenter-action-send-email"}
                  />{" "}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <table
                        style={{ backgroundColor: "#303265", color: "white" }}
                      >
                        <tbody>
                          <tr>
                            <td>Confirmed</td>
                            <td>
                              <Checkbox
                                checked={checked.confirmed}
                                className="filter-check"
                                name="email_confirmed_2"
                                onChange={handleFilterChange}
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{ borderColor: "green" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Logged In</td>
                            <td>
                              <Checkbox
                                checked={checked.opened_dashboard}
                                className="filter-check"
                                name="opened_dashboard"
                                onChange={handleFilterChange}
                                inputProps={{ "aria-label": "controlled" }}
                                color="success"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Presentation</td>
                            <td>
                              <Checkbox
                                checked={checked.presentation_link}
                                className="filter-check"
                                name="presentation_link"
                                onChange={handleFilterChange}
                                inputProps={{ "aria-label": "controlled" }}
                                color="success"
                              />
                            </td>
                          </tr>
                          {/* <tr>
                      <td>H, L, O-L</td>
                      <td>
                        <Checkbox
                          checked={checked.presentation_link}
                          className="filter-check"
                          name="presentation_link"
                          onChange={handleFilterChange}
                          inputProps={{ "aria-label": "controlled" }}
                          color="success"
                        />
                      </td>
                    </tr> */}
                        </tbody>
                      </table>
                      <table
                        style={{ backgroundColor: "#303265", color: "white" }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <Checkbox
                                checked={checked2.confirmed}
                                className="filter-check-2"
                                name="email_confirmed_2"
                                onChange={handleFilterChange2}
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{ borderColor: "green" }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Checkbox
                                checked={checked2.opened_dashboard}
                                className="filter-check-2"
                                name="opened_dashboard"
                                onChange={handleFilterChange2}
                                inputProps={{ "aria-label": "controlled" }}
                                color="success"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Checkbox
                                checked={checked2.presentation_link}
                                className="filter-check-2"
                                name="presentation_link"
                                onChange={handleFilterChange2}
                                inputProps={{ "aria-label": "controlled" }}
                                color="success"
                              />
                            </td>
                          </tr>
                          {/* <tr>
                      <td>
                        <Checkbox
                          checked={checked.presentation_link}
                          className="filter-check-2"
                          name="presentation_link"
                          onChange={handleFilterChange2}
                          inputProps={{ "aria-label": "controlled" }}
                          color="success"
                        />
                      </td>
                    </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </Popover>
                </>
              )}
            </div>
            <div>
              {ddOptions &&
                <div
                  style={{
                    display: "flex",
                    gap: "2.5%",
                    visibility: !fetchUrl.includes("confirmed") && "hidden",
                  }}
                >
                  {/* <HoverButton
                  initialUrl={"/assets/Property 1=Default (10).png"}
                  hoverUrl={"/assets/Property 1=Variant2 (10).png"}
                  className={"presenter-action-send-email"}
                  onClick={handleClickOpen3}
                /> */}

                  {/* <Select
                  sx={{ minWidth: 120 }}
                  size="small"
                  labelId="demo-simple-select-label"
                  className="filterBtn"
                  label="Send Email"
                  renderValue={(value) => (
                    <div style={{ color: "white" }}>Send Email</div>
                  )}
                  //  value="0"
                  defaultValue={"Send Email"}
                  onChange={(e) => {
                    if (multipleSelect.length < 1) {
                      toast.error(
                        "Please select first the people to send the email to!"
                      );
                      return;
                    }
                    setConfirmDialogProps({ emailId: e.target.value });
                    setConfirmDialogDisplay(true);
                  }}
                >
                  <MenuItem
                    value={"Send Email"}
                    disabled
                    style={{ fontStyle: "italic" }}
                  >
                    Send Email
                  </MenuItem>
                  {ddOptions?.emails_sent?.map((e, i) => (
                    <MenuItem key={i} value={i.option_value} onClick={ev => {

                      setConfirmDialogDisplay(true);
                    }}>
                      {e.option_display_short}
                    </MenuItem>
                  ))}
                </Select> */}

                  <Dialog
                    props={confirmDialogProps}
                    open={confirmDialogDisplay}
                    onClose={() => {
                      setConfirmDialogDisplay(false);
                    }}
                  >
                    <DialogTitle>Confirm</DialogTitle>
                    <Typography sx={{ color: "black", margin: "35px" }}>
                      Confirm send{" "}
                      <b>
                        {
                          ddOptions.emails_sent?.filter(
                            (i) => i.option_value == confirmDialogProps.emailId
                          )[0]?.option_display_short
                        }
                      </b>{" "}
                      email to <b>{new Set(multipleSelect).size}</b>{" "}
                      people?
                    </Typography>
                    <Button
                      onClick={(e) => {
                        let uniqueSelect = [...new Set(multipleSelect)];
                        // console.log(uniqueSelect)
                        let finalResult = [];
                        let result = uniqueSelect.map((id, i) => {
                          if (Array.isArray(data)) {
                            // console.log('array!')
                            data.map((row, i) => {
                              if (id === row.id) {
                                id = row.attendee_id;
                                finalResult.push(row.attendee_id)
                              }
                            })
                            return
                          } else if (typeof data === "object" && !Array.isArray(data)) {
                            // console.log('object!')
                            Object.entries(data).map(([key, value]) => {
                              // console.log(`${key}: ${value}`)
                              value.map((row, i) => {
                                // console.log(row)
                                if (id === row.id) {
                                  // console.log(id)
                                  // console.log(row.id)
                                  // console.log('found!')
                                  finalResult.push(row.attendee_id)
                                  // id = row.attendee_id;
                                }
                              })
                            })
                          } else {
                            // console.log('neither')
                            return id
                          }
                        })
                        console.log(finalResult)

                        let uniqueArr = [...new Set(finalResult)];
                        // console.log(confirmDialogProps)

                        postData("https://youngstartup.io/api/db-a/emails/send_queued_template_v2",

                          {
                            // {"body": {"ids": [2961, 152, 452, 57],"template_id": 7}}
                            body: {
                              ids: uniqueArr,
                              template_id: confirmDialogProps.emailId,
                            },
                          }
                        ).then((data) => {
                          if (data.success) {
                            toast.success("Email was sent!");
                          } else {
                            toast.error("something went wrong!");
                          }
                        });
                        // setConfirmDialogProps({});
                        setConfirmDialogDisplay(false);
                      }}
                    >
                      Send!
                    </Button>
                  </Dialog>

                  {/* <Badge badgeContent={4} color="error">
                  <HoverButton
                    initialUrl={"/assets/Property 1=Default (11).png"}
                    hoverUrl={"/assets/Property 1=Variant2 (11).png"}
                    className={"presenter-action-send-email"}
                    onClick={handleClickOpen5}
                  />
                </Badge> */}
                </div>
              }
            </div>
          </div >
          <table className="filter-container">
            <tbody className="filter-table">
              <tr className="filter-table-row">
                <td className="row-title">Top Innovator</td>
                <td>
                  <div className={"filter-action-container"}>
                    {buttons?.map((button, i) => {
                      return (
                        <button
                          key={i}
                          className={
                            (fetchUrl.includes(button.option_link) || (fetchUrl.includes("not+going+ahead") && button.option_display === "NGA"))
                              && fetchUrl.includes("top")
                              ? "filter-action-btn-active"
                              : "filter-action-btn"
                          }
                          sx={{
                            backgroundColor:
                              fetchUrl.includes(button.option_link) &&
                              "white !important",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // console.log("fetchUrl",fetchUrl)
                            setFetchUrl(
                              `${button.option_link}&company_stage=top`
                            );
                            addOrUpdateQueryParam("stage", button.option_value);
                            addOrUpdateQueryParam("company_stage", "top");
                            if (
                              button.option_display == "Selected" ||
                              button.option_display == "NGA" ||
                              button.option_display == "Pitch" 
                            ) {
                              setCurrentColumn(columnsLevel2);
                            } else if (
                              button.option_display == "Applied"
                            ) {
                              setCurrentColumn(columnsLevel1);
                            } else if (
                              button.option_display == "Maybe" ||
                              button.option_display == "Rejected"
                            ) {
                              setCurrentColumn(columnsLevel4);
                            } else {
                              setCurrentColumn(columnsLevel3);
                            }
                          }}
                        >
                          {button.option_display}
                        </button>
                      );
                    })}
                  </div>
                </td>
              </tr>
              <div className="filter-action-divider" />
              <tr>
                <td className="row-title">Seed Stage</td>
                <td>
                  <div className={"filter-action-container"}>
                    {buttons?.map((button, i) => {
                      return (
                        <button
                          key={i}
                          className={
                            (fetchUrl.includes(button.option_link) || (fetchUrl.includes("not+going+ahead") && button.option_display === "NGA"))
                              &&
                              fetchUrl.includes("seed")
                              ? "filter-action-btn-active"
                              : "filter-action-btn"
                          }
                          onClick={() => {
                            // console.log("fetchUrl")
                            setFetchUrl(
                              `${button.option_link}&company_stage=seed`
                            );
                            addOrUpdateQueryParam("stage", button.option_value);
                            addOrUpdateQueryParam("company_stage", "seed");
                            if (
                              button.option_display == "Selected" ||
                              button.option_display == "NGA"
                            ) {
                              setCurrentColumn(columnsLevel2);
                            } else if (
                              button.option_display == "Applied"
                            ) {
                              setCurrentColumn(columnsLevel1);
                            } else if (
                              button.option_display == "Maybe" ||
                              button.option_display == "Rejected"
                            ) {
                              setCurrentColumn(columnsLevel4);
                            } else {
                              setCurrentColumn(columnsLevel3);
                            }
                          }}
                        >
                          {button.option_display}
                        </button>
                      );
                    })}
                    {ddOptions?.so_inclusion?.map((option, i) => {
                      return <button key={option.id}
                        onClick={() => {
                          setFetchUrl("https://youngstartup.io/api/db/presenters/getallapplications?stage=so_inclusion&company_stage=seed");
                          addOrUpdateQueryParam("stage", "so_inclusion");
                          addOrUpdateQueryParam("company_stage", "seed");
                          setCurrentColumn(columnsLevel1);
                        }}
                        className={fetchUrl.includes("so_inclusion") ? "filter-action-btn-active" : "filter-action-btn"}>{option.option_display_short}</button>
                    })}
                  </div>
                </td>
              </tr>
            </tbody>
            <StatsDisplay stats={stats} />
          </table>
        </section >
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{ bgcolor: "#303265", color: "white" }}
          >
            {"Secondary Contact"}
          </DialogTitle>
          <DialogContent sx={{ bgcolor: "#303265" }}>
            <Stack spacing={2}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "20%", color: "#F0AD4E" }}>Name:</div>{" "}
                <input
                  style={{
                    backgroundColor: "#000120",
                    border: "none",
                    borderRadius: "15px",
                    color: "white",
                    padding: "2%",
                  }}
                  value={contactInputs.name}
                  onChange={(e) =>
                    setContactInputs({ ...contactInputs, name: e.target.value })
                  }
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "20%", color: "#F0AD4E" }}>Email:</div>{" "}
                <input
                  style={{
                    backgroundColor: "#000120",
                    border: "none",
                    borderRadius: "15px",
                    color: "white",
                    padding: "2%",
                  }}
                  value={contactInputs.email}
                  onChange={(e) =>
                    setContactInputs({ ...contactInputs, email: e.target.value })
                  }
                />
              </div>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ bgcolor: "#303265" }}>
            <Button onClick={handleCloseDialog} variant="contained">
              Close
            </Button>
            <Button
              variant="contained"
              autoFocus
              onClick={(e) => {
                let body = {
                  body: {
                    id: contactInputs.id,
                    secondary_contacts: JSON.stringify([
                      { name: contactInputs.name, email: contactInputs.email },
                    ]),
                  },
                };

                patchData(
                  `https://youngstartup.io/api/db-a/presenters/updateapplication`,
                  body
                ).then((data) => {
                  if (data.success) {
                    handleSuccessMsg();
                    handleCloseDialog();
                    fetchData();
                  } else {
                    handleErrMsg();
                  }
                });
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialog2}
          onClose={handleCloseDialog2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Alert severity={err ? "error" : "success"}>
              <h5>{err ? "An error has occured" : "Update successful!"}</h5>
            </Alert>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openDialog4}
          onClose={handleCloseDialog4}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"lg"}
        >
          <DetailsPopup startupInputs={startupInputs} handleCloseDialog4={handleCloseDialog4} ddOptions={ddOptions} changeStartupInputs={changeStartupInputs}
            ticketsDetails={ticketsDetails} currentCell={currentCell} setStartupInputs={setStartupInputs} handleSuccessMsg={handleSuccessMsg} handleErrMsg={handleErrMsg}
            fetchData={fetchData} fetchUrl={fetchUrl} setData={setData} removeUser={removeUser}
          />
        </Dialog>

        <Dialog
          open={openDialog5}
          onClose={handleCloseDialog5}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"lg"}
          fullWidth
        >
          <DialogContent sx={{ display: "flex" }}>
            <Schedule />
          </DialogContent>
        </Dialog>
        <div style={{ color: "white", paddingBottom: "20px", backgroundColor: "000120", zIndex: "101" }}>Selected: {[...new Set(multipleSelect)].length}</div>
      </div>

      {
        loading ? (
          <Skeleton
            sx={{ bgcolor: "white", margin: "auto" }}
            variant="rectangular"
            width={"98vw"}
            height={"71vh"}
          />
        ) : (
          <div >
            {/* <div style={{ height: "65vh" }}> */}

            {Array.isArray(data) ? (
              <div style={{ height: "65vh" }}>
                {/* <div style={{ height: "auto" }}> */}
                {/* <div style={{ height: searchedData.length > 25 && "65vh" }}> */}
                {/* <div style={{ height: data.length > 25 && "71vh" }}>  */}
                <DataGrid
                  // columns={columnsLevel1}
                  columns={currentColumn}
                  rows={searchedData}
                  // rows={data}
                  pagination
                  hideFooterPagination={false}
                  className="presenter-table"
                  rowHeight={100}
                  columnVisibilityModel={{
                    time_preference: urlStage === "selected" || urlStage === "confirmed" || urlStage === "expo" || urlStage === "pitch"
                  }}
                  sx={{
                    '& .MuiDataGrid-footerContainer, & .MuiTablePagination-select, & .MuiTablePagination-selectIcon, & .MuiTablePagination-actions .MuiSvgIcon-root': {
                      color: "white"
                    },
                  }}
                  style={{ minHeight: "fit-content" }}
                />
                {Array.isArray(searchedData)}
                {/* {Array.isArray(data)} */}
              </div>
            ) : (
              Object.entries(searchedFilteredData).map(([key, value], i) => {
                // Object.entries(filteredData).map(([key, value], i) => {
                return (
                  <div key={key}>
                    <h2 style={{ color: "white" }}>{key} - {value?.length}</h2>
                    <PresenterGrid
                      i={i}
                      setData={setData}
                      currentColumn={currentColumn}
                      value={value}
                      selectedContacts={selectedContacts}
                      setSelectedContacts={setSelectedContacts}
                      fetchUrl={fetchUrl}
                      openDialog3={openDialog3}
                      setOpenDialog3={setOpenDialog3}
                      multipleSelect={multipleSelect}
                      setMultipleSelect={setMultipleSelect}
                      setAttendeeIdArr={setAttendeeIdArr}
                      initialValue={clearSelection}
                      fetchData={fetchData}
                      handleCloseActions={handleCloseActions}
                      deadlinesStructure={deadlinesStructure}
                    />
                    {/* <DataGrid
                columns={currentColumn}
                rows={value}
                autoHeight
                rowHeight={115}
                className="presenter-table"
              /> */}
                  </div>
                );
              })
            )}
          </div>
        )
      }

    </div >
  );
};
