import React, { useMemo, useState, useEffect } from "react";
import ReactTable, {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
  usePagination,
  useColumnOrder,
} from "react-table";
import { ATTENDEE_COLUMNS } from "../columns";
import {
  GlobalFilter2,
  Checkbox,
  ContactNewForm,
  AttendeeAddForm,
  AttendeeIndex,
} from "../index";
import "../../components/Table.css";
import toast from "react-hot-toast";
import { Offcanvas } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../utils/funcs";
import Select from "react-select";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Fab from "@mui/material/Fab";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsIcon from "@mui/icons-material/Settings";
import FilterListIcon from "@mui/icons-material/FilterList";
import Tooltip from "@mui/material/Tooltip";
import GetAppIcon from "@mui/icons-material/GetApp";
import XlsExport from "xlsexport";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import Button from "@mui/material/Button";

const notify = () => toast("Updated Successfuly!!");
// toast.success('Successfully updated!!');
// toast.loading('Waiting...');

export const CompanyListResultsTable = props => {
  //STATES AND VARIABLES
  const [dataArr, setDataArr] = useState([]);
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState("none");
  const [skipPageReset, setSkipPageReset] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [autocompleteArr, setAutocompleteArr] = useState([]);
  const [autocompleteArr2, setAutocompleteArr2] = useState([]);
  const [showFilters, setShowFilters] = useState("none");
  const [select2, setSelect2] = useState(undefined);
  const [currentRow, setCurrentRow] = useState(undefined);
  const [currentRowObject, setCurrentRowObject] = useState(undefined);
  const [currentRowName, setCurrentRowName] = useState(undefined);
  const [currentRow2, setCurrentRow2] = useState(undefined);
  const [currentRow2Name, setCurrentRow2Name] = useState(undefined);
  const [companyId, setCompanyId] = useState(undefined);
  const [companyName, setCompanyName] = useState(undefined);
  const [companyAssoc, setCompanyAssoc] = useState([]);
  const [dealAssoc, setDealAssoc] = useState([]);
  const [dealId, setDealId] = useState(undefined);
  const [dealName, setDealName] = useState(undefined);
  const [actionBtn, setActionBtn] = useState(false);
  const [hiddenActionBtns, setHiddenActionBtns] = useState("block");
  const [autoSearchValue, setAutoSearchValue] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const columns = useMemo(() => ATTENDEE_COLUMNS, []);
  const [loader, setLoader] = useState("none");
  const [modalHeight, setModalHeight] = useState("30%");
  const [hideBtns, setHideBtns] = useState("inline-block");
  const [showOptionForm, setShowOptionForm] = useState("none");
  const [showNewForm, setShowNewForm] = useState("none");
  const [attendeeForm, setAttendeeForm] = useState("none");
  const [initialCount, setInitialCount] = useState("");
  const [showIndex, setShowIndex] = useState("none");

  const NotEditableCells = [
    "id",
    "properties.createdate",
    "properties.company",
  ];

  //TEMPLATE FOR EDITABLE CELLS
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
  }) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onBlur = () => {
      updateMyData(index, id, value);
    };

    const onFocus = (e) => {
      if (e.target.dataset.disabled == "true") {
        e.target.blur();
        return;
      }
      e.target.dataset.disabled = true;
    };

    const onDoubleClick = (e) => {
      e.target.dataset.disabled = false;
      e.target.focus();
    };

    const exportTableToExcel = (data) => {
      let xls = new XlsExport([data], "exprtedtable");
      xls.exportToXLS("export2017.xls");
    };

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (NotEditableCells.includes(id)) {
      return value;
    }
    return (
      <input
        className="editable-cell"
        value={value}
        onChange={onChange}
        data-disabled={true}
        onFocus={onFocus}
        onDoubleClick={onDoubleClick}
        onBlur={onBlur}
        onKeyPress={(e) => {
          if (e.code == "NumpadEnter" || e.code == "Enter") {
            e.target.blur();
          }
        }}
      />
    );
  };

  const defaultColumn = useMemo(() => {
    return {
      Cell: EditableCell,
    };
  }, []);

  ////////////////////////////////////////////////////////
  //FILTER FUNCTION

  const onFilteredChangeCustom = (value, accessor) => {
    let insertNewFilter = 1;
    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    setFiltered(filtered);
  };

  //////////////////////////////////////////////////////

  const updateMyData = (rowIndex, columnId, value) => {
    columnId = columnId.split(".").pop();
    setSkipPageReset(true);
    let currentRow = dataArr.filter((row, index) => {
      if (index === rowIndex) {
        // console.log(row);
        return row;
      }
    });

    currentRow = currentRow[0];

    // check if there is a change in the value
    if (currentRow[columnId] === value) {
      return;
    }

    let newObj = {
      body: {
        id: currentRow.id,
        [columnId]: value,
      },
    };
    console.log(newObj);
    console.log(currentRow);

    const myPromise = editContact(
      "https://youngstartup.io/api/db/attendees/update",
      newObj
    ).then((data) => {
      console.log(data);

      if (data.status && data.status == "error") {
        console.error(data.message);

        throw new Error(data.message);
      }
      // FIX - reset component so you get fresh info from API
      return data;
    });

    toast.promise(
      myPromise,
      {
        loading: "Loading",
        success: (data) =>
          `Successfully updated ${currentRow.firstname} ${currentRow.lastname}`,
        error: (err) => `This just happened: ${err.toString()}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
          // icon: '🔥',
        },
      }
    );
    // setTimeout(() => window.location.reload(), 1500);
  };

  useEffect(() => {
    setSkipPageReset(false);
  }, [dataArr]);

  async function deleteContact(url) {
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return;
  }

  async function editContact(url, data) {
    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async function getData(url) {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json();
  }

  const handleAddAttendee = (e) => {
    e.preventDefault();
    setAttendeeForm("flex");
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShowForm("none");
    setShowNewForm("none");
    setHideBtns("inline-block");
  };

  const handleCloseSide = () => setShow(false);

  const handleShowSide = () => setShow(true);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const highliteCell = (e) => {
    e.target
      .closest("tbody")
      .querySelectorAll("td")
      .forEach((cell) => {
        cell.classList.remove("highlighted-cells");
      });
    e.target.classList.add("highlighted-cells");
  };

  const selectCells = (e) => {
    e.target
      .closest("tbody")
      .querySelectorAll("tr")
      .forEach((row) => {
        row.classList.remove("selected-cells");
      });
    e.target.closest("tr").classList.add("selected-cells");
  };

  async function postFavorite(url, input) {
    const reqBody = { body: input };
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(reqBody),
    });

    return response.json();
  }

  async function deleteFavorite(url, input) {
    const myBody = { body: input };
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(myBody),
    });
    return;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    page,
    setGlobalFilter,
    nextPage,
    selectedFlatRows,
    getToggleHideAllColumnsProps,
    allColumns,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    setColumnOrder,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: dataArr,
      defaultColumn,
      initialState: { pageSize: 1000000 },
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <>
                <div>Actions</div>
                <Checkbox {...getToggleAllRowsSelectedProps()} />
              </>
            ),
            Cell: ({ row, data }) => (
              <>
                <div className="select-container">
                  <Checkbox
                    {...row.getToggleRowSelectedProps()}
                    style={{
                      marginRight: "1rem",
                      marginTop: "25%",
                    }}
                    className="checkbox"
                  />
                  <Tooltip title="Delete contact">
                    <Fab
                      style={{ display: hiddenActionBtns }}
                      color="secondary"
                      className="delete-icon"
                      onClick={() => {
                        let item = data[row.index];
                        let id = item.id;
                        // console.log(data[row.index]);
                        if (
                          window.confirm("Permenantly delete the user?") ==
                          false
                        ) {
                          return;
                        } else {
                          const myPromise = deleteContact(
                            "https://youngstartup.io/api/db/attendees/remove/" +
                              data[row.index].id
                          ).then(() =>
                            getData(
                              `https://youngstartup.io/api/db/attendees/all`
                            ).then((data) => {
                              let dataArrCopy = data;
                              let filteredArr = dataArrCopy.filter(
                                (item) => item.has_duplicate == true
                              );
                              setDataArr(filteredArr);
                            })
                          );

                          toast.promise(
                            myPromise,
                            {
                              loading: "Loading",
                              success: (data) =>
                                `Successfully deleted id: ${capitalizeFirstLetter(
                                  item.firstname
                                )} ${capitalizeFirstLetter(item.lastname)}`,
                              error: (err) => `error: ${err.toString()}`,
                            },
                            {
                              style: {
                                minWidth: "250px",
                              },
                              success: {
                                duration: 5000,
                                // icon: '🔥',
                              },
                            }
                          );
                        }
                      }}
                    >
                      <DeleteIcon
                        style={{ transform: "scale(1.5)" }}
                        key={data[row.index].id}
                      />
                    </Fab>
                  </Tooltip>
                  {data[row.index].has_duplicate == true && (
                    <Tooltip title="Find duplicates">
                      <Fab
                        color="secondary"
                        className="delete-icon"
                        onClick={() => {
                          getData(
                            `https://youngstartup.io/api/db/attendees-by-email/${
                              data[row.index].email
                            }`
                          ).then((data) => setDataArr(data));
                        }}
                      >
                        <FindInPageIcon
                          style={{ transform: "scale(1.5)" }}
                          key={data[row.index].id}
                        />
                      </Fab>
                    </Tooltip>
                  )}
                </div>
              </>
            ),
          },
          ...columns,
          [],
        ];
      });
    }
  );

  console.log(dataArr);

  const multiIdArr = selectedFlatRows.map((x) => {
    return x.original.id;
  });

  async function deleteMulti(
    url = "https://youngstartup.io/api/db/attendees/removemulti"
  ) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(multiDeleteArr),
    });
    return;
  }

  const refreshTableData = () => {
    let myPromise = getData(
      `https://youngstartup.io/api/db/attendees/all`
    ).then((data) => {
      console.log(data);
      setDataArr(data);
    });

    toast.promise(
      myPromise,
      {
        loading: "Refreshing...",
        success: (data) => `Refreshed!`,
        error: (err) => `This just happened: ${err.toString()}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 1000,
        },
      }
    );
  };

  const multiDeleteArr = {
    body: { ids: { multiIdArr } },
  };
  console.log(multiDeleteArr);

  const { globalFilter, pageIndex, pageSize } = state;

  useEffect(() => {
    
  }, []);

  //console.log(filtered)
  const filteredArr = filtered.map((x) => x.name);
  //console.log(filteredArr)
  const filterOptions = [];
  for (let i = 0; i < filteredArr.length; i++) {
    filterOptions.push({ value: filteredArr[i], label: filteredArr[i] });
  }
  //console.log(filterOptions)
  //console.log(currentRow);
  //console.log(dataArr)

  async function putAssociateCompany(url) {
    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });

    return response.json();
  }

  async function putAssociateDeal(url) {
    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });

    return response.json();
  }

  const itemArr = [];
  autocompleteArr.map((x) => {
    const newObj = {};
    newObj.id = x.id;
    newObj.name = x.properties.name;
    itemArr.push(newObj);
  });

  const itemArr2 = [];

  autocompleteArr2.map((x) => {
    const newObj = {};
    newObj.id = x.id;
    newObj.name = x.properties.dealname;
    itemArr2.push(newObj);
  });
  //console.log(currentRowObject);
  //console.log(itemArr2);
  //console.log(itemArr);
  //console.log(companyAssoc)
  //console.log(dealAssoc);

  const companyAssocList = companyAssoc.map((company) => {
    return <li style={{ listStyleType: "none" }}>{company.properties.name}</li>;
  });

  const dealAssocList = dealAssoc.map((deal) => {
    if (deal) {
      return (
        <li style={{ listStyleType: "none" }}>{deal.properties.dealname}</li>
      );
    } else {
      return <div>No current associations</div>;
    }
  });

  return (
    <div style={{display: props.showResultsTable}}>
      <AttendeeAddForm
        attendeeForm={attendeeForm}
        setAttendeeForm={setAttendeeForm}
      />
      {/* <Tooltip title="Add contact">
        <Fab
          color="primary"
          onClick={handleAddAttendee}
          className="add-circle-icon"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      </Tooltip> */}
      <GlobalFilter2
        dataArr={dataArr}
        setDataArr={setDataArr}
        filter={globalFilter}
        refreshTableData={refreshTableData}
        setFilter={setGlobalFilter}
      />
      <Tooltip title="Column settings">
        <Fab
          color="primary"
          style={{ display: hideBtns }}
          className="add-circle-icon settings-icon"
          aria-label="add"
          onClick={handleShowSide}
        >
          <SettingsIcon />
        </Fab>
      </Tooltip>
      <Offcanvas
        show={show}
        onHide={handleCloseSide}
        style={{ width: "fit-content", backgroundColor: "#212529" }}
      >
        <Offcanvas.Header closeButton>Column Settings</Offcanvas.Header>
        <div className="column-display" style={{ fontSize: "1.2rem" }}>
          Display Columns:
          <br />
          <span style={{ color: "white", marginRight: "1rem" }}>
            <Checkbox {...getToggleHideAllColumnsProps()} />
            Toggle All
          </span>
          {allColumns.map((column) => {
            if (column.id)
              return (
                <div key={column.id} style={{ display: "block" }}>
                  <label style={{ color: "white" }}>
                    <input type="checkbox" {...column.getToggleHiddenProps()} />
                    {typeof column.Header === "string"
                      ? column.Header
                      : "Select"}
                  </label>
                </div>
              );
          })}
        </div>
      </Offcanvas>
      <Tooltip title="Refresh contacts">
        <Fab
          color="primary"
          style={{ marginRight: "2rem", marginLeft: "2rem", display: hideBtns }}
          aria-label="add"
          onClick={() => {
            let myPromise = getData(
              `https://youngstartup.io/api/db/attendees/all`
            ).then((data) => {
              setDataArr(data);
            });

            toast.promise(
              myPromise,
              {
                loading: "Loading",
                success: (data) => `Refreshed!`,
                error: (err) => `This just happened: ${err.toString()}`,
              },
              {
                style: {
                  minWidth: "250px",
                },
                success: {
                  duration: 1000,
                },
              }
            );
          }}
        >
          <RefreshIcon />
        </Fab>
      </Tooltip>
      {/* <Tooltip title="Filter options">
        <Fab
          color="primary"
          onClick={() => {
            showFilters == "none"
              ? setShowFilters("block")
              : setShowFilters("none");
          }}
          style={{ display: hideBtns }}
          className="add-circle-icon settings-icon filter-icon"
        >
          <FilterListIcon />
        </Fab>
      </Tooltip> */}
      <Tooltip title="Download as CSV">
        <Fab
          color="primary"
          className="add-circle-icon settings-icon "
          style={{ display: hideBtns }}
          onClick={(e) => {
            console.log("exporting...");
            let orderArr = [
              "email",
              "firstname",
              "lastname",
              "company",
              // "company_id",
              "speaker_panel_contact",
              "final_judging_track_placement_contact",
              "final_coaching_track_placement_contact",
              "final_presenting_track",
              "presenting_round",
              "presenting_order_slot",
              "coaching_room_number",
              "brella_code",
              "meetmatch_role",
              "matchmaking_type",
              "ticket_type",
              // "source",
            ];
            let tableArr = dataArr.map((item) => {
              let id = item.id;
              // return sortKeys({ id, ...item.properties }, orderArr);
              for (const key in item) {
                if (Object.hasOwnProperty.call(item, key)) {
                  const prop = item[key];
                  if (!orderArr.includes(key)) {
                    delete item[key];
                  }
                }
              }
              return item;
            });
            // console.log(tableArr);

            let xls = new XlsExport(tableArr, "exprtedtable");
            xls.exportToCSV("attendees_VSVC21.csv");
          }}
        >
          <GetAppIcon>export {dataArr.length} listed contacts</GetAppIcon>
        </Fab>
      </Tooltip>
      {selectedFlatRows.length >= 1 && (
        <Tooltip title="Delete selected">
          <Fab
            color="secondary"
            style={{ display: hideBtns }}
            className="add-circle-icon settings-icon"
            aria-label="add"
            onClick={() => {
              if (
                window.confirm("Permenantly delete all selected users?") ==
                false
              ) {
                return;
              } else {
                let myPromise = deleteMulti().then((data) => console.log(data));

                toast.promise(
                  myPromise,
                  {
                    loading: "Loading",
                    success: (data) => {
                      refreshTableData();
                      return `Successfully deleted!!`;
                    },
                    error: (err) => `This just happened: ${err.toString()}`,
                  },
                  {
                    style: {
                      minWidth: "250px",
                    },
                    success: {
                      duration: 5000,
                    },
                  }
                );
              }
            }}
          >
            <DeleteForeverIcon className="refresh-btn" variant="danger" />
          </Fab>
        </Tooltip>
      )}
      {/* <button onClick={()=>{setHiddenActionBtns('none');console.log(hiddenActionBtns)}}>Show actions</button> */}
      <div style={{ display: showFilters }}>
        <h4 style={{ textAlign: "center", color: "white", marginTop: "1rem" }}>
          Filter Options:
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Select
            style={{
              width: "10% !important",
              marginBottom: "20px",
              margin: "2rem",
              display: "flex",
              color: "black",
            }}
            className="advanced-filter-input"
            onCreateOption={(old, entry) => setFiltered([...old, entry])}
            onChange={(entries) => {
              setSelect2(entries);
              onFilteredChangeCustom(
                entries.map((entry) => {
                  return entry.value;
                })
              );
              setDataArr(
                dataArr.filter((x, i) => {
                  for (let j = 0; j < entries.length; j++) {
                    if (x.properties == entries[j].value) return dataArr;
                  }
                })
              );
            }}
            value={select2}
            isMulti
            options={[...filterOptions]}
          />
        </div>
      </div>
      {/*<span>
        <button onClick={() => setShowColumnForm("block")}>
          Set Column Order
        </button>
      </span>
      <form className="column-form" style={{display:showColumnForm, textAlign:'center', padding:'2rem', borderRadius:'10px'}}>
        <div style={{textAlign:'right'}} onClick={()=>setShowColumnForm('none')}>X</div>
        <label style={{justifyContent:"center"}}>Column Order</label>
        <ul style={{listStyleType:'none', paddingLeft:'0'}}>
          {
          allColumns.map((column) => {
            if (typeof column.Header == "string")
              return <li draggable="true" onDrop={handleDrop} key={column.id} style={{color:'white', border:'1px solid white', margin:'1rem'}}>{column.Header}</li>;
          })}
        </ul>
        </form> */}
      <div className="investor-count">
        Currently displaying{" "}
        <span
          style={{
            color: "red",
            fontSize: "1.5rem",
            marginRight: ".5rem",
            marginLeft: ".5rem",
          }}
        >
          {dataArr.length}
        </span>{" "}
        of{" "}
        <span
          style={{
            color: "red",
            fontSize: "1.5rem",
            marginRight: ".5rem",
            marginLeft: ".5rem",
          }}
        >
          {initialCount}
        </span>
          companies
        <span style={{ marginLeft: "5vw" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowIndex("block")}
          >
            Index
          </Button>
        </span>
        <span style={{ marginLeft: "5vw" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              let dataArrCopy = dataArr;
              let filteredArr = dataArrCopy.filter(
                (item) => item.has_duplicate == true
              );
              setDataArr(filteredArr);
              console.log(dataArr);
            }}
          >
            Filter Duplicates
          </Button>
        </span>
      </div>

      <AttendeeIndex showIndex={showIndex} setShowIndex={setShowIndex} />
      <img
        style={{
          display: loader,
          position: "absolute",
          top: "50%",
          left: "45%",
        }}
        src="pulse.gif"
        alt=""
        />
      <div>
        <table {...getTableProps()} style={{ width: "225%", height: "400px" }}>
          <thead
            style={{
              position: "sticky",
              zIndex: "1",
              width: "fit-content",
              top: "0",
            }}
          >
            {headerGroups.map((headerGroup) => {
              if (headerGroup)
                return (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th>Row Number</th>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {/*<div>{column.canFilter ? column.render("Filter") : null}</div>*/}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "⬆️"
                              : "⬇️"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onMouseDown={selectCells}>
                  {
                    <td style={{ padding: "0" }}>
                      <span>{parseInt(row.id) + 1}</span>
                    </td>
                  }

                  {row.cells.map((cell) => {
                    if (cell !== row.cells[row.cells.length - 1]) {
                      if (row.original.has_duplicate == false) {
                        return (
                          <td
                            style={{ padding: "0" }}
                            onMouseDown={selectCells}
                            onMouseEnter={highliteCell}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            style={{ padding: "0", backgroundColor: "#BE82FF" }}
                            onMouseDown={selectCells}
                            onMouseEnter={highliteCell}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      }
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div style={{ textAlign: "center", padding: "0", margin: "0" }}>
        <span
          style={{
            color: "white",
            marginRight: "1rem",
            backgroundColor: "#212529",
          }}
        >
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <span style={{ color: "white" }}>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            className="next-btn page-num"
          />
        </span>
        <select
          style={{
            backgroundColor: "inherit",
            color: "white",
            border: "1px solid white",
            borderRadius: "15px",
            marginTop: "1vh",
            marginLeft: "1rem",
          }}
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 25, 50, 100, "All"].map((pageSize) =>
            pageSize == "All" ? (
              <option
                key={"All"}
                style={{ backgroundColor: "#212529", color: "white" }}
                value={1000000}
              >
                Show {pageSize}
              </option>
            ) : (
              <option
                key={pageSize}
                style={{ backgroundColor: "#212529", color: "white" }}
                value={pageSize}
              >
                Show {pageSize}
              </option>
            )
          )}
        </select>
        {!canPreviousPage && (
          <button className="next-btn" disabled onClick={() => gotoPage(0)}>
            {"<<"}
          </button>
        )}
        {canPreviousPage && (
          <button className="next-btn" onClick={() => gotoPage(0)}>
            {"<<"}
          </button>
        )}
        {!canPreviousPage && (
          <button className="next-btn" disabled onClick={() => previousPage()}>
            Previous
          </button>
        )}
        {canPreviousPage && (
          <button className="next-btn" onClick={() => previousPage()}>
            Previous
          </button>
        )}
        {!canNextPage && (
          <button className="next-btn" disabled onClick={() => nextPage()}>
            Next
          </button>
        )}
        {canNextPage && (
          <button className="next-btn" onClick={() => nextPage()}>
            Next
          </button>
        )}
        {canNextPage && (
          <button className="next-btn" onClick={() => gotoPage(pageCount - 1)}>
            {">>"}
          </button>
        )}
        {!canNextPage && (
          <button
            className="next-btn"
            disabled
            onClick={() => gotoPage(pageCount - 1)}
          >
            {">>"}
          </button>
        )}
      </div>
    </div>
  );
};
