import { useEffect, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import { getData, postData, putData } from "../../api/apiCalls";

export const MealToggle = ({ cell, meal, mealSubs }) => {
  
  
  const mealSub = mealSubs.find(i => i.vc_id === cell.row.id)?.submissions.find((event, i) => {
    return event.session === meal
  }) || false;

  const [status, setStatus] = useState(mealSub.status);
  
  if (!mealSub) {
    return <></>
  }
  

  const getColor = (_status) => {
    if (_status === "") {
      return "white";
    } else if (_status === "no") {
      return "red";
    } else if (_status === "maybe") {
      return "orange";
    } else if (_status === "yes") {
      return "green";
    }
  }


  return (
    <ToggleButton
      sx={{ backgroundColor: `${getColor(status)} !important`, border: "1px solid white" }}
      onClick={() => {
        let temp;
        if (status === "yes") {
          temp = "maybe";
        } else if (status === "maybe") {
          temp = "no";
        } else {
          temp = "yes";
        }

        let body = {
          body: {
            id: mealSub?.id,
            status: temp
          },
        };
        console.log(mealSub?.mealId);
        putData("https://youngstartup.io/api/dbt/event_session_attendance/update", body)
          .then(data => { setStatus(temp); })
      }}
    />
  );
};
