import { useState } from "react";

export const StatsDisplay = ({ stats }) => {
  const [stage, setStage] = useState("all");

  return (
    <div
      style={{
        width: "55%",
        maxWidth: "375px",
        border: "1px solid white",
        backgroundColor: "#303265",
        padding: ".5%",
        fontSize: ".75rem",
      }}
    >
      {" "}
      <span style={{ display: "flex", justifyContent: "center" }}>
        <h5 style={{ textAlign: "center", color: "#F0AD4E" }}>Stats</h5>
      </span>{" "}
      <div
        style={{
          display: "flex",
          gap: "2.5%",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{ display: "flex", gap: "5px", flexDirection: "column" }}
        >
          <button
            style={{
              backgroundColor: stage === "all" ? "#F0AD4E" : "#000120",
              color: stage === "all" ? "#000120" : "#F0AD4E",
            }}
            onClick={(e) => setStage("all")}
          >
            ALL
          </button>{" "}
          <button
            style={{
              backgroundColor: stage === "top" ? "#F0AD4E" : "#000120",
              color: stage === "top" ? "#000120" : "#F0AD4E",
            }}
            onClick={(e) => setStage("top")}
          >
            TI
          </button>{" "}
          <button
            style={{
              backgroundColor: stage === "seed" ? "#F0AD4E" : "#000120",
              color: stage === "seed" ? "#000120" : "#F0AD4E",
            }}
            onClick={(e) => setStage("seed")}
          >
            SPF
          </button>
        </span>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  borderRight: "1px solid #F0AD4E",
                  paddingRight: "10px",
                  textAlign: "right",
                }}
              >
                Applied
              </td>
              <td style={{ paddingLeft: "10px" }}>
                {stats[`${stage}`]?.Applied}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1px solid #F0AD4E",
                  textAlign: "right",
                  paddingRight: "10px",
                }}
              >
                Selected
              </td>
              <td style={{ paddingLeft: "10px" }}>
                {stats[`${stage}`]?.Selected}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1px solid #F0AD4E",
                  paddingRight: "10px",
                  textAlign: "right",
                }}
              >
                Confirmed
              </td>
              <td style={{ paddingLeft: "10px" }}>
                {stats[`${stage}`]?.Confirmed}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  borderRight: "1px solid #F0AD4E",
                  textAlign: "right",
                  paddingRight: "10px",
                }}
              >
                NGA
              </td>
              <td style={{ paddingLeft: "10px" }}>
                {stats[`${stage}`]?.NGA}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1px solid #F0AD4E",
                  textAlign: "right",
                  paddingRight: "10px",
                }}
              >
                Rejected
              </td>
              <td style={{ paddingLeft: "10px" }}>
                {stats[`${stage}`]?.Rejected}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1px solid #F0AD4E",
                  textAlign: "right",
                  paddingRight: "10px",
                }}
              >
                Maybe
              </td>
              <td style={{ paddingLeft: "10px" }}>
                {stats[`${stage}`]?.Maybe}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1px solid #F0AD4E",
                  textAlign: "right",
                  paddingRight: "10px",
                }}
              >
                SO
              </td>
              <td style={{ paddingLeft: "10px" }}>
                {stats[`${stage}`]?.SO}
              </td>
            </tr>
          </tbody>
        </table>
      </div>{" "}
    </div>
  );
};