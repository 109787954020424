import { Toaster } from 'react-hot-toast';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Fab from "@mui/material/Fab"
import { unsetLocalTok } from '../utils/funcs';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';

export const NavBar = () => {
  let selected = {backgroundColor:'#515960', padding:'1rem',marginTop:'2rem'}
  const pathName = window.location.pathname;

  if(pathName == '/contacts'){
  return (
    <div className="nav">
      <a href="/landing">
        <img
          src="https://events.youngstartup.com/wp-content/uploads/2019/01/trns-logo.png"
          className="brand"
          alt=""
          />
      </a>
      <a href='/contacts'><h1 className="nav-contacts" style={selected}>CONTACTS</h1></a>
      <a href='/deals'><h1 className="nav-contacts" >DEALS</h1></a>
      <a href='/companies'><h1 className="nav-contacts">COMPANIES</h1></a>
      <a href='/recruiters'><h1 className="nav-contacts">RECRUITERS</h1></a>
      <a href='/attendees'><h1 className="nav-contacts" >ATTENDEES</h1></a>
      <a href='/lists'><h1 className="nav-contacts">LISTS</h1></a>
      {/* <a href='/summits'><h1 className="nav-contacts">SUMMITS</h1></a> */}
      <a href='/wishlist'><Button color="primary" variant='contained' className='wish-nav-btn'>WISH LIST</Button></a>
      <a href='/dash'><Avatar alt="User" src="" style={{marginTop:'2.5rem', marginRight:'2rem', transform:'scale(1.5)',marginLeft:'2.25rem'}}/></a>
      <Tooltip title="Logout">
      <Fab
        color="secondary"
        className="add-circle-icon settings-icon filter-icon"
        style={{marginTop:'2rem', marginLeft:'2.25rem'}}
        onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/"}}
        // onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/login"}}
      >
        <ExitToAppIcon>Logout</ExitToAppIcon>
        </Fab>
        </Tooltip>
      <Toaster />
    </div>
  )
}


if(pathName == '/deals'){
  return (
    <div className="nav">
      <a href="/">
        <img
          src="https://events.youngstartup.com/wp-content/uploads/2019/01/trns-logo.png"
          className="brand"
          alt=""
          />
      </a>
      <a href='/contacts'><h1 className="nav-contacts">CONTACTS</h1></a>
      <a href='/deals'><h1 className="nav-contacts" style={selected}>DEALS</h1></a>
      <a href='/companies'><h1 className="nav-contacts">COMPANIES</h1></a>
      <a href='/recruiters'><h1 className="nav-contacts">RECRUITERS</h1></a>
      <a href='/attendees'><h1 className="nav-contacts" >ATTENDEES</h1></a>
      <a href='/lists'><h1 className="nav-contacts">LISTS</h1></a>
      {/* <a href='/summits'><h1 className="nav-contacts" >SUMMITS</h1></a> */}
      <a href='/wishlist'><Button color="primary" variant='contained' className='wish-nav-btn'>WISH LIST</Button></a>
      <a href='/dash'><Avatar alt="User" src="" style={{marginTop:'2.5rem', marginRight:'2rem', transform:'scale(1.5)' ,marginLeft:'2.25rem'}}/></a>
      <Tooltip title="Logout">
      <Fab
        color="secondary"
        className="add-circle-icon settings-icon filter-icon"
        style={{marginTop:'2rem', marginLeft:'2.25rem'}}
        onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/"}}
        // onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/login"}}
      >
        <ExitToAppIcon>Logout</ExitToAppIcon>
        </Fab>
        </Tooltip>
      <Toaster />
    </div>
  );
}


if(pathName == '/companies'){
  return (
    <div className="nav">
      <a href="/">
        <img
          src="https://events.youngstartup.com/wp-content/uploads/2019/01/trns-logo.png"
          className="brand"
          alt=""
          />
      </a>
      <a href='/contacts'><h1 className="nav-contacts">CONTACTS</h1></a>
      <a href='/deals'><h1 className="nav-contacts" >DEALS</h1></a>
      <a href='/companies'><h1 className="nav-contacts" style={selected}>COMPANIES</h1></a>
      <a href='/recruiters'><h1 className="nav-contacts">RECRUITERS</h1></a>
      <a href='/attendees'><h1 className="nav-contacts" >ATTENDEES</h1></a>
      <a href='/lists'><h1 className="nav-contacts">LISTS</h1></a>
      {/* <a href='/summits'><h1 className="nav-contacts">SUMMITS</h1></a> */}
      <a href='/wishlist'><Button color="primary" variant='contained' className='wish-nav-btn'>WISH LIST</Button></a>
      <a href='/dash'><Avatar alt="User" src="" style={{marginTop:'2.5rem', marginRight:'2rem', transform:'scale(1.5)',marginLeft:'2.25rem'}}/></a>
      <Tooltip title="Logout">
      <Fab
        color="secondary"
        className="add-circle-icon settings-icon filter-icon"
        style={{marginTop:'2rem', marginLeft:'2.25rem'}}
        onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/"}}
        // onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/login"}}
      >
        <ExitToAppIcon>Logout</ExitToAppIcon>
        </Fab>
        </Tooltip>
      <Toaster />
    </div>
  );
}


if(pathName == '/recruiters'){
  return (
    <div className="nav">
      <a href="/">
        <img
          src="https://events.youngstartup.com/wp-content/uploads/2019/01/trns-logo.png"
          className="brand"
          alt=""
          />
      </a>
      <a href='/contacts'><h1 className="nav-contacts">CONTACTS</h1></a>
      <a href='/deals'><h1 className="nav-contacts" >DEALS</h1></a>
      <a href='/companies'><h1 className="nav-contacts">COMPANIES</h1></a>
      <a href='/recruiters'><h1 className="nav-contacts" style={selected}>RECRUITERS</h1></a>
      <a href='/attendees'><h1 className="nav-contacts" >ATTENDEES</h1></a>
      <a href='/lists'><h1 className="nav-contacts">LISTS</h1></a>
      {/* <a href='/summits'><h1 className="nav-contacts">SUMMITS</h1></a> */}
      <a href='/wishlist'><Button color="primary" variant='contained' className='wish-nav-btn'>WISH LIST</Button></a>
      <a href='/dash'><Avatar alt="User" src="" style={{marginTop:'2.5rem', marginRight:'2rem', transform:'scale(1.5)',marginLeft:'2.25rem'}}/></a>
      <Tooltip title="Logout">
      <Fab
        color="secondary"
        className="add-circle-icon settings-icon filter-icon"
        style={{marginTop:'2rem', marginLeft:'2.25rem'}}
        onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/"}}
        // onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/login"}}
      >
        <ExitToAppIcon>Logout</ExitToAppIcon>
        </Fab>
        </Tooltip>
      <Toaster />
    </div>
  );
}


if(pathName == '/wishlist'){
  return (
    <div className="nav">
      <a href="/">
        <img
          src="https://events.youngstartup.com/wp-content/uploads/2019/01/trns-logo.png"
          className="brand"
          alt=""
          />
      </a>
      <a href='/contacts'><h1 className="nav-contacts">CONTACTS</h1></a>
      <a href='/deals'><h1 className="nav-contacts" >DEALS</h1></a>
      <a href='/companies'><h1 className="nav-contacts">COMPANIES</h1></a>
      <a href='/recruiters'><h1 className="nav-contacts" >RECRUITERS</h1></a>
      <a href='/attendees'><h1 className="nav-contacts" >ATTENDEES</h1></a>
      <a href='/lists'><h1 className="nav-contacts">LISTS</h1></a>
      {/* <a href='/summits'><h1 className="nav-contacts" >SUMMITS</h1></a> */}
      <a href='/wishlist'><Button color="primary" variant='contained' className='wish-nav-btn'>WISH LIST</Button></a>
      <a href='/dash'><Avatar alt="User" src="" style={{marginTop:'2.5rem', marginRight:'2rem', transform:'scale(1.5)',marginLeft:'2.25rem'}}/></a>
      <Tooltip title="Logout">
      <Fab
        color="secondary"
        className="add-circle-icon settings-icon filter-icon"
        style={{marginTop:'2rem', marginLeft:'2.25rem'}}
        onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/"}}
        // onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/login"}}
      >
        <ExitToAppIcon>Logout</ExitToAppIcon>
        </Fab>
        </Tooltip>
      <Toaster />
    </div>
  );
}


if(pathName == '/dash'){
  return (
    <div className="nav">
      <a href="/">
        <img
          src="https://events.youngstartup.com/wp-content/uploads/2019/01/trns-logo.png"
          className="brand"
          alt=""
          />
      </a>
      <a href='/contacts'><h1 className="nav-contacts">CONTACTS</h1></a>
      <a href='/deals'><h1 className="nav-contacts" >DEALS</h1></a>
      <a href='/companies'><h1 className="nav-contacts">COMPANIES</h1></a>
      <a href='/recruiters'><h1 className="nav-contacts" >RECRUITERS</h1></a>
      <a href='/attendees'><h1 className="nav-contacts" >ATTENDEES</h1></a>
      <a href='/lists'><h1 className="nav-contacts">LISTS</h1></a>
      {/* <a href='/summits'><h1 className="nav-contacts" >SUMMITS</h1></a> */}
      <a href='/wishlist'><Button color="primary" variant='contained' className='wish-nav-btn'>WISH LIST</Button></a>
      <a href='/dash'><Avatar alt="User" src="" style={{marginTop:'2.5rem', marginRight:'2rem', transform:'scale(1.5)',marginLeft:'2.25rem'}}/></a>
      <Tooltip title="Logout">
      <Fab
        color="secondary"
        className="add-circle-icon settings-icon filter-icon"
        style={{marginTop:'2rem', marginLeft:'2.25rem'}}
        onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/"}}
        // onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/login"}}
      >
        <ExitToAppIcon>Logout</ExitToAppIcon>
        </Fab>
        </Tooltip>
      <Toaster />
    </div>
  );
}


if(pathName == '/attendees'){
  return (
    <div className="nav">
      <a href="/">
        <img
          src="https://youngstartup.com/assets/media/trns-logo.webp"
          className="brand"
          alt=""
          />
      </a>
      <a href='/contacts'><h1 className="nav-contacts">CONTACTS</h1></a>
      <a href='/deals'><h1 className="nav-contacts" >DEALS</h1></a>
      <a href='/companies'><h1 className="nav-contacts">COMPANIES</h1></a>
      <a href='/recruiters'><h1 className="nav-contacts" >RECRUITERS</h1></a>
      <a href='/attendees'><h1 className="nav-contacts" style={selected}>ATTENDEES</h1></a>
      <a href='/speakers'><h1 className="nav-contacts" >FEATURED SPEAKERS</h1></a>
      <a href='/lists'><h1 className="nav-contacts">LISTS</h1></a>
      {/* <a href='/summits'><h1 className="nav-contacts" >SUMMITS</h1></a> */}
      <a href='/wishlist'><Button color="primary" variant='contained' className='wish-nav-btn'>WISH LIST</Button></a>
      <a href='/dash'><Avatar alt="User" src="" style={{marginTop:'2.5rem', marginRight:'2rem', transform:'scale(1.5)',marginLeft:'2.25rem'}}/></a>
      <Tooltip title="Logout">
      <Fab
        color="secondary"
        className="add-circle-icon settings-icon filter-icon"
        style={{marginTop:'2rem', marginLeft:'2.25rem'}}
        onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/"}}
        // onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/login"}}
      >
        <ExitToAppIcon>Logout</ExitToAppIcon>
        </Fab>
        </Tooltip>
      <Toaster />
    </div>
  );
}

if(pathName == '/speakers'){
  return (
    <div className="nav">
      <a href="/">
        <img
          src="https://youngstartup.com/assets/media/trns-logo.webp"
          className="brand"
          alt=""
          />
      </a>
      <a href='/contacts'><h1 className="nav-contacts">CONTACTS</h1></a>
      <a href='/deals'><h1 className="nav-contacts" >DEALS</h1></a>
      <a href='/companies'><h1 className="nav-contacts">COMPANIES</h1></a>
      <a href='/recruiters'><h1 className="nav-contacts" >RECRUITERS</h1></a>
      <a href='/attendees'><h1 className="nav-contacts" >ATTENDEES</h1></a>
      <a href='/speakers'><h1 className="nav-contacts" style={selected}>FEATURED SPEAKERS</h1></a>
      <a href='/lists'><h1 className="nav-contacts">LISTS</h1></a>
      {/* <a href='/summits'><h1 className="nav-contacts" >SUMMITS</h1></a> */}
      <a href='/wishlist'><Button color="primary" variant='contained' className='wish-nav-btn'>WISH LIST</Button></a>
      <a href='/dash'><Avatar alt="User" src="" style={{marginTop:'2.5rem', marginRight:'2rem', transform:'scale(1.5)',marginLeft:'2.25rem'}}/></a>
      <Tooltip title="Logout">
      <Fab
        color="secondary"
        className="add-circle-icon settings-icon filter-icon"
        style={{marginTop:'2rem', marginLeft:'2.25rem'}}
        onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/"}}
        // onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/login"}}
      >
        <ExitToAppIcon>Logout</ExitToAppIcon>
        </Fab>
        </Tooltip>
      <Toaster />
    </div>
  );
}


if(pathName == '/lists' || pathName == '/companylists' || pathName == '/contactlists'){
  return (
    <div className="nav">
      <a href="/">
        <img
          src="https://events.youngstartup.com/wp-content/uploads/2019/01/trns-logo.png"
          className="brand"
          alt=""
          />
      </a>
      <a href='/contacts'><h1 className="nav-contacts">CONTACTS</h1></a>
      <a href='/deals'><h1 className="nav-contacts" >DEALS</h1></a>
      <a href='/companies'><h1 className="nav-contacts">COMPANIES</h1></a>
      <a href='/recruiters'><h1 className="nav-contacts" >RECRUITERS</h1></a>
      <a href='/attendees'><h1 className="nav-contacts">ATTENDEES</h1></a>
      <a href='/lists'><h1 className="nav-contacts" style={selected}>LISTS</h1></a>
      {/* <a href='/summits'><h1 className="nav-contacts" >SUMMITS</h1></a> */}
      <a href='/wishlist'><Button color="primary" variant='contained' className='wish-nav-btn'>WISH LIST</Button></a>
      <a href='/dash'><Avatar alt="User" src="" style={{marginTop:'2.5rem', marginRight:'2rem', transform:'scale(1.5)',marginLeft:'2.25rem'}}/></a>
      <Tooltip title="Logout">
      <Fab
        color="secondary"
        className="add-circle-icon settings-icon filter-icon"
        style={{marginTop:'2rem', marginLeft:'2.25rem'}}
        onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/"}}
        // onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/login"}}
      >
        <ExitToAppIcon>Logout</ExitToAppIcon>
        </Fab>
        </Tooltip>
      <Toaster />
    </div>
  );
}

// if(pathName == '/summits'){
//   return (
//     <div className="nav">
//       <a href="/">
//         <img
//           src="https://events.youngstartup.com/wp-content/uploads/2019/01/trns-logo.png"
//           className="brand"
//         />
//       </a>
//       <a href='/contacts'><h1 className="nav-contacts">CONTACTS</h1></a>
//       <a href='/deals'><h1 className="nav-contacts" >DEALS</h1></a>
//       <a href='/companies'><h1 className="nav-contacts">COMPANIES</h1></a>
//       <a href='/recruiters'><h1 className="nav-contacts" >RECRUITERS</h1></a>
//       <a href='/summits'><h1 className="nav-contacts" style={selected} >SUMMITS</h1></a>
//       <a href='/wishlist'><Button color="primary" variant='contained' className='wish-nav-btn'>WISH LIST</Button></a>
//       <a href='/dash'><Avatar alt="User" src="" style={{marginTop:'2.5rem', marginRight:'2rem', transform:'scale(1.5)',marginLeft:'2.25rem'}}/></a>
//       <Tooltip title="Logout">
//       <Fab
//         color="secondary"
//         className="add-circle-icon settings-icon filter-icon"
//         style={{marginTop:'2rem', marginLeft:'2.25rem'}}
//         onClick={()=>{unsetLocalTok(localStorage.tok); window.location.href = "/login"}}
//       >
//         <ExitToAppIcon>Logout</ExitToAppIcon>
//         </Fab>
//         </Tooltip>
//       <Toaster />
//     </div>
//   );
// }
}