import { Form } from "react-bootstrap";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import Input from '@mui/material/Input';
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import Button from "@mui/material/Button";

export const DealForm = (props) => {
  const [inputs, setInputs] = useState({
    dealname: '',
    pipeline: '',
    closedate: '',
    amount: '',
  });

  const formData = {
    properties: inputs,
  };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    async function getData(url, data) {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          tok: localStorage.tok,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(formData),
      });

      return response.json();
    };

    getData(`https://youngstartup.io/api/hs/deals/addnew`, formData)
      .then((data) => {
        console.log(data);
        props.setShowForm("none");
      })
      .then(() =>
        props
          .getData(`https://youngstartup.io/api/hs/deals/all`)
          .then((data) => {
            props.setDataArr(data.results.reverse());
          })
      ).then(()=>{
        setInputs({
            dealname: '',
            pipeline: '',
            closedate: '',
            amount: '',
        });
      });
      
  };

  return (
    <>
      <Form
        style={{ display: `${props.showForm}`, paddingTop:'20vh !important' }}
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="contact-form"
      >
                <a
          onClick={props.handleClose}
          style={{
            marginLeft: "80%",
            marginBottom: "2rem !important",
            cursor: "pointer",
          }}
          className="close-btn"
        >
          <CloseIcon />
        </a>
        <Form.Group className="mb-3" controlId="formBasicEmail">
        <InputLabel>Deal Name</InputLabel>
          <Input
            name="dealname"
            value={inputs.dealname}
            onChange={handleChange}
            type="text"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
        <InputLabel>Deal Stage</InputLabel>
          <Select
            native
            name="dealstage"
            value={inputs.dealstage}
            onChange={handleChange}
            placeholder="Stage"
            style={{width:'100%'}}
          >
            <option style={{color:'black'}}></option>
            <option style={{color:'black'}}>Presentation Scheduled</option>
            <option style={{color:'black'}}>Appointment Scheduled</option>
          </Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
        <InputLabel>Pipeline</InputLabel>
          <Input
            name="pipeline"
            value={inputs.pipeline}
            onChange={handleChange}
            type="text"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
        <InputLabel>Amount</InputLabel>
          <Input
            name="amount"
            value={inputs.amount}
            onChange={handleChange}
            type="text"
          />
        </Form.Group>

        <div className="form-btn-container">
          <Button
            color="primary"
            variant='contained'
            className="form-btn"
            onClick={props.handleSubmit}
            type="submit"
            style={{marginLeft:'1rem'}}
          >
            Add Deal
          </Button>
        </div>
      </Form>
    </>
  );
};
