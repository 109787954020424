//react imports
import { useEffect, useState } from "react";

//mui imports
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { DataGrid } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import Select from "@mui/material/Select";

//local imports
import { MuiAppBar } from "../components/index";
import { getData, putData, deleteData, postData } from "../api/apiCalls";

//misc. imports
import toast, { Toaster } from "react-hot-toast";

export const DashboardNew = () => {
  const [user, setUser] = useState({});
  const [userArr, setUserArr] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [accountInputs, setAccountInputs] = useState({});
  const [addUserInputs, setAddUserInputs] = useState({});
  const [editInputs, setEditInputs] = useState({});
  const [showPassword, setShowPassword] = useState("password");
  const [content, setContent] = useState(null);




  const columns = [
    {
      field: "last_name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (cell) => (
        <div style={{ textTransform: "capitalize" }}>
          {cell.row.first_name} {cell.row.last_name}
        </div>
      ),
    },
    {
      field: "first_name",
      headerName: "Edit",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (cell) => (
        <div>
          <Fab variant="contained" color="primary" onClick={e => {setEditInputs(cell.row);console.log(editInputs); setOpen3(true)}}>
            <EditIcon />
          </Fab>
        </div>
      ),
    },
    {
      field: "id",
      headerName: "Delete",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (cell) => (
        <div>
          <Fab
            variant="contained"
            color="secondary"
            onClick={() => {
              if (window.confirm("Permenantly delete the user?") == false) {
                return;
              } else {
                deleteData(
                  `https://youngstartup.io/api/db/users/removeuser/${cell.row.id}`
                )
                  .then((data) => console.log(data))
                  .then(() => {
                    getData("https://youngstartup.io/api/db/users/all").then(
                      (data) => {
                        setUserArr(data);
                        handleClose()
                      }
                    );
                    // let userArrCopy = userArr;
                    // userArrCopy.pop(user.id);
                    // setUserArr(userArrCopy);
                    // setTimeout(()=>window.location.reload(),500);
                  });
              }
            }}
          >
            <DeleteForeverIcon />
          </Fab>
        </div>
      ),
    },
  ];

  const handleAccountChange = (e) => {
    setAccountInputs({
      ...accountInputs,
      [e.target.name]: e.target.value,
    });
    console.log(accountInputs);
  };

  const handleAddAccount = (e) => {
    setAddUserInputs({
      ...addUserInputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditAccount = (e) => {
    setEditInputs({
      ...editInputs,
      [e.target.name]: e.target.value,
    });
    console.log(editInputs)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const addForm = (
    <>
      <h2>Add user</h2>
      <Stack spacing={1}>
        <TextField
          id="outlined-basic"
          name="first_name"
          value={addUserInputs.first_name}
          fullWidth
          label={!addUserInputs.first_name && "First name"}
          variant="outlined"
          onChange={handleAddAccount}
        />
        <TextField
          id="outlined-basic"
          name="last_name"
          value={addUserInputs.last_name}
          fullWidth
          label={!addUserInputs.last_name && "Last name"}
          variant="outlined"
          onChange={handleAddAccount}
        />
        <TextField
          id="outlined-basic"
          name="email"
          value={addUserInputs.email}
          fullWidth
          label={!addUserInputs.email && "Email"}
          variant="outlined"
          onChange={handleAddAccount}
        />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={addUserInputs.role}
          label="Role"
          defaultValue={"Role"}
          onChange={(e) => {
            setAddUserInputs({ ...addUserInputs, role: e.target.value });
          }}
        >
          <MenuItem value={"Role"} disabled sx={{ fontStyle: "italic" }}>
            Role
          </MenuItem>
          <MenuItem value={18}>Standard</MenuItem>
          <MenuItem value={36}>Admin</MenuItem>
        </Select>
        <TextField
          id="outlined-basic"
          name="password"
          value={addUserInputs.password}
          fullWidth
          label={!addUserInputs.password && "Password"}
          variant="outlined"
          onChange={handleAddAccount}
        />
        <TextField
          id="outlined-basic"
          name="confirm_password"
          value={addUserInputs.confirm_password}
          fullWidth
          label={!addUserInputs.confirm_password && "Confirm password"}
          variant="outlined"
          onChange={handleAddAccount}
        />
      </Stack>
    </>
  );

  const editForm = (
    <>
      <h2>Edit user</h2>
      <Stack spacing={1}>
        <TextField
          id="outlined-basic"
          name="first_name_2"
          value={editInputs.first_name}
          fullWidth
          label={!editInputs.first_name && "First name"}
          variant="outlined"
          onChange={handleEditAccount}
        />
        <TextField
          id="outlined-basic"
          name="last_name_2"
          value={editInputs.last_name}
          fullWidth
          label={!editInputs.last_name && "Last name"}
          variant="outlined"
          onChange={handleEditAccount}
        />
        <TextField
          id="outlined-basic"
          name="email_2"
          value={editInputs.email}
          fullWidth
          label={!editInputs.email && "Email"}
          variant="outlined"
          onChange={handleEditAccount}
        />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={editInputs.role}
          label="Role"
          defaultValue={"Role"}
          onChange={(e) => {
            setEditInputs({ ...editInputs, role: e.target.value });
          }}
        >
          <MenuItem value={"Role"} disabled sx={{ fontStyle: "italic" }}>
            Role
          </MenuItem>
          <MenuItem value={18}>Standard</MenuItem>
          <MenuItem value={36}>Admin</MenuItem>
        </Select>
        {/* <TextField
          id="outlined-basic"
          name="password_2"
          value={editInputs.password}
          fullWidth
          label={!editInputs.password && "Password"}
          variant="outlined"
          onChange={handleEditAccount}
        />
        <TextField
          id="outlined-basic"
          name="confirm_password_2"
          value={editInputs.confirm_password}
          fullWidth
          label={!editInputs.confirm_password && "Confirm password"}
          variant="outlined"
          onChange={handleEditAccount}
        /> */}
      </Stack>
    </>
  );


  const editUserForm = (
    <form>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          gap: "10%",
        }}
      >
        <Stack spacing={2}>
          <h5>Email</h5>
          <TextField
            id="outlined-basic"
            name="email"
            value={accountInputs.email}
            fullWidth
            label={!accountInputs.email && "Email"}
            variant="outlined"
            onChange={handleEditAccount}
          />
          <TextField
            onChange={handleEditAccount}
            value={accountInputs.confirm_email}
            fullWidth
            name="confirm_email"
            id="outlined-basic"
            label="Confirm Email"
            variant="outlined"
          />
        </Stack>
        <Stack spacing={2}>
          <h5>Password</h5>
          <TextField
            onChange={handleEditAccount}
            id="outlined-basic"
            value={accountInputs.password}
            InputProps={{
              endAdornment:
                showPassword == "password" ? (
                  <VisibilityIcon
                    sx={{ color: "black" }}
                    onClick={(e) => setShowPassword("text")}
                  />
                ) : (
                  <VisibilityOffIcon
                    sx={{ color: "black" }}
                    onClick={(e) => setShowPassword("password")}
                  />
                ),
            }}
            type={showPassword}
            name="password"
            fullWidth
            label="Password"
            variant="outlined"
          />
          <TextField
            onChange={handleEditAccount}
            fullWidth
            value={accountInputs.confirm_password}
            InputProps={{
              endAdornment:
                showPassword == "password" ? (
                  <VisibilityIcon
                    sx={{ color: "black" }}
                    onClick={(e) => setShowPassword("text")}
                  />
                ) : (
                  <VisibilityOffIcon
                    sx={{ color: "black" }}
                    onClick={(e) => setShowPassword("password")}
                  />
                ),
            }}
            name="confirm_password"
            type={showPassword}
            id="outlined-basic"
            label="Confirm Password"
            variant="outlined"
          />
        </Stack>
      </div>
    </form>
  );

  const manageUserForm = (
    <div style={{ height: 400, width: "100%", overflowX: "hidden" }}>
      <DataGrid
        rowHeight={85}
        rows={userArr}
        columns={columns}
        sx={{ overflow: "hidden", width: "100%" }}
        hideFooter
      />
    </div>
  );

  useEffect(() => {
    getData("https://youngstartup.io/api/db/users/current").then((data) => {
      setUser(data);
      setAccountInputs({ ...accountInputs, email: data.email });
    });
    getData("https://youngstartup.io/api/db/users/all").then((data) =>
      setUserArr(data)
    );
  }, []);

  return (
    <div>
      <MuiAppBar />
      <Dialog
        open={open3}
        onClose={(e) => setOpen3(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>{editForm}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="warning"
            onClick={(e) => setOpen3(false)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              let body = {
                body: {
                  ...addUserInputs,
                },
              };

              const myPromise = postData(
                `https://youngstartup.io/api/db/users/adduser`,
                body
              )
                .then((data) => {
                  console.log(data);
                  // console.log(inputs);
                  return data;
                })
                .then((data) => data);

              toast
                .promise(
                  myPromise,
                  {
                    loading: "Loading",
                    success: (data) => `${data.message}`,
                    error: (err) => `This just happened: ${err.toString()}`,
                  },
                  {
                    style: {
                      minWidth: "250px",
                    },
                    success: {
                      duration: 2500,
                    },
                  }
                )
                .then(() =>
                  getData("https://youngstartup.io/api/db/users/all").then(
                    (data) => {
                      setUserArr(data);
                      setOpen2(false);
                      handleClose();
                    }
                  )
                );
            }}
            autoFocus
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={(e) => setOpen2(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>{addForm}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="warning"
            onClick={(e) => setOpen2(false)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // const inputs = {
              //   first_name: addUserInputs.first_name,
              //   last_name: addUserInputs.last_name,
              //   password: addUserInputs.password,
              //   confirm_password: addUserInputs.confirm_password,
              //   email: addUserInputs.email,
              //   role: addUserInputs.role,
              // };

              let body = {
                body: {
                  ...addUserInputs,
                },
              };

              const myPromise = postData(
                `https://youngstartup.io/api/db/users/adduser`,
                body
              )
                .then((data) => {
                  console.log(data);
                  // console.log(inputs);
                  return data;
                })
                .then((data) => data);

              toast
                .promise(
                  myPromise,
                  {
                    loading: "Loading",
                    success: (data) => `${data.message}`,
                    error: (err) => `This just happened: ${err.toString()}`,
                  },
                  {
                    style: {
                      minWidth: "250px",
                    },
                    success: {
                      duration: 2500,
                    },
                  }
                )
                .then(() =>
                  getData("https://youngstartup.io/api/db/users/all").then(
                    (data) => {
                      setUserArr(data);
                      setOpen2(false);
                      handleClose();
                    }
                  )
                );
            }}
            autoFocus
          >
            Add User
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 5, overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5%",
              padding: "2%",
            }}
          >
            <h4 style={{ display: "flex", alignItems: "center" }}>
              {modalTitle}
            </h4>
            {modalTitle == "Manage Users" && (
              <Fab
                variant="contained"
                color="primary"
                onClick={(e) => {setOpen2(true)}}
              >
                <AddIcon />
              </Fab>
            )}
          </div>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="warning">
            Back
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={(e) => {
              if (modalTitle == "Edit My Profile") {
                let body = {
                  body: {
                    email: accountInputs.email,
                    password: accountInputs.password,
                  },
                };
                if (
                  accountInputs.email == accountInputs.confirm_email &&
                  accountInputs.password == accountInputs.confirm_password
                ) {
                  const myPromise = putData(
                    `https://youngstartup.io/api/db/users/updateuser/${user.id}`,
                    body
                  ).then(() => handleClose());
                  toast.promise(
                    myPromise,
                    {
                      loading: "Loading",
                      success: (data) => `Successfully updated your details!`,
                      error: (err) => `This just happened: ${err.toString()}`,
                    },
                    {
                      style: {
                        minWidth: "250px",
                      },
                      success: {
                        duration: 5000,
                      },
                    }
                  );
                } else return;
              } else {
                handleClose();
              }
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <h2 style={{ color: "white", margin: "2.5% 0%" }}>
          Welcome {user.first_name} {user.last_name}!
        </h2>
        <div
          style={{
            display: "flex",
            gap: "5%",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={(e) => {
              handleClickOpen();
              setModalTitle("Edit My Profile");
              setContent(editUserForm);
            }}
            size="large"
            endIcon={<AccountCircleIcon />}
          >
            Edit My Profile
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              handleClickOpen();
              setModalTitle("Manage Users");
              setContent(manageUserForm);
            }}
            size="large"
            endIcon={<GroupIcon />}
          >
            Manage Users
          </Button>
        </div>
      </div>
    </div>
  );
};
