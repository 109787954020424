//MUI IMPORTS
import Stack from "@mui/material/Stack";

import {
    AddOnToggle, ConfirmOptionSelect, HeadshotToggle, HoverButton, PassesToggle,
    PresenterNotes, PresenterOptionSelect, PresenterToggle, PresentingVirtualPicker,
    TrackPicker
} from "../index";

import { patchData } from "../../api/apiCalls";
import { abbreviateNumber } from "../../utils/funcs";
import styles from "./presenterColumns.module.css";

const regex1 = new RegExp("https://");
const regex2 = new RegExp("http://");

export function presenterColumns(handleErrMsg, handleSuccessMsg, setData, setStats, refreshStats,
    handleClickOpen4, fetchUrl, colors, fetchData, handleClickOpen, ddOptions, setStartupInputs,
    deadlinesStructure) {
    const columnsLevel1 = [
        {
            field: "lastname",
            headerName: "Name",
            flex: 2,
            // disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "left",
            renderCell: (cell) => {
                return (
                    <div className="cell-name-container">
                        <Stack spacing={1}>
                            <PresenterOptionSelect
                                cell={cell}
                                handleErrMsg={handleErrMsg}
                                handleSuccessMsg={handleSuccessMsg}
                                setData={setData}
                                setStats={setStats}
                                refreshStats={refreshStats}
                                fetchData={fetchData} />
                            <HoverButton
                                initialUrl={"/assets/carbon_document (1).png"}
                                hoverUrl={"/assets/carbon_document.png"}
                                onClick={() => handleClickOpen4(cell)}
                                style={{ cursor: "pointer", width: "25px", height: "25px" }} />
                        </Stack>
                        <Stack>
                            <span>
                                <a
                                    href={`mailto:${cell.row.email}`}
                                    style={{ fontSize: "1rem", color: "white" }}
                                    className="email-link"
                                >
                                    {cell.row.firstname} {cell.row.lastname}{" "}
                                </a>
                            </span>
                            <a
                                href={regex1.test(cell.row.website) || regex2.test(cell.row.website)
                                    ? cell.row.website
                                    : `//${cell.row.website}`}
                                target="_blank"
                                className={"cell-company-link"}
                            >
                                {cell.row.company_name}
                            </a>
                        </Stack>
                    </div>
                );
            },
        },
        {
            field: "rivka_last_highest_status",
            headerName: "Previous Stage",
            flex: 1,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                const val = cell.row.rivka_last_highest_status;
                let first;
                switch (val?.status) {
                    case "applied":
                        first = <span style={{ backgroundColor: "#000120", border: "1px solid white", padding: "4px 6px" }}>A</span>;
                        break;
                    case "selected":
                        first = <span style={{ backgroundColor: "#14ba05", border: "1px solid white", padding: "4px 6px" }}>S</span>;
                        break;
                    case "confirmed":
                    case "expo":
                    case "pitch":
                        first = <span style={{ backgroundColor: "#026600", border: "1px solid white", padding: "4px 6px" }}>C</span>;
                        break;
                    case "not going ahead":
                        first = <span style={{ backgroundColor: "#14ba05", border: "1px solid white", padding: "4px" }}>NGA</span>;
                        break;
                    case "rejected":
                        first = <span style={{ backgroundColor: "#d70026", border: "1px solid white", padding: "4px 6px" }}>R</span>;
                        break;
                    case "maybe":
                        first = <span style={{ backgroundColor: "#ff7a00", border: "1px solid white", padding: "4px 6px" }}>M</span>;
                        break;
                    default:
                        if (val?.status?.startsWith("nominated")) {
                            val.event = val.status.slice(13).split("-")[0];
                            first = <span style={{ backgroundColor: "#172ACF", border: "1px solid white", padding: "4px 6px" }}>N</span>;
                        }
                        break;
                }
                return first ? <div className={styles.previousEventCell} style={{ padding: "5px", overflowX: "scroll", scrollbarWidth: "thin" }}>{first} &nbsp; {val.event}</div> : <div> </div>;
            },
        },
        {
            field: "funding_raised",
            headerName: "$ Raised",
            flex: 0.5,
            type: "number",
            // disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                // if (cell.value === null ) console.log("test5", cell)
                if (cell.value === null)
                    return <div> </div>;
                const val = abbreviateNumber(cell.value).split(" ");
                // return <div>{abbreviateNumber(cell.value)}</div>;
                return <div>{val[0]} <span style={{ color: "orange" }}>{val[1]}</span></div>;
            },
        },
        {
            field: "funding_sought",
            headerName: "$ Sought",
            flex: 0.5,
            type: "number",
            // disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                //returns abbreviated number with letter e.g. "10 M"
                const val = abbreviateNumber(cell.value).split(" ");
                return <div>{val[0]} <span style={{ color: "orange" }}>{val[1]}</span></div>;
            },
        },
        {
            field: "primary_industry",
            headerName: "Industry",
            flex: 1,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "country",
            headerName: "Country",
            flex: 1,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                if (cell.value == "United States of America") {
                    return "USA";
                }
            },
        },
        {
            field: "company_stage",
            headerName: "Stage",
            flex: 1,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "revenue_positive",
            headerName: "Revenue",
            flex: .5,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <img
                        src={cell.value == "yes"
                            ? "/assets/flat-color-icons_positive-dynamic.png"
                            : "/assets/flat-color-icons_negative.png"}
                        className="revenue-icon"
                        alt="" />
                );
            },
        },
        {
            field: "presenting_virtually",
            headerName: "Status",
            flex: .5,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <PresentingVirtualPicker
                        cell={cell}
                        key={cell.id}
                        handleErrMsg={handleErrMsg}
                        handleSuccessMsg={handleSuccessMsg}
                        fetchUrl={fetchUrl}
                        setData={setData} />
                );
            },
        },
        {
            field: "rivka_status_current",
            headerName: "Notes",
            flex: 3,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <PresenterNotes
                        cell={cell}
                        key={cell.id}
                        colors={colors}
                        setData={setData}
                        fetchData={fetchData}
                        handleErrMsg={handleErrMsg}
                        handleSuccessMsg={handleSuccessMsg} />
                );
            },
        },
    ];

    // const [currentColumn, setCurrentColumn] = useState();
    // const [currentColumn, setCurrentColumn] = useState(columnsLevel1);
    const columnsLevel2 = [
        {
            field: "lastname",
            headerName: "Name",
            flex: 1.5,
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "left",
            renderCell: (cell) => {
                let contactList = JSON.parse(cell.row.secondary_contacts);
                let additionalContact = "";
                if (contactList && contactList[0]?.email) {
                    additionalContact = "?cc=" + contactList[0].email;
                }
                return (
                    <div className="cell-name-container">
                        <Stack spacing={1}>
                            <ConfirmOptionSelect
                                cell={cell}
                                setData={setData}
                                handleErrMsg={handleErrMsg}
                                handleSuccessMsg={handleSuccessMsg}
                                fetchData={fetchData} 
                                fetchUrl={fetchUrl}
                                />
                            <HoverButton
                                initialUrl={"/assets/carbon_document (1).png"}
                                hoverUrl={"/assets/carbon_document.png"}
                                onClick={() => handleClickOpen4(cell)}
                                style={{ cursor: "pointer", width: "25px", height: "25px" }} />
                        </Stack>

                        <Stack>
                            <span>
                                <a
                                    href={`mailto:${cell.row.email}${additionalContact}`}
                                    style={{ fontSize: "1rem", color: "white" }}
                                    className="email-link"
                                >
                                    {cell.row.firstname} {cell.row.lastname}{" "}
                                </a>
                                <button onClick={() => navigator.clipboard.writeText(cell.row.email)}>Copy email</button>
                                {/* <OpenInNewIcon
                            onClick={() => handleClickOpen4(cell)}
                            sx={{ cursor: "pointer" }}
                          /> */}
                            </span>
                            <a
                                // href={cell.row.website}
                                href={regex1.test(cell.row.website) || regex2.test(cell.row.website)
                                    ? cell.row.website
                                    : `//${cell.row.website}`}

                                target="_blank"
                                className={"cell-company-link"}
                            >
                                {cell.row.company_name}
                            </a>
                        </Stack>
                    </div>
                );
            },
        },
        {
            field: "secondary_contacts",
            headerName: "Secondary Contact",
            flex: 1.1,
            // flex: 1.25,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                let contactList = JSON.parse(cell.row.secondary_contacts);
                return (
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <HoverButton
                            initialUrl={"/assets/Property 1=Default.png"}
                            hoverUrl={"/assets/Property 1=Variant2.png"}
                            className="status-picker-icon"
                            onClick={() => handleClickOpen(cell)} />
                        <Stack sx={{ alignItems: "right" }}>
                            <div style={{ width: "20%", textAlign: "center" }}>
                                {Array.isArray(contactList) && contactList[0]?.name}
                            </div>
                            <div
                                style={{
                                    width: "20%",
                                    textAlign: "center",
                                    fontSize: ".85rem",
                                }}
                            >
                                {Array.isArray(contactList) && contactList[0]?.email}
                            </div>
                        </Stack>
                    </div>
                );
            },
        },
        {
            field: "phone_number",
            headerName: "Phone Number",
            flex: 0.75,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "primary_industry",
            headerName: "Industry",
            flex: 1,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#303265",
                            padding: "5%",
                            gap: "5%",
                            alignItems: "center",
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflowX: "scroll"
                        }}
                    >
                        {ddOptions &&
                            <TrackPicker
                                cell={cell}
                                ddOptions={ddOptions}
                                handleErrMsg={handleErrMsg}
                                handleSuccessMsg={handleSuccessMsg}
                                setData={setData}
                                setStartupInputs={setStartupInputs} />}
                        <Stack>
                            <span style={{ color: "#F0AD4E", fontSize: "1rem", width: "75%" }}>
                                {cell.row.primary_industry}
                            </span>
                            <div>{cell.row.industry_sub_category}</div>
                        </Stack>
                    </div>
                );
            },
        },
        {
            field: "country",
            headerName: "Country",
            flex: 0.75,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                if (cell.value == "United States of America") {
                    return "USA";
                }
            },
        },
        {
            field: "company_stage",
            headerName: "Stage",
            flex: 1.1,
            // flex: 0.75,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "presenting_virtually",
            headerName: "Status",
            flex: 1.1,
            // flex: 0.5,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <PresentingVirtualPicker
                        cell={cell}
                        fetchUrl={fetchUrl}
                        setData={setData}
                        handleErrMsg={handleErrMsg}
                        handleSuccessMsg={handleSuccessMsg} />
                );
            },
        },
        {
            field: "time_preference",
            headerName: "Slot Pref",
            flex: 0.5,
            // type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <input
                        style={{ width: "100%", color: "white", backgroundColor: "#303265" }}
                        defaultValue={cell.row.time_preference}
                        onBlur={(e) => {
                            if (e.target.value !== cell.row.time_preference) {
                                let body = {
                                    body: {
                                        id: cell.row.id,
                                        time_preference: e.target.value
                                    }
                                };
                                patchData("https://youngstartup.io/api/db-a/presenters/updateapplication", body)
                                    .then((data) => {
                                        if (data.success) {
                                            handleSuccessMsg();
                                        }
                                    });
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.code === "Space") {
                                e.stopPropagation();
                            }
                        }} />
                );
            },
        },
        {
            field: "rivka_status_current",
            headerName: "Notes",
            flex: 2.,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <PresenterNotes
                        cell={cell}
                        colors={colors}
                        setData={setData}
                        handleErrMsg={handleErrMsg}
                        handleSuccessMsg={handleSuccessMsg}
                        fetchData={fetchData} />
                );
            },
        },
    ];

    const columnsLevel3 = [
        {
            field: "lastname",
            headerName: "Featured Presenter",
            flex: 1.5,
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "left",
            renderCell: (cell) => {
                return (
                    <div className="cell-name-container">
                        <Stack spacing={1}>
                            <ConfirmOptionSelect
                                cell={cell}
                                setData={setData}
                                handleErrMsg={handleErrMsg}
                                handleSuccessMsg={handleSuccessMsg}
                                fetchData={fetchData} 
                                fetchUrl={fetchUrl}
                                />
                            <HoverButton
                                initialUrl={"/assets/carbon_document (1).png"}
                                hoverUrl={"/assets/carbon_document.png"}
                                onClick={() => handleClickOpen4(cell)}
                                style={{ cursor: "pointer", width: "25px", height: "25px" }} />
                        </Stack>
                        <Stack>
                            <img
                                src={cell.row.logo_url
                                    ? cell.row.logo_url
                                    : "/assets/placeholder.jpeg"}
                                loading="lazy"
                                style={{
                                    height: "75px",
                                    width: "75px",
                                    objectFit: "contain",
                                    backgroundColor: "white",
                                }}
                                alt="" />
                        </Stack>
                        <Stack>
                            <span>
                                <a
                                    style={{ fontSize: "1.25rem" }}
                                    href={`mailto:${cell.row.email}`}
                                    className="email-link"
                                >
                                    {cell.row.firstname} {cell.row.lastname}
                                </a>
                                {/* <OpenInNewIcon
                            onClick={() => handleClickOpen4(cell)}
                            sx={{ cursor: "pointer" }}
                          /> */}
                            </span>
                            <a
                                // href={cell.row.website}
                                href={regex1.test(cell.row.website) || regex2.test(cell.row.website)
                                    ? cell.row.website
                                    : `//${cell.row.website}`}
                                target="_blank"
                                className={"cell-company-link"}
                            >
                                {cell.row.company_name}
                            </a>
                            <span>
                                {cell.row.country}
                            </span>
                        </Stack>
                    </div>
                );
            },
        },
        {
            //the primary_industry field doesn't work: "An error occurred."
            //"MUI: GridErrorHandler - An unexpected error occurred. Error: Maximum call stack size exceeded."
            // field: "primary_industry",
            field: "secondary_contacts",
            headerName: "Industry",
            flex: 1,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                // console.log("test", cell.row)
                return (
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#303265",
                            padding: "5%",
                            gap: "5%",
                            alignItems: "center",
                            width: "100%",
                            whiteSpace: "nowrap",
                            overflowX: "scroll"
                        }}
                    >
                        {ddOptions &&
                            <TrackPicker
                                cell={cell}
                                ddOptions={ddOptions}
                                handleErrMsg={handleErrMsg}
                                handleSuccessMsg={handleSuccessMsg}
                                setData={setData}
                                setStartupInputs={setStartupInputs} />}
                        <Stack>
                            <span style={{ color: "#F0AD4E", fontSize: "1rem", width: "75%" }}>
                                {cell.row.primary_industry}
                            </span>
                            <div>{cell.row.industry_sub_category}</div>
                        </Stack>
                    </div>
                );
            },
        },
        // {
        //   field: "secondary_contacts",
        //   headerName: "Secondary Contact",
        //   flex: 1.1,
        //   type: "number",
        //   disableColumnMenu: true,
        //   headerClassName: "super-app-theme--header",
        //   headerAlign: "center",
        //   align: "center",
        //   renderCell: (cell) => {
        //     let contactList = JSON.parse(cell.row.secondary_contacts);
        //     return (
        //       <div
        //         style={{
        //           display: "flex",
        //           gap: "10px",
        //           alignItems: "center",
        //           width: "100%",
        //         }}
        //       >
        //         <HoverButton
        //           initialUrl={"/assets/Property 1=Default.png"}
        //           hoverUrl={"/assets/Property 1=Variant2.png"}
        //           className="status-picker-icon"
        //           onClick={() => handleClickOpen(cell)}
        //         />
        //         <Stack sx={{ alignItems: "right" }}>
        //           <div style={{ width: "20%", textAlign: "center" }}>
        //             {Array.isArray(contactList) && contactList[0]?.name}
        //           </div>
        //           <div
        //             style={{
        //               width: "20%",
        //               textAlign: "center",
        //               fontSize: ".85rem",
        //             }}
        //           >
        //             {Array.isArray(contactList) && contactList[0]?.email}
        //           </div>
        //         </Stack>
        //       </div>
        //     );
        //   },
        // },
        {
            field: "email_confirmed",
            headerName: "Status",
            flex: 1.1,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <div style={{ display: "flex", gap: "2.5%" }}>
                        <Stack spacing={1}>
                            <div style={{ display: "flex", alignItems: "center", gap: "5%" }}>
                                <div style={{ width: "85px" }}>Confirmed</div>
                                <div>
                                    <PresenterToggle
                                        property={cell.row.email_confirmed_2}
                                        prop={"email_confirmed_2"}
                                        newValue={null}
                                        cell={cell}
                                        id={cell.row.id}
                                        setData={setData}
                                        handleErrMsg={handleErrMsg}
                                        handleSuccessMsg={handleSuccessMsg} />
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "5%" }}>
                                <div style={{ width: "85px" }}>Logged In</div>
                                <div>
                                    <PresenterToggle
                                        property={cell.row.opened_dashboard}
                                        newValue={""}
                                        setData={setData}
                                        id={cell.row.id} />
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "5%" }}>
                                <div style={{ width: "85px" }}>Passes</div>
                                <div>
                                    <PassesToggle cell={cell} />
                                </div>
                            </div>
                        </Stack>
                        <Stack spacing={1}>
                            <div style={{ display: "flex", alignItems: "center", gap: "5%" }}>
                                {cell.row.presentation_link ? (
                                    <a href={cell.row.presentation_link} className="email-link">
                                        <div style={{ width: "85px", color: "white !important" }}>
                                            Presentation
                                        </div>
                                    </a>
                                ) : (
                                    <div style={{ width: "85px", color: "white !important" }}>
                                        Presentation
                                    </div>
                                )}
                                <div>
                                    <PresenterToggle
                                        property={cell.row.presentation_link}
                                        newValue={""}
                                        id={cell.row.id}
                                        setData={setData} />
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "5%" }}>
                                <div style={{ width: "90px" }}>H, L, O-L</div>
                                <div>
                                    <HeadshotToggle cell={cell} />
                                </div>
                            </div>
                        </Stack>
                    </div>
                );
            },
        },
        {
            field: "emails_sent",
            headerName: "Emails Sent",
            // flex: .75,
            flex: 1,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                let emails = [];
                if (cell?.row.emails_sent) {
                    emails = cell.row.emails_sent.map(e => {
                        return e == 32 ? e : e.option_display_short;
                    });
                }
                return (
                    <Stack>
                        {emails.length ?
                            emails.map((email, i) => {
                                // return <div key={i} style={{  padding: "5px", overflow: "scroll !important", scrollbarWidth: "thin"  }}>
                                return <div key={i} style={{ display: "block", width: "100%" }}>
                                    {email} , &nbsp;
                                </div>;
                            })
                            : <div></div>}
                    </Stack>
                );
            },
        },
        {
            field: "primary_industry",
            headerName: "Track Info",
            flex: 0.5,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <Stack sx={{ display: "flex", alignItems: "center" }} spacing={1}>
                        {ddOptions && <TrackPicker
                            ddOptions={ddOptions}
                            cell={cell}
                            handleSuccessMsg={handleSuccessMsg}
                            handleErrMsg={handleErrMsg}
                            setData={setData}
                            setStartupInputs={setStartupInputs} />}

                        <PresentingVirtualPicker
                            cell={cell}
                            fetchUrl={fetchUrl}
                            setData={setData}
                            handleErrMsg={handleErrMsg}
                            handleSuccessMsg={handleSuccessMsg} />
                    </Stack>
                );
            },
        },
        {
            field: "passes_deadline",
            headerName: "Deadlines",
            flex: .85,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <table>
                        <tbody>
                            {deadlinesStructure.map((dl) => {
                                return (
                                    <tr key={dl.id}>
                                        <td style={{ borderRight: "1px solid gray", color: "#F0AD4E" }}>
                                            {dl.option_display_short}
                                        </td>
                                        <td>
                                            {cell.row[dl.option_value] === ""
                                                ? "TBA"
                                                : new Date(cell.row[dl.option_value]).toLocaleString("en-US", { month: "long", day: "numeric" })}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                );
            },
        },
        {
            field: fetchUrl.includes("top") ? "opted_in_coaching" : "so_inclusion",
            headerName: fetchUrl.includes("top") ? "Coaching" : "SO",
            flex: 0.5,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                if (cell.row.final_presenting_track !== "Seed") {
                    return (
                        <div style={{ display: "flex", gap: "5%" }}>
                            <div>Opt-in:</div>
                            <PresenterToggle
                                property={cell.row.opted_in_coaching}
                                cell={cell}
                                prop={"opted_in_coaching"}
                                newValue={"no"}
                                handleErrMsg={handleErrMsg}
                                setData={setData}
                                handleSuccessMsg={handleSuccessMsg}
                                id={cell.row.id} />
                        </div>
                    );
                } else {
                    return (
                        <span>
                            <span>SO: </span>
                            <AddOnToggle
                                cell={cell}
                                setData={setData}
                                handleSuccessMsg={handleSuccessMsg} />
                        </span>
                    );
                }
            },
        },
        {
            field: "time_preference",
            headerName: "Slot Pref",
            flex: 0.5,
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <input
                        style={{ width: "100%", color: "white", backgroundColor: "#303265" }}
                        defaultValue={cell.row.time_preference}
                        onBlur={(e) => {
                            if (e.target.value !== cell.row.time_preference) {
                                let body = {
                                    body: {
                                        id: cell.row.id,
                                        time_preference: e.target.value
                                    }
                                };
                                patchData("https://youngstartup.io/api/db-a/presenters/updateapplication", body)
                                    .then((data) => {
                                        if (data.success) {
                                            handleSuccessMsg();
                                        }
                                    });
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.code === "Space") {
                                e.stopPropagation();
                            }
                        }} />
                );
            },
        },
        {
            field: "rivka_status_current",
            headerName: "Notes",
            flex: 1.5,
            // flex: 1.35,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <PresenterNotes
                        cell={cell}
                        colors={colors}
                        handleErrMsg={handleErrMsg}
                        handleSuccessMsg={handleSuccessMsg}
                        setData={setData}
                        fetchData={fetchData} />
                );
            },
        },
    ];

    const columnsLevel4 = [
        {
            field: "lastname",
            headerName: "Name",
            flex: 2,
            // disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "left",
            renderCell: (cell) => {
                // console.log("cell", cell.row.rivka_last_highest_status);
                return (
                    <div className="cell-name-container">
                        <Stack spacing={1}>
                            <PresenterOptionSelect
                                cell={cell}
                                handleErrMsg={handleErrMsg}
                                handleSuccessMsg={handleSuccessMsg}
                                setData={setData}
                                setStats={setStats}
                                refreshStats={refreshStats}
                                fetchData={fetchData} />
                            <HoverButton
                                initialUrl={"/assets/carbon_document (1).png"}
                                hoverUrl={"/assets/carbon_document.png"}
                                onClick={() => handleClickOpen4(cell)}
                                style={{ cursor: "pointer", width: "25px", height: "25px" }} />
                        </Stack>
                        <Stack>
                            <span>
                                <a
                                    href={`mailto:${cell.row.email}`}
                                    style={{ fontSize: "1rem", color: "white" }}
                                    className="email-link"
                                >
                                    {cell.row.firstname} {cell.row.lastname}{" "}
                                </a>
                            </span>
                            <a
                                href={regex1.test(cell.row.website) || regex2.test(cell.row.website)
                                    ? cell.row.website
                                    : `//${cell.row.website}`}
                                target="_blank"
                                className={"cell-company-link"}
                            >
                                {cell.row.company_name}
                            </a>
                        </Stack>
                    </div>
                );
            },
        },
        {
            field: "funding_raised",
            headerName: "$ Raised",
            flex: 0.5,
            type: "number",
            // disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                const val = abbreviateNumber(cell.value).split(" ");
                // return <div>{abbreviateNumber(cell.value)}</div>;
                return <div>{val[0]} <span style={{ color: "orange" }}>{val[1]}</span></div>;
            },
        },
        {
            field: "funding_sought",
            headerName: "$ Sought",
            flex: 0.5,
            type: "number",
            // disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                //returns abbreviated number with letter e.g. "10 M"
                const val = abbreviateNumber(cell.value).split(" ");
                return <div>{val[0]} <span style={{ color: "orange" }}>{val[1]}</span></div>;
            },
        },
        {
            field: "primary_industry",
            headerName: "Industry",
            flex: 1,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "country",
            headerName: "Country",
            flex: 1,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                if (cell.value == "United States of America") {
                    return "USA";
                }
            },
        },
        {
            field: "company_stage",
            headerName: "Stage",
            flex: 1,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
        },
        {
            field: "revenue_positive",
            headerName: "Revenue",
            flex: .5,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <img
                        src={cell.value == "yes"
                            ? "/assets/flat-color-icons_positive-dynamic.png"
                            : "/assets/flat-color-icons_negative.png"}
                        className="revenue-icon"
                        alt="" />
                );
            },
        },
        {
            field: "presenting_virtually",
            headerName: "Status",
            flex: .5,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <PresentingVirtualPicker
                        cell={cell}
                        key={cell.id}
                        handleErrMsg={handleErrMsg}
                        handleSuccessMsg={handleSuccessMsg}
                        fetchUrl={fetchUrl}
                        setData={setData} />
                );
            },
        },
        {
            field: "rivka_status_current",
            headerName: "Notes",
            flex: 3,
            type: "number",
            disableColumnMenu: true,
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            align: "center",
            renderCell: (cell) => {
                return (
                    <PresenterNotes
                        cell={cell}
                        key={cell.id}
                        colors={colors}
                        setData={setData}
                        fetchData={fetchData}
                        handleErrMsg={handleErrMsg}
                        handleSuccessMsg={handleSuccessMsg} />
                );
            },
        },
    ];
    return { columnsLevel1, columnsLevel2, columnsLevel3, columnsLevel4 };
}
