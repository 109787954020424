import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import Stack from "@mui/material/Stack";

export const ContactListForm = (props) => {
  return (
    <form
      style={{
        zIndex: "2",
        display: props.showListForm,
        backgroundColor: "gray",
        height: "auto",
        width: "25vw",
        padding: "2.5rem",
        paddingLeft:'1.75rem',
        position: "fixed",
        borderRadius: "5px",        
        right:'35vw',
        top:"5%"
      }}
    >
        <CloseIcon  
            style={{marginBottom:'1rem'}}
            onClick={()=>props.setShowListForm('none')}
        />

        <Stack spacing={3}>
      <TextField id="outlined-basic" label="Outlined" variant="outlined" />
      <TextField id="outlined-basic" label="Outlined" variant="outlined" />
      <TextField id="outlined-basic" label="Outlined" variant="outlined" />
      <TextField id="outlined-basic" label="Outlined" variant="outlined" />
      <TextField id="outlined-basic" label="Outlined" variant="outlined" />
      <TextField id="outlined-basic" label="Outlined" variant="outlined" />

      <Button variant='contained' color="primary" style={{marginLeft:'3rem', justifyContent:'center', width:'80%'}}>Create List</Button>
      </Stack>
    </form>
  );
};
