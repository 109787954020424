import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { ScheduleDnD } from '../index'

export const Schedule = () =>{
    return(
        <DndProvider backend={HTML5Backend}>
					<ScheduleDnD />
				</DndProvider>
    )
}