import React, { useMemo, useState, useEffect } from "react";
import ReactTable, {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
  usePagination,
  useColumnOrder,
} from "react-table";
import { WISH_COLUMNS } from "../columns";
import { GlobalFilter2, Checkbox } from "../index";
import { ContactForm } from "../Forms/ContactForm";
import "../../components/Table.css";
import toast from "react-hot-toast";
import { Offcanvas, Tabs, Tab } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../utils/funcs";
import CreatableSelect from "react-select/creatable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Fab from "@mui/material/Fab";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsIcon from "@mui/icons-material/Settings";
import FilterListIcon from "@mui/icons-material/FilterList";
import Tooltip from "@mui/material/Tooltip";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

const notify = () => toast("Updated Successfuly!!");
// toast.success('Successfully updated!!');
// toast.loading('Waiting...');

export const WishContactTable = () => {
  //STATES AND VARIABLES
  const [dataArr, setDataArr] = useState([]);
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState("none");
  const [skipPageReset, setSkipPageReset] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [showFilters, setShowFilters] = useState("none");
  const [select2, setSelect2] = useState(undefined);
  const columns = useMemo(() => WISH_COLUMNS, []);
  const [loader, setLoader] = useState("none");
  const [hideBtns, setHideBtns] = useState("inline-block");
  const NotEditableCells = [
    "id",
    "properties.createdate",
    "properties.company",
  ];

  //TEMPLATE FOR EDITABLE CELLS
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
  }) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onBlur = () => {
      updateMyData(index, id, value);
    };

    const onFocus = (e) => {
      if (e.target.dataset.disabled == "true") {
        e.target.blur();
        return;
      }
      e.target.dataset.disabled = true;
    };

    const onDoubleClick = (e) => {
      e.target.dataset.disabled = false;
      e.target.focus();
    };

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (NotEditableCells.includes(id)) {
      return value;
    }
    return (
      <input
        className="editable-cell"
        value={value}
        onChange={onChange}
        data-disabled={true}
        onFocus={onFocus}
        onDoubleClick={onDoubleClick}
        onBlur={onBlur}
        onKeyPress={(e) => {
          if (e.code == "NumpadEnter" || e.code == "Enter") {
            e.target.blur();
          }
        }}
      />
    );
  };

  const defaultColumn = useMemo(() => {
    return {
      Cell: EditableCell,
    };
  }, []);

  ////////////////////////////////////////////////////////
  //FILTER FUNCTION

  const onFilteredChangeCustom = (value, accessor) => {
    let insertNewFilter = 1;
    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    setFiltered(filtered);
  };

  //////////////////////////////////////////////////////

  const updateMyData = (rowIndex, columnId, value) => {
    columnId = columnId.split(".").pop();
    setSkipPageReset(true);
    let currentRow = dataArr.filter((row, index) => {
      if (index === rowIndex) {
        // console.log(row);
        return row;
      }
    });

    currentRow = currentRow[0];

    // check if there is a change in the value
    if (currentRow.properties[columnId] === value) {
      return;
    }

    let newObj = {
      properties: {
        [columnId]: value,
      },
    };
    console.log(newObj);

    const myPromise = editContact(
      "https://youngstartup.io/api/hs/contacts/update/" + currentRow.id,
      newObj
    ).then((data) => {
      console.log(data);

      if (data.status && data.status == "error") {
        console.error(data.message);

        throw new Error(data.message);
      }
      // FIX - reset component so you get fresh info from API
      return data;
    });

    toast.promise(
      myPromise,
      {
        loading: "Loading",
        success: (data) => `Successfully updated id: ${data.id}`,
        error: (err) => `This just happened: ${err.toString()}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
          // icon: '🔥',
        },
      }
    );
    // setTimeout(() => window.location.reload(), 1500);
  };

  useEffect(() => {
    setSkipPageReset(false);
  }, [dataArr]);

  async function deleteContact(url, input) {
    const myBody = { body: input };
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(myBody),
    });
    return;
  }

  async function editContact(url, data) {
    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async function getData(url) {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json();
  }

  const handleAddContact = (e) => {
    e.preventDefault();
    setShowForm("block");
    setHideBtns("none");
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShowForm("none");
    setHideBtns("inline-block");
  };

  const handleCloseSide = () => setShow(false);

  const handleShowSide = () => setShow(true);

  const highliteCell = (e) => {
    e.target
      .closest("tbody")
      .querySelectorAll("td")
      .forEach((cell) => {
        cell.classList.remove("highlighted-cells");
      });
    e.target.classList.add("highlighted-cells");
  };

  const selectCells = (e) => {
    e.target
      .closest("tbody")
      .querySelectorAll("td")
      .forEach((cell) => {
        cell.classList.remove("selected-cells");
      });
    e.target.classList.add("selected-cells");
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    page,
    setGlobalFilter,
    nextPage,
    selectedFlatRows,
    getToggleHideAllColumnsProps,
    allColumns,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    setColumnOrder,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: dataArr,
      defaultColumn,
      initialState: { pageSize: 50 },
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <>
                <div>Select</div>
                <Checkbox {...getToggleAllRowsSelectedProps()} />
              </>
            ),
            Cell: ({ row, data, value }) => (
              <>
                <div className="select-container">
                  <Checkbox
                    {...row.getToggleRowSelectedProps()}
                    style={{ marginRight: "1rem", marginTop: "11.5%" }}
                    className="checkbox"
                    value={value}
                  />
                  <Tooltip title="Unsave">
                    <Fab
                      color="secondary"
                      className="delete-icon"
                      onClick={() => {
                        let item = data[row.index];
                        let id = item.id;
                        console.log(item);
                        const myPromise = deleteContact(
                          "https://youngstartup.io/api/db/wishlist/delete/contacts/" +
                            data[row.index].id
                        ).then(() => {
                          getData(
                            `https://youngstartup.io/api/db/wishlist/getuserwishlist/contacts`
                          ).then((data) => {
                            setDataArr(data.results);
                          });
                        });

                        toast.promise(
                          myPromise,
                          {
                            loading: "Loading",
                            success: (data) =>
                              `Successfully removed ${capitalizeFirstLetter(
                                item.properties.firstname
                              )} ${capitalizeFirstLetter(
                                item.properties.lastname
                              )}`,
                            error: (err) => `error: ${err.toString()}`,
                          },
                          {
                            style: {
                              minWidth: "250px",
                            },
                            success: {
                              duration: 5000,
                              // icon: '🔥',
                            },
                          }
                        );
                      }}
                    >
                      <FavoriteBorderOutlinedIcon
                        style={{ transform: "scale(1.5)" }}
                        key={data[row.index].id}
                      />
                    </Fab>
                  </Tooltip>
                </div>
              </>
            ),
          },
          ...columns,
          [],
        ];
      });
    }
  );

  const multiIdArr = selectedFlatRows.map((x) => {
    return { id: `${x.original.id}` };
  });

  async function deleteMulti(
    url = "https://youngstartup.io/api/hs/contacts/delmulti"
  ) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(multiDeleteArr),
    });
    return;
  }

  const refreshTableData = () => {
    getData(`https://youngstartup.io/api/db/wishlist/getuserwishlist`).then(
      (data) => setDataArr(data.results)
    );
  };

  const multiDeleteArr = { inputs: multiIdArr };

  const { globalFilter, pageIndex, pageSize } = state;

  // useEffect(() => {
  //   if (dataArr <= 0)
  //     getData(`https://youngstartup.io/api/hs/contacts/all`).then((data) => {
  //       setLoader("none");
  //       setDataArr(data.results);
  //     });
  // }, []);

  useEffect(() => {
    getData(
      "https://youngstartup.io/api/db/wishlist/getuserwishlist/contacts"
    ).then((data) => setDataArr(data.results));
  }, []);

  console.log(dataArr);

  return (
    <>
      <ContactForm
        showForm={showForm}
        setShowForm={setShowForm}
        handleClose={handleClose}
        getData={getData}
        setDataArr={setDataArr}
        dataArr={dataArr}
      />

      <GlobalFilter2
        dataArr={dataArr}
        setDataArr={setDataArr}
        filter={globalFilter}
        setFilter={setGlobalFilter}
      />
      <Tooltip title="Column settings">
        <Fab
          color="primary"
          style={{ display: hideBtns }}
          className="add-circle-icon settings-icon"
          aria-label="add"
          onClick={handleShowSide}
        >
          <SettingsIcon />
        </Fab>
      </Tooltip>
      <Offcanvas
        show={show}
        onHide={handleCloseSide}
        style={{ width: "fit-content", backgroundColor: "#212529" }}
      >
        <Offcanvas.Header closeButton>Column Settings</Offcanvas.Header>
        <div className="column-display" style={{ fontSize: "1.2rem" }}>
          Display Columns:
          <br />
          <span style={{ color: "white", marginRight: "1rem" }}>
            <Checkbox {...getToggleHideAllColumnsProps()} />
            Toggle All
          </span>
          {allColumns.map((column) => {
            if (column.id)
              return (
                <div key={column.id} style={{ display: "block" }}>
                  <label style={{ color: "white" }}>
                    <input type="checkbox" {...column.getToggleHiddenProps()} />
                    {typeof column.Header === "string"
                      ? column.Header
                      : "Select"}
                  </label>
                </div>
              );
          })}
        </div>
      </Offcanvas>
      <Tooltip title="Refresh page">
        <Fab
          color="primary"
          style={{ marginRight: "2rem", marginLeft: "2rem", display: hideBtns }}
          aria-label="add"
          onClick={() => {
            let myPromise = getData(
              `https://youngstartup.io/api/db/wishlist/getuserwishlist/contacts`
            ).then((data) => {
              setDataArr(data.results);
            });

            toast.promise(
              myPromise,
              {
                loading: "Loading",
                success: (data) => `Refreshed!`,
                error: (err) => `This just happened: ${err.toString()}`,
              },
              {
                style: {
                  minWidth: "250px",
                },
                success: {
                  duration: 1000,
                },
              }
            );
          }}
        >
          <RefreshIcon />
        </Fab>
      </Tooltip>
      <Tooltip title="Filter options">
        <Fab
          color="primary"
          onClick={() => {
            showFilters == "none"
              ? setShowFilters("block")
              : setShowFilters("none");
          }}
          style={{ display: hideBtns }}
          className="add-circle-icon settings-icon filter-icon"
          aria-label="add"
        >
          <FilterListIcon />
        </Fab>
      </Tooltip>
      {selectedFlatRows.length >= 1 && (
        <Tooltip title="Delete selected">
          <Fab
            color="secondary"
            style={{ display: hideBtns }}
            className="add-circle-icon settings-icon"
            aria-label="add"
            onClick={() => {
              let myPromise = deleteMulti();

              toast.promise(
                myPromise,
                {
                  loading: "Loading",
                  success: (data) => {
                    refreshTableData();
                    return `Successfully deleted!!`;
                  },
                  error: (err) => `This just happened: ${err.toString()}`,
                },
                {
                  style: {
                    minWidth: "250px",
                  },
                  success: {
                    duration: 5000,
                  },
                }
              );
            }}
          >
            <DeleteForeverIcon className="refresh-btn" variant="danger" />
          </Fab>
        </Tooltip>
      )}
      <div style={{ display: showFilters }}>
        <h4 style={{ textAlign: "center", color: "white", marginTop: "1rem" }}>
          Filter Options:
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CreatableSelect
            style={{
              width: "10% !important",
              marginBottom: "20px",
              margin: "2rem",
              display: "flex",
            }}
            className="advanced-filter-input"
            onCreateOption={(old, entry) => setFiltered([...old, entry])}
            onChange={(entries) => {
              setSelect2(entries);
              onFilteredChangeCustom(
                entries.map((entry) => {
                  return entry.value;
                })
              );
              setDataArr(
                dataArr.filter((x, i) => {
                  for (let j = 0; j < entries.length; j++) {
                    if (x.properties.firstname == entries[j].value)
                      return dataArr;
                  }
                })
              );
            }}
            value={select2}
            isMulti
            options={
              dataArr > 1 &&
              dataArr.map((contact, i) => {
                const myOption = {
                  id: i,
                  value: contact.properties.firstname,
                  label: contact.properties.firstname,
                };
                console.log(myOption);
              })
            }
          />
        </div>
      </div>
      {/*<span>
        <button onClick={() => setShowColumnForm("block")}>
          Set Column Order
        </button>
      </span>
      <form className="column-form" style={{display:showColumnForm, textAlign:'center', padding:'2rem', borderRadius:'10px'}}>
        <div style={{textAlign:'right'}} onClick={()=>setShowColumnForm('none')}>X</div>
        <label style={{justifyContent:"center"}}>Column Order</label>
        <ul style={{listStyleType:'none', paddingLeft:'0'}}>
          {
          allColumns.map((column) => {
            if (typeof column.Header == "string")
              return <li draggable="true" onDrop={handleDrop} key={column.id} style={{color:'white', border:'1px solid white', margin:'1rem'}}>{column.Header}</li>;
          })}
        </ul>
        </form> */}
      <div className="investor-count">
        Currently displaying{" "}
        <span
          style={{
            color: "red",
            fontSize: "1.5rem",
            marginRight: ".5rem",
            marginLeft: ".5rem",
          }}
        >
          {dataArr.length}
        </span>{" "}
        contacts
      </div>
      <img
        style={{
          display: loader,
          position: "absolute",
          top: "50%",
          left: "45%",
        }}
        src="pulse.gif"
        alt=""
        />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            if (headerGroup)
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  <th>Row Number</th>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/*<div>{column.canFilter ? column.render("Filter") : null}</div>*/}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "⬆️"
                            : "⬇️"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {
                  <td>
                    <span>{parseInt(row.id) + 1}</span>
                  </td>
                }
                {row.cells.map((cell) => {
                  if (cell !== row.cells[row.cells.length - 1]) {
                    // console.log(row.cells[0])
                    return (
                      <td
                        onMouseDown={selectCells}
                        onMouseEnter={highliteCell}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
        {/* // FOOTER FOR TABLE //
        <tfoot>
          {footerGroups.map((footerGroup) => (
            <tr {...footerGroup.getFooterGroupProps()}>
              {footerGroup.headers.map((column) => (
                <td {...column.getFooterProps()}>{column.render("Footer")}</td>
              ))}
            </tr>
          ))}
              </tfoot>*/}
      </table>
      <div style={{ textAlign: "center", padding: "0", margin: "0" }}>
        <span
          style={{
            color: "white",
            marginRight: "1rem",
            backgroundColor: "#212529",
          }}
        >
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <span style={{ color: "white" }}>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            className="next-btn page-num"
          />
        </span>
        <select
          style={{
            backgroundColor: "inherit",
            color: "white",
            border: "1px solid white",
            borderRadius: "15px",
            marginTop: "1vh",
            marginLeft: "1rem",
          }}
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 25, 50, 100].map((pageSize) => (
            <option
              key={pageSize}
              style={{ backgroundColor: "#212529", color: "white" }}
              value={pageSize}
            >
              Show {pageSize}
            </option>
          ))}
        </select>
        {!canPreviousPage && (
          <button className="next-btn" disabled onClick={() => gotoPage(0)}>
            {"<<"}
          </button>
        )}
        {canPreviousPage && (
          <button className="next-btn" onClick={() => gotoPage(0)}>
            {"<<"}
          </button>
        )}
        {!canPreviousPage && (
          <button className="next-btn" disabled onClick={() => previousPage()}>
            Previous
          </button>
        )}
        {canPreviousPage && (
          <button className="next-btn" onClick={() => previousPage()}>
            Previous
          </button>
        )}
        {!canNextPage && (
          <button className="next-btn" disabled onClick={() => nextPage()}>
            Next
          </button>
        )}
        {canNextPage && (
          <button className="next-btn" onClick={() => nextPage()}>
            Next
          </button>
        )}
        {canNextPage && (
          <button className="next-btn" onClick={() => gotoPage(pageCount - 1)}>
            {">>"}
          </button>
        )}
        {!canNextPage && (
          <button
            className="next-btn"
            disabled
            onClick={() => gotoPage(pageCount - 1)}
          >
            {">>"}
          </button>
        )}
      </div>
    </>
  );
};
