import { useState } from "react";
import {
  NavBar,
  CompanyListsTable,
  CompanyListResultsTable,
} from "../components/index";

export const CompanyLists = () => {
  const [showTable, setShowTable] = useState(null);
  const [showResultsTable, setShowResultsTable] = useState('none');
  const [rowId, setRowId] = useState(null);

  return (
    <>
      <NavBar />
      <CompanyListsTable
        showTable={showTable}
        setShowTable={setShowTable}
        showResultsTable={showResultsTable}
        setShowResultsTable={setShowResultsTable}
        rowId={rowId}
      />
      <CompanyListResultsTable 
        showResultsTable={showResultsTable} 
        setShowResultsTable={setShowResultsTable}
        rowId={rowId}
      />
    </>
  );
};
