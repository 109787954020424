import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
  updateMyData,
  usePagination,
  useColumnOrder,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import { ColumnFilter } from "../index";
import { NESTED_COLUMNS } from "../columns";
import { GlobalFilter, Checkbox } from "../index";
import "../../components/Table.css";
import toast from "react-hot-toast";
import { capitalizeFirstLetter } from "../../utils/funcs";
import Fab from "@mui/material/Fab";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';

const notify = () => toast("Updated Successfuly!!");
// toast.success('Successfully updated!!');
// toast.loading('Waiting...');

export const NestedTable = (props) => {
  const selectedArr = props.selectedArr;
  const setSelectedArr = props.setSelectedArr;
  const inheritedRow = props.row.original.id;
  const emailList = props.emailList;
  const setEmailList = props.setEmailList;
  const [dataArr, setDataArr] = useState([]);
  const [currentRow, setCurrentRow] = useState(undefined);
  const [companyId, setCompanyId] = useState(undefined);
  const [currentRowName, setCurrentRowName] = useState(undefined);
  const [companyName, setCompanyName] = useState(undefined);
  const columns = useMemo(() => NESTED_COLUMNS, []);
  const [loader, setLoader] = useState('none')
  const [originalData] = useState(dataArr);
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState("none");
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [showColumnForm, setShowColumnForm] = useState("none");

  const handleCloseSide = () => setShow(false);
  const handleShowSide = () => setShow(true);

  console.log(inheritedRow)

  const NotEditableCells = [
    "id",
    "properties.createdate",
    "properties.company",
    "properties.email",
    "properties.firstname",
    "properties.lastname"
  ];

  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
  }) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onBlur = () => {
      updateMyData(index, id, value);
    };

    const onFocus = (e) => {
      if (e.target.dataset.disabled == "true") {
        e.target.blur();
        return;
      }
      e.target.dataset.disabled = true;
    };

    const onDoubleClick = (e) => {
      e.target.dataset.disabled = false;
      e.target.focus();
    };

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (NotEditableCells.includes(id)) {
      console.log(id)
      return value;
    }
    return (
      <input
        className="editable-cell"
        value={value}
        onChange={onChange}
        data-disabled={true}
        onFocus={onFocus}
        onDoubleClick={onDoubleClick}
        onBlur={onBlur}
        onKeyPress={(e) => {
          if (e.code == "NumpadEnter" || e.code == "Enter") {
            e.target.blur();
          }
        }}
      />
    );
  };

  const defaultColumn = useMemo(() => {
    return {
      Cell: () => EditableCell,
    };
  }, []);

  const highliteCell = (e) => {
    e.target
      .closest("tbody")
      .querySelectorAll("td")
      .forEach((cell) => {
        cell.classList.remove("highlighted-cells");
      });
    e.target.classList.add("highlighted-cells");
  };

  const selectCells = (e) => {
    e.target
      .closest("tbody")
      .querySelectorAll("td")
      .forEach((cell) => {
        cell.classList.remove("selected-cells");
      });
    e.target.classList.add("selected-cells");
  };

  const updateMyData = (rowIndex, columnId, value) => {
    columnId = columnId.split(".").pop();
    setSkipPageReset(true);
    let currentRow = dataArr.filter((row, index) => {
      if (index === rowIndex) {
        // console.log(row);
        return row;
      }
    });

    currentRow = currentRow[0];

    // check if there is a change in the value
    if (currentRow.properties[columnId] === value) {
      return;
    }

    let newObj = {
      properties: {
        [columnId]: value,
      },
    };
    console.log(newObj);

    const myPromise = editContact(
      "https://youngstartup.io/api/hs/contacts/update/" + inheritedRow,
      newObj
    ).then((data) => {
      console.log(data);

      if (data.status && data.status == "error") {
        console.error(data.message);

        throw new Error(data.message);
      }
      // FIX - reset component so you get fresh info from API
      return data;
    });

    toast.promise(
      myPromise,
      {
        loading: "Loading",
        success: (data) => `Successfully updated id: ${data.id}`,
        error: (err) => `This just happened: ${err.toString()}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
          // icon: '🔥',
        },
      }
    );
    // setTimeout(() => window.location.reload(), 1500);
  };

  const openEmailWindow = (contact) => {
    props.setShowEmailForm("block");
    [contact] = contact;
    props.setEmailTo(contact);
  }

  useEffect(() => {
    setSkipPageReset(false);
  }, [dataArr]);

  async function deleteContact(url) {
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return;
  }

  async function editContact(url, data) {
    const response = await fetch(url, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async function getData(url) {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.json();
  }

  async function deleteAssociateCompany(url) {
    const response = await fetch(url, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });

    return response.json();
  }

  useEffect(() => {
    console.log(props.row.original.properties.contacts)
    if (props.row.original.properties.contacts) {
      setDataArr(props.row.original.properties.contacts)
    }
  }, []);

  //console.log(dataArr)
  //console.log(selectedArr)
  console.log(props.row.original.properties.contacts)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    page,
    setGlobalFilter,
    nextPage,
    selectedFlatRows,
    getToggleHideAllColumnsProps,
    allColumns,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    setColumnOrder,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: dataArr,
      defaultColumn,
      initialState: { pageSize: 50 },
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <>
                <div>Select</div>
                <Checkbox {...getToggleAllRowsSelectedProps()} />
              </>
            ),
            Cell: ({ row, data, value }) => (
              <>
                <div className="select-container">
                  {<Checkbox
                    {...row.getToggleRowSelectedProps()}
                    style={{ marginRight: "1rem", marginTop: '9%' }}
                    className="checkbox"
                    onClick={(e) => { e.preventDefault(); setSelectedArr(old => [...old, data[row.index]]); setEmailList('block'); return false }}
                    value={value}
                  />
                  }
                  <Fab color="secondary" className='delete-icon'>
                    <DeleteIcon
                      style={{ transform: 'scale(1.5)' }}
                      key={data[row.index].id}
                      onClick={() => {
                        let item = data[row.index];
                        let id = item.id;
                        // console.log(data[row.index]);
                        const myPromise = deleteContact(
                          "https://youngstartup.io/api/hs/contacts/delete/" +
                          data[row.index].id
                          // ).then(() =>
                          //   getData(
                          //     `https://youngstartup.io/api/hs/contacts/all`
                          //   ).then((data) => {
                          //     setDataArr(data.results);
                          //   })
                        );

                        toast.promise(
                          myPromise,
                          {
                            loading: "Loading",
                            success: (data) =>
                              `Successfully deleted id: ${capitalizeFirstLetter(
                                item.properties.firstname
                              )} ${capitalizeFirstLetter(
                                item.properties.lastname
                              )}`,
                            error: (err) => `error: ${err.toString()}`,
                          },
                          {
                            style: {
                              minWidth: "250px",
                            },
                            success: {
                              duration: 5000,
                              // icon: '🔥',
                            },
                          }
                        );
                      }}
                    />
                  </Fab>
                  <Tooltip title="unassign">
                    <Fab
                      color="secondary"
                      className='delete-icon'
                      key={data[row.index].id}
                      onClick={() => {
                        console.log(data[row.index].id)
                        setCurrentRow(data[row.index].id);
                        setCurrentRowName(data[row.index].properties.firstname)

                        const myPromise = deleteAssociateCompany(
                          `https://youngstartup.io/api/hs/contacts/${data[row.index].id}/assoc/companies/${inheritedRow}/contact_to_company`
                        )
                        toast.promise(
                          myPromise,
                          {
                            loading: "Loading",
                            success: (data) =>
                              `Successfully unassociated ${currentRowName} from ${companyName}!`,
                            error: (err) => `This just happened: ${err.toString()}`,
                          },
                          {
                            style: {
                              minWidth: "250px",
                            },
                            success: {
                              duration: 2500,
                            },
                          }
                        );
                        setTimeout(() => window.location.reload(), 2000)
                        // setTimeout(() => window.location.reload(), 3000)
                      }}
                    >
                      <AssignmentIndOutlinedIcon

                      />
                    </Fab>
                  </Tooltip>
                </div>
              </>
            ),
          },
          ...columns,
          [],
        ];
      });
    }
  );

  return (
    <>
      <table {...getTableProps()}>
        <img style={{ display: loader, position: 'absolute', top: '50%', left: '45%' }} src='pulse.gif' alt="" />
        <thead>
          {headerGroups.map((headerGroup) => {
            if (headerGroup)
              return (
                <tr {...headerGroup.getHeaderGroupProps()}
                  className='subrow-header'>
                  <th className='subrow-header'>Row Number</th>
                  {headerGroup.headers.map((column) => (
                    <th
                      className='subrow-header'
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/*<div>{column.canFilter ? column.render("Filter") : null}</div>*/}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "⬆️"
                            : "⬇️"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} >
                {
                  <td >
                    <span>{parseInt(row.id) + 1}</span>
                  </td>
                }
                {row.cells.map((cell) => {
                  if (cell !== row.cells[row.cells.length - 1]) {
                    return (
                      <td
                        onMouseDown={selectCells}
                        onMouseEnter={highliteCell}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                })}<td>
                  <Fab color="primary" style={{ transform: 'scale(.7)' }} onClick={() => { openEmailWindow([dataArr[row.index]]) }}>
                    <EmailIcon />
                  </Fab>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
