import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Stack from '@mui/material/Stack';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const SpeakerQr = ({ cell }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dashLink, setDashLink] = useState(`https://youngstartup.com/vc-dashboard?ysv_id=${cell.row.id}`);
  const [showCopied, setShowCopied] = useState(false)


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <span
        onClick={handleClick}
        style={{cursor: "pointer", textDecoration: "underline"}} 
      >Dash</span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ width: "500px", height: "375px", display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#303265' }}>
          <Stack spacing={1} >
            <span>
              <input
                style={{ color: 'white', backgroundColor: '#000120', borderRadius: '15px', border: 'none', width: "350px" }}
                readOnly
                value={dashLink}
              />
              <a href={dashLink} target="target_blank">
                <OpenInNewIcon
                // onClick={() => { navigator.clipboard.writeText(dashLink); setShowCopied(true); setTimeout(() => setShowCopied(false), 3000) }}
                />
              </a>
            </span>
            {/* <span style={{ opacity: showCopied ? "1" : "0", color: 'white', textAlign: 'center' }}>{"Copied!"}</span> */}
            <img
              src={`https://api.qrserver.com/v1/create-qr-code/?data=https://youngstartup.com/vc-dashboard?ysv_id=${cell.row.id}size=100x100`}
              alt=""
              title=""
              style={{ width: '250px', height: '250px' }}
            />
          </Stack>
        </div>
      </Popover>
    </div>
  );
};
