import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import XlsExport from "xlsexport";

import ClickAwayListener from "@mui/base/ClickAwayListener";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";

import { MealToggle, SpeakerQr, SpeakerVirtualPicker, MuiAppBar, StatsPopup } from "../components/index";
import { addOrUpdateQueryParam, applyCustomOrder, getQueryParameterByName, parseDateForDBFormat } from "../utils/funcs";
import { createAttendee, deleteData, deleteMultiAttendee, getData, postData } from "../api/apiCalls";
import { Chip, Switch } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" sx={{ color: "#F0AD4E" }} />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" sx={{ color: "#F0AD4E" }} />;
}

export function Speakers() {
  const [ddOptions, setDdOptions] = useState({});
  const [data, setData] = useState([]);
  const [backupData, setBackupData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmMulti, setConfirmMulti] = useState(false);
  const [emailValue, setEmailValue] = useState(null);
  const [emailName, setEmailName] = useState(null);
  const [mealSubs, setMealSubs] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    id: "",
    speaker_display: "",
    vc_invited_stage: "Confirmed",
    headshot_url: "assets/placeholder.jpeg",
    headshot: "",
    attendee_tag: "",
    firstname: "",
    lastname: "",
    jobtitle: "",
    website: "",
    company: "",
    mobilephone: "",
    email: "",
    linkedin_uid: "",
    source: "",
    vc_investment_location_s_: "",
    industry_custom: "",
    // vc_s_industry_focuses: "",
    judging_track_contact: "",
    judging_track_2_contact: "",
    final_judging_track_placement_contact: "",
    panel_choice_1_contact: "",
    panel_choice_2_contact: "",
    panel_choice_3_contact: "",
    speaker_panel_contact: "",
    coaching_track_contact: "",
    coaching_track_2_contact: "",
    final_coaching_track_placement_contact: "",
    notes: "",
    bio: "",
    speaker_role: "",
    speaker_role_round: ""
  });
  const [showInput, setShowInput] = useState(false);
  const [saveText, setSaveText] = useState("Save");
  const [btnColor, setBtnColor] = useState("primary");
  const [filterObj, setFilterObj] = useState({});
  const [currentStat, setCurrentStat] = useState("value");
  const [options, setOptions] = useState({
    attendee_tag: [],
    speaker_display: [],
    coaching_track_contact: [],
    judging_track_contact: [],
    speaker_panel_contact: [],
    vc_investment_location_s_: [],
    vc_invited_stage: [],
    final_panel_status: [],
    final_judging_track_status: [],
    final_coaching_track_status: [],
    coaching_room_number: [],
    final_judging_track_placement_contact: [],
    industry_custom: [],
  });
  const [speakerStats, setSpeakerStats] = useState({
    panels: [],
    judging_tracks: [],
    coaching_tracks: [],
    total_speakers: 0,
    total_judges: 0,
    total_coaches: 0,
    total_panelists: 0,
    displayed_on_website: 0,
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const [popperContent, setPopperContent] = useState({
    title: "",
    content: "",
  });
  const [initialCount, setInitialCount] = useState({
    speakers: "",
    judges: "",
    coaches: "",
    pending: "",
    panelists: "",
  });
  // const [formats, setFormats] = useState(() => ["italic"]);
  const [open2, setOpen2] = useState(false);
  const [openStats, setOpenStats] = useState(false);
  const handleOpenStats = () => {
    setOpenStats(true);
  }
  const handleCloseStats = () => {
    setOpenStats(false);
  }

  useEffect(() => {
    getData("https://youngstartup.io/api/db-info/property-options-dd/all_attendees")
      .then(res => setDdOptions(res))
  }, [])

  //   useEffect(() => {
  //     getData("https://youngstartup.io/api/db-a/attendees/get_vc_stats")
  //         .then(data => {
  //             console.log(data);
  //         })
  // }, []);

  const MuiToggleStats = ({ stat, prop, cell }) => {
    const [selected, setSelected] = useState(stat);

    return (
      <ToggleButton
        // value="check"
        selected={stat}
        sx={{
          backgroundColor: selected ? "green !important" : "red !important",
          border: "1px solid white",
        }}
        onChange={() => {
          if (prop == "cell.row.email_confirmed") {
            setSelected(!selected);
            let body = {
              body: {
                id: cell.row.id,
                email_confirmed:
                  selected == "" ? parseDateForDBFormat(new Date()) : "",
              },
            };
            postData(
              "https://youngstartup.io/api/db/attendees/add-or-update",
              body
            ).then((data) => console.log(data));
          }
        }}
      ></ToggleButton>
    );
  };

  // const handleFormat = (event, newFormats) => {
  //   setFormats(newFormats);
  // };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setModalInfo({ ...modalInfo, attendee_tag: value.join(";") });
    // console.log(modalInfo);
  };

  const statOptions = ["value", "confirmed", "pending"];

  const attendeeTagOptions = [
    "Coach",
    "Judge",
    "Panelist",
    "Moderator",
    "Investor",
    "Sponsor",
  ];

  const investmentLocationOptions = [
    "United States",
    "Canada",
    "UK",
    "Israel",
    "Specific Region Only",
    "Europe",
  ];

  const industryFocusOptions = [
    "Tech",
    "CleanTech",
    "EdTech",
    "FinTech",
    "MedTech",
    "Lifesciences/Healthcare",
    "Healthcare IT",
    "Mobile/Communication",
    "Consumer Products",
    "Software",
    "FoodTech",
    "Seed Stage",
  ];

  const styles = {
    color: "white",
    minWidth: "fit-content",

    maxWidth: "100%",
    input: { color: "white", fontSize: ".7rem" },
    textarea: { color: "white" },
    "&": {
      color: "white !important",
      backgroundColor: "#303265",
      borderRadius: "5px",
      minWidth: "fit-content",
    },
    "& label": {
      color: "transparent",
    },
    "& label.Mui-focused": {
      color: "transparent",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
      color: "white",
    },
    "& .MuiInputBase-root": {
      "& fieldset": {
        borderColor: "transparent",
        color: "white",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
        color: "white",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
        color: "white",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
        color: "white",
      },
      "& .MuiSvgIcon-root-MuiSelect-icon": {
        fill: "white",
      },
      "& .MuiPaper-root": {
        backgroundColor: "lightblue !important",
      },
      "& .PrivateSwitchBase-input, .MuiSvgIcon-root, .MuiSvgIcon-fontSizeMedium, .css-i4bv87-MuiSvgIcon-root": {
        // "& .MuiSvgIcon-root":{
        color: "white !important",
        fill: "white !important",
      }
    },
  };

  const dropdownStyles = {
    "&": {
      color: "white",
      fontSize: "11px",
      backgroundColor: "#303265",
      p: 2,
      height: "25px",
      display: "flex",
      width: 'fit-content'
    },
  };

  const uploadHeadshot = async (e) => {
    if (e.target.files[0].size > 4194304) {
      alert("File is too big!");
      e.target.value = "";
      return;
    }
    const file = e.target.files[0];
    if (file) {
      const base64 = await convertBase64(file);
      setModalInfo({ ...modalInfo, headshot: base64 });
    }

    // console.log(modalInfo);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // const stageOptions = [
  //   { value: "Confirmed Participation ( JDG )", label: "Confirmed Participation ( JDG )", },
  //   { value: "Cancelled", label: "Cancelled" },
  //   { value: "Cancelled, sent replacement", label: "Cancelled, sent replacement", },
  //   { value: "Never invite", label: "Never invite" },
  //   { value: "Never invite company", label: "Never invite company" },
  // ];

  const judgingOptions = [
    { value: "Tech", label: "Tech" },
    { value: "Life Sciences/Healthcare", label: "Life Sciences/Healthcare" },
    { value: "Clean Tech", label: "Clean Tech" },
    { value: "Fin Tech", label: "Fin Tech" },
    { value: "Ed Tech", label: "Ed Tech" },
    { value: "Seed", label: "Seed" },
    { value: "Medtech", label: "Medtech" },
    { value: "Consumer Products", label: "Consumer Products" },
    { value: "--None--", label: "--None--" },
  ];

  const speakerOptions = [
    { value: "Venture Roadmap", label: "Venture Roadmap" },
    {
      value: "Coaching Panel",
      label: "Coaching Panel - Getting your Company Funded",
    },
    { value: "Fund of Funds", label: "Fund of Funds" },
    { value: "Family Offices", label: "Family Offices" },
    { value: "Due Diligence", label: "Due Diligence" },
    { value: "Opening Panel", label: "Opening Panel - Changing Venture World" },
    { value: "Angel", label: "Raising Money from Angel Investors" },
    { value: "Startup to IPO", label: "Startup to IPO or SPAC" },
    { value: "Fin Tech", label: "Banking on Fintech Innovation" },
    { value: "Medtech Panel", label: "Medtech Panel" },
    { value: "Term Sheets", label: "Negotiating a Winning Term Sheet" },
    { value: "Fundable Deals", label: "Fundable Deals - Getting VCs Hooked" },
    { value: "Where's the Exit", label: "Where's the Exit" },
    {
      value: "Corporate VC",
      label: "Corporate Venture Funding: All You Need To Know",
    },
    { value: "Accelerators", label: "Accelerators" },
    {
      value: "Founders turned VCs",
      label: "Both sides of the Table - Founders turned VCs",
    },
    { value: "Clean Tech", label: "Green Dollars for Cleantech Innovators" },
    {
      value: "LS/HC Checkup",
      label:
        "The Future of Innovation for Life Sciences and Healthcare Startups",
    },
    {
      value: "Female Funders",
      label: "Female Advantage: Why Female Founders make Great Leaders",
    },
    {
      value: "European Investors",
      label: "Scaling your Startup Internationally",
    },
    { value: "Ed Tech", label: "NA - Ed Tech" },
    { value: "Food Tech", label: "NA - Food Tech" },
    {
      value: "Alternative Capital",
      label: "Alternative Capital",
    } /*{"value":"workshop1","label":"workshop1"},{"value":"workshop2","label":"workshop2"},{"value":"workshop3","label":"workshop3"},{"value":"workshop4","label":"workshop4"},{"value":"workshop5","label":"workshop5"},{"value":"workshop6","label":"workshop6"},{"value":"workshop7","label":"workshop7"},{"value":"workshop8","label":"workshop8"},{"value":"workshop9","label":"workshop9"},{"value":"workshop10","label":"workshop10"},{"value":"workshop11","label":"workshop11"},{"value":"workshop12","label":"workshop12"},{"value":"workshop13","label":"workshop13"},{"value":"workshop14","label":"workshop14"},{"value":"workshop15","label":"workshop15"}*/,
    ,
    { value: "", label: "" },
  ];

  const coachingOptions = [
    {
      label: "Tech",
      value: "Tech",
    },
    {
      label: "Life Sciences/Healthcare",
      value: "Life Sciences/Healthcare",
    },
    {
      label: "Clean Tech",
      value: "Clean Tech",
    },
    {
      label: "Fin Tech",
      value: "Fin Tech",
    },
    {
      label: "Medtech",
      value: "Medtech",
    },
    {
      label: "Consumer Products",
      value: "Consumer Products",
    },
  ];

  const MuiToggleButton = ({ cell }) => {
    const [alignment, setAlignment] = useState(
      cell.formattedValue ? cell.formattedValue : cell.speaker_display
    );
    return (
      <Paper sx={{
        width: "fit-content",
        "& .MuiSvgIcon-root, .MuiSvgIcon-colorSuccess, .MuiSvgIcon-fontSizeMedium, .css-4b67si-MuiSvgIcon-root": {
          color: "inherit"
        }
      }}>
        <StyledToggleButtonGroup
          value={alignment}
          exclusive
          onChange={(event, newAlignment) => {
            if (newAlignment !== null) {
              setAlignment(newAlignment);
              setModalInfo({ ...modalInfo, speaker_display: newAlignment });
            }
            if (open == false) {
              // console.log(alignment);
              let body = {
                body: {
                  id: cell.id,
                  speaker_display: newAlignment,
                },
              };
              // console.log(body);
              postData("https://youngstartup.io/api/db/attendees/add-or-update", body)
                .then((data) => console.log(data))
                .then(() =>
                  getData(
                    `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                  ).then((data) => {
                    setBackupData(data);
                    setData(filterData(filterObj, data));
                  })
                );
            }
          }}
          aria-label="text alignment"
        >
          {/* not displayed */}
          <ToggleButton
            sx={{
              p: 0,
              filter: alignment == "yes" ? "grayscale(0)" : "grayscale(1)",
              "&:hover": {
                filter: "grayscale(0)",
                transition: "1s",
              },
            }}
            value={"yes"}
          >
            <SquareRoundedIcon
              // color="success" 
              sx={{
                color: "green !important"
              }} />
          </ToggleButton>

          {/* pending */}
          <ToggleButton
            sx={{
              p: 0,
              filter: alignment == "pending" ? "grayscale(0)" : "grayscale(1)",
              "&:hover": {
                filter: "grayscale(0)",
                transition: "1s",
              },
            }}
            value={"pending"}
          >
            <SquareRoundedIcon
              // color="warning" 
              sx={{
                color: "orange !important"
              }} />
          </ToggleButton>
          <ToggleButton
            sx={{
              p: 0,
              filter: alignment == "no" ? "grayscale(0)" : "grayscale(1)",
              width: "100%",
              "&:hover": {
                filter: "grayscale(0)",
                transition: "1s",
              },
            }}
            value={"no"}
          >
            <SquareRoundedIcon
              // color="error" 
              sx={{
                color: "red !important"
              }} />
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    );
  };

  const SpeakerDropdown = ({ cell }) => {
    const [value, setValue] = useState(cell.formattedValue);
    const [display, setDisplay] = useState(null);

    useEffect(() => {
      for (let i = 0; i < options.speaker_panel_contact.length; i++) {
        if (value == options.speaker_panel_contact[i].option_value) {
          setDisplay(options.speaker_panel_contact[i].option_display_short);
        }
      }
    }, []);

    options.speaker_panel_contact.sort((a, b) =>
      a.option_value > b.option_value
        ? 1
        : b.option_value > a.option_value
          ? -1
          : 0
    );
    // console.log(options.speaker_panel_contact);
    if (value !== null) {
      // for (let i = 0; i < options.speaker_panel_contact.length; i++) {
      //   if (value == options.speaker_panel_contact[i]) {
      //     delete options.speaker_panel_contact[i];
      //   }
      // }
      return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
          <Select
            style={{ width: "150px" }}
            value={value}
            sx={dropdownStyles}
            onChange={(e) => {
              let dataCopy = data;
              let rowCopy;

              for (let i = 0; i < dataCopy.length; i++) {
                if (dataCopy[i].id == cell.row.id) {
                  rowCopy = dataCopy[i];
                  rowCopy.speaker_panel_contact = e.target.value;
                  setValue(rowCopy.speaker_panel_contact);
                  // console.log(value);
                }
              }

              let editBody = {
                body: {
                  id: cell.row.id,
                  speaker_panel_contact: e.target.value,
                },
              };

              postData(
                "https://youngstartup.io/api/db/attendees/add-or-update",
                editBody
              )
                .then((data) => console.log(data))
                .then(() => {
                  getData(
                    `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
                  ).then((data) => {
                    console.log("speakerStats", data);
                    setSpeakerStats(data);
                    console.log(speakerStats);
                  });
                });
            }}
          >
            {options.speaker_panel_contact.map((option, i) => (
              <MenuItem key={i} value={option.option_value}>
                {option.option_display_short}
              </MenuItem>
            ))}
          </Select>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex" }}>
          <Select
            sx={dropdownStyles}
            value={value ? value : ""}
            onChange={(e) => {
              let dataCopy = data;
              let rowCopy;

              for (let i = 0; i < dataCopy.length; i++) {
                if (dataCopy[i].id == cell.row.id) {
                  rowCopy = dataCopy[i];
                  rowCopy.speaker_panel_contact = e.target.value;
                  setValue(rowCopy.speaker_panel_contact);
                  // console.log(value);
                }
              }

              let editBody = {
                body: {
                  id: cell.row.id,
                  speaker_panel_contact: e.target.value,
                },
              };

              postData(
                "https://youngstartup.io/api/db/attendees/add-or-update",
                editBody
              )
                .then((data) => console.log(data))
                .then(() => {
                  getData(
                    `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
                  ).then((data) => {
                    // console.log(data);
                    setSpeakerStats(data);
                    // console.log(speakerStats);
                  });
                });
            }}
          >
            <MenuItem value={value}>{display}</MenuItem>
            {options.speaker_panel_contact.map((option, i) => {
              return (
                <MenuItem
                  key={i}
                  value={option.option_value ? option.option_value : ""}
                  sx={{ whiteSpace: "normal", overflowWrap: "break-word" }}
                >
                  {option.option_display_short}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      );
    }
  };

  const JudgingDropdown = ({ cell }) => {
    const [value, setValue] = useState(cell.row.final_judging_track_placement_contact);
    // const [value, setValue] = useState(cell.formattedValue);
    const [display, setDisplay] = useState(null);
    useEffect(() => {
      for (let i = 0; i < options.judging_track_contact.length; i++) {
        if (value == options.judging_track_contact[i].option_value) {
          setDisplay(options.judging_track_contact[i].option_display_short);
        }
      }
    }, []);

    options.judging_track_contact.sort((a, b) =>
      a.option_value > b.option_value
        ? 1
        : b.option_value > a.option_value
          ? -1
          : 0
    );
    // console.log(options.judging_track_contact);
    if (value !== null) {
      return (
        <Select
          value={value}
          sx={dropdownStyles}
          style={{ fontSize: ".7rem", width: "135px" }}
          onChange={(e) => {
            let dataCopy = data;
            let rowCopy;

            for (let i = 0; i < dataCopy.length; i++) {
              if (dataCopy[i].id == cell.row.id) {
                rowCopy = dataCopy[i];
                rowCopy.final_judging_track_placement_contact = e.target.value;
                setValue(rowCopy.final_judging_track_placement_contact);
                // console.log(value);
              }
            }

            let editBody = {
              body: {
                id: cell.row.id,
                final_judging_track_placement_contact: e.target.value,
              },
            };

            postData(
              "https://youngstartup.io/api/db/attendees/add-or-update",
              editBody
            )
              .then((data) => console.log(data))
              .then(() => {
                getData(
                  `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
                ).then((data) => {
                  // console.log(data);
                  setSpeakerStats(data);
                  // console.log(speakerStats);
                });
              });
          }}
        >
          <MenuItem value={value} >{display}</MenuItem>
          {ddOptions.final_judging_track_placement_contact?.map((option, i) => {
            // {options.judging_track_contact.map((option, i) => {
            // if (option.option_value !== value)
            return (
              <MenuItem key={i} value={option.option_value}  >
                {option.option_display_short}
              </MenuItem>
            );
          })}
        </Select>
      );
    } else {
      return (
        <Select
          value={value}
          sx={dropdownStyles}
          style={{ fontSize: ".7rem", paddingTop: "0", paddingBottom: "0", width: "125px" }}
          onChange={(e) => {
            let dataCopy = data;
            let rowCopy;

            for (let i = 0; i < dataCopy.length; i++) {
              if (dataCopy[i].id == cell.row.id) {
                rowCopy = dataCopy[i];
                rowCopy.final_judging_track_placement_contact = e.target.value;
                setValue(rowCopy.final_judging_track_placement_contact);
                // console.log(value);
              }
            }

            let editBody = {
              body: {
                id: cell.row.id,
                final_judging_track_placement_contact: e.target.value,
              },
            };

            postData(
              "https://youngstartup.io/api/db/attendees/add-or-update",
              editBody
            )
              .then((data) => console.log(data))
              .then(() => {
                getData(
                  `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
                ).then((data) => {
                  // console.log(data);
                  setSpeakerStats(data);
                  // console.log(speakerStats);
                });
              });
          }}
        >
          <MenuItem value={""} >None</MenuItem>
          {ddOptions.final_judging_track_placement_contact?.map((option, i) => {
            // {options.judging_track_contact.map((option, i) => {
            if (option.option_value !== value)
              return (
                <MenuItem key={i} value={option.option_value} >
                  {option.option_display_short}
                </MenuItem>
              );
          })}
        </Select>
      );
    }
  };

  const PanelChoice = ({ cell }) => {
    useEffect(() => {
      for (let i = 0; i < options.speaker_panel_contact.length; i++) {
        if (
          options.speaker_panel_contact[i].option_value ==
          cell.row.panel_choice_1_contact
        ) {
          cell.row.panel_choice_1_contact =
            options.speaker_panel_contact[i].option_display_short;
        }
        if (
          options.speaker_panel_contact[i].option_value ==
          cell.row.panel_choice_2_contact
        ) {
          cell.row.panel_choice_2_contact =
            options.speaker_panel_contact[i].option_display_short;
        }
        if (
          options.speaker_panel_contact[i].option_value ==
          cell.row.panel_choice_3_contact
        ) {
          cell.row.panel_choice_3_contact =
            options.speaker_panel_contact[i].option_display_short;
        }
      }
    }, []);

    return (
      cell.row.attendee_tag?.includes("Panelist") && (
        <div style={{ fontSize: ".7rem" }}>
          {cell.row.panel_choice_1_contact && (
            <div>1) {cell.row.panel_choice_1_contact}</div>
          )}
          {cell.row.panel_choice_2_contact && (
            <div>2) {cell.row.panel_choice_2_contact}</div>
          )}
          {cell.row.panel_choice_3_contact && (
            <div>3) {cell.row.panel_choice_3_contact}</div>
          )}
        </div>
      )
    );
  };

  const PanelConfirm = ({ cell }) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [color, setColor] = useState("blue");

    useEffect(() => {
      for (let i = 0; i < options.final_panel_status.length; i++) {
        if (
          cell.row.final_panel_status ==
          options.final_panel_status[i].option_value
        ) {
          setColor(options.final_panel_status[i].option_display_short);
        }
      }
    }, []);

    return (
      <div
        style={{
          backgroundColor: color,
          borderRadius: "5px",
        }}
      >
        {" "}
        <ArrowDropDownIcon
          id={"basic-button-" + cell.id}
          style={{ fill: "#F0AD4E" }}
          aria-controls={
            Boolean(menuAnchorEl) ? "basic-menu-" + cell.id : undefined
          }
          aria-haspopup="true"
          icon={<AddIcon />}
          aria-expanded={Boolean(menuAnchorEl) ? "true" : undefined}
          onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        ></ArrowDropDownIcon>
        <Menu
          id={"basic-menu-" + cell.id}
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={(e) => setMenuAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button-" + cell.id,
          }}
        >
          {options.final_panel_status.map((option, i) => {
            return (
              <MenuItem
                key={i}
                sx={{
                  backgroundColor: option.option_display_short,
                  color: "white",
                  "&:hover": {
                    backgroundColor: option.option_display_short,
                    color: "white",
                  },
                }}
                data-value={option.option_value}
                onClick={(e) => {
                  // console.log(e.currentTarget);
                  const { value } = e.currentTarget.dataset;
                  // console.log(value);
                  setColor(`${option.option_display_short}`);
                  // console.log(color);

                  let body = {
                    body: {
                      id: cell.row.id,
                      final_panel_status: value,
                    },
                  };

                  // console.log(body);
                  setMenuAnchorEl(null);
                  postData(
                    "https://youngstartup.io/api/db/attendees/add-or-update",
                    body
                  )
                    .then((data) => console.log(data))
                    .then(() => {
                      getData(
                        `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                      ).then((data) => {
                        setBackupData(data);
                        setData(filterData(filterObj, data));
                      });
                    })
                    .then(() => {
                      getData(
                        `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
                      ).then((data) => {
                        // console.log(data);
                        setSpeakerStats(data);
                        // console.log(speakerStats);
                      });
                    });
                }}
              >
                {option.option_display}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  };

  const JudgeConfirm = ({ cell }) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [color, setColor] = useState("blue");

    useEffect(() => {
      for (let i = 0; i < options.final_judging_track_status.length; i++) {
        if (
          cell.row.final_judging_track_status ==
          options.final_judging_track_status[i].option_value
        ) {
          setColor(options.final_judging_track_status[i].option_display_short);
        }
      }
    }, []);

    return (
      <div
        style={{
          backgroundColor: color,
          borderRadius: "5px",
          maxHeight: "2em"
        }}
      >
        {" "}
        <ArrowDropDownIcon
          id={"basic-button-" + cell.id}
          style={{ fill: "#F0AD4E" }}
          aria-controls={
            Boolean(menuAnchorEl) ? "basic-menu-" + cell.id : undefined
          }
          aria-haspopup="true"
          icon={<AddIcon />}
          aria-expanded={Boolean(menuAnchorEl) ? "true" : undefined}
          onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        ></ArrowDropDownIcon>
        <Menu
          id={"basic-menu-" + cell.id}
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={(e) => setMenuAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button-" + cell.id,
          }}
        >
          {options.final_judging_track_status.map((option, i) => {
            return (
              <MenuItem
                key={i}
                sx={{
                  backgroundColor: option.option_display_short,
                  color: "white",
                  "&:hover": {
                    backgroundColor: option.option_display_short,
                    color: "white",
                  },
                }}
                data-value={option.option_value}
                data-display={option.option_display}
                data-display-short={option.option_display_short}
                onClick={(e) => {
                  const { value } = e.currentTarget.dataset;
                  // console.log(value);
                  setColor(`${option.option_display_short}`);
                  // console.log(color);

                  let body = {
                    body: {
                      id: cell.row.id,
                      final_judging_track_status: value,
                    },
                  };
                  // console.log(body);
                  setMenuAnchorEl(null);
                  postData(
                    "https://youngstartup.io/api/db/attendees/add-or-update",
                    body
                  )
                    .then((data) => console.log(data))
                    .then(() => {
                      getData(
                        `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                      ).then((data) => {
                        setBackupData(data);
                        setData(filterData(filterObj, data));
                      });
                    })
                    .then(() => {
                      getData(
                        `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
                      ).then((data) => {
                        // console.log(data);
                        setSpeakerStats(data);
                        // console.log(speakerStats);
                      });
                    });
                }}
              >
                {option.option_display}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  };

  const SpeakerRound = ({ cell }) => {
    return (
      <select name="speaker_role_round" defaultValue={cell.row.speaker_role_round}
        style={{ backgroundColor: cell.row.speaker_role_round ? "green" : "blue", color: "white" }}
        onChange={(e) => {
          let editBody = {
            body: {
              id: cell.row.id,
              speaker_role_round: e.target.value,
            },
          };
          postData("https://youngstartup.io/api/db/attendees/add-or-update", editBody)
            .then(() => {
              getData(`https://youngstartup.io/api/db-a/attendees/getspeakerstats`)
                .then((data) => {
                  setSpeakerStats(data);
                });
            });
        }}
      >
        <option value="">--</option>
        {
          ddOptions.speaker_role_round?.map((item) => {
            return <option key={item.id} value={item.option_value}>{item.option_display}</option>
          })
        }
      </select >
    )
  }
  const GeneralDropdownWithUpdates = ({ id, prop, val, dict, url = "https://youngstartup.io/api/db/attendees/add-or-update", addEmpty = false }) => {
    return (
      <select defaultValue={val}
        style={{ backgroundColor: "#303265", color: "white", border: "none" }}
        onChange={(e) => {
          let editBody = {
            body: {
              id,
              [prop]: e.target.value,
            },
          };
          postData(url, editBody)
            .then(() => {
              toast.success("updated successfully")
            })
            .catch(err => {
              toast.error(err)
            });
        }}
      >
        {addEmpty && <option value="">--</option>}
        {
          dict?.map((item) => {
            return <option key={item.id} value={item.option_value}>{item.option_display_short}</option>
          })
        }
      </select >
    )
  }

  const DisplaySwitch = ({ cell, prop, val, url = "https://youngstartup.io/api/db/attendees/add-or-update", is_array = false }) => (
    <Switch
      sx={{
        "& .MuiSwitch-switchBase": {
          color: `${cell.row[prop]?.includes(val) ? "green !important" : "#303265 !important"
            }`,
        },
        "& .MuiSwitch-track": {
          backgroundColor: "white",
        },
      }}
      checked={cell.row[prop]?.includes(val) ? true : false}
      onChange={(e) => {

        if (is_array) {
          let arr = cell.row[prop];
          arr = typeof arr === "string" ? JSON.parse(arr) : arr;
          if(cell.row[prop]?.includes(val)) {
            arr = arr.filter(i => i != val)
          } else {
            arr.push(val)
          }
          val = JSON.stringify(arr)
          cell.row[prop] = val
        } else {
          val = e.target.checked ? "yes" : "no";
          cell.row[prop] = val
        }
        let editBody = {
          body: {
            id: cell.row.id,
            [prop]: val,
          },
        };
        console.log(editBody);
        postData(url, editBody)
          .then(() => {
            toast.success("updated successfully")
          })
          .catch(err => {
            toast.error(err)
          });
      }}
    />
  );

  // const MuiSelect = ({ cell, options }) => {
  //   const [value, setValue] = useState(cell.row.coaching_room_number);
  //   console.log(options)
  //   return (
  //     <Select
  //       value={value}
  //       sx={dropdownStyles}
  //       label="Age"
  //       onChange={(e) => {
  //         setValue(e.target.value)
  //         let body = {
  //           body: {
  //             id: cell.row.id,
  //             coaching_room_number: e.target.value
  //           }
  //         }
  //         console.log(body)
  //         postData(`https://youngstartup.io/api/db/attendees/add-or-update`, body)
  //           .then(data => console.log(data))
  //       }}
  //     // renderValue={(value) => <div style={{ color: "white" }}>{value}</div>}
  //     >
  //       {options.map((option, i) => (
  //         <MenuItem key={i} value={option.option_value ? option.option_value : option}>{option.option_display_short ? option.option_display_short : option}</MenuItem>
  //       ))}
  //     </Select>
  //   );
  // };


  // const CoachConfirm = ({ cell }) => {
  //   const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  //   const [color, setColor] = useState("blue");

  //   useEffect(() => {

  //     for (let i = 0; i < options.final_coaching_track_status.length; i++) {
  //       if (
  //         cell.row.final_coaching_track_status ==
  //         options.final_coaching_track_status[i].option_value
  //       ) {
  //         setColor(options.final_coaching_track_status[i].option_display_short);
  //       }
  //     }
  //   }, []);

  //   return (
  //     <div
  //       style={{
  //         backgroundColor: color,
  //         borderRadius: "5px",
  //       }}
  //     >
  //       {" "}
  //       <ArrowDropDownIcon
  //         id={"basic-button-" + cell.id}
  //         style={{ fill: "#F0AD4E" }}
  //         aria-controls={
  //           Boolean(menuAnchorEl) ? "basic-menu-" + cell.id : undefined
  //         }
  //         aria-haspopup="true"
  //         icon={<AddIcon />}
  //         aria-expanded={Boolean(menuAnchorEl) ? "true" : undefined}
  //         onClick={(e) => setMenuAnchorEl(e.currentTarget)}
  //       ></ArrowDropDownIcon>
  //       <Menu
  //         id={"basic-menu-" + cell.id}
  //         anchorEl={menuAnchorEl}
  //         open={Boolean(menuAnchorEl)}
  //         onClose={(e) => setMenuAnchorEl(null)}
  //         MenuListProps={{
  //           "aria-labelledby": "basic-button-" + cell.id,
  //         }}
  //       >
  //         {options.final_coaching_track_status.map((option, i) => {
  //           return (
  //             <MenuItem
  //               key={i}
  //               sx={{
  //                 backgroundColor: option.option_display_short,
  //                 color: "white",
  //                 "&:hover": {
  //                   backgroundColor: option.option_display_short,
  //                   color: "white",
  //                 },
  //               }}
  //               data-value={option.option_value}
  //               onClick={(e) => {
  //                 const { value } = e.currentTarget.dataset;
  //                 // console.log(value);
  //                 setColor(option.option_display_short);

  //                 let body = {
  //                   body: {
  //                     id: cell.row.id,
  //                     final_coaching_track_status: value,
  //                   },
  //                 };

  //                 // console.log(body);
  //                 setMenuAnchorEl(null);
  //                 postData(
  //                   "https://youngstartup.io/api/db/attendees/add-or-update",
  //                   body
  //                 )
  //                   .then((data) => console.log(data))
  //                   .then(() => {
  //                     getData(
  //                       `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
  //                     ).then((data) => {
  //                       setBackupData(data);
  //                       setData(filterData(filterObj, data));
  //                     });
  //                   })
  //                   .then(() => {
  //                     getData(
  //                       `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
  //                     ).then((data) => {
  //                       // console.log(data);
  //                       setSpeakerStats(data);
  //                       // console.log(speakerStats);
  //                     });
  //                   });
  //               }}
  //             >
  //               {option.option_display}
  //             </MenuItem>
  //           );
  //         })}
  //       </Menu>
  //     </div>
  //   );
  // };

  // const JudgeChoice = ({ cell }) => {
  //   useEffect(() => {
  //     for (let i = 0; i < options.judging_track_contact.length; i++) {
  //       if (
  //         options.judging_track_contact[i].option_value ==
  //         cell.row.judging_track_contact
  //       ) {
  //         cell.row.judging_track_contact =
  //           options.judging_track_contact[i].option_display_short;
  //         // console.log(cell.row.judging_track_contact);
  //       }
  //       if (
  //         options.judging_track_contact[i].option_value ==
  //         cell.row.judging_track_2_contact
  //       ) {
  //         cell.row.judging_track_2_contact =
  //           options.judging_track_contact[i].option_display_short;
  //         // console.log(cell.row.judging_track_2_contact);
  //       }
  //     }
  //   }, []);

  //   return (
  //     cell.row.attendee_tag?.includes("Judge") && (
  //       <div style={{ fontSize: ".7rem" }}>
  //         {cell.row.judging_track_contact && (
  //           <div>1) {cell.row.judging_track_contact}</div>
  //         )}
  //         {cell.row.judging_track_2_contact && (
  //           <div>2) {cell.row.judging_track_2_contact}</div>
  //         )}
  //       </div>
  //     )
  //   );
  // };

  // const CoachChoice = ({ cell }) => {
  //   useEffect(() => {
  //     for (let i = 0; i < options.coaching_track_contact.length; i++) {
  //       if (
  //         options.coaching_track_contact[i].option_value ==
  //         cell.row.coaching_track_contact
  //       ) {
  //         cell.row.coaching_track_contact =
  //           options.coaching_track_contact[i].option_display_short;
  //         // console.log(cell.row.coaching_track_contact);
  //       }
  //       if (
  //         options.coaching_track_contact[i].option_value ==
  //         cell.row.coaching_track_2_contact
  //       ) {
  //         cell.row.coaching_track_2_contact =
  //           options.coaching_track_contact[i].option_display_short;
  //         // console.log(cell.row.coaching_track_2_contact);
  //       }
  //     }
  //   }, []);

  //   return (
  //     cell.row.attendee_tag?.includes("Coach") && (
  //       <div style={{ fontSize: ".7rem" }}>
  //         {cell.row.coaching_track_contact && (
  //           <div>1) {cell.row.coaching_track_contact}</div>
  //         )}
  //         {cell.row.coaching_track_2_contact && (
  //           <div>2) {cell.row.coaching_track_2_contact}</div>
  //         )}
  //       </div>
  //     )
  //   );
  // };

  // const CoachingDropdown = ({ cell }) => {
  //   const [value, setValue] = useState(cell.formattedValue);
  //   const [display, setDisplay] = useState(null);

  //   options.coaching_track_contact.sort((a, b) =>
  //     a.option_value > b.option_value
  //       ? 1
  //       : b.option_value > a.option_value
  //         ? -1
  //         : 0
  //   );

  //   useEffect(() => {
  //     for (let i = 0; i < options.coaching_track_contact.length; i++) {
  //       if (value == options.coaching_track_contact[i].option_value) {
  //         setDisplay(options.coaching_track_contact[i].option_display_short);
  //       }
  //     }
  //   }, []);

  //   if (value !== null) {
  //     // for (let i = 0; i < coachingOptions.length; i++) {
  //     //   if (value == coachingOptions[i]) {
  //     //     delete coachingOptions[i];
  //     //   }
  //     // }

  //     return (
  //       <Select
  //         value={value}
  //         sx={dropdownStyles}
  //         style={{ width: 'fit-content' }}
  //         onChange={(e) => {
  //           let dataCopy = data;
  //           let rowCopy;

  //           for (let i = 0; i < dataCopy.length; i++) {
  //             if (dataCopy[i].id == cell.row.id) {
  //               rowCopy = dataCopy[i];
  //               rowCopy.final_coaching_track_placement_contact = e.target.value;
  //               setValue(rowCopy.final_coaching_track_placement_contact);
  //               // console.log(value);
  //             }
  //           }

  //           let editBody = {
  //             body: {
  //               id: cell.row.id,
  //               final_coaching_track_placement_contact: e.target.value,
  //             },
  //           };

  //           postData(
  //             "https://youngstartup.io/api/db/attendees/add-or-update",
  //             editBody
  //           )
  //             .then((data) => console.log(data))
  //             .then(() => {
  //               getData(
  //                 `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
  //               ).then((data) => {
  //                 // console.log(data);
  //                 setSpeakerStats(data);
  //                 // console.log(speakerStats);
  //               });
  //             });
  //         }}
  //       >
  //         <MenuItem value={value}>{display}</MenuItem>
  //         {options.coaching_track_contact.map((option, i) => {
  //           if (option.option_value !== value)
  //             return (
  //               <MenuItem key={i} value={option.option_value}>
  //                 {option.option_display_short}
  //               </MenuItem>
  //             );
  //         })}
  //       </Select>
  //     );
  //   } else {
  //     return (
  //       <Select
  //         value={value}
  //         sx={dropdownStyles}
  //         style={{ fontSize: ".7rem" }}
  //         onChange={(e) => {
  //           let dataCopy = data;
  //           let rowCopy;

  //           for (let i = 0; i < dataCopy.length; i++) {
  //             if (dataCopy[i].id == cell.row.id) {
  //               rowCopy = dataCopy[i];
  //               rowCopy.final_coaching_track_placement_contact = e.target.value;
  //               setValue(rowCopy.final_coaching_track_placement_contact);
  //               // console.log(value);
  //             }
  //           }

  //           let editBody = {
  //             body: {
  //               id: cell.row.id,
  //               final_coaching_track_placement_contact: e.target.value,
  //             },
  //           };

  //           postData(
  //             "https://youngstartup.io/api/db/attendees/add-or-update",
  //             editBody
  //           )
  //             .then((data) => console.log(data))
  //             .then(() => {
  //               getData(
  //                 `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
  //               ).then((data) => {
  //                 // console.log(data);
  //                 setSpeakerStats(data);
  //                 // console.log(speakerStats);
  //               });
  //             });
  //         }}
  //       >
  //         {options.coaching_track_contact.map((option, i) => {
  //           if (option.option_value !== value)
  //             return (
  //               <MenuItem key={i} value={option.option_value}>
  //                 {option.option_display_short}
  //               </MenuItem>
  //             );
  //         })}
  //       </Select>
  //     );
  //   }
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const filterProperties = [
    "email",
    "linkedin_uid",
    "firstname",
    "lastname",
    "company",
    "speaker_panel_contact",
    "final_judging_track_placement_contact",
    "final_coaching_track_placement_contact",
    "judging_track_contact",
    "judging_track_2_contact",
    "panel_choice_1_contact",
    "panel_choice_2_contact",
    "panel_choice_3_contact",
    "coaching_track_contact",
    "coaching_track_2_contact",
    "coach_status_contact",
    "vc_company_region__provided_by_the_investor_themselves_",
    "vc_investment_location_s_",
    "industry_custom",
    // "vc_s_industry_focuses",
    "speaker_role",
    "speaker_role_round",
    "notes",
    "coaching_room_number",
    "brella_code",
    "meetmatch_role",
    "email_confirmed",
    "opened_dashboard",
    "submission_status",
    "emails_sent",
    "status",
    "hs_id",
  ];

  useEffect(() => {
    getData("https://youngstartup.io/api/db-a/attendees/getmealsub").then(
      (data) => setMealSubs(data)
    );
  }, []);

  const filterRows = (
    val,
    props = filterProperties,
    localData = backupData,
    operator = "contains",
    multiOptionOperator = "OR"
  ) => {
    val = val.trim();
    let valArr = [val];
    if (val.includes(";")) {
      valArr = val.split(";");
    } else if (val.includes(" ")) {
      valArr = val.split(" ");
    }
    let res = [];

    valArr.map((searchItem) => {
      searchItem = searchItem.toLowerCase(); //.replace(" ", "")
      let temp = localData.filter((item) => {
        let flag = false;
        for (let i = 0; i < props.length; i++) {
          if (
            searchItem &&
            item[props[i]] &&
            typeof item[props[i]] === "string" &&
            (item[props[i]].toLowerCase() == searchItem ||
              (operator == "contains" &&
                (item[props[i]].toLowerCase().includes(searchItem) ||
                  item[props[i]]
                    .toLowerCase()
                    .replace(" ", "")
                    .includes(searchItem))))
          ) {
            flag = true;
            break;
          }
        }
        return flag;
      });
      if (multiOptionOperator === "AND") {
        localData = temp;
        res = temp;
        return;
      }
      res = [...res, ...temp];
    });
    return [...new Set(res)];
  };

  const filterData = (filtersObj, data = backupData) => {
    let tempData = data;
    // filter vc status
    let vc_status_str = "";
    vc_status_str +=
      filterObj.rejected && filterObj.rejected != false ? "rejected;never;" : "";
    vc_status_str +=
      filterObj.cancelled && filterObj.cancelled != false ? "Cancelled;" : "";
    vc_status_str +=
      filterObj.applied && filterObj.applied != false ? "applied;" : "";
    vc_status_str +=
      filterObj.maybe && filterObj.maybe != false ? "maybe;" : "";
    vc_status_str +=
      filterObj.confirmed && filterObj.confirmed != false
        ? "Confirmed;In touch;"
        : "";
    tempData = filterRows(vc_status_str, ["vc_invited_stage"], tempData);

    if (
      filterObj.coaching_track &&
      filterObj.coaching_track != false &&
      typeof filterObj.coaching_track === "string"
    ) {
      tempData = filterRows(
        filterObj.coaching_track,
        filterObj.include_choices
          ? [
            "final_coaching_track_placement_contact",
            "coaching_track_contact",
            "coaching_track_2_contact",
          ]
          : ["final_coaching_track_placement_contact"],
        tempData,
        "="
      );
    }
    if (
      filterObj.judging_track &&
      filterObj.judging_track != false &&
      typeof filterObj.judging_track === "string"
    ) {
      tempData = filterRows(
        filterObj.judging_track,
        filterObj.include_choices
          ? [
            "final_judging_track_placement_contact",
            // "judging_track_contact",
            // "judging_track_2_contact",
            "industry_custom",
          ]
          : ["final_judging_track_placement_contact"],
        tempData,
        "contains"
        // "="
      );
    }
    if (
      filterObj.panel &&
      filterObj.panel != false &&
      typeof filterObj.panel === "string"
    ) {
      tempData = filterRows(
        filterObj.panel,
        filterObj.include_choices
          ? [
            "speaker_panel_contact",
            "panel_choice_1_contact",
            "panel_choice_2_contact",
            "panel_choice_3_contact",
          ]
          : ["speaker_panel_contact"],
        tempData,
        "="
      );
    }

    if (
      filterObj.speaker_display &&
      filterObj.speaker_display != false &&
      typeof filterObj.speaker_display === "string"
    ) {
      tempData = filterRows(
        filterObj.speaker_display,
        ["speaker_display"],
        tempData,
        "="
      );
    }
    if (
      filterObj.q &&
      filterObj.q != false &&
      typeof filterObj.q === "string"
    ) {
      tempData = filterRows(
        filterObj.q,
        undefined,
        tempData,
        "contains",
        "AND"
      );
    }

    updateQueryParams();
    return tempData.sort((a, b) => (a.date_created > b.date_created ? -1 : 1));
  };

  const updateQueryParams = (filtersObj = filterObj) => {
    for (const param in filterObj) {
      if (Object.hasOwnProperty.call(filterObj, param)) {
        if (param) {
          addOrUpdateQueryParam(String(param), filterObj[param]);
        }
      }
    }
  };

  const updateFiltersfromQuery = () => {
    let queryOptions = [
      "q",
      "panel",
      "judging_track",
      "coaching_track",
      "confirmed",
      "cancelled",
      "rejected",
      "applied",
      "maybe",
      "speaker_display",
      "include_choices",
    ];
    let temp;
    let obj = {};
    queryOptions.map((option) => {
      if ((temp = getQueryParameterByName(option))) {
        if (temp === "false") {
          temp = false;
        }
        obj[option] = temp;
      }
    });
    if (!obj.confirmed && !obj.cancelled && !obj.rejected && !obj.applied && !obj.maybe) {
      obj["confirmed"] = true;
      obj["applied"] = true;
      obj["maybe"] = true;
    }
    setFilterObj(obj);
  };

  useEffect(() => {
    getData(
      `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
    ).then((data) => {
      // console.log(data)
      setData(data);
      setBackupData(data);
      setInitialCount({
        speakers: data.filter(
          (speaker) =>
            speaker.attendee_tag?.includes("Panelist") &&
            speaker.speaker_display !== "no" &&
            speaker.speaker_display !== "pending" &&
            speaker.speaker_display !== ""
        ).length,
        judges: data.filter(
          (speaker) =>
            speaker.attendee_tag?.includes("Judge") &&
            speaker.speaker_display !== "no" &&
            speaker.speaker_display !== "pending"
        ).length,
        coaches: data.filter(
          (speaker) =>
            speaker.attendee_tag?.includes("Coach") &&
            speaker.speaker_display !== "no" &&
            speaker.speaker_display !== "pending"
        ).length,
        pending: data.filter((speaker) => speaker.speaker_display !== "pending" && speaker.speaker_display !== "")
          .length,
        panelists: data.filter(
          (speaker) =>
            speaker.speaker_display !== "no" &&
            speaker.speaker_display !== "pending"
        ).length,
      });

      // console.log(initialCount);
      // console.log(data);

      updateFiltersfromQuery();
    });

    getData(
      "https://youngstartup.io/api/db-info/property-options-dd/all_attendees"
    ).then((data) => {
      setOptions({
        attendee_tag: data.attendee_tag,
        coaching_track_contact: data.coaching_track_contact,
        vc_invited_stage: data.vc_invited_stage,
        judging_track_contact: data.judging_track_contact,
        speaker_panel_contact: data.speaker_panel_contact,
        vc_investment_location_s_: data.vc_investment_location_s_,
        speaker_display: data.speaker_display,
        final_panel_status: data.final_panel_status,
        final_judging_track_status: data.final_judging_track_status,
        final_coaching_track_status: data.final_coaching_track_status,
        emails_sent: data.emails_sent,
        coaching_room_number: data.coaching_room_number,
        final_judging_track_placement_contact: data.final_judging_track_placement_contact,
        industry_custom: data.industry_custom
        //all attendees
        //email sent
      });
    });

    getData(`https://youngstartup.io/api/db-a/attendees/getspeakerstats`).then(
      (data) => {
        // console.log(data);
        setSpeakerStats(data);
      }
    );
    // console.log(options);
    //AUTO-REFRESH TABLE
    //
    // setInterval(() => {
    //   getData(
    //     `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
    //   ).then((data) => {
    //     setData(data);
    //     setBackupData(data);
    //     // console.log(data);
    //     updateFiltersfromQuery();
    //   });
    // }, 45000);
  }, []);

  useEffect(() => {
    // debounce(setData(filterData(filterObj)), 400);
    setData(filterData(filterObj));
  }, [filterObj]);

  const rows = data;

  const columns = [
    {
      field: "lastname",
      headerName: "Investor",
      width: 300,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      cellClassName: "font-tabular-nums",
      renderCell: (cell) => {
        // let str = cell.row.attendee_tag ? cell.row.attendee_tag : "";
        // let arr = str.split(";");
        // console.log(arr)

        return (
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <img
              width={"70px"}
              onClick={() => {
                setModalInfo({
                  id: cell.row.id,
                  date_created: cell.row.date_created,
                  industry_custom: cell.row.industry_custom,
                  // vc_s_industry_focuses: cell.row.vc_s_industry_focuses,
                  speaker_display: cell.row.speaker_display,
                  vc_invited_stage: cell.row.vc_invited_stage,
                  headshot_url: cell.row.headshot_url,
                  source: cell.row.source,
                  attendee_tag:
                    cell.row.attendee_tag === null ? "" : cell.row.attendee_tag,
                  firstname: cell.row.firstname,
                  lastname: cell.row.lastname,
                  jobtitle: cell.row.jobtitle,
                  website: cell.row.website,
                  mobilephone: cell.row.mobilephone,
                  company: cell.row.company,
                  email: cell.row.email,
                  linkedin_uid: cell.row.linkedin_uid,
                  vc_investment_location_s_: cell.row.vc_investment_location_s_,
                  judging_track_contact: cell.row.judging_track_contact,
                  judging_track_2_contact: cell.row.judging_track_2_contact,
                  final_judging_track_placement_contact:
                    cell.row.final_judging_track_placement_contact,
                  panel_choice_1_contact: cell.row.panel_choice_1_contact,
                  panel_choice_2_contact: cell.row.panel_choice_2_contact,
                  panel_choice_3_contact: cell.row.panel_choice_3_contact,
                  speaker_panel_contact: cell.row.speaker_panel_contact,
                  coaching_track_contact: cell.row.coaching_track_contact,
                  coaching_track_2_contact: cell.row.coaching_track_2_contact,
                  final_coaching_track_placement_contact:
                    cell.row.final_coaching_track_placement_contact,
                  notes: cell.row.notes,
                  bio: cell.row.bio,
                  speaker_role: cell.row.speaker_role,
                  speaker_role_round: cell.row.speaker_role_round,
                });
                // console.log(modalInfo);
                // let myStr = modalInfo.attendee_tag.split(';');
                // console.log(myStr);
                // setPersonName(myStr);
                //   console.log(personName);
                setOpen(true);
              }}
              src={
                cell.row.headshot_url == ""
                  ? "assets/placeholder.jpeg"
                  : cell.row.headshot_url
              }
              alt=""
            />
            <div>
              <b
                onClick={() => {
                  setModalInfo({
                    id: cell.row.id,
                    industry_custom: cell.row.industry_custom,
                    // vc_s_industry_focuses: cell.row.vc_s_industry_focuses,
                    speaker_display: cell.row.speaker_display,
                    vc_invited_stage: cell.row.vc_invited_stage,
                    headshot_url: cell.row.headshot_url,
                    attendee_tag:
                      cell.row.attendee_tag === null
                        ? ""
                        : cell.row.attendee_tag,
                    firstname: cell.row.firstname,
                    date_created: cell.row.date_created,
                    lastname: cell.row.lastname,
                    jobtitle: cell.row.jobtitle,
                    website: cell.row.website,
                    company: cell.row.company,
                    mobilephone: cell.row.mobilephone,
                    email: cell.row.email,
                    linkedin_uid: cell.row.linkedin_uid,
                    source: cell.row.source,
                    vc_investment_location_s_:
                      cell.row.vc_investment_location_s_,
                    judging_track_contact: cell.row.judging_track_contact,
                    judging_track_2_contact: cell.row.judging_track_2_contact,
                    final_judging_track_placement_contact:
                      cell.row.final_judging_track_placement_contact,
                    panel_choice_1_contact: cell.row.panel_choice_1_contact,
                    panel_choice_2_contact: cell.row.panel_choice_2_contact,
                    panel_choice_3_contact: cell.row.panel_choice_3_contact,
                    speaker_panel_contact: cell.row.speaker_panel_contact,
                    coaching_track_contact: cell.row.coaching_track_contact,
                    coaching_track_2_contact: cell.row.coaching_track_2_contact,
                    final_coaching_track_placement_contact:
                      cell.row.final_coaching_track_placement_contact,
                    notes: cell.row.notes,
                    bio: cell.row.bio,
                    speaker_role: cell.row.speaker_role,
                    speaker_role_round: cell.row.speaker_role_round,
                  });
                  setOpen(true);
                }}
              >
                {cell.row.firstname} {cell.row.lastname}
              </b>
              <div>{cell.row.jobtitle}</div>
              <div className="company-link">
                <a
                  href={cell.row.website}
                  target="_blank"
                  style={{ color: "#F0AD4E" }}
                >
                  {cell.row.company}
                </a>
              </div>
              {/* <div style={{ display:'flex', flexDirection:'row', gap:'2.5px', flexWrap:'wrap' }}>
              {arr.map((item,index)=>(item !== "" && <div style={{backgroundColor:'#303265', borderRadius:'5px', padding:'2.5px'}}>{item}</div>))}
            </div> */}
            </div>
          </div>
        );
      },
    },
    {
      field: "speaker_display",
      headerName: "Display on Site",
      flex: .6,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (cell) => {
        return (
          <>
            <DisplaySwitch cell={cell} prop={"speaker_display"} val={"yes"} />
            {/* <MuiToggleButton cell={cell} /> */}
            {/* <Switch
            size="small"
            sx={{
              "& .MuiSwitch-switchBase": {
                color: `${
                  cell.row.speaker_display == "yes"
                    ? "green !important"
                    : "red !important"
                }`,
              },
              "& .MuiSwitch-track": {
                backgroundColor: "white",
              },
            }}
            checked={cell.row.speaker_display === "yes" ? true : false}
            onClick={(e) => {
              if (e.target.checked) {
                cell.row.speaker_display = "yes";
                setModalInfo({
                  ...modalInfo,
                  speaker_display: "yes",
                });
                console.log(modalInfo);
              } else {
                cell.row.speaker_display = "no";
                setModalInfo({
                  ...modalInfo,
                  speaker_display: "no",
                });
                console.log(modalInfo);
              }

              let body = {
                body: {
                  id: cell.row.id,
                  speaker_display: cell.row.speaker_display,
                },
              };
              console.log(body);
              postData(
                "https://youngstartup.io/api/db/attendees/add-or-update",
                body
              )
                .then((data) => console.log(data))
                .then(() =>
                  getData(
                    `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                  ).then((data) => {
                    setData(data);
                    console.log(data);
                  })
                );
            }}
          /> */}
          </>
        );
      },
    },
    // {
    //   field: "attendee_tag",
    //   headerName: "Role",
    //   headerAlign: "center",
    //   disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    //   align: "center",
    //   flex: 1,
    //   renderCell: (cell) => {
    //     let str = cell.row.attendee_tag ? cell.row.attendee_tag : "";
    //     let arr = str.split(";");

    //     let sortOrder = [
    //       "Sponsor",
    //       "Moderator",
    //       "Panelist",
    //       "Judge",
    //       "Coach",
    //       "Investor",
    //     ];

    //     applyCustomOrder(arr, sortOrder);

    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //           flexDirection: "row",
    //           gap: "2.5px",
    //           flexWrap: "wrap",
    //         }}
    //       >
    //         {arr.map(
    //           (item, index) =>
    //             item !== "" && (
    //               <div
    //                 key={index}
    //                 style={{
    //                   backgroundColor: "#303265",
    //                   borderRadius: "2px",
    //                   padding: "2.5px 5px",
    //                 }}
    //               >
    //                 {item[0]}
    //               </div>
    //             )
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "status",
      headerName: "Stats",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
      flex: .65,
      renderCell: (cell) => {
        return (
          <Stack spacing={1}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <div>Confirm</div>
              <MuiToggleStats
                prop={`cell.row.email_confirmed`}
                stat={cell.row.email_confirmed ? true : false}
                cell={cell}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <div><SpeakerQr cell={cell} /></div>
              <MuiToggleStats
                prop={`cell.row.opened_dashboard`}
                stat={cell.row.opened_dashboard ? true : false}
                cell={cell}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <div>Brella</div>
              <MuiToggleStats
                prop={`cell.row.matchmaking_signup`}
                stat={cell.row.matchmaking_signup ? true : false}
                cell={cell}
              />
            </div>
          </Stack>
        );
      },
    },
    {
      field: "",
      headerName: "Stats 2",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (cell) => {

        return (
          <Stack spacing={1}>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <div>Lunch</div>
              <MealToggle cell={cell} meal={"VC Only Lunch"} mealSubs={mealSubs} />
            </div> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <div>Cocktail</div>
              <MealToggle cell={cell} meal={"Cocktail Reception"} mealSubs={mealSubs} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <div>Dinner</div>
              <MealToggle cell={cell} meal={"Venture Dinner"} mealSubs={mealSubs} />
            </div>
          </Stack>

        );
      },
    },
    {
      field: "vc_invited_stage",
      headerName: "VC Invited Stage",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
      flex: .75,
      renderCell: (cell) => {
        return (
          <div>
            <Select
              size="small"
              // sx={dropdownStyles}
              sx={{ "&": {
                ...dropdownStyles['&'], 
                backgroundColor: (
                  cell.row.vc_invited_stage.toLowerCase() === "confirmed" ? "green" : (
                    cell.row.vc_invited_stage.toLowerCase() === "applied" ? "blue" : (
                      cell.row.vc_invited_stage.toLowerCase() === "rejected" ? "red" : (
                        cell.row.vc_invited_stage.toLowerCase() === "maybe" ? "orange" : (
                          cell.row.vc_invited_stage.toLowerCase().includes("cancelled") ? "red" : "yellow")))))
                        }
                      }}
              fullWidth
              value={cell.row.vc_invited_stage}
              onChange={(e) => {
                let body = {
                  body: {
                    id: cell.row.id,
                    vc_invited_stage: e.target.value
                  }
                }
                postData(`https://youngstartup.io/api/db/attendees/add-or-update`, body)
                  // .then(data => console.log(data))
                  .then(() =>
                    getData(
                      `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                    ).then((data) => {
                      setBackupData(data);
                      setData(filterData(filterObj, data));
                    })
                  )
              }}
            >
              {options.vc_invited_stage.map((e, i) => {
                return (
                  <MenuItem value={e.option_value} key={i}                  >
                    {e.option_value}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        );
      },
    },
    // {
    //   field: "volunteer_days",
    //   // field: "1",
    //   headerName: "Attending",
    //   headerAlign: "center",
    //   align: "center",
    //   headerClassName: "super-app-theme--header",
    //   editable: false,
    //   sortable: false,
    //   flex: .5,
    //   renderCell: (cell) => {
    //     return (
    //       <div><GeneralDropdownWithUpdates id={cell.row.id} prop={"volunteer_days"} val={cell.row.volunteer_days} dict={ddOptions.volunteer_days} /></div>
    //     );
    //   },
    // },
    {
      field: "speaker_role",
      headerName: "Role",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
      flex: .5,
      renderCell: (cell) => {
        let role = "";
        switch (cell.row.speaker_role) {
          case "both":
            role = "S&J";
            break;
          case "speaker":
            role = "S";
            break;
          case "judge":
            role = "J";
            break;
          default:
            break;
        }
        return (
          // <div>{role}</div>
          <div>
            {cell.row.attendee_tag?.includes("Panelist") && <GeneralDropdownWithUpdates
              id={cell.row.id}
              prop={"speaker_role"}
              val={cell.row.speaker_role}
              dict={ddOptions.speaker_role}
            />}
          </div>
        );
      },
    },
    {
      field: "emails_sent",
      headerName: "Emails Sent",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (cell) => {
        let emails = cell.value;
        if (emails) {
          emails = emails.map(
            (item) =>
              options.emails_sent?.filter((i) => i.option_value == item)[0]
                ?.option_display_short
          );
          // console.log(emails);
        }
        return (
          <Stack>
            {Array.isArray(emails)
              ? emails.map((email, i) => (
                <div key={i} style={{ display: "block" }}>
                  {email}, &nbsp;
                </div>
              ))
              : emails}
          </Stack>
        );
      },
    },
    {
      field: "panel_choice_1_contact",
      headerName: "Panel Choice",
      headerAlign: "center",
      align: "left",
      // align: "center",
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (cell) => {
        return <PanelChoice cell={cell} />;
      },
    },
    {
      field: "speaker_panel_contact",
      headerName: "Panel Final",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (cell) =>
        cell.row.attendee_tag?.includes("Panelist") ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <SpeakerDropdown cell={cell} />
            <PanelConfirm cell={cell} />
          </div>
        ) : (
          ""
        ),
    },
    {
      // field: "industry_custom",
      field: "judging_track_contact",
      flex: .75,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      headerName: "Judge Choice",
      disableColumnMenu: true,
      renderCell: (cell) => {
        return (
          <div style={{ fontSize: ".7rem" }}>
          {cell.row.judging_track_contact && (
            <div>1) {cell.row.judging_track_contact}</div>
          )}
          {cell.row.judging_track_2_contact && (
            <div>2) {cell.row.judging_track_2_contact}</div>
          )}
        </div>
        );
      },
    },
    // {
    //   field: "final_judging_track_placement_contact",
    //   headerName: "Judge Final",
    //   headerAlign: "center",
    //   disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    //   align: "center",
    //   flex: 1.5,
    //   renderCell: (cell) =>
    //     cell.row.attendee_tag?.includes("Judge") && (
    //       <div
    //         style={{
    //           display: "flex",
    //           flexDirection: "row",
    //           alignItems: "center",
    //           gap: "5px",
    //         }}
    //       >
    //         {/* <div>{cell.row?.final_judging_track_placement_contact}</div> */}
    //         <JudgingDropdown cell={cell} />
    //         <JudgeConfirm cell={cell} />
    //         <SpeakerRound cell={cell} />
    //       </div>
    //     ),
    // },
    {
      field: "final_judging_track_round_1",
      headerName: "JR 1",
      headerAlign: "center",
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      align: "center",
      flex: 0.75,
      renderCell: (cell) => {
        return cell.row.speaker_role !== "speaker" 
        // && ['First Day','Both Days'].includes(cell.row.volunteer_days) 
        ? (
          <div style={{display: "flex"}}>
            <JudgeConfirm cell={cell} />
            <div>
              <GeneralDropdownWithUpdates id={cell.row.id} prop={"final_judging_track_round_1"} val={cell.row.final_judging_track_round_1} dict={ddOptions.final_judging_track_placement_contact} addEmpty={true} />
              <br />
              <DisplaySwitch cell={cell} prop={"speaker_role_round"} val={1} is_array={true} />
            </div>
          </div>
        ) : ("");
      }
    },
    {
      field: "final_judging_track_round_2",
      headerName: "JR 2",
      headerAlign: "center",
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      align: "center",
      flex: 0.75,
      renderCell: (cell) => {
        return cell.row.speaker_role !== "speaker" 
        // && ['First Day','Both Days'].includes(cell.row.volunteer_days) 
        ? (
          <div>
            <GeneralDropdownWithUpdates id={cell.row.id} prop={"final_judging_track_round_2"} val={cell.row.final_judging_track_round_2} dict={ddOptions.final_judging_track_placement_contact} addEmpty={true} />
            <br />
            <DisplaySwitch cell={cell} prop={"speaker_role_round"} val={2} is_array={true} />
          </div>
        ) : ("");
      }
    },
    {
      field: "final_judging_track_round_3",
      headerName: "JR 3",
      headerAlign: "center",
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      align: "center",
      flex: 0.75,
      renderCell: (cell) => {
        return cell.row.speaker_role !== "speaker" 
        // && ['Second Day','Both Days'].includes(cell.row.volunteer_days) 
        ? (
          <div>
            <GeneralDropdownWithUpdates id={cell.row.id} prop={"final_judging_track_round_3"} val={cell.row.final_judging_track_round_3} dict={ddOptions.final_judging_track_placement_contact} addEmpty={true} />
            <br />
            <DisplaySwitch cell={cell} prop={"speaker_role_round"} val={3} is_array={true} />
          </div>
        ) : ("");
      }
    },
    // {
    //   field: "final_judging_track_round_4",
    //   headerName: "JR 4",
    //   headerAlign: "center",
    //   disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    //   align: "center",
    //   flex: 0.75,
    //   renderCell: (cell) => {
    //     return cell.row.speaker_role !== "speaker" && 
    //     ['Second Day','Both Days'].includes(cell.row.volunteer_days) ? (
    //       <div>
    //         <GeneralDropdownWithUpdates id={cell.row.id} prop={"final_judging_track_round_4"} val={cell.row.final_judging_track_round_4} dict={ddOptions.final_judging_track_round_4} addEmpty={true} />
    //         <br />
    //         <DisplaySwitch cell={cell} prop={"speaker_role_round"} val={4} is_array={true} />
    //       </div>
    //     ) : ("");
    //   }
    // },
    // {
    //   field: "final_judging_track_round_5",
    //   headerName: "JR 5",
    //   headerAlign: "center",
    //   disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    //   align: "center",
    //   flex: 0.75,
    //   renderCell: (cell) => {
    //     return cell.row.speaker_role !== "speaker" && 
    //     ['Second Day','Both Days'].includes(cell.row.volunteer_days) ? (
    //       <div>
    //         <GeneralDropdownWithUpdates id={cell.row.id} prop={"final_judging_track_round_5"} val={cell.row.final_judging_track_round_5} dict={ddOptions.final_judging_track_round_5} addEmpty={true} />
    //         <br />
    //         <DisplaySwitch cell={cell} prop={"speaker_role_round"} val={5} is_array={true} />
    //       </div>
    //     ) : ("");
    //   }
    // },
    // {
    //   field: "coaching_track_contact",
    //   headerName: "Coach Choice",
    //   headerAlign: "center",
    //   disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    //   align: "center",
    //   flex: .75,
    //   renderCell: (cell) =>
    //     cell.row.attendee_tag.includes("Coach") && <CoachChoice cell={cell} />,
    // },

    // {
    //   field: "final_coaching_track_placement_contact",
    //   headerName: "Coach Final",
    //   headerClassName: "super-app-theme--header",
    //   disableColumnMenu: true,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: 1,
    //   renderCell: (cell) =>
    //     cell.row.attendee_tag.includes("Coach") && (
    //       <Stack>
    //         <div
    //           style={{
    //             display: "flex",
    //             flexDirection: "row",
    //             alignItems: "center",
    //             gap: "5px",
    //             width: '80%',
    //             justifyContent: 'center'
    //           }}
    //         >
    //           <CoachingDropdown cell={cell} />
    //           <CoachConfirm cell={cell} />
    //           {/*final_coaching_track_status*/}
    //         </div>
    //         {cell.row.final_coaching_track_status == 'confirmed' && <div style={{ display: 'flex', alignItems: 'center' }}>
    //           {/* <div>Room:</div> */}
    //           <div>
    //             {/* <MuiSelect cell={{formattedValue: cell.row.coaching_room_number}}  cell={cell} options={options.coaching_room_number} /> */}
    //           </div></div>}
    //       </Stack>
    //     )

    // },
    // {
    //   field: "id",
    //   headerName: "Dash",
    //   headerClassName: "super-app-theme--header",
    //   disableColumnMenu: true,
    //   headerAlign: "center",
    //   align: "center",
    //   flex: .5,
    //   renderCell: (cell) => {
    //     return (
    //       <SpeakerQr cell={cell} />
    //     )
    //   }
    // },
  ];

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [judgingOptionsTemp, setJudgingOptionsTemp] = useState([]);
  useEffect(() => {
    if (options.final_judging_track_placement_contact.length && options.industry_custom.length) {
      let arr = [...options.final_judging_track_placement_contact, ...options.industry_custom];
      let arr2 = [];
      let arr3 = arr.filter(x => {
        if (!arr2.includes(x.option_value)) {
          arr2.push(x.option_value);
          return x;
        }
      })
      arr3.sort((a, b) => {
        if (a.option_value < b.option_value) {
          return -1;
        } else if (a.option_value > b.option_value) {
          return 1;
        } else {
          return 0;
        }
      })
      setJudgingOptionsTemp(arr3);
    }
  }, [options.final_judging_track_placement_contact])

  return (
    <div>
      <Toaster />
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          {"Send emails?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "center" }}
          >
            {selectionModel.length >= 1
              ? `Send email "${emailName}" to ${selectionModel.length} speakers?`
              : `You must select a speaker to send email to!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {selectionModel.length >= 1 ? (
            <Button
              endIcon={<SendIcon />}
              // onClick={handleClose2}
              variant="contained"
              onClick={(e) => {
                let body = {
                  body: {
                    ids: selectionModel,
                    template_id: emailValue,
                  },
                };

                const myPromise = createAttendee(
                  "https://youngstartup.io/api/db-a/emails/send_queued_template_v2",
                  body
                )
                  .then((data) => data)
                  .then(() => handleClose2());

                toast.promise(
                  myPromise,
                  {
                    loading: "Loading",
                    success: (data) => `Emails sent successfully!`,
                    error: (err) => `${err.toString()}`,
                  },
                  {
                    style: {
                      minWidth: "250px",
                    },
                    success: {
                      duration: 5000,
                      // icon: '🔥',
                    },
                  }
                );
              }}
            >
              Send
            </Button>
          ) : (
            <Button variant="contained" onClick={handleClose2}>
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <MuiAppBar setFilterObj={setFilterObj} filterObj={filterObj} />
      <div
        style={{
          // height: 625,
          // height: "82vh",
          height: "67vh",
          width: "100%",
          backgroundColor: "#000120",
          color: "black !important",
        }}
      >
        {modalInfo !== null && (
          <Dialog
            open={open}
            fullWidth
            maxWidth="lg"
            //onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title" sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:"15px"}}>
          <img width={125} src={
              modalInfo.headshot_url == ""
                ? "assets/placeholder.jpeg"
                : modalInfo.headshot_url
            } /> <div><div style={{fontSize:'1.5rem'}}>{modalInfo.firstname} {modalInfo.lastname}</div><div style={{textTransform:'capitalize', fontSize:'1rem'}}>{modalInfo.attendee_tag}</div></div> 
        </DialogTitle> */}
            <DialogActions sx={{ backgroundColor: "#030120" }}>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <CloseIcon sx={{ color: "white" }} onClick={handleClose} />
              </div>
            </DialogActions>
            <DialogContent
              className="dialog"
              sx={{ backgroundColor: "#000120", color: "white" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "25px" }}
                >
                  <div>
                    <div style={{ paddingBottom: ".5rem" }}                    >
                      VC Invited Stage
                    </div>
                    <div>
                      <Select
                        size="small"
                        sx={dropdownStyles}
                        fullWidth
                        value={modalInfo.vc_invited_stage}
                        onChange={(e) =>
                          setModalInfo({
                            ...modalInfo,
                            vc_invited_stage: e.target.value,
                          })
                        }
                      >
                        {options.vc_invited_stage.map((e, i) => {
                          return (
                            <MenuItem value={e.option_value} key={i}>
                              {e.option_value}
                            </MenuItem>
                          );
                        })}

                        {/* <MenuItem value={modalInfo.vc_invited_stage}>
                          {modalInfo.vc_invited_stage}
                        </MenuItem> 
                        {console.log("checkl", options.vc_invited_stage.map(e=>e.option_value))}
                         {stageOptions.map((option) => {
                          // console.log(stageOptions);
                          return (
                            <MenuItem value={option.value}>
                              {option.label}
                            </MenuItem>
                          );
                        })} */}

                      </Select>
                    </div>
                    <br />
                    <div>
                      {" "}
                      <label>Display:</label>
                      <MuiToggleButton cell={modalInfo} />
                      {/* <Switch
                      size="small"
                      checked={
                        modalInfo.speaker_display === "yes" ? true : false
                      }
                      sx={{
                        "& .MuiSwitch-switchBase": {
                          color: `${
                            modalInfo.speaker_display == "yes"
                              ? "green !important"
                              : "red !important"
                          }`,
                        },
                        "& .MuiSwitch-track": {
                          backgroundColor: "white",
                        },
                      }}
                      onClick={(e) => {
                        console.log(e.target.checked);
                        if (e.target.checked) {
                          setModalInfo({
                            ...modalInfo,
                            speaker_display: "yes",
                          });
                        } else {
                          setModalInfo({
                            ...modalInfo,
                            speaker_display: "no",
                          });
                        }

                        console.log(modalInfo);
                      }}
                    /> */}
                    </div>
                    <div style={{ position: "relative" }}>
                      <Fab
                        color="primary"
                        style={{
                          position: "absolute",
                          top: "17.5%",
                          right: "8%",
                          transform: "scale(.7)",
                        }}
                        onClick={() =>
                          showInput == true
                            ? setShowInput(false)
                            : setShowInput(true)
                        }
                      >
                        <EditIcon sx={{ position: "absolute" }} />
                        <input
                          style={{ opacity: "0" }}
                          accept="image/*"
                          id="raised-button-file"
                          multiple
                          type="file"
                          onChange={(e) => {
                            // console.log(e.target.value);
                            uploadHeadshot(e).then(() =>
                              console.log(modalInfo.headshot_url)
                            );
                          }}
                        />
                      </Fab>
                      {modalInfo.headshot_url !== "" ? (
                        <img
                          width="200px"
                          style={{ marginTop: "5vh" }}
                          src={
                            modalInfo.headshot
                              ? modalInfo.headshot
                              : modalInfo.headshot_url
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          width="200px"
                          style={{ marginTop: "5vh" }}
                          src={"assets/placeholder.jpeg"}
                          alt=""
                        />
                      )}
                    </div>
                  </div>

                  <div>
                    <Stack spacing={0.5} sx={{ display: "relative" }}>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2.5px",
                        }}
                      >
                        <div style={{ width: "80px" }}>Role:</div>
                        <Select
                          sx={dropdownStyles}
                          label="Role"
                          multiple
                          value={modalInfo.attendee_tag
                            .split(";")
                            .filter((i) => i)}
                          onChange={handleChange}
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => {
                            // console.log(selected);
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5px",
                                }}
                              >
                                {selected.map((item, i) => (
                                  <div
                                    key={i}
                                    style={{
                                      backgroundColor: "#000120",
                                      padding: "5px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {item}
                                  </div>
                                ))}
                              </div>
                            );
                          }}
                          MenuProps={MenuProps}
                        >
                          {attendeeTagOptions.map((name) => {
                            return (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={
                                    modalInfo.attendee_tag
                                      .split(";")
                                      .indexOf(name) > -1
                                  }
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {/* <select
                        onChange={(e) => {
                          let options = optionsState;
                          options.push(e.target.value);
                          let uniqueArr = options.filter(function (item, pos) {
                            return options.indexOf(item) == pos;
                          });
                        }}
                        multiple
                        style={{ width: "250px" }}
                      >
                        <option value={modalInfo.attendee_tag}>
                          {modalInfo.attendee_tag}
                        </option>
                        {attendeeTagOptions.map((tag) => {
                          let options = optionsState;
                          if (
                            modalInfo.attendee_tag.includes(`${tag}`) == true &&
                            tag !== ""
                          ) {
                            options.push(tag);
                            return (
                              
                              <option
                                value={tag}
                                selected={true}
                                onClick={(e) => {
                                  console.log(e);
                                  let options = optionsState;
                                  console.log(e.target.value);
                                  options.push(e.target.value);
                                  console.log(options);
                                  let uniqueChars = [...new Set(options)];
                                  console.log(uniqueChars);
                                }}
                              >
                                {tag == "" ? "None" : tag}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                value={tag}
                                selected={false}
                                onClick={(e) => {
                                  console.log(e);
                                  let options = optionsState;
                                  console.log(e.target.value);
                                  options.push(e.target.value);
                                  console.log(options);
                                  let uniqueChars = [...new Set(options)];
                                  console.log(uniqueChars);
                                }}
                              >
                                {tag == "" ? "None" : tag}
                              </option>
                            );
                          }
                        })}
                      </select> */}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div style={{ width: "80px" }}>Name:</div>
                        <TextField
                          inputProps={{ readOnly: false }}
                          size="small"
                          sx={styles}
                          label="First Name"
                          style={{ width: "25%" }}
                          value={modalInfo.firstname}
                          onChange={(e) =>
                            setModalInfo({
                              ...modalInfo,
                              firstname: e.target.value,
                            })
                          }
                        />{" "}
                        <TextField
                          size="small"
                          sx={styles}
                          label="Last Name"
                          style={{ width: "25%" }}
                          value={modalInfo.lastname}
                          onChange={(e) =>
                            setModalInfo({
                              ...modalInfo,
                              lastname: e.target.value,
                            })
                          }
                        />
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div style={{ width: "80px" }}>Title:</div>
                        <TextField
                          size="small"
                          sx={styles}
                          label="Title"
                          value={modalInfo.jobtitle}
                          onChange={(e) =>
                            setModalInfo({
                              ...modalInfo,
                              jobtitle: e.target.value,
                            })
                          }
                        />{" "}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div style={{ width: "80px" }}>Company:</div>
                        <TextField
                          size="small"
                          sx={styles}
                          label="Company"
                          value={modalInfo.company}
                          onChange={(e) =>
                            setModalInfo({
                              ...modalInfo,
                              company: e.target.value,
                            })
                          }
                        />{" "}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div style={{ width: "80px" }}>Web:</div>
                        <TextField
                          size="small"
                          sx={styles}
                          label="Website"
                          value={modalInfo.website}
                          onChange={(e) =>
                            setModalInfo({
                              ...modalInfo,
                              website: e.target.value,
                            })
                          }
                        />{" "}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div style={{ width: "80px" }}>Phone:</div>
                        <TextField
                          size="small"
                          sx={styles}
                          label="Phone"
                          value={modalInfo.mobilephone}
                          onChange={(e) =>
                            setModalInfo({
                              ...modalInfo,
                              mobilephone: e.target.value,
                            })
                          }
                        />{" "}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div style={{ width: "80px" }}>Email:</div>
                        <TextField
                          size="small"
                          sx={styles}
                          label="Email"
                          required
                          value={modalInfo.email}
                          onChange={(e) =>
                            setModalInfo({
                              ...modalInfo,
                              email: e.target.value,
                            })
                          }
                        />{" "}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div style={{ width: "80px" }}>Linkedin:</div>
                        <TextField
                          size="small"
                          sx={styles}
                          label="Linkedin"
                          required
                          value={modalInfo.linkedin_uid}
                          onChange={(e) =>
                            setModalInfo({
                              ...modalInfo,
                              linkedin_uid: e.target.value,
                            })
                          }
                        />{" "}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div style={{ width: "80px" }}>Date Created:</div>
                        <TextField
                          readOnly
                          size="small"
                          sx={styles}
                          label="Date Created"
                          value={modalInfo.date_created}
                        />{" "}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        Regions:{" "}
                        <Select
                          sx={dropdownStyles}
                          multiple
                          value={
                            modalInfo.vc_investment_location_s_
                              ? modalInfo.vc_investment_location_s_
                                .split(";")
                                .filter((i) => i)
                              : []
                          }
                          onChange={(e) => {
                            const {
                              target: { value },
                            } = e;
                            setModalInfo({
                              ...modalInfo,
                              vc_investment_location_s_: value.join(";"),
                            });
                            // console.log(modalInfo);
                          }}
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => {
                            // console.log(selected);
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5px",
                                }}
                              >
                                {selected.map((item, i) => (
                                  <div
                                    key={i}
                                    style={{
                                      backgroundColor: "#000120",
                                      padding: "5px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {item}
                                  </div>
                                ))}
                              </div>
                            );
                          }}
                          MenuProps={MenuProps}
                        >
                          {investmentLocationOptions.map((name) => {
                            return (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={
                                    modalInfo.vc_investment_location_s_ &&
                                    modalInfo.vc_investment_location_s_
                                      .split(";")
                                      .indexOf(name) > -1
                                  }
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </span>
                      <span>
                        Industry Focus(es):{" "}
                        <Select
                          sx={dropdownStyles}
                          style={{ height: "auto" }}
                          multiple
                          value={
                            modalInfo.industry_custom
                              ? modalInfo.industry_custom
                                // modalInfo.vc_s_industry_focuses
                                //   ? modalInfo.vc_s_industry_focuses
                                .split(";")
                                .filter((i) => i)
                              : []
                          }
                          onChange={(e) => {
                            const {
                              target: { value },
                            } = e;
                            setModalInfo({
                              ...modalInfo,
                              industry_custom: value.join(";"),
                              // vc_s_industry_focuses: value.join(";"),
                            });
                            // console.log(modalInfo);
                          }}
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => {
                            // console.log(selected);
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5px",
                                  flexWrap: "wrap",
                                  maxWidth: "300px",
                                  height: "fit-content"
                                }}
                              >
                                {selected.map((item, i) => (
                                  <div
                                    key={i}
                                    style={{
                                      backgroundColor: "#000120",
                                      padding: "5px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {item}
                                  </div>
                                ))}
                              </div>
                            );
                          }}
                          MenuProps={MenuProps}
                        >
                          {industryFocusOptions.map((name) => {
                            return (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={
                                    modalInfo.industry_custom
                                      ? modalInfo.industry_custom
                                        // modalInfo.vc_s_industry_focuses
                                        //   ? modalInfo.vc_s_industry_focuses
                                        .split(";")
                                        .indexOf(name) > -1
                                      : []
                                  }
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </span>
                    </Stack>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "space-around",
                    marginTop: "5px",
                  }}
                >
                  <table
                    style={{
                      textAlign: "center",
                      fontSize: ".9rem !important",
                    }}
                  >
                    <tr>
                      <th></th>
                      <th>Choice 1</th>
                      <th>Choice 2</th>
                      <th>Final</th>
                    </tr>
                    <tr>
                      <td style={{ color: "#F0AD4E" }}>Judging:</td>
                      <td>
                        <div
                          style={{
                            backgroundColor: "#303265",
                            borderRadius: "5px",
                            fontSize: "12px",
                            padding: "5px",
                          }}
                        >
                          {modalInfo.judging_track_contact}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            backgroundColor: "#303265",
                            borderRadius: "5px",
                            fontSize: "12px",
                            padding: "5px",
                          }}
                        >
                          {modalInfo.judging_track_2_contact}
                        </div>
                      </td>
                      <td>
                        <Select
                          sx={dropdownStyles}
                          value={
                            modalInfo.final_judging_track_placement_contact
                          }
                          onChange={(e) =>
                            setModalInfo({
                              ...modalInfo,
                              final_judging_track_placement_contact:
                                e.target.value,
                            })
                          }
                        >
                          <MenuItem
                            value={
                              modalInfo.final_judging_track_placement_contact
                            }
                          >
                            {modalInfo.final_judging_track_placement_contact}
                          </MenuItem>
                          {judgingOptions.map((option, i) => {
                            if (
                              option.value !==
                              modalInfo.final_judging_track_placement_contact
                            )
                              return (
                                <MenuItem value={option.value} key={i}>
                                  {option.label}
                                </MenuItem>
                              );
                          })}
                        </Select>
                      </td>

                      <select name="speaker_role_round" defaultValue={modalInfo.speaker_role_round}
                        style={{ backgroundColor: "blue", color: "white" }}
                        onChange={(e) => {
                          modalInfo.speaker_role_round = e.target.value;
                        }}
                      >
                        <option value="">--</option>
                        {
                          ddOptions.speaker_role_round?.map((item) => {
                            return <option key={item.id} value={item.option_value}>{item.option_display}</option>
                          })
                        }
                      </select >

                    </tr>
                    <tr>
                      <td style={{ color: "#F0AD4E" }}>Coaching:</td>
                      <td>
                        <div
                          style={{
                            backgroundColor: "#303265",
                            borderRadius: "5px",
                            fontSize: "12px",
                            padding: "5px",
                          }}
                        >
                          {modalInfo.coaching_track_contact}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            backgroundColor: "#303265",
                            borderRadius: "5px",
                            fontSize: "12px",
                            padding: "5px",
                          }}
                        >
                          {modalInfo.coaching_track_2_contact}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <Select
                          sx={dropdownStyles}
                          value={
                            modalInfo.final_coaching_track_placement_contact
                          }
                          onChange={(e) =>
                            setModalInfo({
                              ...modalInfo,
                              final_coaching_track_placement_contact:
                                e.target.value,
                            })
                          }
                        >
                          <MenuItem
                            value={
                              modalInfo.final_coaching_track_placement_contact
                            }
                          >
                            {modalInfo.final_coaching_track_placement_contact}
                          </MenuItem>
                          {coachingOptions.map((option, i) => {
                            if (
                              option.value !==
                              modalInfo.final_coaching_track_placement_contact
                            )
                              return (
                                <MenuItem value={option.value} key={i}>
                                  {option.label}
                                </MenuItem>
                              );
                          })}
                        </Select>
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                      <th>Choice 1</th>
                      <th>Choice 2</th>
                      <th>Choice 3</th>
                      <th>Final</th>
                    </tr>
                    <tr>
                      <td style={{ color: "#F0AD4E" }}>Speaking:</td>
                      <td>
                        <div
                          style={{
                            backgroundColor: "#303265",
                            borderRadius: "5px",
                            fontSize: "12px",
                            padding: "5px",
                          }}
                        >
                          {modalInfo.panel_choice_1_contact}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            backgroundColor: "#303265",
                            borderRadius: "5px",
                            fontSize: "12px",
                            padding: "5px",
                          }}
                        >
                          {modalInfo.panel_choice_2_contact}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            backgroundColor: "#303265",
                            borderRadius: "5px",
                            fontSize: "12px",
                            padding: "5px",
                          }}
                        >
                          {modalInfo.panel_choice_3_contact}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <Select
                          sx={dropdownStyles}
                          value={modalInfo.speaker_panel_contact}
                          onChange={(e) =>
                            setModalInfo({
                              ...modalInfo,
                              speaker_panel_contact: e.target.value,
                            })
                          }
                        >
                          <MenuItem value={modalInfo.speaker_panel_contact}>
                            {modalInfo.speaker_panel_contact}
                          </MenuItem>
                          {speakerOptions.map((option, i) => {
                            if (
                              option.value !== modalInfo.speaker_panel_contact
                            )
                              return (
                                <MenuItem value={option.value} key={i}>
                                  {option.label}
                                </MenuItem>
                              );
                          })}
                        </Select>
                      </td>
                    </tr>
                  </table>
                </div>

                <div style={{ width: "250px" }}>Speaker/Judge Role:</div>
                <Select
                  // sx={dropdownStyles}
                  style={{ color: "white", width: "fit-content", backgroundColor: "#303265" }}
                  label="Speaker/Judge Role"
                  value={modalInfo.speaker_role}
                  onChange={(e) =>
                    setModalInfo({
                      ...modalInfo,
                      speaker_role: e.target.value,
                    })
                  }
                  input={<OutlinedInput label="Tag" />}
                  // renderValue={(selected) => {
                  //   // console.log(selected);
                  //   return (
                  //     <div
                  //       style={{
                  //         display: "flex",
                  //         flexDirection: "row",
                  //         gap: "5px",
                  //       }}
                  //     >
                  //       {selected.map((item, i) => (
                  //         <div
                  //           key={i}
                  //           style={{
                  //             backgroundColor: "#000120",
                  //             padding: "5px",
                  //             borderRadius: "5px",
                  //           }}
                  //         >
                  //           {item}
                  //         </div>
                  //       ))}
                  //     </div>
                  //   );
                  // }}
                  MenuProps={MenuProps}
                >
                  {ddOptions.speaker_role?.map((role) => {
                    return (
                      <MenuItem key={role.id} value={role.option_value}>
                        <Checkbox
                          checked={modalInfo.speaker_role}
                          style={{ backgroundColor: "white" }}
                        />
                        <ListItemText primary={role.option_display} />
                      </MenuItem>
                    );
                  })}
                </Select>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "5vh",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    Notes:{" "}
                    <TextField
                      sx={styles}
                      label="Notes"
                      multiline
                      rows={7}
                      value={modalInfo.notes}
                      onChange={(e) => {
                        setModalInfo({ ...modalInfo, notes: e.target.value });
                      }}
                    >
                      {modalInfo.notes}
                    </TextField>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "80%",
                    }}
                  >
                    Bio:{" "}
                    <TextField
                      sx={styles}
                      label="Bio"
                      multiline
                      rows={7}
                      onChange={(e) => {
                        setModalInfo({ ...modalInfo, bio: e.target.value });
                      }}
                      value={modalInfo.bio}
                    >
                      {modalInfo.bio}
                    </TextField>
                  </div>
                </div>
              </div>

              {/* <DialogContentText id="alert-dialog-description" sx={{textAlign:'center', fontSize:'.75rem'}}>
            
          </DialogContentText> */}
            </DialogContent>
            <DialogActions sx={{ backgroundColor: "#000120" }}>
              <Dialog open={confirm} onClose={() => setConfirm(false)}>
                <DialogContent>
                  Delete {modalInfo.firstname} {modalInfo.lastname}?
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setConfirm(false);
                    }}
                  >
                    Go Back
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    endIcon={<DeleteIcon />}
                    onClick={() => {
                      if (modalInfo.id) {
                        deleteData(
                          `https://youngstartup.io/api/db/attendees/remove/${modalInfo.id}`
                        )
                          .then((data) => console.log(data))
                          .then(() =>
                            getData(
                              `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                            ).then((data) => {
                              setBackupData(data);
                              setData(filterData(filterObj, data));
                              setConfirm(false);
                              handleClose();
                            })
                          );
                      }
                    }}
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
              <Button
                variant="contained"
                color="warning"
                endIcon={<DeleteIcon />}
                onClick={() => {
                  // console.log(modalInfo.id);
                  setConfirm(true);
                }}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color={btnColor}
                endIcon={
                  saveText == "Saved!" ? (
                    <CheckCircleOutlineIcon />
                  ) : (
                    <SaveIcon />
                  )
                }
                onClick={() => {
                  setSaveText("Saving...");
                  let body;
                  modalInfo.id
                    ? (body = {
                      body: {
                        id: modalInfo.id,
                        vc_invited_stage: modalInfo.vc_invited_stage,
                        attendee_tag: modalInfo.attendee_tag,
                        firstname: modalInfo.firstname,
                        lastname: modalInfo.lastname,
                        company: modalInfo.company,
                        jobtitle: modalInfo.jobtitle,
                        website: modalInfo.website,
                        source: modalInfo.source,
                        mobilephone: modalInfo.mobilephone,
                        email: modalInfo.email,
                        linkedin_uid: modalInfo.linkedin_uid,
                        vc_investment_location_s_:
                          modalInfo.vc_investment_location_s_,
                        // judging_track_contact:
                        //   modalInfo.judging_track_contact,
                        // judging_track_2_contact:
                        //   modalInfo.judging_track_2_contact,
                        final_judging_track_placement_contact:
                          modalInfo.final_judging_track_placement_contact,
                        panel_choice_1_contact:
                          modalInfo.panel_choice_1_contact,
                        panel_choice_2_contact:
                          modalInfo.panel_choice_2_contact,
                        panel_choice_3_contact:
                          modalInfo.panel_choice_3_contact,
                        speaker_panel_contact:
                          modalInfo.speaker_panel_contact,
                        // coaching_track_contact:
                        //   modalInfo.coaching_track_contact,
                        // coaching_track_2_contact:
                        //   modalInfo.coaching_track_2_contact,
                        final_coaching_track_placement_contact:
                          modalInfo.final_coaching_track_placement_contact,
                        notes: modalInfo.notes,
                        speaker_display: modalInfo.speaker_display,
                        bio: modalInfo.bio,
                        industry_custom:
                          modalInfo.industry_custom,
                        // vc_s_industry_focuses:
                        //   modalInfo.vc_s_industry_focuses,
                        speaker_role: modalInfo.speaker_role,
                        speaker_role_round: modalInfo.speaker_role_round,
                      },
                    })
                    : (body = {
                      body: {
                        attendee_tag: modalInfo.attendee_tag,
                        firstname: modalInfo.firstname,
                        lastname: modalInfo.lastname,
                        company: modalInfo.company,
                        vc_invited_stage: modalInfo.vc_invited_stage,
                        jobtitle: modalInfo.jobtitle,
                        source: modalInfo.source,
                        website: modalInfo.website,
                        mobilephone: modalInfo.mobilephone,
                        email: modalInfo.email,
                        linkedin_uid: modalInfo.linkedin_uid,
                        vc_investment_location_s_:
                          modalInfo.vc_investment_location_s_,
                        // judging_track_contact:
                        //   modalInfo.judging_track_contact,
                        // judging_track_2_contact:
                        //   modalInfo.judging_track_2_contact,
                        final_judging_track_placement_contact:
                          modalInfo.final_judging_track_placement_contact,
                        panel_choice_1_contact:
                          modalInfo.panel_choice_1_contact,
                        panel_choice_2_contact:
                          modalInfo.panel_choice_2_contact,
                        panel_choice_3_contact:
                          modalInfo.panel_choice_3_contact,
                        speaker_panel_contact:
                          modalInfo.speaker_panel_contact,
                        // coaching_track_contact:
                        //   modalInfo.coaching_track_contact,
                        // coaching_track_2_contact:
                        //   modalInfo.coaching_track_2_contact,
                        final_coaching_track_placement_contact:
                          modalInfo.final_coaching_track_placement_contact,
                        notes: modalInfo.notes,
                        speaker_display: modalInfo.speaker_display,
                        bio: modalInfo.bio,
                        industry_custom:
                          modalInfo.industry_custom,
                        // vc_s_industry_focuses:
                        //   modalInfo.vc_s_industry_focuses,
                        speaker_role: modalInfo.speaker_role,
                        speaker_role_round: modalInfo.speaker_role_round,
                      },
                    });
                  // console.log(body);

                  modalInfo.id
                    ? postData(
                      "https://youngstartup.io/api/db/attendees/add-or-update",
                      body
                    )
                      .then((data) => console.log(data))
                      .then(() =>
                        getData(
                          `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                        ).then((data) => {
                          setBackupData(data);
                          setData(filterData(filterObj, data));
                        })
                      )
                      .then(() => {
                        getData(
                          `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
                        ).then((data) => {
                          // console.log(data);
                          setSpeakerStats(data);
                          // console.log(speakerStats);
                        });
                      })
                    : createAttendee(
                      "https://youngstartup.io/api/db/attendees/add",
                      body
                    )
                      .then((data) => console.log(data))
                      .then(() =>
                        getData(
                          `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                        ).then((data) => {
                          setBackupData(data);
                          setData(filterData(filterObj, data));
                        })
                      )
                      .then(() => {
                        getData(
                          `https://youngstartup.io/api/db-a/attendees/getspeakerstats`
                        ).then((data) => {
                          // console.log(data);
                          setSpeakerStats(data);
                          // console.log(speakerStats);
                        });
                      });

                  let headshotBody = {
                    body: {
                      id: modalInfo.id,
                      headshot: modalInfo.headshot,
                    },
                  };
                  // console.log(headshotBody);
                  // console.log(modalInfo.headshot);
                  if (modalInfo.headshot) {
                    fetch(
                      "https://youngstartup.io/api/db-a/attendees/uploadspeakerheadshot",
                      {
                        method: "POST",
                        mode: "cors",
                        headers: {
                          "Content-Type": "application/json",
                          tok: localStorage.tok,
                        },
                        redirect: "follow",
                        referrerPolicy: "no-referrer",
                        body: JSON.stringify(headshotBody),
                      }
                    )
                      .then((data) => console.log(data))
                      .then(() => {
                        setSaveText("Saved!");
                        setBtnColor("success");
                        setTimeout(() => {
                          setBtnColor("primary");
                          setSaveText("Save");
                        }, 2000);
                        // }, 4000);
                      });
                  } else {
                    setSaveText("Saved!");
                    setBtnColor("success");
                    setTimeout(() => {
                      setBtnColor("primary");
                      setSaveText("Save");
                    }, 2000);
                    // }, 4000);
                  }
                }}
              >
                {saveText}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              // width: "57.5%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "space-around",
              color: "white",
              fontSize: "1.2rem",
              gap: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1.75%",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div>
                <span
                  style={{
                    color: speakerStats.general_stats?.displayed?.stat < speakerStats.general_stats?.displayed?.init
                      ? "red"
                      : "#F0AD4E"
                  }}
                >{speakerStats.general_stats?.displayed?.stat}</span>
                <span> of </span>
                <span style={{ color: "#F0AD4E" }}>{speakerStats.general_stats?.displayed?.init}</span>
                <span> Displayed</span>
              </div>

              <div style={{ marginLeft: "5%" }}>Filters:</div>
              <button
                className="filterBtn"
                style={{
                  color: filterObj.speaker_display ? "#f0ad4e" : "white",
                }}
                onClick={(e) => {
                  anchorEl == null
                    ? setAnchorEl(e.currentTarget)
                    : setAnchorEl(null);
                  setPopperContent({
                    title: "Featured",
                  });
                }}
              >
                Featured
              </button>
              <button
                className="filterBtn"
                style={{ color: filterObj.panel ? "#f0ad4e" : "white" }}
                onClick={(e) => {
                  anchorEl == null
                    ? setAnchorEl(e.currentTarget)
                    : setAnchorEl(null);
                  setPopperContent({
                    title: "Panel",
                  });
                }}
              >
                Panel
              </button>
              <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                sx={{ backgroundColor: "#000120" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <ClickAwayListener onClickAway={(e) => setAnchorEl(null)}>
                  <div
                    style={{
                      border: "1px solid grey",
                      color: "white",
                      padding: "1rem",
                      backgroundColor: "#000120",
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "200px !important",
                    }}
                  >
                    <div style={{ fontSize: ".9rem" }}>
                      {popperContent.title}
                      {["Panel", "Judge", "Coach"].includes(
                        popperContent.title
                      ) ? (
                        <span>
                          {" "}
                          &nbsp;&nbsp;&nbsp; include choices:{" "}
                          <Checkbox
                            checked={filterObj.include_choices}
                            style={{ backgroundColor: "white" }}
                            onChange={(e) => {
                              setFilterObj({
                                ...filterObj,
                                include_choices: e.target.checked,
                              });
                            }}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    {popperContent.title !== "VC stage" &&
                      popperContent.title !== "Featured" &&
                      (popperContent.title == "Panel" ? (
                        <Autocomplete
                          multiple
                          sx={styles}
                          value={
                            filterObj.panel
                              ? filterObj.panel.split(";").filter((i) => i)
                              : []
                          }
                          id="checkboxes-tags-demo"
                          onChange={(e, value) => {
                            // console.log(value);
                            let filterValue =
                              value.length > 1
                                ? value.join(";")
                                : value[0]
                                  ? value[0] + ";"
                                  : "";
                            setFilterObj({ ...filterObj, panel: filterValue });
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip variant="outlined" label={option} {...getTagProps({ index })}
                                sx={{ color: "white" }}
                              />
                            ))
                          }
                          options={options.speaker_panel_contact.map(
                            (i) => i.option_value
                          )}
                          disableCloseOnSelect
                          getOptionLabel={(option) => {
                            // console.log(option);
                            return option;
                          }}
                          renderOption={(props, option, { selected }) => {
                            return (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8, color: "black", backgroundColor: "gray" }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            );
                          }}
                          style={{ width: 500 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Checkboxes"
                              placeholder="Panel"
                              autoFocus
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          multiple
                          sx={styles}
                          value={
                            popperContent.title == "Judge"
                              ? filterObj.judging_track
                                ? filterObj.judging_track
                                  .split(";")
                                  .filter((i) => i)
                                : []
                              : filterObj.coaching_track
                                ? filterObj.coaching_track
                                  .split(";")
                                  .filter((i) => i)
                                : []
                          }
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip variant="outlined" label={option} {...getTagProps({ index })}
                                sx={{ color: "white" }}
                              />
                            ))
                          }
                          id="checkboxes-tags-demo"
                          onChange={(e, value) => {
                            let filterValue =
                              value.length > 1
                                ? value.join(";")
                                : value[0]
                                  ? value[0] + ";"
                                  : "";
                            popperContent.title == "Coach"
                              ? setFilterObj({
                                ...filterObj,
                                coaching_track: filterValue,
                              })
                              : setFilterObj({
                                ...filterObj,
                                judging_track: filterValue,
                              });
                          }}
                          options={
                            popperContent.title == "Judge"
                              ?
                              judgingOptionsTemp
                                .map(
                                  // options.final_judging_track_placement_contact.map(
                                  // ? 
                                  // options.industry_custom.map(
                                  // ? 
                                  // options.judging_track_contact.map(
                                  (i) => {
                                    return i.option_value
                                  }
                                )
                              : options.coaching_track_contact.map(
                                (i) => i.option_value
                              )
                          }
                          disableCloseOnSelect
                          getOptionLabel={(option) => {
                            return option;
                          }}
                          renderOption={(props, option, { selected }) => {
                            return (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8, color: "black", backgroundColor: "gray" }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            );
                          }}
                          style={{ width: 500 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Checkboxes"
                              autoFocus
                              placeholder={
                                popperContent.title == "Judge"
                                  ? "Judge"
                                  : "Coach"
                              }
                            />
                          )}
                        />
                      ))}
                    {popperContent.title == "VC stage" && (
                      <Stack>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            checked={filterObj.applied ? true : false}
                            sx={{
                              "& .PrivateSwitchBase-input": {
                                opacity: "1 !important"
                              }
                            }}
                            onChange={(e) => {
                              const {
                                target: { checked },
                              } = e;
                              setFilterObj({
                                ...filterObj,
                                applied: checked,
                              });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <div>Applied</div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            checked={filterObj.cancelled ? true : false}
                            sx={{
                              "& .PrivateSwitchBase-input": {
                                opacity: "1 !important"
                              }
                            }}
                            onChange={(e) => {
                              const {
                                target: { checked },
                              } = e;
                              setFilterObj({
                                ...filterObj,
                                cancelled: checked,
                              });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <div>Cancelled</div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            checked={filterObj.confirmed ? true : false}
                            sx={{
                              "& .PrivateSwitchBase-input": {
                                opacity: "1 !important"
                              }
                            }}
                            onChange={(e) => {
                              const {
                                target: { checked },
                              } = e;
                              setFilterObj({
                                ...filterObj,
                                confirmed: checked,
                              });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <div>Confirmed</div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            checked={filterObj.maybe ? true : false}
                            sx={{
                              "& .PrivateSwitchBase-input": {
                                opacity: "1 !important"
                              }
                            }}
                            onChange={(e) => {
                              const {
                                target: { checked },
                              } = e;
                              setFilterObj({
                                ...filterObj,
                                maybe: checked,
                              });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <div>Maybe</div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            checked={filterObj.rejected ? true : false}
                            sx={{
                              "& .PrivateSwitchBase-input": {
                                opacity: "1 !important"
                              }
                            }}
                            onChange={(e) => {
                              const {
                                target: { checked },
                              } = e;
                              setFilterObj({ ...filterObj, rejected: checked });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          <div>Rejected</div>
                        </div>
                      </Stack>
                    )}
                    {popperContent.title == "Featured" && (
                      <Autocomplete
                        multiple
                        sx={styles}
                        value={
                          filterObj.speaker_display
                            ? filterObj.speaker_display
                              .split(";")
                              .filter((i) => i)
                            : []
                        }
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="outlined" label={option} {...getTagProps({ index })}
                              sx={{ color: "white" }}
                            />
                          ))
                        }
                        id="checkboxes-tags-demo"
                        onChange={(e, value) => {
                          // console.log(value);
                          let filterValue =
                            value.length > 1
                              ? value.join(";")
                              : value[0]
                                ? value[0] + ";"
                                : "";
                          setFilterObj({
                            ...filterObj,
                            speaker_display: filterValue,
                          });
                        }}
                        options={options.speaker_display.map(
                          (i) => i.option_display_short
                        )}
                        disableCloseOnSelect
                        getOptionLabel={(option) => {
                          // console.log(option);
                          return option;
                        }}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, color: "black" }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          );
                        }}
                        style={{ width: 500 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Checkboxes"
                            placeholder="Featured"
                            autoFocus
                          />
                        )}
                      />
                    )}
                  </div>
                </ClickAwayListener>
              </Popper>
              <button
                className="filterBtn"
                style={{ color: filterObj.judging_track ? "#f0ad4e" : "white" }}
                onClick={(e) => {
                  anchorEl == null
                    ? setAnchorEl(e.currentTarget)
                    : setAnchorEl(null);
                  setPopperContent({
                    title: "Judge",
                  });
                }}
              >
                Judge
              </button>
              <button
                className="filterBtn"
                style={{
                  color: filterObj.coaching_track ? "#f0ad4e" : "white",
                }}
                onClick={(e) => {
                  anchorEl == null
                    ? setAnchorEl(e.currentTarget)
                    : setAnchorEl(null);
                  setPopperContent({
                    title: "Coach",
                  });
                }}
              >
                Coach
              </button>
              <button
                className="filterBtn"
                style={{
                  color:
                    filterObj.cancelled || filterObj.rejected
                      ? "#f0ad4e"
                      : "white",
                }}
                onClick={(e) => {
                  anchorEl == null
                    ? setAnchorEl(e.currentTarget)
                    : setAnchorEl(null);
                  setPopperContent({
                    title: "VC stage",
                  });
                }}
              >
                VC stage
              </button>
              {/* <Divider
                orientation="vertical"
                flexItem
                sx={{ height: "75px !important" }}
              /> */}
              <button
                className="filterBtn"
                style={{
                  border: "1px solid #F0AD4E",
                  color: "#F0AD4E",
                  display: "flex",
                  flexDirection: "row",
                  whiteSpace: 'nowrap'
                }}
                onClick={(e) => {
                  let newurl =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    window.location.pathname;
                  window.history.pushState({ path: newurl }, "", newurl);
                  let temp = filterObj;
                  for (const prop in temp) {
                    temp[prop] = "";
                  }
                  setFilterObj({ ...temp, confirmed: true, applied: true, maybe: true });
                  setData(backupData);
                }}
              >
                Clear Filters
              </button>

              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                sx={{
                  ml: 5,
                  color: "white",
                  minWidth: "150px",
                  input: { color: "white" },
                  textarea: { color: "white" },
                  "&": {
                    color: "white !important",
                    backgroundColor: "#303265",
                    borderRadius: "5px",
                  },
                  "& label": {
                    color: "lightgray",
                    zIndex: '1100'
                  },
                  "& label.Mui-focused": {
                    color: "lightgray",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "white",
                    color: "white",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "green",
                    color: "white",
                  },
                  "& .MuiInputBase-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                      color: "white",
                    },
                  },
                }}
                value={filterObj?.q ? filterObj.q : ""}

                onChange={(e) => {
                  const { target: { value }, } = e;
                  // const {
                  //   target: { value },
                  // } = e;
                  if (filterObj) {
                    setFilterObj((filterObj) => ({ ...filterObj, q: value }));
                  }
                }}
              />

              {/*<Tooltip title="Add Speaker">
                <button
                  className="filterBtn"
                  aria-label="add"
                  onClick={() => {
                    setModalInfo({
                      speaker_display: "",
                      source: "speakersj",
                      headshot_url: "assets/placeholder.jpeg",
                      headshot: "",
                      attendee_tag: "",
                      firstname: "",
                      lastname: "",
                      vc_invited_stage: "Confirmed",
                      jobtitle: "",
                      website: "",
                      mobilephone: "",
                      email: "",
                      linkedin_uid: "",
                      company: "",
                      vc_investment_location_s_: "",
                      industry_custom: "",
                      // vc_s_industry_focuses: "",
                      judging_track_contact: "",
                      judging_track_2_contact: "",
                      final_judging_track_placement_contact: "",
                      panel_choice_1_contact: "",
                      panel_choice_2_contact: "",
                      panel_choice_3_contact: "",
                      speaker_panel_contact: "",
                      coaching_track_contact: "",
                      coaching_track_2_contact: "",
                      final_coaching_track_placement_contact: "",
                      notes: "",
                      bio: "",
                      speaker_role: "",
                      speaker_role_round: "",
                    });
                    setOpen(true);
                  }}
                >
                  <AddIcon />
                </button>
              </Tooltip>
              <Tooltip title="Download as CSV">
                <button
                  className="filterBtn"
                  onClick={(e) => {
                    let xls = new XlsExport(data, "exprtedtable");
                    // xls.exportToCSV("speakers_judges.csv");
                    xls.exportToXLS("speakers_judges.xls");
                  }}
                >
                  <FileDownloadIcon />
                </button>
              </Tooltip>
              <Tooltip title="Refresh Table">
                <button
                  className="filterBtn"
                  onClick={() => {
                    getData(
                      `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                    ).then((data) => {
                      setBackupData(data);
                      setData(filterData(filterObj, data));
                    });
                  }}
                >
                  <RefreshIcon />
                </button>
              </Tooltip>
              <Dialog open={confirmMulti}>
                <DialogContent>Delete the selected values?</DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setConfirmMulti(false)}
                  >
                    Go back
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    endIcon={<DeleteForeverIcon />}
                    onClick={() => {
                      let body = {
                        body: {
                          ids: selectionModel,
                        },
                      };

                      deleteMultiAttendee(
                        `https://youngstartup.io/api/db/attendees/removemulti`,
                        body
                      )
                        .then((data) => data)
                        .then(() =>
                          getData(
                            `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                          ).then((data) => {
                            setBackupData(data);
                            setData(filterData(filterObj, data));
                            setConfirmMulti(false);
                          })
                        );
                    }}
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
              {selectionModel.length > 0 && (
                <Tooltip title="Delete Selected">
                  <Fab
                    color="secondary"
                    onClick={() => {
                      setConfirmMulti(true);
                    }}
                  >
                    <DeleteForeverIcon />
                  </Fab>
                </Tooltip>
              )}
              <a
                className="filterBtn"
                style={{ display: "flex", alignItems: "center" }}
                href="https://youngstartup.com/invited-judge-submission/"
              >
                <span>Judge Invite</span>
              </a>
              <a
                className="filterBtn"
                style={{ display: "flex", alignItems: "center" }}
                href="https://youngstartup.com/invited-speaker-submission/"
              >
                <span>Speaker Invite</span>
              </a>
              <Select
                sx={{ border: "1px solid white", color: "white" }}
                defaultValue={"Send Email"}
                onChange={(e) => {
                  setEmailValue(e.target.value);
                }}
              >
                <MenuItem
                  disabled
                  value="Send Email"
                  style={{ fontStyle: "italic" }}
                >
                  Send Email
                </MenuItem>
                {options.emails_sent?.map((option, i) => {
                  return (
                    <MenuItem
                      key={i}
                      onClick={(e) => {
                        handleClickOpen2();
                        setEmailName(option.option_display);
                      }}
                      value={option.option_value}
                    >
                      {option.option_display}
                    </MenuItem>
                  );
                })}
              </Select> */}

              {/* <button
                className="filterBtn"
                style={{ display: "flex", alignItems: "center" }}
                onClick={handleOpenStats}
              >
                Open Stats
              </button>
              <StatsPopup openStats={openStats} setOpenStats={setOpenStats} handleCloseStats={handleCloseStats} /> */}

            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-around",
                gap: "15px",
                padding: "0% 5%",
              }}
            >
              {/* <table
                style={{
                  fontSize: "1rem",
                  boxShadow: "none",
                  width: "40%",
                }}
              >
                <tr
                  style={{
                    height: "fit-content !important",
                    borderBottom: "none !important",
                  }}
                >
                  <td
                    style={{
                      color:
                        speakerStats.general_stats?.displayed?.stat < speakerStats.general_stats?.displayed?.init
                          ? "red"
                          : "#F0AD4E",
                      border: "none",
                    }}
                  >
                    {
                      speakerStats.general_stats?.displayed?.stat
                    }
                  </td>{" "}
                  <td>of</td>{" "}
                  <td style={{ color: "#F0AD4E" }}>{speakerStats.general_stats?.displayed?.init}</td>{" "}
                  <td>Displayed</td>
                </tr>
                <tr
                  style={{
                    height: "fit-content !important",
                    borderBottom: "none !important",
                  }}
                >
                  <td
                    style={{
                      color:
                        speakerStats.general_stats?.speakers?.stat < speakerStats.general_stats?.speakers?.init
                          ? "red"
                          : "#F0AD4E",
                      border: "none",
                    }}
                  >
                    {
                      speakerStats.general_stats?.speakers?.stat
                    }
                  </td>{" "}
                  <td>of</td>{" "}
                  <td style={{ color: "#F0AD4E" }}>{speakerStats.general_stats?.speakers?.init}</td>{" "}
                  <td>Speakers</td>
                </tr>
                <tr>
                  <td
                    style={{
                      color:
                        speakerStats.general_stats?.judges?.stat < speakerStats.general_stats?.judges?.init
                          ? "red"
                          : "#F0AD4E",
                    }}
                  >
                    {
                      speakerStats.general_stats?.judges?.stat
                    }
                  </td>{" "}
                  <td>of</td>{" "}
                  <td style={{ color: "#F0AD4E" }}>{speakerStats.general_stats?.judges?.init}</td>{" "}
                  <td>Judges</td>
                </tr>
                <tr>
                  <td
                    style={{
                      color:
                        speakerStats.general_stats?.coaches?.stat < speakerStats.general_stats?.coaches?.init
                          ? "red"
                          : "#F0AD4E",
                    }}
                  >
                    {
                      speakerStats.general_stats?.coaches?.stat
                    }
                  </td>{" "}
                  <td>of</td>{" "}
                  <td style={{ color: "#F0AD4E" }}>{speakerStats.general_stats?.coaches?.init}</td>{" "}
                  <td>Coaches</td>
                </tr>
                <tr>
                  <td
                    style={{
                      color:
                        speakerStats.general_stats?.pending_display?.stat < speakerStats.general_stats?.pending_display?.init
                          ? "red"
                          : "#F0AD4E",
                    }}
                  >
                    {
                      speakerStats.general_stats?.pending_display?.stat
                    }
                  </td>{" "}
                  <td>of</td>{" "}
                  <td style={{ color: "#F0AD4E" }}>{speakerStats.general_stats?.pending_display?.init}</td>{" "}
                  <td>Pending</td>
                </tr>
              </table> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "15px",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Add Speaker">
                  <button
                    className="filterBtn"
                    aria-label="add"
                    onClick={() => {
                      setModalInfo({
                        speaker_display: "",
                        source: "speakersj",
                        headshot_url: "assets/placeholder.jpeg",
                        headshot: "",
                        attendee_tag: "",
                        firstname: "",
                        lastname: "",
                        vc_invited_stage: "Confirmed",
                        jobtitle: "",
                        website: "",
                        mobilephone: "",
                        email: "",
                        linkedin_uid: "",
                        company: "",
                        vc_investment_location_s_: "",
                        industry_custom: "",
                        // vc_s_industry_focuses: "",
                        judging_track_contact: "",
                        judging_track_2_contact: "",
                        final_judging_track_placement_contact: "",
                        panel_choice_1_contact: "",
                        panel_choice_2_contact: "",
                        panel_choice_3_contact: "",
                        speaker_panel_contact: "",
                        coaching_track_contact: "",
                        coaching_track_2_contact: "",
                        final_coaching_track_placement_contact: "",
                        notes: "",
                        bio: "",
                        speaker_role: "",
                        speaker_role_round: "",
                      });
                      setOpen(true);
                    }}
                  >
                    <AddIcon />
                  </button>
                </Tooltip>
                <Tooltip title="Download as CSV">
                  <button
                    className="filterBtn"
                    onClick={(e) => {
                      let xls = new XlsExport(data, "exprtedtable");
                      // xls.exportToCSV("speakers_judges.csv");
                      xls.exportToXLS("speakers_judges.xls");
                    }}
                  >
                    <FileDownloadIcon />
                  </button>
                </Tooltip>
                <Tooltip title="Refresh Table">
                  <button
                    className="filterBtn"
                    onClick={() => {
                      getData(
                        `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                      ).then((data) => {
                        setBackupData(data);
                        setData(filterData(filterObj, data));
                      });
                    }}
                  >
                    <RefreshIcon />
                  </button>
                </Tooltip>
                <Dialog open={confirmMulti}>
                  <DialogContent>Delete the selected values?</DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setConfirmMulti(false)}
                    >
                      Go back
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      endIcon={<DeleteForeverIcon />}
                      onClick={() => {
                        let body = {
                          body: {
                            ids: selectionModel,
                          },
                        };

                        deleteMultiAttendee(
                          `https://youngstartup.io/api/db/attendees/removemulti`,
                          body
                        )
                          .then((data) => data)
                          .then(() =>
                            getData(
                              `https://youngstartup.io/api/db/attendees/all?filterby=source&val=speakersj`
                            ).then((data) => {
                              setBackupData(data);
                              setData(filterData(filterObj, data));
                              setConfirmMulti(false);
                            })
                          );
                      }}
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
                {selectionModel.length > 0 && (
                  <Tooltip title="Delete Selected">
                    <Fab
                      color="secondary"
                      onClick={() => {
                        setConfirmMulti(true);
                      }}
                    >
                      <DeleteForeverIcon />
                    </Fab>
                  </Tooltip>
                )}
                <a
                  className="filterBtn"
                  style={{ display: "flex", alignItems: "center" }}
                  href="https://youngstartup.com/invited-judge-submission/"
                >
                  <span>Judge Invite</span>
                </a>
                <a
                  className="filterBtn"
                  style={{ display: "flex", alignItems: "center" }}
                  href="https://youngstartup.com/invited-speaker-submission/"
                >
                  <span>Speaker Invite</span>
                </a>
                <Select
                  sx={{ border: "1px solid white", color: "white" }}
                  defaultValue={"Send Email"}
                  onChange={(e) => {
                    setEmailValue(e.target.value);
                  }}
                >
                  <MenuItem
                    disabled
                    value="Send Email"
                    style={{ fontStyle: "italic" }}
                  >
                    Send Email
                  </MenuItem>
                  {options.emails_sent?.map((option, i) => {
                    return (
                      <MenuItem
                        key={i}
                        onClick={(e) => {
                          handleClickOpen2();
                          setEmailName(option.option_display);
                        }}
                        value={option.option_value}
                      >
                        {option.option_display}
                      </MenuItem>
                    );
                  })}
                </Select>
                <button
                  className="filterBtn"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={handleOpenStats}
                >
                  Open Stats
                </button>
                {openStats && <StatsPopup openStats={openStats} setOpenStats={setOpenStats} handleCloseStats={handleCloseStats} />}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "45%",
              display: "flex",
              gap: "15px",
              margin: ".5%",
              justifyContent: "center",
              padding: ".5%",
            }}
          >
            <div
              style={{
                width: "fit-content",
                backgroundColor: "#303265",
                display: "flex",
                gap: "15px",
                margin: ".5%",
                justifyContent: "center",
                padding: "1%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "white",
                }}
                onClick={(e) => {
                  if (currentStat == statOptions[0]) {
                    setCurrentStat(statOptions[1]);
                  } else if (currentStat == statOptions[1]) {
                    // setCurrentStat(statOptions[2]);
                    setCurrentStat(statOptions[0]);
                  }
                  // else if (currentStat == statOptions[2]) {
                  //   setCurrentStat(statOptions[0]);
                  // }
                }}
              >
                <div style={{ color: "#F0AD4E", fontSize: ".8rem" }}>
                  Speaking Panels
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    border: "1px solid white",
                    borderRight: "none",
                    borderLeft: "none",
                    fontSize: ".7rem",
                  }}
                  onClick={(e) => {
                    if (currentStat == statOptions[0]) {
                      setCurrentStat(statOptions[1]);
                    } else if (currentStat == statOptions[1]) {
                      setCurrentStat(statOptions[0]);
                      // setCurrentStat(statOptions[2]);
                    }
                    // else if (currentStat == statOptions[2]) {
                    //   setCurrentStat(statOptions[0]);
                    // }
                  }}
                >
                  {speakerStats.panels.sort().map((panel, i) => (
                    <span
                      key={i}
                      style={{
                        width: "100%",
                        borderBottom:
                          i === speakerStats.panels.length - 1
                            ? ""
                            : "1px solid white",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{panel.name}</span>{" "}
                      <span
                        style={{
                          marginRight: "7.5px",
                          marginLeft: "15px",
                          color:
                            (currentStat == "value" && "#E8AD4E") ||
                            (currentStat == "confirmed" && "lightgreen") ||
                            (currentStat == "pending" && "yellow"),
                        }}
                      >
                        {currentStat == "value" && panel.value}
                        {currentStat == "confirmed" && panel.confirmed}
                        {currentStat == "pending" && panel.pending}
                      </span>
                    </span>
                  ))}
                </div>
              </div>
              <br />
              <Stack sx={{ color: "white" }}>
                <div style={{ color: "#E8AD4E", fontSize: ".8rem" }}>
                  Judging Panels
                </div>
                <div
                  style={{
                    borderTop: "1px solid white",
                    borderBottom: "1px solid white",
                    fontSize: ".7rem",
                  }}
                >
                  {speakerStats.judging_tracks.sort().map((panel, i) => (
                    <span
                      key={i}
                      style={{
                        width: "100%",
                        borderBottom:
                          i === speakerStats.judging_tracks.length - 1
                            ? ""
                            : "1px solid white",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{panel.name}</span>{" "}
                      <span
                        style={{
                          marginLeft: "7.5px",
                          color:
                            (currentStat == "value" && "#E8AD4E") ||
                            (currentStat == "confirmed" && "lightgreen") ||
                            (currentStat == "pending" && "yellow"),
                        }}
                      >
                        {currentStat == "value" && panel.value}
                        {currentStat == "confirmed" && panel.confirmed}
                        {currentStat == "pending" && panel.pending}
                      </span>
                    </span>
                  ))}
                </div>
              </Stack>

              <Stack sx={{ color: "white" }}>
                <div style={{ color: "#E8AD4E", fontSize: ".8rem" }}>
                  Coaching Tracks
                </div>
                <div
                  style={{
                    borderTop: "1px solid white",
                    borderBottom: "1px solid white",
                    fontSize: ".7rem",
                  }}
                >
                  {speakerStats.coaching_tracks.sort().map((panel, i) => (
                    <span
                      key={i}
                      style={{
                        width: "100%",
                        borderBottom:
                          i === speakerStats.coaching_tracks.length - 1
                            ? ""
                            : "1px solid white",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{panel.name}</span>{" "}
                      <span
                        style={{
                          marginLeft: "7.5px",
                          color:
                            (currentStat == "value" && "#E8AD4E") ||
                            (currentStat == "confirmed" && "lightgreen") ||
                            (currentStat == "pending" && "yellow"),
                        }}
                      >
                        {currentStat == "value" && panel.value}
                        {currentStat == "confirmed" && panel.confirmed}
                        {currentStat == "pending" && panel.pending}
                      </span>
                    </span>
                  ))}
                </div>
              </Stack>
            </div>
          </div>

        </div>

        <DataGrid
          checkboxSelection
          disableSelectionOnClick
          rows={rows}
          columns={columns}
          rowHeight={100}
          components={{
            ColumnSortedDescendingIcon: SortedDescendingIcon,
            ColumnSortedAscendingIcon: SortedAscendingIcon,
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          sx={{
            color: "white !important",
            border: "none",
            backgroundColor: "#000120",
            fontFamily: "Manrope, sans-serif",
            "& .MuiDataGrid-footerContainer, .MuiSelect-select, .MuiSvgIcon-root": {
              color: "white"
            }
          }}
        />
      </div>
    </div >
  );
}
