import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { prettyDOM } from "@testing-library/react";
import { ContactMail } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
export const FilterModal = (props) => {
  const [newArr, setNewArr] = useState([]);

  // console.log(props.finalFilters);
  let myMap = props.finalFilters.map((filter, i) => {
    let id = filter.id;
    let filters = filter.filters;
    let myObj = {};
    myObj.name = id;
    myObj.values = filters;
    // console.log(myObj);

    newArr.push(myObj);
  });

  // console.log(newArr);

  let result = newArr.reduce(function (r, a) {
    r[a.name] = r[a.name] || [];
    r[a.name].push(a);
    return r;
  }, Object.create(null));

  // console.log(result);

  //WRITE FUNCTION TO FILTER DUPLICATE OBJECTS FROM newArr (AND KEEP ONLY NEWEST), THEN SEND POST REQUEST AND ZEHU
  //THIS FUNCTION IS WORKING WHEN USED WITH TEST ARR, BUT NOT WITH THE ACTUAL STATE

  return (
    <div
      style={{
        width: "fit-content",
        display: props.showFilterModal,
        backgroundColor: "white",
        position: "fixed",
        zIndex: "1100",
        margin: "auto",
        textAlign: "center",
        padding: "2rem",
        left:"25%",
        borderRadius: "5px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span style={{ textAlign: "center", marginRight: "-10.5%", fontWeight:'900' }}>
          Filters 
        </span>
        <span style={{ fontWeight:'900'}}>Applied</span>
        <CloseIcon onClick={() => props.setShowFilterModal("none")} />
      </div>
      <div
        style={{ color: "black" }}
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${props.finalFilters.length}, 1fr)`,
          gridTemplateRows: "1fr",
          gridColumnGap: "5px",
          gridRowGap: "0px",
        }}
      >
        {props.finalFilters.map((item, i) => (
          <div key={i} style={{ textAlign: "center" }}>
            <span>{item.name}</span>
            <div>
              {item.filters.map((filter, i) => (
                <div key={i}><span>{filter}</span><span><DeleteIcon onClick={()=>console.log('Button clicked!')} /></span></div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Button
        onClick={() => {
          let finalArr = [];
          let dataArr = Object.entries(result);
          // console.log(dataArr)

          for(let i = 0; i < dataArr.length; i++){
          let max = dataArr[i][1].reduce(function(prev, current) {
            return (prev.values > current.values) ? prev : current
          }) 
              finalArr.push(max)
          }
          // console.log(finalArr)
          let bodyArr = [];
          let bodyObj = {};
          for(let i = 0; i < finalArr.length; i++){
            bodyObj[finalArr[i].name] = finalArr[i].values
          }
          let body = {body: bodyObj}
          // console.log(body)
          props.postData(`https://youngstartup.io/api/db/attendees/filter`, body)
          .then(data => props.setDataArr(data))
        }}
      >
        Apply Filters
      </Button>
    </div>
  );
};
