import { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import XlsExport from "xlsexport";

import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RefreshIcon from "@mui/icons-material/Refresh";
import SendIcon from "@mui/icons-material/Send";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import { DataGrid } from "@mui/x-data-grid";

import { MuiAppBar } from "../components/index";
import CurrentEventContext from "../utils/current_event_context";
import { createAttendee, deleteData, getData, patchData } from "../api/apiCalls";
import { addOrUpdateQueryParam, getQueryParameterByName } from "../utils/funcs";

export function Attendees() {
  let currentEvent = useContext(CurrentEventContext).currentEvent;

  // states
  const [data, setData] = useState([]);
  const [backupData, setBackupData] = useState([]);
  const [options, setOptions] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [popoverStatus, setPopoverStatus] = useState({ sendEmail: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterObj, setFilterObj] = useState({});
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [emailValue, setEmailValue] = useState(null);
  const [emailName, setEmailName] = useState(null);
  const [attendeeInputs, setAttendeeInputs] = useState({ source: "other" });
  const [currentUser, setCurrentUser] = useState({});
  const [contactData, setContactData] = useState();
  const [addTicketData, setAddTicketData] = useState();

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleContact = (email) => {
    setOpen3(true);

    getData(`https://youngstartup.io/api/db/attendees-by-event-email/${currentEvent}/${email}`)
      .then(res => {
        // console.log("res", res)
        const contactsArr = res.map(x => {
          const { id, firstname, lastname, jobtitle, email, source, paid_amount } = x;
          const ticket_type = x.ticket_type ? JSON.parse(x.ticket_type) : null;
          return { id, firstname, lastname, jobtitle, email, ticket_type, source, paid_amount }
        })

        // console.log("contactsArr", contactsArr)
        const contacts = {};
        contacts.registrants = contactsArr.find(x => x.source === "registrants");
        contacts.extra_passes = contactsArr.find(x => x.source === "extra_passes");
        const presenters1 = contactsArr.find(x => x.source === "presenters");
        const presenters2 = contactsArr.find(x => x.source === "speakersj");
        contacts.presenters = presenters1 || presenters2;

        setContactData(contacts);
      })
  }

  const handleCloseContact = () => {
    setOpen3(false);
    setContactData();
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleDelete = (id) => {
    const myPromise = deleteData("https://youngstartup.io/api/db/attendees/remove/" + id)
      .then(res => {
        // console.log(res);
        if (res.success) { handleCloseContact() };
        return res
      });
    toast.promise(
      myPromise,
      {
        loading: "Loading",
        success: (data) => `${data.success ? data.message : "An error occured"}`,
        error: (err) => `${err.toString()}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
          icon: data ? '✅' : '❌',
        },
      }
    );
  }

  // use effects & initial calls

  useEffect(() => {
    getData('https://youngstartup.io/api/db/users/current')
      .then(data => setCurrentUser(data))
      .then(() => console.log(currentUser))

    getData(
      `https://youngstartup.io/api/db-info/property-options-dd/all_attendees`
    ).then((data) => {
      setOptions(data);
    });

  }, []);

  useEffect(() => {
    getData(`https://youngstartup.io/api/db/attendees/all?no_dup=true`)
      .then(
        (data) => {
          setBackupData(data);
          setData(data);
        }
      )
      .then(() => updateFiltersfromQuery())
  }, [refresh]);

  // handle the inline edits
  const handleCellFocusOut = (cell, event) => {
    // collect row data
    if (!cell.row) {
      [cell.row] = data.filter((i) => i.id == cell.id);
    }
    // check if any edits were made
    if (cell.row[cell.field] != cell.value) {
      let obj = { id: cell.row.id };
      obj[cell.field] = cell.value;

      try {
        // patchData("https://youngstartup.io/api/db/attendees/add-or-update", {
        patchData("https://youngstartup.io/api/db/attendees/update", {
          body: obj,
        }).then((data) => {
          if (data.success == true) {
            toast.success("Successfully updated!");
          } else {
            toast.error("Error while updateing.");
          }
          setRefresh((old) => old + 1);
        });
      } catch (err) {
        console.error(err);
        toast.error("Error while updateing.");
      }
    }
  };


  // filter section

  useEffect(() => {
    setData(filterData(filterObj));
  }, [filterObj]);

  const filterProperties = [
    "email",
    "firstname",
    "lastname",
    "company",
    "notes",
    "email_confirmed",
    "opened_dashboard",
    "submission_status",
    "emails_sent",
    "ticket_type",
    // "ticket_type_bridge",
    "promo_code",
    "brella_code",
    "status",
    "hs_id",
  ];

  const filterRows = (
    val,
    props = filterProperties,
    localData = backupData,
    operator = "contains",
    multiOptionOperator = "OR"
  ) => {
    val = val.trim();
    let valArr = [val];
    if (val.includes(";")) {
      valArr = val.split(";");
    } else if (val.includes(" ")) {
      valArr = val.split(" ");
    }
    let res = [];
    valArr.map((searchItem) => {
      searchItem = searchItem.toLowerCase(); //.replace(" ", "")
      let temp = localData.filter((item) => {
        let flag = false;
        for (let i = 0; i < props.length; i++) {
          if (typeof item[props[i]] !== "string" && Array.isArray(item[props[i]]) && item[props[i]].includes(Number(searchItem))) {
            flag = true;
            break;
          }
          if (
            searchItem &&
            item[props[i]]
            && typeof item[props[i]] === "string"
            && (item[props[i]].toLowerCase() == searchItem ||
              (operator == "contains" &&
                (item[props[i]].toLowerCase().includes(searchItem) ||
                  item[props[i]]
                    .toLowerCase()
                    .replace(" ", "")
                    .includes(searchItem))))
          ) {
            flag = true;
            break;
          }
          if (
            searchItem &&
            item[props[i]]
            && typeof item[props[i]] === "number"
            && (item[props[i]] == searchItem ||
              (operator == "contains" &&
                (String(item[props[i]]).includes(searchItem))))
          ) {
            flag = true;
            break;
          }
        }
        return flag;
      });
      if (multiOptionOperator === "AND") {
        localData = temp;
        res = temp;
        return;
      }
      res = [...res, ...temp];
    });
    return [...new Set(res)];
  };

  const filterData = (filtersObj, data = backupData) => {
    let tempData = data;

    if (
      filterObj.final_presenting_track &&
      filterObj.final_presenting_track != false &&
      typeof filterObj.final_presenting_track === "string"
    ) {
      tempData = filterRows(
        filterObj.final_presenting_track,
        ["final_presenting_track"],
        tempData,
        "="
      );
    }
    if (
      filterObj.submission_status &&
      filterObj.submission_status != false &&
      typeof filterObj.submission_status === "string"
    ) {
      tempData = filterRows(
        filterObj.submission_status,
        ["submission_status"],
        tempData,
        "="
      );
    }
    if (
      filterObj.emails_sent &&
      filterObj.emails_sent != false &&
      typeof filterObj.emails_sent === "string"
    ) {
      tempData = filterRows(
        filterObj.emails_sent,
        ["emails_sent"],
        tempData,
        "="
      );
    }
    if (
      filterObj.q &&
      filterObj.q != false &&
      typeof filterObj.q === "string"
    ) {
      tempData = filterRows(
        filterObj.q,
        undefined,
        tempData,
        "contains",
        "AND"
      );
    }

    updateQueryParams();
    return tempData;
  };

  const updateQueryParams = (filtersObj = filterObj) => {
    for (const param in filterObj) {
      if (Object.hasOwnProperty.call(filterObj, param)) {
        if (param) {
          addOrUpdateQueryParam(String(param), filterObj[param]);
        }
      }
    }
  };

  const updateFiltersfromQuery = () => {
    let queryOptions = [
      "q",
      "final_presenting_track",
      "submission_status",
      "emails_sent",
    ];
    let temp;
    let obj = {};
    queryOptions.map((option) => {
      if ((temp = getQueryParameterByName(option))) {
        if (temp === "false") {
          temp = false;
        }
        obj[option] = temp;
      }
    });
    setFilterObj(obj);
  };
  // end of filter section


  const columns = [
    {
      field: "source",
      headerName: "Source",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (cell) => {
        let srcArr = cell.row.source?.split(";");
        return (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%" }}>
            <div>
              {srcArr?.map((i, index) => (
                <div key={index}>{i}</div>
              ))}
            </div>
            <img src="./assets/carbon_document (1).png" alt=""
              onClick={() => handleContact(cell.row.email)}
              style={{ display: "inline-block", width: "20px", height: "25px", alignSelf: "center", cursor: "pointer" }} />
          </div>
        );
      },
    },
    {
      field: "date_created",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (cell) => {
        let d = new Date(cell.row.date_created);
        return <>{d.toLocaleDateString()}</>;
      },
    },
    {
      field: "lastname",
      headerName: "Attendee",
      headerAlign: "center",
      align: "left",
      headerClassName: "super-app-theme--header",
      width: 250,
      renderCell: (cell) => (
        <>
          <div>
            <div>
              <b>
                {cell.row.firstname} {cell.row.lastname}
              </b>
            </div>
            <div>{cell.row.jobtitle}</div>
            <div className="company-link">
              <a
                href={cell.row.website}
                target="_blank"
                style={{ color: "#F0AD4E" }}
              >
                {cell.row.company}
              </a>
            </div>
          </div>
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      width: 220,
      renderCell: (cell) => (
        <a style={{ color: "white" }} href={"mailto:" + cell.row.email}>{cell.row.email}</a>
      ),
    },
    {
      field: "ticket_type",
      // field: "ticket_type_bridge",
      headerName: "Ticket",
      headerAlign: "center",
      align: "left",
      headerClassName: "super-app-theme--header",
      width: 300,
      renderCell: (cell) => {
        let data = cell.row.ticket_type;
        let tickets;
        if (data?.length) {
          let arr = JSON.parse(cell.row.ticket_type)
            .map(item => item.name)
          tickets = arr.join(", ");
        }
        return <>
          <div>
            <div>{tickets}</div>
            <div>{cell.row.paid_amount ? "$" + cell.row.paid_amount : ""}</div>
            <div>{cell.row.promo_code}</div>
          </div>
        </>
      },
    },
    {
      field: "brella_code",
      headerName: "Brella Code",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      width: 150,
      renderCell: (cell) => {
        return <div>{cell.row.brella_code}</div>
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "right",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderCell: (cell) => (
        <>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <div>
              Sent Matchmaking:{" "}
              <MuiToggle
                stat={
                  cell.row.emails_sent.includes(7) ||
                    cell.row.emails_sent.includes(5)
                    ? true
                    : false
                }
              />
            </div>
            <div>
              Sent Welcome Email:{" "}
              <MuiToggle
                stat={cell.row.emails_sent.includes(18) ? true : false}
              />
            </div>
            <div>
              Signed up to MatchM:{" "}
              <MuiToggle stat={cell.row.matchmaking_signup ? true : false} />
            </div>
          </div>
        </>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
      editable: true,
      flex: 1,
    },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const styles = {
    color: "white",
    minWidth: "fit-content",

    maxWidth: "100%",
    input: { color: "white", fontSize: ".7rem" },
    textarea: { color: "white" },
    "&": {
      color: "white !important",
      // backgroundColor: "#303265",
      borderRadius: "5px",
      minWidth: "fit-content",
    },
    "& label": {
      color: "transparent",
    },
    "& label.Mui-focused": {
      color: "transparent",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "white",
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
      color: "white",
    },
    "& .MuiInputBase-root": {
      "& fieldset": {
        borderColor: "transparent",
        color: "white",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
        // color: "white",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
        color: "white",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
        color: "white",
      },
      "& .MuiSvgIcon-root-MuiSelect-icon": {
        fill: "white",
      },
      "& .MuiPaper-root": {
        backgroundColor: "lightblue !important",
      },
    },
  };

  // const dropdownStyles = {
  //   "& .MuiSelect-outlined": {
  //     color: "white",
  //     fontSize: "10px",
  //     backgroundColor: "#303265",
  //     p: 2,
  //     mb: 5,
  //     height: "auto",
  //     display: "flex",
  //   },
  // };

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#000120 !important", color: "white" }}
        >
          add attendee
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: "#000120 !important", color: "white" }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "white" }}
          >
            <Stack spacing={2}>
              <div style={{ display: "flex", alignItems: 'center', gap: "15px" }}>
                <div style={{ color: '#B48242', flex: 1 }}>Source:</div>
                <Select required fullWidth
                  // sx={dropdownStyles} 
                  sx={{ backgroundColor: "#303265", color: "white" }}
                  defaultValue={"other"} onChange={e => setAttendeeInputs({ ...attendeeInputs, source: e.target.value })}>
                  {options.source?.map((option, i) => {
                    return <MenuItem key={i} value={option.option_value}>{option.option_display}</MenuItem>
                  })}
                </Select>
              </div>
              <div style={{ display: "flex", alignItems: 'center', gap: "15px" }}>
                <span style={{ color: '#B48242', flex: 1 }}>Ticket Type:</span>
                <Select fullWidth
                  // sx={dropdownStyles} 
                  sx={{ backgroundColor: "#303265", color: "white" }}
                  defaultValue={"Select..."}
                  onChange={e => setAttendeeInputs({
                    ...attendeeInputs, ticket_type: e.target.value
                  })}>
                  <MenuItem sx={{ fontStyle: 'italic' }} disabled value={"Select..."}>Select...</MenuItem>
                  {options.ticket_type?.map((option, i) => {
                    return <MenuItem key={i} value={option.option_value}>{option.option_display}</MenuItem>
                  })}
                </Select>
              </div>
              <div style={{ display: "flex", alignItems: 'center', gap: "15px" }}>
                <div style={{ color: '#B48242', flex: 1 }}>Name:</div>
                <TextField required fullWidth
                  sx={{
                    ...styles, backgroundColor: "#303265", borderRadius: "50px",
                  }}
                  onChange={e => setAttendeeInputs({ ...attendeeInputs, firstname: e.target.value })} />
                <TextField required fullWidth
                  sx={{
                    ...styles, backgroundColor: "#303265", borderRadius: "50px",
                  }}
                  onChange={e => setAttendeeInputs({ ...attendeeInputs, lastname: e.target.value })} />
              </div>
              <div style={{ display: "flex", alignItems: 'center', gap: "15px" }}>
                <div style={{ color: '#B48242', flex: 1 }}>Company:</div>
                <TextField fullWidth
                  sx={{
                    ...styles, backgroundColor: "#303265", borderRadius: "50px",
                  }}
                  onChange={e => setAttendeeInputs({ ...attendeeInputs, company: e.target.value })} />
              </div>
              <div style={{ display: "flex", alignItems: 'center', gap: "15px" }}>
                <div style={{ color: '#B48242', flex: 1 }}>Title:</div>
                <TextField fullWidth
                  sx={{
                    ...styles, backgroundColor: "#303265", borderRadius: "50px",
                  }}
                  onChange={e => setAttendeeInputs({ ...attendeeInputs, jobtitle: e.target.value })} />
              </div>
              <div style={{ display: "flex", alignItems: 'center', gap: "15px" }}>
                <div style={{ color: '#B48242', flex: 1 }}>Web:</div>
                <TextField fullWidth
                  sx={{
                    ...styles, backgroundColor: "#303265", borderRadius: "50px",
                  }}
                  onChange={e => setAttendeeInputs({ ...attendeeInputs, website: e.target.value })} />
              </div>
              <div style={{ display: "flex", alignItems: 'center', gap: "15px" }}>
                <div style={{ color: '#B48242', flex: 1 }}>Email:</div>
                <TextField required fullWidth
                  sx={{
                    ...styles, backgroundColor: "#303265", borderRadius: "50px",
                  }}
                  onChange={e => setAttendeeInputs({ ...attendeeInputs, email: e.target.value })} />
              </div>
              <div style={{ display: "flex", alignItems: 'center', gap: "15px" }}>
                <div style={{ color: '#B48242', flex: 1 }}>Amount Paid:</div>
                <TextField fullWidth
                  sx={{
                    ...styles, backgroundColor: "#303265", borderRadius: "50px",
                  }}
                  onChange={e => setAttendeeInputs({ ...attendeeInputs, paid_amount: e.target.value })} />
              </div>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "#000120 !important" }}>
          <Button onClick={handleClose} color="warning" variant="contained">Cancel</Button>
          <Button
            onClick={e => {
              const rawDate = new Date();
              const date = rawDate.toISOString().split('T')[0];

              const body = {
                body: {
                  ...attendeeInputs,
                  event: currentEvent,
                  ticket_type: {
                    id: "att-" + Date.now(),
                    name: attendeeInputs.ticket_type,
                    paid_amount: attendeeInputs.paid_amount,
                    date: date
                  }
                }
              }
              console.log(body)
              const myPromise = createAttendee("https://youngstartup.io/api/db/attendees/add-or-update", body)
                .then(res => {
                  // console.log(res);
                  if (res.data.success) { handleClose() };
                  return res.data
                });
              toast.promise(
                myPromise,
                {
                  loading: "Loading",
                  success: (data) => `${data.success ? data.message : "An error occured"}`,
                  error: (err) => `${err.toString()}`,
                },
                {
                  style: {
                    minWidth: "250px",
                  },
                  success: {
                    duration: 5000,
                    icon: data ? '✅' : '❌',
                  },
                }
              );
            }}
            variant="contained"
            color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open3}
        onClose={handleCloseContact}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"md"}
        PaperProps={{
          style: { border: "2px solid white", }
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#000120 !important", color: "white" }}
          style={{ display: "flex", justifyContent: "space-between", paddingBottom: "8px" }}
        >
          <div>Contact</div>

          <DialogActions sx={{ backgroundColor: "#000120 !important" }}>
            <img src="./assets/Close.png" alt="" width="25px" onClick={handleCloseContact}
              style={{ cursor: "pointer" }} />
            &nbsp;
            <img src="./assets/Save Button.png" alt="" width="25px"
              style={{ cursor: "pointer" }}
              onClick={e => {
                const arr = [];
                for (const person in contactData) {
                  if (!contactData[person]?.id) {
                    continue;
                  }
                  console.log(person);
                  delete contactData[person]?.ticket_type;
                  arr.push(contactData[person])
                }
                let body = {
                  body: {
                    data: arr
                  }
                }
                console.log(body)
                const myPromise = createAttendee("https://youngstartup.io/api/db/attendees/add-or-update-multi", body)
                  .then(res => {
                    // console.log(res);
                    if (res[0].data.success) { handleCloseContact() };
                    return res[0].data
                  });
                toast.promise(
                  myPromise,
                  {
                    loading: "Loading",
                    success: (data) => `${data.success ? data.message : "An error occured"}`,
                    error: (err) => `${err.toString()}`,
                  },
                  {
                    style: {
                      minWidth: "250px",
                    },
                    success: {
                      duration: 5000,
                      icon: data ? '✅' : '❌',
                    },
                  }
                );
              }}
            />
          </DialogActions>
        </DialogTitle>
        <hr style={{ border: "none", margin: ".4px 0 0 0" }} />

        <DialogContent
          sx={{ backgroundColor: "#000120 !important", color: "white" }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "white" }}
          >
            <Stack spacing={2}>
              <div>
                {contactData?.registrants &&
                  <div style={{ backgroundColor: "#303265", padding: "15px" }}>
                    <h3>Registrant</h3>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>

                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>First Name:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.registrants.firstname}
                          onChange={e => setContactData({ ...contactData, registrants: { ...contactData.registrants, firstname: e.target.value } })}
                        />
                      </div>
                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>Last Name:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.registrants.lastname}
                          onChange={e => setContactData({ ...contactData, registrants: { ...contactData.registrants, lastname: e.target.value } })}
                        />
                      </div>
                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>Job Title:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.registrants.jobtitle}
                          onChange={e => setContactData({ ...contactData, registrants: { ...contactData.registrants, jobtitle: e.target.value } })}
                        />
                      </div>
                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>Email:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.registrants.email}
                          onChange={e => setContactData({ ...contactData, registrants: { ...contactData.registrants, email: e.target.value } })}
                        />
                      </div>

                      <img src="./assets/Delete Button.png" alt=""
                        onClick={() => handleDelete(contactData.registrants.id)}
                        style={{ display: "inline-block", width: "30px", height: "30px", alignSelf: "top", cursor: "pointer" }} />
                    </div>

                    <div style={{ backgroundColor: "#000120", padding: "10px" }}>
                      <div style={{ color: '#B48242', }}>Tickets: &nbsp;
                        <img src="./assets/Add.png" alt=""
                          onClick={() => setOpen4(true)}
                          style={{ display: "inline-block", width: "20px", height: "20px", alignSelf: "center", cursor: "pointer" }} />
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {contactData.registrants.ticket_type?.map((ticket) => {
                          return <div key={ticket.id}
                            style={{ backgroundColor: "#303265", padding: "10px 20px", height: "100px", borderRadius: "15px", margin: "5px", fontSize: ".9rem" }}>
                            <div>{ticket.name}</div>
                            <div>${ticket.paid_amount}</div>
                            <div>{ticket.date}</div>
                          </div>
                        })
                        }
                      </div>

                    </div>
                  </div>
                }
                <br />

                {contactData?.extra_passes &&
                  <div style={{ backgroundColor: "#303265", padding: "15px" }}>
                    <h3>Extra Pass</h3>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>

                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>First Name:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.extra_passes.firstname}
                          onChange={e => setContactData({ ...contactData, extra_passes: { ...contactData.extra_passes, firstname: e.target.value } })}
                        />
                      </div>
                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>Last Name:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.extra_passes.lastname}
                          onChange={e => setContactData({ ...contactData, extra_passes: { ...contactData.extra_passes, lastname: e.target.value } })}
                        />
                      </div>
                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>Job Title:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.extra_passes.jobtitle}
                          onChange={e => setContactData({ ...contactData, extra_passes: { ...contactData.extra_passes, jobtitle: e.target.value } })}
                        />
                      </div>
                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>Email:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.extra_passes.email}
                          onChange={e => setContactData({ ...contactData, extra_passes: { ...contactData.extra_passes, email: e.target.value } })}
                        />
                      </div>

                      <img src="./assets/Delete Button.png" alt=""
                        onClick={() => handleDelete(contactData.extra_passes.id)}
                        style={{ display: "inline-block", width: "30px", height: "30px", alignSelf: "top", cursor: "pointer" }} />
                    </div>
                  </div>
                }
                <br />

                {contactData?.presenters &&
                  <div style={{ backgroundColor: "#303265", padding: "15px" }}>
                    <h3>Speaker</h3>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>

                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>First Name:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.presenters.firstname}
                          onChange={e => setContactData({ ...contactData, presenters: { ...contactData.presenters, firstname: e.target.value } })}
                        />
                      </div>
                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>Last Name:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.presenters.lastname}
                          onChange={e => setContactData({ ...contactData, presenters: { ...contactData.presenters, lastname: e.target.value } })}
                        />
                      </div>
                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>Job Title:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.presenters.jobtitle}
                          onChange={e => setContactData({ ...contactData, presenters: { ...contactData.presenters, jobtitle: e.target.value } })}
                        />
                      </div>
                      <div style={{ display: "inlineFlex", flexDirection: "column", marginBottom: "10px" }}>
                        <div style={{ color: '#B48242' }}>Email:</div>
                        <TextField required size="small"
                          sx={{
                            ...styles, backgroundColor: "#000120", borderRadius: "50px",
                          }}
                          value={contactData.presenters.email}
                          onChange={e => setContactData({ ...contactData, presenters: { ...contactData.presenters, email: e.target.value } })}
                        />
                      </div>

                      <img src="./assets/Delete Button.png" alt=""
                        onClick={() => handleDelete(contactData.presenters.id)}
                        style={{ display: "inline-block", width: "30px", height: "30px", alignSelf: "top", cursor: "pointer" }} />
                    </div>
                  </div>
                }
              </div>
            </Stack>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open4}
        onClose={handleClose4}
        PaperProps={{
          style: { border: "1px solid white", backgroundColor: "#303265", minWidth: "400px" }
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "left", color: "white" }}>
          Add Tickets
        </DialogTitle>
        {console.log(contactData?.registrants?.id)}

        <DialogContent>
          <div >
            <div style={{ color: '#B48242', marginBottom: "5px" }}>Ticket Type:</div>
            <Select fullWidth
              sx={{ backgroundColor: "black", color: "white" }}
              defaultValue={"Select..."}
              onChange={e => setAddTicketData({
                ...addTicketData, ticket_type: e.target.value
              })}>
              <MenuItem sx={{ fontStyle: 'italic' }} disabled value={"Select..."}>
                Select...
              </MenuItem>
              {options.ticket_type?.map((option, i) => {
                return <MenuItem key={i} value={option.option_value}>
                  {option.option_display}
                </MenuItem>
              })}
            </Select>
          </div>
          <br />
          <div >
            <div style={{ color: '#B48242', marginBottom: "5px" }}>
              Amount Paid:
            </div>
            <TextField fullWidth size="small"
              sx={{
                ...styles, backgroundColor: "black", borderRadius: "50px",
              }}
              onChange={e => setAddTicketData({ ...addTicketData, paid_amount: e.target.value })} />
          </div>
        </DialogContent>

        <DialogActions style={{ margin: "auto" }}>
          <img src="./assets/Close.png" alt="" width="25px" onClick={handleClose4}
            style={{ cursor: "pointer" }} />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <img src="./assets/Save Button.png" alt="" width="25px"
            style={{ cursor: "pointer" }}
            onClick={e => {
              const rawDate = new Date();
              const date = rawDate.toISOString().split('T')[0];

              const body = {
                body: {
                  id: contactData.registrants.id,
                  email: contactData.registrants.email,
                  source: contactData.registrants.source,
                  ticket_type: {
                    id: "att-" + Date.now(),
                    name: addTicketData.ticket_type,
                    paid_amount: addTicketData.paid_amount,
                    date: date,
                  }
                }
              }
              // console.log(body)

              const myPromise = createAttendee("https://youngstartup.io/api/db/attendees/add-or-update", body)
                .then(res => {
                  // console.log(res);
                  if (res.data.success) { handleClose4() };
                  return res.data
                });
              toast.promise(
                myPromise,
                {
                  loading: "Loading",
                  success: (data) => `${data.success ? data.message : "An error occured"}`,
                  error: (err) => `${err.toString()}`,
                },
                {
                  style: {
                    minWidth: "250px",
                  },
                  success: {
                    duration: 5000,
                    icon: data ? '✅' : '❌',
                  },
                }
              );
            }}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          {"Send emails?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "center" }}
          >
            {selectionModel.length >= 1
              ? `Send email "${emailName}" to ${selectionModel.length} attendees?`
              : `You must select a speaker to send email to!`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {selectionModel.length >= 1 ? (
            <Button
              endIcon={<SendIcon />}
              // onClick={handleClose2}
              variant="contained"
              onClick={(e) => {
                let body = {
                  body: {
                    ids: selectionModel,
                    template_id: emailValue,
                  },
                };

                const myPromise = createAttendee(
                  "https://youngstartup.io/api/db-a/emails/send_queued_template_v2",
                  body
                )
                  .then((data) => data)
                  .then(() => handleClose2());

                toast.promise(
                  myPromise,
                  {
                    loading: "Loading",
                    success: (data) => `Emails sent successfully!`,
                    error: (err) => `${err.toString()}`,
                  },
                  {
                    style: {
                      minWidth: "250px",
                    },
                    success: {
                      duration: 5000,
                      // icon: '🔥',
                    },
                  }
                );
              }}
            >
              Send
            </Button>
          ) : (
            <Button variant="contained" onClick={handleClose2}>
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <MuiAppBar setFilterObj={setFilterObj} filterObj={filterObj} />
      <div style={{ color: "white", marginBottom: "0.2vh" }}>
        <div>
          <Toaster />
        </div>
        <div
          style={{
            display: "flex",
            gap: "15px",
            padding: "1.5%",
          }}
        >
          <button className="filterBtn" onClick={handleClickOpen}>
            <AddIcon />
          </button>
          <button
            onClick={(e) => {
              let tempData = data;
              tempData = tempData.map((i) => {
                i.submission_status = JSON.stringify(i.submission_status);
                return i;
              });
              let xls = new XlsExport(tempData, "exprtedtable");
              // xls.exportToCSV("all_attendees.csv");
              xls.exportToXLS("all_attendees.xls");
            }}
            className="filterBtn"
          >
            <FileDownloadIcon />
          </button>
          <button
            onClick={() => {
              toast.success("Refreshing...");
              setRefresh((i) => i + 1);
            }}
            className="filterBtn"
          >
            <RefreshIcon />
          </button>
          <Select
            sx={{ border: "1px solid white", color: "white" }}
            defaultValue={"Send Email"}
            onChange={(e) => {
              setEmailValue(e.target.value);
            }}
          >
            <MenuItem
              disabled
              value="Send Email"
              style={{ fontStyle: "italic" }}
            >
              Send Email
            </MenuItem>
            {options.emails_sent?.map((option, i) => {
              return (
                <MenuItem
                  key={i}
                  onClick={(e) => {
                    handleClickOpen2();
                    setEmailName(option.option_display);
                  }}
                  value={option.option_value}
                >
                  {option.option_display}
                </MenuItem>
              );
            })}
          </Select>
          <Popover
            open={popoverStatus.sendEmail}
            onClose={(e) => {
              setPopoverStatus({ ...popoverStatus, sendEmail: false });
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div style={{ display: "block" }}>
              <Button
                style={{ display: "block" }}
                onClick={(e) => {
                  if (selectedIds.length < 1) {
                    alert("no rows selected!!");
                    return;
                  }
                }}
              >
                Brella email
              </Button>
              <Button
                style={{ display: "block" }}
                onClick={(e) => {
                  if (selectedIds.length < 1) {
                    alert("no rows selected!!");
                    return;
                  }
                }}
              >
                Brella email
              </Button>
            </div>
          </Popover>
          <button
            title="download files for matchmaking"
            onClick={() => {

              if (window.confirm("This action will mark all that are expoted as 'uploaded to matchmaking', confirm?")) {

                toast.promise(
                  getData("https://youngstartup.io/api/db-a/attendees/getformatchmaking")
                    .then(data => {
                      if (data.success) {
                        console.log(data);
                        let tempData = [];
                        for (const key in data.data) {
                          if (Object.hasOwnProperty.call(data.data, key)) {
                            const element = data.data[key];
                            if (element.length > 0) {
                              tempData = [...tempData, ...element]
                            } else {
                              toast.error("No one to add for " + key + "!");
                            }
                          }
                        }
                        let xls = new XlsExport(tempData, "exprtedtable");
                        // xls_inv.exportToCSV("attendees_to_upload_mm.csv");
                        xls.exportToXLS("attendees_to_upload_mm.xls");
                      } else {
                        toast.error("an error hapend!!");
                      }
                    }),
                  {
                    loading: "Loading",
                    success: (data) => `Getting data...`,
                    error: (err) => `${err.toString()}`,
                  },
                  {
                    style: {
                      minWidth: "250px",
                    },
                    success: {
                      duration: 5000,
                      // icon: '🔥',
                    },
                  }
                );
              }

            }}
            className="filterBtn"
          >
            <FileDownloadIcon /> MM
          </button>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            sx={{
              ml: 5,
              color: "white",
              minWidth: "150px",
              input: { color: "white" },
              textarea: { color: "white" },
              "&": {
                color: "white !important",
                backgroundColor: "#303265",
                borderRadius: "5px",
              },
              "& label": {
                color: "lightgray",
              },
              "& label.Mui-focused": {
                color: "lightgray",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "white",
                color: "white",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "green",
                color: "white",
              },
              "& .MuiInputBase-root": {
                "& fieldset": {
                  borderColor: "transparent",
                  color: "white",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                  color: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                  color: "white",
                },
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent",
                  color: "white",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                  color: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                  color: "white",
                },
              },
            }}
            value={filterObj?.q ? filterObj.q : ""}
            onChange={(e) => {
              const {
                target: { value },
              } = e;
              if (filterObj) {
                setFilterObj((filterObj) => ({ ...filterObj, q: value }));
              }
            }}
          />
        </div>

      </div>
      <div style={{ height: "100%" }}>
        <DataGrid
          columns={columns}
          rows={data}
          checkboxSelection
          disableSelectionOnClick
          rowHeight={85}
          onCellEditCommit={handleCellFocusOut}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          sx={{
            color: "white !important",
            border: "none",
            backgroundColor: "#000120",
            fontFamily: "Manrope, sans-serif",
            "& .MuiDataGrid-footerContainer, .MuiSelect-select, .MuiSvgIcon-root": {
              color: "white"
            },
            "& .MuiDataGrid-footerContainer": {
              padding: "5px",
            }
          }}
        />
      </div>
    </div>
  );
}

// const AttendeeModal = (attendee) => {
//   return (
//     <>
//       <Dialog />
//     </>
//   );
// };

const MuiToggle = ({ stat }) => {
  // const [selected, setSelected] = useState(stat);
  return (
    <ToggleButton
      value="check"
      selected={stat}
      sx={{
        backgroundColor: stat ? "green !important" : "red !important",
        border: "1px solid white",
      }}
    // onChange={() => {
    //   // setSelected(!selected);
    //   console.log(stat);
    // }}
    >
    </ToggleButton>
  );
};
