import { v4 as uuidv4 } from "uuid";
import ChunkedUploady from "@rpldy/chunked-uploady";
import { asUploadButton } from "@rpldy/upload-button";
import { useItemProgressListener, useItemErrorListener, useItemFinishListener } from "@rpldy/uploady";

//MUI IMPORTS
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";

//LOCAL IMPORTS
import { HoverButton, PresentingVirtualPicker, TrackPicker } from "../index";
import { getData, patchData, postData } from "../../api/apiCalls";
import { convertBase64 } from "../../utils/convertBase64";
import { forwardRef, useCallback } from "react";

export const DetailsPopup = ({ startupInputs, handleCloseDialog4, ddOptions, changeStartupInputs, ticketsDetails, currentCell, setStartupInputs,
    handleSuccessMsg, handleErrMsg, fetchData, fetchUrl, setData,
    // removeUser
}) => {
    let params = new URLSearchParams(document.location.search);
    let urlStage = params.get("stage");

    const handleUpload = async (e, propertyName) => {
        let file = e.target.files[0];
        if (file.type !== 'image/png' && file.type !== 'image/jpeg') return;
        if (file.size > 4194304) return;
        const base64 = await convertBase64(file)
        let data = {
            body: {
                id: startupInputs.id,
                [propertyName]: base64
            }
        }
        await patchData(`https://youngstartup.io/api/db-a/presenters/updateapplication`, data)
            .then((data) => {
                if (data.success) {
                    handleCloseDialog4();
                    handleSuccessMsg();
                    fetchData();
                } else {
                    handleErrMsg();
                }
            });
    }


    const filterByType = useCallback((file) => {
        const fileIsValid = file.name.includes(".pptx") || file.name.includes(".ppsx") || file.name.includes(".pdf");
        // if (!fileIsValid) return setMsg(msgs.type);
        // setMsg(msgs.uploading)
        return fileIsValid;
    }, []);

    const DivUploadButton = asUploadButton(forwardRef(
        (props, ref) => {
            return (
                <button {...props}>
                    Upload Presentation
                    {/* {svgs.upload} */}
                    {/* <p>{landingData.user.so_application.presentation_link ? 'Change file' : 'Upload file'}</p> */}
                </button>
            )
        }
    ));

    const FinishListener = () => {
        useItemFinishListener((item) => {
            fetchData();
            handleCloseDialog4();
            // setMsg(msgs.success);
            // setLink(item.uploadResponse.results[0].data.path);
        });
    }

    const ProgressListener = () => {
        const item = useItemProgressListener((item) => {
        });
        return <div  >
            <label htmlFor="file">Upload Progress: {(item?.completed) && `${(item.completed.toFixed(0))}%`}</label>
            <progress id="file" max="100" value={item?.completed || 0} > </progress>
        </div>
    };

    const ErrorListener = () => {
        useItemErrorListener((item) => {
            // setMsg(msgs.error);
        });
    };

    return (
        <div>
            <DialogActions
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    bgcolor: "#000120",
                    padding: "2.5%",
                }}
            >
                <h4 style={{ color: "white" }}>
                    {startupInputs.company_name
                        ? startupInputs.company_name
                        : "Not Available"}
                </h4>
                <span style={{ display: "flex", gap: "7.5%" }}>
                    <HoverButton
                        initialUrl={"/assets/Property 1=Default (4).png"}
                        hoverUrl={"/assets/Property 1=Variant2 (4).png"}
                        className="presenter-action-btn"
                        onClick={handleCloseDialog4}
                    />
                    <HoverButton
                        initialUrl={"/assets/Property 1=Default (5).png"}
                        hoverUrl={"/assets/Property 1=Variant2 (5).png"}
                        className="presenter-action-btn"
                        onClick={(e) => {
                            let body = {
                                body: {
                                    ...startupInputs,
                                },
                            };
                            patchData(
                                `https://youngstartup.io/api/db-a/presenters/updateapplication`,
                                body
                            )
                                .then((data) => {
                                    if (data.success) {
                                        handleSuccessMsg();
                                        // if (startupInputs.rivka_stage !== urlStage) {
                                        //     removeUser(startupInputs.id);
                                        // }
                                        fetchData();
                                        // getData(
                                        //   `https://youngstartup.io/api/db/presenters/getallapplications?stage=${startupInputs.rivka_stage
                                        //   }&company_stage=${startupInputs.company_stage === "Seeking Angel Round"
                                        //     ? "seed"
                                        //     : "top"
                                        //   }`
                                        // )
                                        // .then((data) => {
                                        //   setData(data)
                                        // }
                                        // );
                                    } else {
                                        handleErrMsg();
                                    }
                                });
                        }}
                    />
                </span>
            </DialogActions>
            <DialogContent sx={{ bgcolor: "#000120" }}>
                <div style={{
                    display: "flex", margin: "auto",
                }}>
                    <div style={{ flex: 1, paddingRight: "20px", margin: "10px" }}>
                        {ddOptions && <Stack spacing={2} sx={{ p: 1 }}>
                            <div
                                style={{ display: "flex", gap: "5%", alignItems: "center", margin: "0 auto 7.5px", maxHeight: "150px" }}
                            >
                                <img src={startupInputs.headshot_url} width="100px" />
                            </div>
                            <label htmlFor="headshot" style={{ color: "white" }}>Choose a Headshot:</label>
                            <input type="file"
                                id="headshot" name="headshot"
                                accept="image/png, image/jpeg"
                                style={{ color: "white" }}
                                onChange={(e) => handleUpload(e, "headshot")}
                            />

                            <div
                                style={{ display: "flex", gap: "5%", alignItems: "center", margin: "5px auto", maxHeight: "100px" }}
                            >
                                <img src={startupInputs.logo_url} width="100px" />
                            </div>

                            <label htmlFor="logo" style={{ color: "white" }}>Choose a Logo:</label>
                            <input type="file"
                                id="logo" name="logo"
                                accept="image/png, image/jpeg"
                                style={{ color: "white" }}
                                onChange={(e) => handleUpload(e, "logo")}
                            />

                            <Stack>
                                <label style={{ color: "#F0AD4E", marginBottom: "5px" }}>
                                    One Liner:
                                </label>
                                <textarea
                                    cols={"42.5"}
                                    rows={"1"}
                                    value={startupInputs.one_liner}
                                    name="one_liner"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                    }}
                                />
                            </Stack>

                            <Stack>
                                <label style={{ color: "#F0AD4E", marginBottom: "5px" }}>
                                    Company Description:
                                </label>
                                <textarea
                                    cols={"42.5"}
                                    rows={"8"}
                                    value={startupInputs.description}
                                    name="description"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                    }}
                                />
                            </Stack>
                        </Stack>}
                    </div>

                    &nbsp;&nbsp;

                    <div style={{ flex: 1 }}>
                        {ddOptions && <Stack spacing={5.5} sx={{ p: 1 }}>
                            <FormControl sx={{ width: "250px" }}>
                                <InputLabel
                                    id="demo-simple-select-label"
                                    sx={{ color: "white !important" }}
                                >
                                    Select stage...
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="rivka_stage"
                                    value={startupInputs.rivka_stage}
                                    sx={{ backgroundColor: "#303265", color: "white" }}
                                    onChange={changeStartupInputs}
                                >
                                    {ddOptions.rivka_stage?.map((option, i) => (
                                        <MenuItem
                                            key={i}
                                            value={option.option_value}
                                            sx={{ textTransform: "capitalize" }}
                                        >
                                            {option.option_display}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div
                                style={{ display: "flex", gap: "5%", alignItems: "center" }}
                            >
                                <PresentingVirtualPicker
                                    cell={currentCell}
                                    fetchUrl={fetchUrl}
                                    setData={setData}
                                    handleErrMsg={handleErrMsg}
                                    handleSuccessMsg={handleSuccessMsg}
                                />
                                <img
                                    src={
                                        startupInputs?.presenting_virtually == "yes"
                                            ? "/assets/flat-color-icons_positive-dynamic.png"
                                            : "/assets/flat-color-icons_negative.png"
                                    }
                                    className="revenue-icon"
                                    alt=""
                                />
                                {ddOptions && <TrackPicker
                                    cell={currentCell}
                                    ddOptions={ddOptions}
                                    handleErrMsg={handleErrMsg}
                                    handleSuccessMsg={handleSuccessMsg}
                                    setData={setData}
                                    setStartupInputs={setStartupInputs}
                                />}
                            </div>
                            <Stack sx={{ color: "#F0AD4E" }} spacing={1}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <div>$ Raised:</div>
                                    <input
                                        value={startupInputs.funding_raised}
                                        name="funding_raised"
                                        onChange={changeStartupInputs}
                                        style={{
                                            width: "100px",
                                            backgroundColor: "#303265",
                                            border: "none",
                                            borderRadius: "15px",
                                            color: "white",
                                            padding: "1.5%",
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <div style={{ color: "#F0AD4E" }}>$ Sought:</div>
                                    <input
                                        value={startupInputs.funding_sought}
                                        name="funding_sought"
                                        onChange={changeStartupInputs}
                                        style={{
                                            width: "100px",
                                            backgroundColor: "#303265",
                                            border: "none",
                                            borderRadius: "15px",
                                            color: "white",
                                            padding: "1.5%",
                                        }}
                                    />
                                </div>
                            </Stack>

                            <Stack style={{ alignSelf: "end" }}>
                                <label style={{ color: "#F0AD4E", marginBottom: "5px" }}>Use of Proceeds:</label>
                                <textarea
                                    cols={"37.5"}
                                    rows={"8"}
                                    value={startupInputs.use_of_proceeds}
                                    name="use_of_proceeds"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        paddingLeft: "2.5%",
                                        padding: "1.5%",
                                    }}
                                />
                            </Stack>

                        </Stack>}
                    </div>

                    <div
                        style={{
                            flex: 1.25,
                            display: "flex",
                            justifyContent: "center",
                            color: "white",
                        }}
                    >
                        <Stack spacing={2}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    Company Name:
                                </div>
                                <input
                                    value={startupInputs.company_name}
                                    name="company_name"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        paddingLeft: "2.5%",
                                        width: "300px",
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    Name:
                                </div>
                                <span>
                                    <input
                                        value={startupInputs.firstname}
                                        name="firstname"
                                        onChange={changeStartupInputs}
                                        style={{
                                            width: "100px",
                                            backgroundColor: "#303265",
                                            border: "none",
                                            borderRadius: "15px",
                                            color: "white",
                                            padding: "1.5%",
                                            paddingLeft: "2.5%",
                                            width: "150px",
                                        }}
                                    />
                                    <input
                                        value={startupInputs.lastname}
                                        name="lastname"
                                        onChange={changeStartupInputs}
                                        style={{
                                            width: "100px",
                                            backgroundColor: "#303265",
                                            border: "none",
                                            borderRadius: "15px",
                                            color: "white",
                                            padding: "1.5%",
                                            paddingLeft: "2.5%",
                                            width: "150px",
                                        }}
                                    />
                                </span>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    Title:
                                </div>
                                <input
                                    //field doesn't exist yet
                                    // value={startupInputs.title}
                                    // name="title"
                                    // onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        paddingLeft: "2.5%",
                                        width: "300px",
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    Email:
                                </div>
                                <input
                                    value={startupInputs.email}
                                    name="email"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        paddingLeft: "2.5%",
                                        width: "300px",
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    Industry:
                                </div>
                                <input
                                    value={startupInputs.primary_industry}
                                    name="primary_industry"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        paddingLeft: "2.5%",
                                        width: "300px",
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    Sec. Industry:
                                </div>
                                <input
                                    value={startupInputs.industry_sub_category}
                                    name="industry_sub_category"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        width: "300px",
                                        paddingLeft: "2.5%",
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    Company Stage:
                                </div>
                                <input
                                    value={startupInputs.company_stage}
                                    name="company_stage"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        paddingLeft: "2.5%",
                                        width: "300px",
                                    }}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    Street:
                                </div>
                                <input
                                    value={startupInputs.street}
                                    name="street"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        paddingLeft: "2.5%",
                                        width: "300px",
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    City:
                                </div>
                                <input
                                    value={startupInputs.city}
                                    name="city"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        paddingLeft: "2.5%",
                                        width: "300px",
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    State:
                                </div>
                                <input
                                    value={startupInputs.state}
                                    name="state"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        paddingLeft: "2.5%",
                                        width: "300px",
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    Country:
                                </div>
                                <input
                                    value={startupInputs.country}
                                    name="country"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        paddingLeft: "2.5%",
                                        width: "300px",
                                    }}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    Web:
                                </div>
                                <input
                                    value={startupInputs.website}
                                    name="website"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        paddingLeft: "2.5%",
                                        width: "300px",
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "150px",
                                        textAlign: "right",
                                        color: "#F0AD4E",
                                    }}
                                >
                                    Phone:
                                </div>
                                <input
                                    value={startupInputs.phone_number}
                                    name="phone_number"
                                    onChange={changeStartupInputs}
                                    style={{
                                        backgroundColor: "#303265",
                                        border: "none",
                                        borderRadius: "15px",
                                        color: "white",
                                        padding: "1.5%",
                                        paddingLeft: "2.5%",
                                        width: "300px",
                                    }}
                                />
                            </div>
                        </Stack>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        color: "white",
                        marginTop: "2.5vh",
                    }}
                >
                    <Stack>
                        <label style={{ color: "#F0AD4E", marginBottom: "5px" }}>Current Investors:</label>
                        <textarea
                            name="current_investors"
                            defaultValue={startupInputs?.current_investors?.length > 0
                                ? JSON.parse(startupInputs?.current_investors)?.reduce(
                                    (accu, curr) => accu + curr.name + "\n", ""
                                )
                                : "N/A"}
                            onChange={(e) => {
                                let data = e.target.value.split("\n");
                                data = JSON.stringify(data.map(item => ({ id: uuidv4(), name: item })));
                                changeStartupInputs(
                                    { target: { name: e.target.name, value: data } }
                                )
                            }
                            }
                            style={{
                                backgroundColor: "#303265",
                                border: "none",
                                borderRadius: "15px",
                                color: "white",
                                padding: "1.5%",
                                width: "350px",
                                paddingLeft: "2.5%",
                                height: "200px",
                            }}
                        />
                    </Stack>
                    <Stack>
                        <label style={{ color: "#F0AD4E", marginBottom: "5px" }}>Management Team:</label>
                        <textarea
                            name="management_team"
                            defaultValue={startupInputs?.management_team?.length > 0
                                ? JSON.parse(startupInputs?.management_team)?.reduce(
                                    (accu, curr) => accu + curr.name + "\n", ""
                                )
                                : "N/A"}
                            onChange={(e) => {
                                let data = e.target.value.split("\n");
                                data = JSON.stringify(data.map(item => ({ id: uuidv4(), name: item })));
                                changeStartupInputs(
                                    { target: { name: e.target.name, value: data } }
                                )
                            }
                            }
                            style={{
                                backgroundColor: "#303265",
                                border: "none",
                                borderRadius: "15px",
                                color: "white",
                                padding: "1.5%",
                                width: "350px",
                                paddingLeft: "2.5%",
                                height: "200px",
                            }}
                        />
                    </Stack>

                    <Stack>
                        <label style={{ color: "#F0AD4E", marginBottom: "5px" }}>Milestones to Date:</label>
                        <textarea
                            cols={"37.5"}
                            rows={"8"}
                            value={startupInputs.milestones}
                            name="milestones"
                            onChange={changeStartupInputs}
                            style={{
                                backgroundColor: "#303265",
                                border: "none",
                                borderRadius: "15px",
                                color: "white",
                                paddingLeft: "2.5%",
                                padding: "1.5%",
                                height: "200px",
                                width: "350px"
                            }}
                        />
                    </Stack>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        color: "white",
                        marginTop: "2.5vh",
                    }}
                >
                    <Stack>
                        <label style={{ color: "#F0AD4E", marginBottom: "5px" }}>Target Markets:</label>
                        <textarea
                            cols={"37.5"}
                            rows={"8"}
                            value={startupInputs.target_markets}
                            name="target_markets"
                            onChange={changeStartupInputs}
                            style={{
                                backgroundColor: "#303265",
                                border: "none",
                                borderRadius: "15px",
                                color: "white",
                                paddingLeft: "2.5%",
                                padding: "1.5%",
                                height: "200px",
                                width: "350px"
                            }}
                        />
                    </Stack>
                    <Stack>
                        <label style={{ color: "#F0AD4E", marginBottom: "5px" }}>Competition:</label>
                        <textarea
                            cols={"37.5"}
                            rows={"8"}
                            value={startupInputs.competition}
                            name="competition"
                            onChange={changeStartupInputs}
                            style={{
                                backgroundColor: "#303265",
                                border: "none",
                                borderRadius: "15px",
                                color: "white",
                                paddingLeft: "2.5%",
                                padding: "1.5%",
                                height: "200px",
                                width: "350px"
                            }}
                        />
                    </Stack>

                    <Stack>
                        <div
                            style={{
                                width: "150px",
                                color: "#F0AD4E",
                                marginBottom: "5px"
                            }}
                        >
                            Law Firm:
                        </div>
                        <input
                            value={startupInputs.law_firm}
                            name="law_firm"
                            onChange={changeStartupInputs}
                            style={{
                                backgroundColor: "#303265",
                                border: "none",
                                borderRadius: "15px",
                                color: "white",
                                padding: "1.5%",
                                paddingLeft: "2.5%",
                                width: "300px",
                            }}
                        />
                        <br />

                        <div
                            style={{
                                width: "150px",
                                color: "#F0AD4E",
                                marginBottom: "5px"
                            }}
                        >
                            Bank:
                        </div>
                        <input
                            value={startupInputs.law_firm}
                            name="law_firm"
                            onChange={changeStartupInputs}
                            style={{
                                backgroundColor: "#303265",
                                border: "none",
                                borderRadius: "15px",
                                color: "white",
                                padding: "1.5%",
                                paddingLeft: "2.5%",
                                width: "300px",
                            }}
                        />
                        <br />
                        <div
                            style={{
                                width: "150px",
                                color: "#F0AD4E",
                                marginBottom: "5px"
                            }}
                        >
                            Accounting Firm:
                        </div>
                        <input
                            value={startupInputs.accounting_firm}
                            name="accounting_firm"
                            onChange={changeStartupInputs}
                            style={{
                                backgroundColor: "#303265",
                                border: "none",
                                borderRadius: "15px",
                                color: "white",
                                padding: "1.5%",
                                paddingLeft: "2.5%",
                                width: "300px",
                            }}
                        />
                    </Stack>
                </div>

                {ticketsDetails?.length && <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        color: "white",
                        marginTop: "2.5vh",
                        flexWrap: "wrap",
                    }}
                >
                    {ticketsDetails.map((ticket, i) => {
                        return (
                            <div key={i} style={{
                                border: "2px solid white", padding: "20px", width: "40%", margin: "10px",
                                marginRight: (i === ticketsDetails.length - 1) && (ticketsDetails.length % 2) && "auto",
                                marginLeft: (i === ticketsDetails.length - 1) && (ticketsDetails.length % 2) && "5%"
                            }}>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span style={{ color: "#F0AD4E", }}>Ticket: {i === 0 ? "Presenter" : "Executive"}</span>
                                    <span style={{ color: "#F0AD4E", }}>Brella Code:
                                        <span style={{ color: "white", }}>{ticket.brella_code}</span>
                                    </span>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span>{ticket.firstname}</span>
                                    <span>{ticket.lastname}</span>
                                    <span>Reception:</span>
                                </div>

                                <div style={{
                                    display: "flex", justifyContent: "space-between",
                                }}>
                                    <span>{ticket.jobtitle}</span>
                                    <span>{ticket.email}</span>
                                    <span style={{ display: "inline-block", backgroundColor: ticket.cocktail_reception ? "green" : "red", width: "20px", height: "20px" }} />
                                </div>

                            </div>
                        )
                    })}
                </div>}

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        color: "white",
                        marginTop: "2.5vh",
                    }}
                >
                    {urlStage === "confirmed" && <>
                        <div style={{ color: "#F0AD4E" }}>Link to Presentation:
                            <div style={{ width: "300px", overflowX: "auto" }}>
                                {startupInputs.presentation_link &&
                                    <a href={startupInputs.presentation_link} target="_blank" rel="noreferrer" style={{ color: "yellow" }}>
                                        {startupInputs.presentation_link.split('/').at(-1)}
                                    </a>}
                            </div>
                        </div>

                        <section>
                            <ChunkedUploady
                                method="POST"
                                destination={{
                                    url: `https://youngstartup.io/api/db-a/presenters/upload_presentation?id=${startupInputs.id}`,
                                    headers: {
                                        tok: localStorage.tok,
                                    }
                                }}
                                chunkSize={20 * 1000 * 1024} //20mb
                                inputFieldName={'file'}
                                accept=".pptx, .ppsx, .pdf"
                                fileFilter={filterByType}
                            >
                                <DivUploadButton />
                                <FinishListener />
                                <ProgressListener />
                                <ErrorListener />
                            </ChunkedUploady>
                        </section>
                    </>}

                    <Stack>
                        <br />
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <div
                                style={{
                                    width: "150px",
                                    textAlign: "right",
                                    color: "#F0AD4E",
                                }}
                            >
                                Secondary Contact Name
                            </div>
                            <input
                                value={startupInputs.secondary_contacts[0]?.name}
                                name="secondary_contacts_name"
                                onChange={(e) => {
                                    let body = { name: e.target.value, email: startupInputs.secondary_contacts[0]?.email || "" };
                                    setStartupInputs({ ...startupInputs, secondary_contacts: [body] });
                                }}
                                style={{
                                    backgroundColor: "#303265",
                                    border: "none",
                                    borderRadius: "15px",
                                    color: "white",
                                    padding: "1.5%",
                                    paddingLeft: "2.5%",
                                    width: "300px",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <div
                                style={{
                                    width: "150px",
                                    textAlign: "right",
                                    color: "#F0AD4E",
                                }}
                            >
                                Secondary Contact Email
                            </div>
                            <input
                                value={startupInputs.secondary_contacts[0]?.email}
                                name="secondary_contacts_email"
                                onChange={(e) => {
                                    let body = { name: startupInputs.secondary_contacts[0]?.name || "", email: e.target.value };
                                    setStartupInputs({ ...startupInputs, secondary_contacts: [body] });
                                }}
                                style={{
                                    backgroundColor: "#303265",
                                    border: "none",
                                    borderRadius: "15px",
                                    color: "white",
                                    padding: "1.5%",
                                    paddingLeft: "2.5%",
                                    width: "300px",
                                }}
                            />
                        </div>

                    </Stack>
                </div>
            </DialogContent>
        </div>
    )
}