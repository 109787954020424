import { useEffect, useState } from "react";

import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";

import { HoverButton } from "../index";
import { getData, patchData } from "../../api/apiCalls";

export const PresenterOptionSelect = ({
  cell,
  handleErrMsg,
  handleSuccessMsg,
  setData,
  setStats,
  refreshStats,
  fetchData
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [open, setOpen] = useState(false);
  const [optionValue, setOptionValue] = useState("");
  const [stage, setStage] = useState("");
  const [content, setContent] = useState(null);

  // useEffect(() => {
  //   setOpen(Boolean(anchorEl))
  // }, [anchorEl])

  let open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setContent(<SelectMenu />);
  };

  const handleSelect = (stage) => {
    setStage(stage);
    if (stage === "selected") {
      setContent(<SelectTrack />);
    } else {
      setContent(<SelectConfirm stage={stage} />);
    }
  };

  const SelectTrack = () => {
    // alert("1")
    const [trackOptions, setTrackOptions] = useState([]);
    const handleAddStatus = (value) => {
      let body = {
        body: {
          id: cell.row.id,
          final_presenting_track: value,
          rivka_stage: "selected",
        },
      };

      patchData(
        `https://youngstartup.io/api/db-a/presenters/updateapplication`,
        body
      ).then((data) => {
        if (data.success) {
          handleSuccessMsg();
          refreshStats()
          // fetchData();
          getData(
            `https://youngstartup.io/api/db/presenters/getallapplications?stage=${cell.row.rivka_stage
            }&company_stage=${cell.row.company_stage === "Seeking Angel Round" ? "seed" : "top"
            }`
          ).then((data) => {
            // console.log(data)
            setData(data.data)
          });
        } else {
          handleErrMsg();
        }
        handleClose();
        setAnchorEl(null);
        // setOpen(false)
        // setContent(null)
      });
    };

    useEffect(() => {
      getData(
        `https://youngstartup.io/api/db-info/property-options-dd/presenters`
      ).then((data) => {
        setTrackOptions(data.final_presenting_track);
      });
    }, []);

    return (
      <Stack className="select-track-container" spacing={2}
        style={{
          display: "-webkit-box",
          flexWrap: "wrap"
        }}>
        <div className="select-header">
          <div className="select-header-text">Select Track</div>
          <div className="confirm-btn-container">
            <HoverButton
              initialUrl={"/assets/Property 1=Default (4).png"}
              hoverUrl={"/assets/Property 1=Variant2 (4).png"}
              className="status-icon"
              onClick={() => setContent(<SelectMenu />)}
            />
            <HoverButton
              initialUrl={"/assets/Property 1=Default (5).png"}
              hoverUrl={"/assets/Property 1=Variant2 (5).png"}
              className="status-icon"
            />
          </div>
        </div>
        {trackOptions?.map((track, i) => {
          return (
            <div
              key={track.value}
              className="track-btn"
              onClick={(e) => handleAddStatus(track.option_value)}
            >
              {track.option_display}
            </div>
          );
        })}
      </Stack>
    );
  };

  const SelectConfirm = ({ stage }) => {
    return (
      <Stack spacing={2}>
        <div style={{ color: "white" }}>Are you sure?</div>
        <div className="confirm-btn-container">
          <button className="no-btn" onClick={() => setContent(<SelectMenu />)}>
            No
          </button>
          <button
            className="yes-btn"
            onClick={() => {
              let body = {
                body: {
                  id: cell.row.id,
                  rivka_stage: stage,
                },
              };
              patchData(
                `https://youngstartup.io/api/db-a/presenters/updateapplication`,
                body
              ).then((data) => {
                if (data.success) {
                  handleSuccessMsg();
                  getData(
                    `https://youngstartup.io/api/db/presenters/getallapplications?stage=${cell.row.rivka_stage
                    }&company_stage=${cell.row.company_stage === "Seeking Angel Round"
                      ? "seed"
                      : "top"
                    }`
                  )
                    .then((data) => { setData(data.data); refreshStats() })
                  // .then((data) => { setData(data); refreshStats() })

                } else {
                  handleErrMsg();
                }
                setAnchorEl(null);
              });
            }}
          >
            Yes
          </button>
        </div>
      </Stack>
    );
  };

  const SelectMenu = () => {
    // alert("3")
    return (
      <Stack spacing={0.5}>
        <div
          className="option-select-btn bg-green"
          onClick={() => handleSelect("selected")}
        >
          Select
        </div>
        <div
          className="option-select-btn bg-orange"
          onClick={() => handleSelect("maybe")}
        >
          Maybe
        </div>
        <div
          className="option-select-btn bg-red"

          onClick={() => { handleSelect("rejected"); console.log("qq") }}
        >
          Reject
        </div>
      </Stack>
    );
  };

  useEffect(() => {
    setContent(<SelectMenu />);
  }, []);

  return (
    <div>
      <HoverButton
        initialUrl={"assets/Property 1=Default (7).png"}
        hoverUrl={"/assets/Property 1=Variant2 (7).png"}
        className="option-btn"
        onClick={handleClick}
      />
      <Popover

        id={id}
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ overflow: 'scroll' }}
        PaperProps={{
          style: {
            maxWidth: '50%',
          },
        }}
      >
        <div className="popover-contents-presenter-option">{content}</div>
      </Popover>
    </div>
  );
};
