import { Container } from "react-bootstrap";
import { LoginForm } from "../components/index";

export const Login = () => {
  if (localStorage.tok && (localStorage.expires > Date.parse(Date()))) {
    window.location.href = "/landing";
    return <div></div>;
  }
  return (
    <Container style={{ textAlign: "center", marginTop: "15vh" }} fluid>
      <Container className="" fluid>
        <LoginForm />
      </Container>
    </Container>
  );
};
