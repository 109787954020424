import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Popover from "@mui/material/Popover";
import { debounce } from "../utils/funcs";
import { postData } from "../api/apiCalls";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { set } from "date-fns";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import InputAdornment from "@mui/material/InputAdornment";
import { getData } from "../api/apiCalls";
import { styled } from "@mui/system";
import SearchIcon from '@mui/icons-material/Search';
import { Link } from "react-router-dom";


const pages = ["Attendees", "Featured Speakers", "Presenters", "Volunteers"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaders": {
    color: "#F0AD4E",
    fontSize: "1.5rem",
    border: "none" /* display:'none' */,
  },
  // "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
  "& .MuiDataGrid": { border: "none" },
  "& .MuiDataGrid-cell": { border: "none" },
  "& > .MuiDataGrid-columnSeparator": {
    opacity: "0",
  },
  "& .MuiDataGrid-columnSeparator .MuiDataGrid-columnSeparator--sideRight": {
    visibility: "hidden",
  },

  border: "none",
  color: "white",
  "& .css-1pans1z-MuiDataGrid-virtualScroller": {
    overflowX: "hidden"
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none !important"
  }
}));

const headerNames = ["Event", "Name", "Email", "Company", "Roles"];

const columns = [
  {
    field: "event",
    headerName: "Event",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    flex: .5,
  },
  {
    field: "lastname",
    headerName: "Name",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    flex: 1,
    // customHeadRender: ()=>null,
    renderCell: (cell) => (
      <div>
        {cell.row.firstname} {cell.row.lastname}
      </div>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    flex: 1,
    // customHeadRender: ()=>null,
  },
  {
    field: "company",
    headerName: "Company",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    flex: 1,
    // customHeadRender: ()=>null,
  },
  {
    field: "links",
    headerName: "Roles",
    headerAlign: "center",
    disableColumnMenu: true,
    sortable: false,
    align: "center",
    flex: 2,
    // customHeadRender: ()=>null,
    renderCell: (cell) => {
      return (
        <div style={{ display: "flex", flexWrap: "nowrap", gap: "10px" }}>
          {cell.row.links.map((link, i) => {
            console.log(cell.row)
            return (
              <a
                key={i}
                href={link.link}
                // target="_blank"
                style={{
                  border: "1px solid #F0AD4E",
                  color: "white",
                  backgroundColor: "#000120",
                  padding: "5px 10px",
                }}
              >
                {link.display_name}
              </a>
            );
          })}
        </div>
      );
    },
  },
];

export const MuiAppBar = ({ setFilterObj, filterObj }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [input, setInput] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [event, setEvents] = useState({});
  const [showSearchResults, setShowSearchResults] = useState("none");
  const [queryObj, setQueryObj] = useState({});

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (!input) {
      setShowSearchResults("none");
    }
  }, [input]);

  useEffect(() => {
    getData(`https://youngstartup.io/api/cwebsite/get_events`).then((data) =>
      setEvents(data)
    );
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <AppBar position="sticky" sx={{ backgroundColor: "#030120" }}>
        <Container maxWidth="xl" sx={{ marginLeft: 0 }}>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, p: 2, display: { xs: "none", md: "flex" } }}
            >
              <Link to="/">
                <img
                  src={
                    "https://events.youngstartup.com/wp-content/uploads/2019/01/trns-logo.png"
                  }
                  width="150px"
                  alt=""
                />
              </Link>
              {
                <ClickAwayListener
                  onClickAway={(e) => {
                    if (showSearchResults == "") {
                      console.log(showSearchResults);
                      setShowSearchResults("none");
                      // console.log("click happened!");
                    }
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <TextField
                      id="outlined-basic"
                      // label="Database search"
                      label={<span>
                        <SearchIcon style={{ color: "#A19191", verticalAlign: "middle" }} />
                        Database search
                      </span>}
                      autoComplete="off"
                      value={input}

                      onClick={() => {
                        setShowSearchResults(
                          showSearchResults == "none" ? "" : "none"
                        );
                      }}
                      variant="outlined"
                      sx={{
                        ml: 5,
                        color: "white",
                        minWidth: "150px",
                        input: { color: "white" },
                        textarea: { color: "white" },
                        "&": {
                          color: "white !important",
                          backgroundColor: "#303265",
                          borderRadius: "5px",
                        },
                        "& label": {
                          color: "lightgray",
                        },
                        "& label.Mui-focused": {
                          color: "lightgray",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "white",
                          color: "white",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "green",
                          color: "white",
                        },
                        "& .MuiInputBase-root": {
                          "& fieldset": {
                            borderColor: "transparent",
                            color: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "transparent",
                            color: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent",
                            color: "white",
                          },
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "transparent",
                            color: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "transparent",
                            color: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent",
                            color: "white",
                          },
                        },
                      }}
                      onChange={(e) => {
                        setInput(e.target.value);
                        setShowSearchResults("");
                        let body = {
                          body: {
                            query: e.target.value,
                          },
                        };

                        postData(
                          `https://youngstartup.io/api/db/attendees/g-filter`,
                          body
                        ).then((data) => {
                          console.log(data);
                          setQueryObj(data);
                        });
                      }}
                    />
                    {/* <img 
                  src={"/clarity_event-line.png"} 
                  style={{position:'absolute', right:'5px', width:'40px', top:'7.5px', cursor:'pointer'}} 
                  onClick={handleClick} /><Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div>
                    {}
                  </div>
                </Popover> */}
                  </div>
                </ClickAwayListener>
              }
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              LOGO
            </Typography>
            <Box
              sx={{
                width: '50%',
                flexGrow: 1,
                gap: "15px",
                display: { xs: "none", md: "flex" },
                justifyContent: 'center'
              }}
            >
              <Link to="/attendees">
                <button
                  className="nav-btn"
                  onClick={handleCloseNavMenu}
                  style={{
                    color: window.location.href.includes("attendees") ? "#303265" : "white",
                    fontWeight: "900",
                    backgroundColor: window.location.href.includes("attendees") ? "#F0AD4E" : "#303265",
                    border: "none",
                  }}
                >
                  Attendees
                </button>
              </Link>
              <Link to="/speakers">
                <button
                  className="nav-btn"
                  onClick={handleCloseNavMenu}
                  style={{
                    color: window.location.href.includes("speakers") ? "#303265" : "white",
                    fontWeight: "900",
                    backgroundColor: window.location.href.includes("speakers") ? "#F0AD4E" : "#303265",
                    border: "none",
                  }}
                >
                  Featured Speakers
                </button>
              </Link>
              <Link to="/presenters">
                <button
                  className="nav-btn"
                  onClick={handleCloseNavMenu}
                  style={{
                    color: window.location.href.includes("presenters") ? "#303265" : "white",
                    fontWeight: "900",
                    backgroundColor: window.location.href.includes("presenters") ? "#F0AD4E" : "#303265",
                    border: "none",
                  }}
                >
                  Presenters
                </button>
              </Link>
              <Link to="/agenda">
                <button
                  className="nav-btn"
                  onClick={handleCloseNavMenu}
                  style={{
                    color: window.location.href.includes("agenda") ? "#303265" : "white",
                    fontWeight: "900",
                    backgroundColor: window.location.href.includes("agenda") ? "#F0AD4E" : "#303265",
                    border: "none",
                  }}
                >
                  Agenda
                </button>
              </Link>
              <Link to="/sponsors">
                <button
                  className="nav-btn"
                  onClick={handleCloseNavMenu}
                  style={{
                    color: window.location.href.includes("sponsors") ? "#303265" : "white",
                    fontWeight: "900",
                    backgroundColor: window.location.href.includes("sponsors") ? "#F0AD4E" : "#303265",
                    border: "none",
                  }}
                >
                  Sponsors
                </button>
              </Link>
              <Link to="/emails">
                <button
                  className="nav-btn"
                  onClick={handleCloseNavMenu}
                  style={{
                    color: window.location.href.includes("emails") ? "#303265" : "white",
                    fontWeight: "900",
                    backgroundColor: window.location.href.includes("emails") ? "#F0AD4E" : "#303265",
                    border: "none",
                  }}
                >
                  Email Templates
                </button>
              </Link>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {queryObj.attendees && input && (
        <div
          style={{
            display: showSearchResults,
            position: "absolute",
            bottom: "",
            zIndex: "1200",
            left: "2.5%",
            border: "1px solid white",
            width: "77.5%",
            color: "white",
            backgroundColor: "white",
            height: "auto",
          }}
        >
          <div
            style={{
              height: queryObj.attendees.length > 9 && "500px",
              backgroundColor: "#303265",
            }}
          >
            {/* <div style={{display:'flex', justifyContent:'center', paddingLeft:'1.5%'}}>
            {headerNames.map((name, i) =>{
              return <h3 style={{textAlign:'center', color: "#F0AD4E", flex: i === (headerNames.length -1) ? 2 : 1}}>{name}</h3>
            })}
        </div> */}
            <DataGrid
              disableSelectionOnClick
              rows={queryObj.attendees}
              columns={columns}
              hideFooter
              autoHeight={queryObj.attendees.length < 9 && true}
            />
          </div>
          {/* {queryObj?.account?.map((attendee, i) => {
            return <div>{attendee.firstname}</div>;
          })} */}
        </div>
      )}
    </div>
  );
};
