//REACT IMPORTS
import { useEffect, useState } from "react";

//MUI IMPORTS
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";

//LOCAL IMPORTS
import { Box } from "../index.js";
import { Dustbin } from "../index.js";
import { getData } from "../../api/apiCalls.js";

const mockData = {
  unassigned: [
    {
      lastname: "Chen",
      firstname: "Ben",
      order_slot: null,
      id: 1,
      final_presenting_track: "Medtech",
    },
    {
      lastname: "Jefferson",
      firstname: "Thomas",
      order_slot: null,
      id: 4,
      final_presenting_track: "Medtech",
    },
    { lastname: "Van ", firstname: "Ben", order_slot: null, id: 5 },
    { lastname: "Fen ", firstname: "Jen", order_slot: null, id: 6 },
    { lastname: "Van Buren ", firstname: "Hen", order_slot: null, id: 7 },
    { lastname: "Van Schtroodel", firstname: "Max", order_slot: null, id: 8 },
    { lastname: "Van ", firstname: "Ben", order_slot: null, id: 9 },
    { lastname: "Van ", firstname: "Ben", order_slot: null, id: 10 },
  ],
  Medtech: {
    round_1: [
      { lastname: "Rodriguez", firstname: "Julio", order_slot: null, id: 2 },
    ],
    round_2: [
      { lastname: "John", firstname: "Smith", order_slot: null, id: 3 },
    ],
  },
};
export const ScheduleDnD = () => {
  const [options, setOptions] = useState([]);
  const [unassigned, setUnassigned] = useState([]);
  const [initialUnassigned, setInitialUnassigned] = useState(
    mockData.unassigned
  );

  useEffect(() => {
    console.log(unassigned);
    setUnassigned(mockData.unassigned);
    getData(
      `https://youngstartup.io/api/db-info/property-options-dd/presenters`
    ).then((data) => setOptions(data.final_presenting_track));
  }, []);

  return (
    <div style={{ backgroundColor: "#000120", width: "100%", display: "flex" }}>
      <div style={{ flex: 1 }}>
        <div style={{ display: "flex", gap: "2.5%", padding: "2.5%" }}>
          {options.map((option, i) => {
            return (
              <Badge badgeContent={4} color="error">
                <button
                  style={{
                    border: "1px solid white",
                    color: "#F0AD4E",
                    backgroundColor: "#000120",
                    width: "50px",
                    height: "50px",
                  }}
                  onClick={(e) => {
                    let mockDataCopy = initialUnassigned;
                    let result = mockDataCopy?.filter(
                      (person, i) =>
                        option.option_value === person.final_presenting_track
                    );
                    setUnassigned(result);
                  }}
                >
                  {option.option_display_short}
                </button>
              </Badge>
            );
          })}
        </div>
        <div
          style={{
            overflow: "hidden",
            clear: "both",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {Array.isArray(unassigned) &&
            unassigned?.map((person, i) => {
              return <Box name={`${person.firstname} ${person.lastname}`} />;
            })}
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <div
          style={{
            overflow: "hidden",
            clear: "both",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack>
            {/* <Dustbin allowedDropEffect="any" />
            <Dustbin allowedDropEffect="copy" />
            <Dustbin allowedDropEffect="move" /> */}
            {Object.entries(mockData).map(([key, value]) => {
              console.log(`${key}: ${value}`);
              if (key !== "unassigned") {
                return (
                  <Stack>
                    <div style={{ color: "white" }}>{key}</div>
                    {Object.entries(value).map(([key, value]) => {
                      return value.map((newValue, i) => (
                        <Dustbin allowedDropEffect={key} />
                      ));
                    })}
                  </Stack>
                );
              }
            })}
          </Stack>
        </div>
      </div>
    </div>
  );
};
