import { Form } from "react-bootstrap";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';


export const EmailWindowForm = (props) => {

  const [inputs, setInputs] = useState({
    to: 'rec',
    subject: '',
    body: ''
  });

  const [templates, setTemplates] = useState([]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!window.confirm(`Are you sure you want to send an actual email to ${inputs.to}??`)) {
      return;
    }
    handleCloseForm();
    toast.promise(
      fetchApi('https://youngstartup.io/api/db/email/send', 'POST', inputs)
      .then((data) => {
        console.log(data);
        if(data.success) {
          let emptyInputs = Object.fromEntries(Object.keys(inputs).map((key) => [key, '']));
          setInputs(emptyInputs);
          props.setShowEmailForm("none")
        }
        }),
      {
        loading: "Sending Email...",
        success: (data) => `Email Sent!`,
        error: (err) => `This just happened: ${err.toString()}`,
      },
      {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 1000,
        },
      }
    );

  }

  const handleOnChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  }

  const handleCloseForm = () => {
    let emptyInputs = Object.fromEntries(Object.keys(inputs).map((key) => [key, '']));
    setInputs(emptyInputs);
    props.setShowEmailForm("none")
  }


  async function fetchApi(url, method, body = false) {
    let obj = {
      method: method,
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        tok: localStorage.tok,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    };
    if(body) {obj.body = JSON.stringify({body})}
    const response = await fetch(url, obj);
    if(method == 'POST') {
      return await response.text();
    }
    console.log(inputs);
    return await response.json();
  }

  useEffect(() => {
    fetchApi('https://youngstartup.io/api/db/email/templates/all', 'GET').then((data) => {
      setTemplates(data);
      if(props.emailTo.properties) {
        setInputs({
          ...inputs,
          to: props.emailTo.properties.email,
        });
      } else {
        setInputs({
          ...inputs,
          to: props.emailTo,
        });
      }
    });
  }, [props.showEmailForm]);


  const handleTemplateSelect = (e) => {
    let [template] = templates.filter(item => item.id == e.target.value);
    setInputs({
      ...inputs,
      subject: template.subject,
      body: template.body,
    });
  }
     
    return( 
    <>
    <div style={{position:'fixed', width:'100%', marginTop:'-20vh',zIndex:'1000'}}>
     <Form
        style={{ display: `${props.showEmailForm}`, paddingTop:'20vh !important', zIndex:"5", position:'relative', height:'auto', width:"22.5vw"}}
        encType="multipart/form-data"
        className="email-form contact-form"
        id="email-window"
        onSubmit={handleOnSubmit}
      >
    <CloseIcon onClick={handleCloseForm} style={{position:'absolute', top:'5%', right:'5%'}}>x</CloseIcon><br/>
    <FormControl variant="outlined">
    <InputLabel style={{position:"absolute", top:"2.5%"}}>
          Template Options
        </InputLabel>
    <Select onChange={handleTemplateSelect} id="templat-select-options" style={{width:'150%',marginRight:"80%"}}>
      {templates.map(item => {
        return (
          <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
        )
      })}
    </Select>
      </FormControl>
      <div style={{marginLeft:'1.75vw', marginTop:'1vh',marginBottom:'1vh'}}>
    <Input name="to" value={inputs.to || '' } placeholder="To" onChange={handleOnChange} style={{minWidth:'250px'}}></Input><br/>
    <Input name="subject" value={inputs.subject || ''} placeholder="subject" onChange={handleOnChange} style={{minWidth:'250px'}}></Input><br/>
    </div>
    <br/>
    <TextField
          id="outlined-multiline-static"
          name="body"
          label="Body"
          multiline
          rows={10}
          variant="outlined"
          value={inputs.body || ''} 
          onChange={handleOnChange}
        />
    <br/>
    <Button color="primary" type="submit" variant='contained' style={{marginLeft:'35%',marginTop:"1vh"}}>Send</Button>
    </Form>
    </div>
    </>
    )
}