import { Form } from "react-bootstrap";
import Button from "@mui/material/Button"
import { useState } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import InputLabel from '@mui/material/InputLabel';
import Input from "@mui/material/Input";
import CloseIcon from '@mui/icons-material/Close';

export const CompanyForm = (props) => {
  const autoCompleteArr = props.dataArr
  const itemArr = []
  autoCompleteArr.map((x,i)=>{
    const newObj = {}
    newObj.id=i;
    newObj.name=x.properties.name
    itemArr.push(newObj)
  })

  //console.log(itemArr)

  const reName = /^[a-z ,.'-]+$/i;

  const [inputs, setInputs] = useState({
    domain: "",
    name: "",
  });

  const formData = {
    properties: inputs,
  };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
    //console.log(inputs)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    async function getData(url, data) {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          tok: localStorage.tok,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(formData),
      });

      return response.json();
    };

    getData(`https://youngstartup.io/api/hs/companies/addnew`, formData)
      .then((data) => {
        //console.log(data);
        props.setShowForm("none");
      })
      .then(() =>
        props
          .getData(`https://youngstartup.io/api/hs/companies/all`)
          .then((data) => {
            props.setDataArr(data.results.reverse());
          })
      ).then(()=>{
        setInputs({
            domain: "",
            name: "",
        });
      });
      
  };

  //console.log(itemArr)

  return (
    <>
      <Form
        style={{ display: `${props.showForm}`, paddingTop:'20vh !important' }}
        onSubmit={handleSubmit}
        autoComplete="on"
        encType="multipart/form-data"
        className="contact-form"
      >
        <a
          onClick={props.handleClose}
          style={{
            marginLeft: "80%",
            marginBottom: "2rem !important",
            cursor: "pointer",
          }}
          className="close-btn"
        >
          <CloseIcon />
        </a>
        <Form.Group className="autocomplete mb-3" controlId="formBasicEmail">
        <InputLabel>Company Name</InputLabel>
          <ReactSearchAutocomplete
            name="name"
            value={inputs.name}
            items={itemArr}
            onChange={handleChange}
            type="text"
            maxResults={4}
            styling={{marginTop:'5rem !important'}}
            required
          />
          <div class='input-divider'></div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
        <InputLabel>Domain</InputLabel>
          <Input
            name="domain"
            value={inputs.domain}
            onChange={handleChange}
            type="text"
          />
        </Form.Group>

        <div className="form-btn-container">
          <Button
            color="primary"
            variant="contained"
            className="form-btn"
            onClick={props.handleSubmit}
            type="submit"
          >
            Add Company
          </Button>
        </div>
      </Form>
    </>
  );
};
