import { useState } from "react";
export const HoverButton = ({initialUrl, hoverUrl, style, className, onClick}) =>{
    const [imgUrl, setImgUrl] = useState(initialUrl);

    return(
        <img 
        src={imgUrl} 
        onMouseEnter={e =>{
            setImgUrl(hoverUrl)
        }}
        onMouseLeave={e =>{
            setImgUrl(initialUrl)
        }}
        style={style}
        className={className}
        onClick={onClick}
        alt=""
        />
    )

}