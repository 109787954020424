import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { getData } from "../../api/apiCalls";

const statsStyle = {
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    // MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation24 MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthSm MuiDialog-paperFullScreen
    "& .MuiDialog-paperFullScreen": {
        bgcolor: '#000120 !important',
    }
};

export const StatsPopup = ({ openStats, handleCloseStats, setOpenStats }) => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        getData("https://youngstartup.io/api/db-a/attendees/get_vc_stats")
            .then(data => {
                setStats(data);
            })
    }, []);

    let speakingStats = stats?.speaking_stats.sort((a, b) => {
        let aName = a.option_display_short.toLowerCase();
        let bName = b.option_display_short.toLowerCase();
        if (aName < bName) {
            return -1;
        } else if (aName > bName) {
            return 1;
        }
        return 0;
    });

    return (
        <Dialog
            sx={statsStyle}
            open={openStats}
            onClose={handleCloseStats}
            fullScreen
        >
            <button
                style={{
                    color: "black", position: "absolute", right: "10px",
                    width: "35px"
                }}
                onClick={() => setOpenStats(false)} >x</button>

            <h2 style={{ paddingLeft: "10px", color: "white" }}>Stats</h2>

            <div style={{
                display: "grid",
                gridTemplateColumns: "26% 25% 15% 14% 14%",
                gridTemplateRows: "18% 18% 18% 18% 28%",
                gridGap: "15px",
                padding: "10px",
            }}>
                <table
                    style={{
                        color: "white", zIndex: "100",
                        overflowY: "auto", backgroundColor: "#303265",
                        padding: "5px"
                    }}>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    color: "#F0AD4E", textAlign: "left"
                                }}>
                                Judging Selections
                            </th>
                            <th>C</th>
                            <th>I</th>
                            <th>F</th>
                            <th>R</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats?.judging_stats?.map((presenter) => {
                            return <tr key={presenter.id}
                            >
                                <td>{presenter.option_display_short}</td>
                                <td style={{ color: "#F0AD4E" }}>
                                    {presenter.selected}
                                </td>
                                <td style={{ color: "#F0AD4E" }}>
                                    {presenter.init_final}
                                </td>
                                <td style={{ color: "#F0AD4E" }}>
                                    {presenter.final}
                                </td>
                                <td style={{ color: "#F0AD4E" }}>
                                    {presenter.round}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <table style={{
                    color: "white", zIndex: "100",
                    overflowY: "auto", backgroundColor: "#303265",
                    padding: "5px"
                }}>
                    <thead>
                        <tr>
                            <th
                                style={{ color: "#F0AD4E", textAlign: "left" }}>
                                Speaking Panels
                            </th>
                            <th>C</th>
                            <th>I</th>
                            <th>F</th>
                        </tr>
                    </thead>
                    <tbody>
                        {speakingStats && speakingStats
                            .map((presenter) => {
                                return <tr key={presenter.id}
                                >
                                    <td>{presenter.option_display_short}</td>
                                    <td style={{ color: "#F0AD4E" }}>
                                        {presenter.selected}
                                    </td>
                                    <td style={{ color: "#F0AD4E" }}>
                                        {presenter.init_final}
                                    </td>
                                    <td style={{ color: "#F0AD4E" }}>
                                        {presenter.final}
                                    </td>
                                </tr>
                            })}
                    </tbody>
                </table>

                <table
                    style={{
                        color: "white", zIndex: "100", overflowY: "auto",
                        backgroundColor: "#303265", padding: "5px"
                    }}>
                    <thead>
                        <tr>
                            <th
                                style={{ color: "#F0AD4E", textAlign: "left" }}>
                                Attending Days</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats?.attending_days_stats?.map((presenter) => {
                            return <tr key={presenter.id}
                            >
                                <td>{presenter.option_display_short}</td>
                                <td style={{ color: "#F0AD4E" }}>
                                    {presenter.count}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <table
                    style={{
                        color: "white", zIndex: "100", overflowY: "auto",
                        backgroundColor: "#303265", gridRowStart: "2",
                        gridColumnStart: "3", padding: "5px"
                    }}>
                    <thead>
                        <tr>
                            <th style={{ color: "#F0AD4E", textAlign: "left" }}>
                                Roles
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats?.vc_roles_stats?.map((presenter) => {
                            return <tr key={presenter.id}
                            >
                                <td>{presenter.option_display_short}</td>
                                <td style={{ color: "#F0AD4E" }}>
                                    {presenter.count}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <table
                    style={{
                        color: "white", zIndex: "100", overflowY: "auto",
                        backgroundColor: "#303265", gridColumnEnd: "span 2",
                        gridRowEnd: "span 2",
                        padding: "5px"
                    }}>
                    <thead>
                        <tr>
                            <th
                                style={{ color: "#F0AD4E", textAlign: "left" }}>
                                Statuses
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats?.vc_invited_stage_stats?.map((presenter) => {
                            return <tr key={presenter.id}
                            >
                                <td>{presenter.option_display_short}</td>
                                <td style={{ color: "#F0AD4E" }}>
                                    {presenter.count}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <table
                    style={{
                        color: "white", zIndex: "100", overflowY: "auto",
                        backgroundColor: "#303265",
                        gridRowStart: "3",
                        gridColumnStart: "3",
                        padding: "5px"
                    }}>
                    <thead>
                        <tr>
                            <th style={{ color: "#F0AD4E", textAlign: "left" }}>
                                Confirm
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Confirmed</td>
                            <td style={{ color: "#F0AD4E" }}>
                                {stats?.vc_email_confirmed.confirmed}
                            </td>
                        </tr>
                        <tr>
                            <td>Unconfirmed</td>
                            <td style={{ color: "#F0AD4E" }}>
                                {stats?.vc_email_confirmed.unconfirmed}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table
                    style={{
                        color: "white", zIndex: "100", overflowY: "auto",
                        backgroundColor: "#303265", gridRowStart: "3",
                        gridColumnStart: "4", padding: "5px"
                    }}>
                    <thead>
                        <tr>
                            <th style={{ color: "#F0AD4E", textAlign: "left" }}>
                                Matchmaking
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>In</td>
                            <td style={{ color: "#F0AD4E" }}>
                                {stats?.vc_matchmaking_stats.confirmed}
                            </td>
                        </tr>
                        <tr>
                            <td>Not In</td>
                            <td style={{ color: "#F0AD4E" }}>
                                {stats?.vc_matchmaking_stats.unconfirmed}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table
                    style={{
                        color: "white", zIndex: "100", overflowY: "auto",
                        backgroundColor: "#303265", gridRowStart: "3",
                        gridColumnStart: "5", padding: "5px"
                    }}>
                    <thead>
                        <tr>
                            <th style={{ color: "#F0AD4E", textAlign: "left" }}>
                                Dashboard
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Opened</td>
                            <td style={{ color: "#F0AD4E" }}>
                                {stats?.vc_opened_dash.confirmed}
                            </td>
                        </tr>
                        <tr>
                            <td>Not Opened</td>
                            <td style={{ color: "#F0AD4E" }}>
                                {stats?.vc_opened_dash.unconfirmed}
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/* <table
                    style={{
                        color: "white", zIndex: "100", overflowY: "auto",
                        backgroundColor: "#303265", gridRowStart: "4",
                        gridColumnStart: "3", padding: "5px"
                    }}>
                    <thead>
                        <tr>
                            <th style={{ color: "#F0AD4E", textAlign: "left" }}>
                                Attendee Count
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(stats?.attendee_count||{}).map(([key, item], index) =>
                        (

                            <tr>
                                <td>{key.replaceAll("_", " ")}</td>
                                <td style={{ color: "#F0AD4E" }}>
                                    {item}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table> */}

                <table
                    style={{
                        color: "white", zIndex: "100", overflowY: "auto",
                        backgroundColor: "#303265", gridRowStart: "4",
                        gridColumnStart: "1", gridColumnEnd: "span 3", padding: "5px"
                    }}>
                    <thead>
                        <tr>
                            <th style={{ color: "#F0AD4E", textAlign: "left" }}>
                                Judging Statuses
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats?.judging_statuses?.map((item, idx) =>
                        (

                            <tr key={idx}>
                                <td style={{width: "100px"}}>{item.name.replaceAll("_", " ")}</td>
                                {item?.values?.map((value, idx) => (
                                    <td style={{
                                        borderRight: "1px solid white"
                                    }}>
                                        <div style={{
                                            width: "200px",
                                            display: "flex",
                                        }}>
                                            <span style={{ color: "#FFF", flexBasis: "50%" }}>
                                                {value.short_name}:
                                            </span>
                                            <span style={{ color: "#F0AD4E", flexBasis: "30%" }}>
                                                {value.total_judges_confirmed}/{value.total_judges}
                                            </span>
                                            <span style={{ color: "#0F0"}}>
                                                {value.total_judges_on_stage_confirmed}/{value.total_judges_on_stage}
                                            </span>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Dialog>
    )
}