import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { HoverButton } from "../index";
import { getData, patchData } from "../../api/apiCalls";
import CurrentEventContext from '../../utils/current_event_context';
import { parseDateForDBFormat } from "../../utils/funcs";

export const PresenterNotes = ({
  cell,
  handleErrMsg,
  handleSuccessMsg,
  fetchData,
  setData,
}) => {

  let currentEvent = useContext(CurrentEventContext).currentEvent;

  useEffect(() => {
    if (currentEvent) {
      // setNotesArr(prevState => notesArr.length > 0 ? prevState.filter(e => e.event === currentEvent)
      //   // setNotesArr(cell.value ? JSON.parse(cell.value).reverse().filter(e => e.event === currentEvent)
      //   : [])
    }
  }, [currentEvent])

  const [notesArr, setNotesArr] = useState(
    cell.value ? (typeof cell.value === "string" ? JSON.parse(cell.value) : cell.value)
      : []
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [value, setValue] = useState(null);

  const [pickerDate, setPickerDate] = useState(parseDateForDBFormat(new Date()));
  // const [pickerDate, setPickerDate] = useState(Date.now());

  const [bgColor, setBgColor] = useState(cell.row.rivka_color);
  const [colors, setColors] = useState([]);
  const [expandNotes, setExpandNotes] = useState("");
  const [position, setPosition] = useState("static");
  const [height, setHeight] = useState("100px");
  const [status, setStatus] = useState("");

  const date = new Date(notesArr[0]?.date);
  const month = date.getMonth();
  const day = date.getDate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    getData(
      `https://youngstartup.io/api/db-info/property-options-dd/presenters`
    )
      .then((data) => setColors(data.rivka_color))
      .then(() => console.log(colors));

    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const id = open ? "simple-popover" : undefined;
  const id2 = open2 ? "simple-popover-2" : undefined;

  useEffect(() => {
    setValue(
      `${notesArr[0]?.date ? `${month + 1}/${day} -` : ""} ${notesArr[0]?.text ? notesArr[0]?.text : ""
      }`
    );
  }, []);

  return (
    <div
      className="presenter-notes-container"
      style={{ backgroundColor: bgColor }}
    >
      <HoverButton
        initialUrl={"/assets/Property 1=Default.png"}
        hoverUrl={"/assets/Property 1=Variant2.png"}
        className={"status-picker-icon"}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{
            // width: "300px",
            backgroundColor: "#000120",
            height: "200px",
            border: "1px solid white",
            color: "white",
            padding: "2.5%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <span>Add New Status</span>
            <span style={{ display: "flex", gap: "5%" }}>
              <HoverButton
                initialUrl={"/assets/Property 1=Default (4).png"}
                hoverUrl={"/assets/Property 1=Variant2 (4).png"}
                style={{ width: "25px", height: "25px" }}
                onClick={handleClose}
              />
              <HoverButton
                initialUrl={"/assets/Property 1=Default (5).png"}
                hoverUrl={"/assets/Property 1=Variant2 (5).png"}
                style={{ width: "25px", height: "25px" }}
                onClick={(e) => {
                  let newNote = {
                    id: Date.now(),
                    status: status,
                    date: pickerDate,
                    event: currentEvent
                  };
                  let notesArrCopy = [...notesArr, newNote];
                  let sortedArr = notesArrCopy.sort((a, b) => new Date(a.date) - new Date(b.date));
                  setNotesArr(sortedArr);

                  let body = {
                    body: {
                      id: cell.row.id,
                      rivka_status: newNote,
                    },
                  };
                  patchData(
                    "https://youngstartup.io/api/db-a/presenters/updateapplication",
                    body
                  ).then((data) => {
                    if (data.success) {
                      handleSuccessMsg();
                      getData(
                        `https://youngstartup.io/api/db/presenters/getallapplications?stage=${cell.row.rivka_stage
                        }&company_stage=${cell.row.company_stage === "Seeking Angel Round"
                          ? "seed"
                          : "top"
                        }`
                      ).then((data) => setData(data.data));
                      // ).then((data) => setData(data));
                      handleClose();
                    } else {
                      handleErrMsg();
                    }
                  });
                }}
              />
            </span>
          </div>
          <Stack spacing={1}>
            <div
              style={{
                color: "white",
                display: "flex",
                gap: "7.5px",
                alignItems: "center",
              }}
            >
              <div style={{ width: "25%" }}>Date</div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={pickerDate}
                  onChange={(newValue) => {
                    setPickerDate(parseDateForDBFormat(newValue));
                    console.log(newValue);
                  }}
                  renderInput={(params) => (
                    <FormControl size="small" sx={{ color: "white" }}>
                      <TextField
                        {...params}
                        sx={{
                          border: "1px solid white !important",
                          borderRadius: "5px",
                          color: "white !important",
                          fill: "black",
                          input: {
                            color: "white",
                          },
                        }}
                      />
                    </FormControl>
                  )}
                />
              </LocalizationProvider>
            </div>
            <div style={{ display: "flex", gap: "7.5%", alignItems: "center" }}>
              <div style={{ width: "15.5%" }}>Status</div>
              <TextField
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                sx={{
                  border: "1px solid white !important",
                  borderRadius: "5px",
                  color: "white !important",
                  input: {
                    color: "white",
                  },
                }}
              />
            </div>
          </Stack>
        </div>
      </Popover>
      <div className="note-display-container">
        {/* <div className="note-display" onDoubleClick={handleExpand} style={{position: position, left: '-100px', height: height, zIndex: position === "absolute" && "100000"}}><span className="presenter-notes-date">{`${notesArr[0]?.date ? `${month}/${day} -` : ""} `}</span> <span>{`${notesArr[0]?.text ? notesArr[0]?.text : ""}`}</span></div>     */}
      </div>
      <FormControl sx={{ width: "300px" }} size="small">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Age"
          value={value}
          sx={{
            width: "100%",
            color: "white",
            borderColor: "white",
            backgroundColor: "#000120",
            borderRadius: "25px",
          }}
        >
          <MenuItem value={value}>{`${notesArr[0]?.date
            ? `${new Date(notesArr[0]?.date).getMonth() + 1}/`
            : ""
            }${notesArr[0]?.date ? new Date(notesArr[0]?.date).getDate() : ""} ${notesArr[0]?.status ? notesArr[0]?.status : ""
            }`}
          </MenuItem>

          {notesArr.map((note, i) => {
            let date = new Date(note.date);
            let month = date.getMonth();
            let day = date.getDate();
            if (note !== value && i !== 0) {
              return (
                <MenuItem value={i} key={i}>
                  {month && month + 1}/{day && day} {note.status && note.status}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
      <HoverButton
        initialUrl={"/assets/Change Color small.png"}
        hoverUrl={"/Change Color small-hover.png"}
        className={"status-picker-icon"}
        onClick={handleClick2}
      />
      <Popover
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack sx={{ p: 2, backgroundColor: "#000120" }} spacing={1}>
          {colors.map((color, i) => {
            return (
              <div
                key={i}
                style={{
                  width: "150px",
                  backgroundColor: color.option_value,
                  height: "50px",
                }}
                onClick={(e) => {
                  let body = {
                    body: {
                      id: cell.row.id,
                      rivka_color: color.option_value,
                    },
                  };
                  patchData(
                    `https://youngstartup.io/api/db-a/presenters/updateapplication`,
                    body
                  ).then((data) => {
                    if (data.success) {
                      handleSuccessMsg();
                      setBgColor(color.option_value);
                      handleClose2();
                      getData(
                        `https://youngstartup.io/api/db/presenters/getallapplications?stage=${cell.row.rivka_stage
                        }&company_stage=${cell.row.company_stage === "Seeking Angel Round"
                          ? "seed"
                          : "top"
                        }`
                      ).then((data) => setData(data.data));
                      // ).then((data) => setData(data));
                    } else {
                      handleErrMsg();
                    }
                  });
                }}
              />
            );
          })}
        </Stack>
      </Popover>
    </div>
  );
};
