import { useState, useEffect, createContext } from 'react';
import { getData } from '../api/apiCalls';

const TicketTypesContext = createContext({
    ticketTypes: []
});

export const TicketTypesContextProvider = (props) => {
    const [ticketTypes, setTicketTypes] = useState({ ticketTypes: [] });

    useEffect(() => {
        getData(`https://youngstartup.io/api/db-info/property-options-dd/presenters`)
            .then(data => setTicketTypes(data.ticket_type))
    }, []);

    return (
        <TicketTypesContext.Provider
            value={{ ticketTypes }}
        >
            {props.children}
        </TicketTypesContext.Provider>
    );
};

export default TicketTypesContext;
