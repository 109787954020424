import { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from '@mui/material/InputLabel';

import { setLocalTok } from "../../utils/funcs";


export const LoginForm = (props) => {
  const [emailVal, setEmailVal] = useState('none');
  const [passVal, setPassVal] = useState('none');
  const [showPassword, setShowPassword] = useState('password')
  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  });
  const re = /\S+@\S+\.\S+/;
  const rePass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{2,}$/
  const { pathname } = useParams();

  const passInp = useRef();

  const formData = {
    body: inputs,
  };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (re.test(inputs.email) == false) {
      setEmailVal('block');
      setTimeout(() => { setEmailVal('none'); }, 2000);
      // setTimeout(() => { setEmailVal('none'); }, 6000);
      e.preventDefault()
    }

    if (re.test(inputs.email) == false) {
      setPassVal('block');
      setTimeout(() => { setPassVal('none'); }, 2000);
      // setTimeout(() => { setPassVal('none'); }, 6000);
      e.preventDefault()
    }

    async function postData(url, data) {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      });

      return response.json();
    };

    postData(`https://youngstartup.io/api/apilogin`, formData)
      .then((data) => {
        console.log(data)
        if (data.success) {
          setLocalTok(data.tok)
          if(pathname){
            window.location.href = "/" + pathname + window.location.search;
          }else{
          window.location.href = "/landing";
          }
        } else {
          console.log(data)
        }
        // props.setShowForm("none");
      })
    // .then(() => {
    //   // setInputs({
    //   //   email: '',
    //   //   password: ''
    //   // });
    //   // window.location.href = "/landing"
    // });

  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Form
        style={{/* display: `${props.showForm}`,*/ paddingTop: '20vh !important', backgroundColor: '#000120', border: '1px solid white', width: 'fit-content' }}
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="login-form"
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}><img
          src="https://events.youngstartup.com/wp-content/uploads/2019/01/trns-logo.png"
          className="login-brand"
          alt=""
        /></div>

        {/* email input */}
        <Form.Group className="mb-3" controlId="loginFormBasicEmail">
          <InputLabel style={{ color: 'white' }}>Username</InputLabel>
          <Input
            name="email"
            value={inputs.email}
            fullWidth
            onChange={handleChange}
            type="text"
            style={{ color: 'white', borderBottom: '1px solid white' }}
          />
          <div style={{ color: 'red', textAlign: 'center', position: 'absolute', display: emailVal, top: '38%', right: '44%' }}>please enter a valid email</div>
        </Form.Group>

        {/* password input */}
        <Form.Group className="mb-3" controlId="loginFormBasicPassword">
          <div style={{ position: "relative", display: "block" }}>
            <InputLabel style={{ color: 'white' }}>Password</InputLabel>
            <Input
              fullWidth
              name="password"
              endAdornment={showPassword == 'password' ? <VisibilityIcon onClick={() => setShowPassword('text')} color="primary" /> : <VisibilityOffIcon onClick={() => setShowPassword('password')} color='primary' />}
              value={inputs.password}
              onChange={handleChange}
              style={{ color: 'white', borderBottom: '1px solid white' }}
              type={showPassword}
              ref={passInp}
            />{/*showPassword == 'password' ? <VisibilityIcon onClick={()=>setShowPassword('text')} color="primary"/> : <VisibilityOffIcon onClick={()=>setShowPassword('password')} color='primary' />*/}
            {/* <ReactPasswordToggleIcon inputRef={passInp} /> */}
          </div>
        </Form.Group>

        <div className="form-btn-container" style={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: '1.3rem', textAlign: 'center' }}
            onClick={props.handleSubmit}
            type="submit"

          >
            Login
          </Button>
        </div>
      </Form>
    </div>
  );
};
