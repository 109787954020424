import { useState, useEffect } from "react";

import ToggleButton from "@mui/material/ToggleButton";

export const HeadshotToggle = ({ cell }) => {
    const [color, setColor] = useState("");
    const [counter, setCounter] = useState(0);

    const props = ["headshot_url", "logo_url", "one_liner"]

    useEffect(() =>{
      // let myArr = [cell.row.headshot_url, cell.row.logo_url, cell.row.one_liner]
      // myArr.forEach((value, i) =>{
      //   if(value === null || !value){
      //     let copy = counter;
      //     setCounter(copy++)
      //   }
      // })

 
        if(/*cell.row.headshot_url && */ cell.row.logo_url && cell.row.one_liner){
            setColor("green")
        } else if(/*!cell.row.headshot && */ !cell.row.logo_url && !cell.row.one_liner){
            setColor("red")
        } else{
            setColor("orange")
        }
    }, [])

  return (
    <ToggleButton
      value="check"
      style={{
        backgroundColor: color,
        cursor: 'default'
      }}
    />
  );
};
