import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import "./App.css";
import {
  AdvancedSearch, Agenda, Attendees, Companies, CompanyLists, Contacts, DashboardNew, Deals, Landing, Login, Presenters, Recruiters, Speakers,
  Sponsors, WishList, EmailTemplates 
} from "./pages/index";

const expired = localStorage.expires > Date.parse(Date()) ? false : true;

const App = () => {
  if (!localStorage.tok || expired) {
    let pathname = window.location.pathname;
    pathname = pathname.split("/").at(-1);
    if (pathname === "login") pathname = "/";
    pathname += window.location.search;

    return (
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login/" element={<Login />} />
          <Route path="/login/:pathname" element={<Login />} />
          <Route path="/*" element={<Navigate replace to={"/login/" + pathname} />} />
          {/* <Route path="/*" element={<Navigate replace to=`/login/${pathname}` />} /> */}
        </Routes>
      </Router>
    )
  } else {
    return (
      <Router>
        <Routes>
          <Route path="/attendees" element={<Attendees />} />
          <Route path="/speakers" element={<Speakers />} />
          <Route path="/presenters" element={<Presenters />} />
          <Route path="/agenda" element={<Agenda />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/emails" element={<EmailTemplates />} />

          <Route path="/companylists" element={<CompanyLists />} />
          <Route path="/dashboard" element={<DashboardNew />} />
          <Route path="/wishlist" element={<WishList />} />
          <Route path="/recruiters" element={<Recruiters />} />
          <Route path="/deals" element={<Deals />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/search" element={<AdvancedSearch />} />

          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    );
  }
};
export default App;