export async function getData(url) {
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      tok: localStorage.tok,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
  if (response.status === 401) {
    localStorage.removeItem("tok");
    if (!window.location.href.includes("/login")) {
      window.location.href = "/login";
    }
    return;
  }
  return response.json();
}

export async function patchData(url, data) {
  const response = await fetch(url, {
    method: "PATCH",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      tok: localStorage.tok,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  if (response.status === 401) {
    localStorage.removeItem("tok");
    if (!window.location.href.includes("/login")) {
      window.location.href = "/login";
    }
    throw "authentication failed";
  }
  return response.json();
}

export async function createAttendee(url, data) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      tok: localStorage.tok,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });

  return response.json();
}



export async function deleteMultiAttendee(url, data) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      tok: localStorage.tok,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return;
}

export async function putData(url, data) {
  const response = await fetch(url, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      tok: localStorage.tok,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function postData(url, data) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      tok: localStorage.tok,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  if (response.status === 401) {
    localStorage.removeItem("tok");
    if (!window.location.href.includes("/login")) {
      window.location.href = "/login";
    }
    throw "authentication failed";
  }
  return response.json();
}

export async function deleteData(url) {
  const response = await fetch(url, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      tok: localStorage.tok,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
  return response.json();
}

// export function getFile(url, method = "GET", filename = "file.xlsx") {
//   fetch(url, {
//     method,
//     mode: "cors",
//     headers: {
//       tok: localStorage.tok,
//       'Accept': '*/*',
//     },
//     redirect: "follow",
//     referrerPolicy: "no-referrer",
//     // 'Accept': '*/*',
//   })

//     // fetch(url, {
//     //   method: 'GET',
//     //   headers: {
//     //     // 'Content-Type': 'application/pdf',
//     //     tok: localStorage.tok,
//     //   },
//     // })
//     // fetch('https://cors-anywhere.herokuapp.com/' + fileURL, {
//     //   method: 'GET',
//     //   headers: {
//     //     'Content-Type': 'application/pdf',
//     //   },
//     // })
//     // .then((response) => {
//     //   console.log("response", response);
//     //   // response.blob() 
//     // }
//     // )
//   // .then((blob) => {
//   //   // Create blob link to download
//   //   const url = window.URL.createObjectURL(
//   //     new Blob([blob]),
//   //   );
//   //   const link = document.createElement('a');
//   //   link.href = url;
//   //   link.setAttribute(
//   //     'download',
//   //     filename,
//   //   );

//   //   // Append to html link element page
//   //   document.body.appendChild(link);

//   //   // Start download
//   //   link.click();

//   //   // Clean up and remove the link
//   //   link.parentNode.removeChild(link);
//   // }
//   // );

//       .then(res => res.blob())
//       .then(blob => {
//         // var file = window.URL.createObjectURL(blob);
//         // window.location.assign(file);
//         var url = window.URL.createObjectURL(blob);
//         var a = document.createElement('a');
//         a.href = url;
//         a.download = filename;
//         document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
//         a.click();
//         a.remove();
//       }
//       );
// }

export function getFile(url, method = "GET", filename = "file.xlsx") {
  fetch(
    "https://youngstartup.io/api/db-a/presenters/get_report/get_selected_presenters_by_date",
    // url, 
    {
      // method,
      // mode: "no-cors",
      // headers: {
      //   tok: localStorage.tok,
      // },
      // redirect: "follow",
      // referrerPolicy: "no-referrer",
      // 'Accept': '*/*',
    })
    .then(res => res.blob())
    .then(blob => {
      // var file = window.URL.createObjectURL(blob);
      // window.location.assign(file);
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    });
}