//REACT IMPORTS
import { useEffect, useState } from "react";

//MUI IMPORTS
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Fab from "@mui/material/Fab";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

//LOCAL IMPORTS
import { postData, getData } from "../../api/apiCalls";
import { parseDateForDBFormat } from "../../utils/funcs";
import { HoverButton } from "../index";

export const PresenterGrid = ({
  currentColumn,
  value,
  multipleSelect,
  setMultipleSelect,
  setOpenDialog3,
  openDialog3,
  setSelectedContacts,
  selectedContacts,
  setAttendeeIdArr,
  fetchUrl,
  setData,
  key,
  initialValue,
  i,
  fetchData,
  handleCloseActions,
  deadlinesStructure
}) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [clearSelection, setClearSelection] = useState(initialValue);
  const [deadlines, setDeadlines] = useState({});
  // const [newIds, setNewIds] = useState([]);

  let params = new URLSearchParams(document.location.search);
  let urlStage = params.get("stage");
  // let urlCompanyStage = params.get("company_stage");

  const handleClickOpen3 = (cell) => {
    setOpenDialog3(true);
  };

  const handleCloseDialog3 = () => {
    setOpenDialog3(false);
    handleCloseActions();
  };

  useEffect(() => {
    if (urlStage === "confirmed") {
      const obj = {};
      if (deadlinesStructure?.length) {
        deadlinesStructure.forEach(x => {
          obj[x.option_value] = value[x.option_value];
        });
      }
      setDeadlines(obj);
    }
  }, []);

  useEffect(() => {
    // setSelectedContacts((old) => [...old, ...value]);
    setSelectedContacts((old) => [...old, value[0]]);
    selectionModel.map((id, i) => {
      value.map((row, i) => {
        if (id === row.id) {
          setAttendeeIdArr((old) => [...old, row.id]);
        }
      });
    });
  }, [selectionModel]);

  useEffect(() => {
    setSelectionModel([]);
    // console.log(selectionModel);
  }, [clearSelection]);

  return (
    <>
      <div style={{ display: "flex", gap: "2.5%" }}>
        <HoverButton
          initialUrl={"/assets/Property 1=Default (14).png"}
          hoverUrl={"/assets/Property 1=Variant2 (13).png"}
          onClick={() => {
            setSelectionModel([]);
            setMultipleSelect([]);
          }}
          className={"presenter-action-send-email"}
        />
        <Fab color="primary">
          {showTable ? (
            <RemoveIcon onClick={(e) => setShowTable(false)} />
          ) : (
            <AddIcon onClick={(e) => setShowTable(true)} />
          )}
        </Fab>
      </div>
      <DataGrid
        sx={{
          display: !showTable && "none",
          '& .MuiCheckbox-colorPrimary svg': {
            color: "white"
          },
          '& .MuiDataGrid-columnHeaders': {
            position: "sticky",
          },
          '& .MuiDataGrid-virtualScroller': {
            // Undo the margins that were added to push the rows below the previously fixed header
            marginTop: "0 !important"
          },
          '& .MuiDataGrid-main': {
            // Not sure why it is hidden by default, but it prevented the header from sticking
            overflow: "visible"
          }
        }}
        columns={currentColumn}
        rows={value}
        pagination
        disableSelectionOnClick
        hideFooter={true}
        autoHeight
        checkboxSelection={fetchUrl.includes("confirmed") && true}
        onSelectionModelChange={(newSelectionModel, e) => {
          console.log(e)
          console.log(newSelectionModel);
          setSelectionModel(newSelectionModel);
          setMultipleSelect((old) => [...old, ...newSelectionModel]
            // .filter((id, i) =>{
            //   for(let i = 0; i < newSelectionModel.length; i++){\
            //     if(id !== newSelectionModel[i]){
            //       return true
            //     }
            //   }
            // })
          );

          // for (let i; i < selectionModel.length; i++) {
          //   console.log(selectionModel[i]);

          // for(let j; j < value.length; j++){
          //   console.log(value[j].id)
          //   if(selectionModel[i] === value[j].id){
          //     setAttendeeIdArr(old => [...old, value[j].attendee_id])
          //   }
          // }
          // }
        }}
        selectionModel={selectionModel}
        rowHeight={150}
        className="presenter-table"

        columnVisibilityModel={{
          time_preference: urlStage === "selected" || urlStage === "confirmed"
        }}
      />

      <Dialog
        open={openDialog3}
        onClose={handleCloseDialog3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions
          style={{
            backgroundColor: "#303265",
            display: "flex",
            justifyContent: "space-between",
            color: "white",
            alignItems: "center",
          }}
        >
          <h4>Select Deadlines</h4>
          <div style={{ display: "flex", gap: "15px" }}>
            <HoverButton
              initialUrl={"/assets/Property 1=Default (4).png"}
              hoverUrl={"/assets/Property 1=Variant2 (4).png"}
              className="status-picker-icon"
              onClick={handleCloseDialog3}
            >
              Close
            </HoverButton>
            <HoverButton
              initialUrl={"/assets/Property 1=Default (5).png"}
              hoverUrl={"/assets/Property 1=Variant2 (5).png"}
              className="status-picker-icon"
              onClick={(e) => {
                // let attendeeIdArr = [];

                // let uniqueChars = multipleSelect?.filter((c, index) => {
                //   return multipleSelect.indexOf(c) === index;
                // });

                // uniqueChars?.forEach((num, i) => {
                //   console.log(num);
                //   for (let i = 0; i < selectedContacts?.length; i++) {
                //     // console.log(selectedContacts[i].id);
                //     if (num == selectedContacts[i]?.id) {
                //       attendeeIdArr.push(selectedContacts[i]?.id);
                //     }
                //   }
                // });

                // let uniqueChars2 = attendeeIdArr?.filter((c, index) => {
                //   return attendeeIdArr.indexOf(c) === index;
                // });
                // console.log("check multipleSelect", multipleSelect)
                // console.log("check selectedContacts", selectedContacts)

                // console.log("check", attendeeIdArr)

                let uniqueIds = Array.from(new Set(multipleSelect));

                let obj = {};
                for (const key in deadlines) {
                  obj[key] = parseDateForDBFormat(new Date(deadlines[key]))
                }
                let body = {
                  body: {
                    ids: uniqueIds,
                    data: obj
                  },
                };
                postData(
                  `https://youngstartup.io/api/db-a/presenters/update_presenters_deadlines`,
                  body
                ).then((data) => {
                  setSelectionModel([]);
                  setMultipleSelect([]);
                  handleCloseDialog3();
                  handleCloseActions();
                  fetchData();
                });
              }}
            >
              Save
            </HoverButton>
          </div>
        </DialogActions>
        <DialogContent sx={{ color: "white", bgcolor: "#303265" }}>
          <Stack spacing={2}>
            <table
              style={{ borderCollapse: "separate", borderSpacing: "20px" }}
            >
              {deadlinesStructure?.length && deadlinesStructure.map((deadline) => {
                return (
                  <tr key={deadline.id}>
                    <td style={{ color: "#F0AD4E" }}>{deadline.option_display}</td>
                    <td>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={deadlines[deadline.option_value]}
                          onChange={(newValue) => {
                            setDeadlines({
                              ...deadlines,
                              [deadline.option_value]: newValue,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{ color: "white", input: { color: "white" } }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </td>
                  </tr>
                )
              })}
            </table>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
