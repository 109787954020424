import { useEffect, useState } from "react";

import LoopIcon from '@mui/icons-material/Loop';
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";

import { getData, patchData } from "../../api/apiCalls";

export const TrackPicker = ({
  cell,
  ddOptions,
  handleErrMsg,
  handleSuccessMsg,
  setData,
  setStartupInputs
}) => {
  // alert("hi")
  const [anchorEl, setAnchorEl] = useState(null);
  const [display, setDisplay] = useState(ddOptions.final_presenting_track.map((option, i) => {

    if (
      option.option_value.toLowerCase() ===
      cell.row.final_presenting_track?.toLowerCase()
    ) {
      return option.option_display_short;
    }
  }))

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const open = false;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover-initial" : undefined;

  useEffect(() => {
    // console.log(display);
  }, [display])


  return (
    <div>
      <span
        style={{
          padding: "5px",
          width: "35px",
          // height: "35px",
          border: "1px solid white",
          display: "flex",
          justifyContent: "center",
          color: "#F0AD4E",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <LoopIcon sx={{ color: "white" }} />
        {/* {display} */}
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack spacing={1} sx={{ p: 2, backgroundColor: "#303265" }}>
          {ddOptions.final_presenting_track.map((option, i) => {
            return (
              <div
                key={i}
                className="track-option"
                style={{
                  textAlign: "center",
                  backgroundColor:
                    (typeof display === "string" && option.option_display_short.toLowerCase() === display.toLowerCase() || option.option_value === cell.row.final_presenting_track)
                      ? "#F0AD4E"
                      : "#000120",
                  color:
                    (typeof display === "string" && option.option_display_short.toLowerCase() === display.toLowerCase() || option.option_value === cell.row.final_presenting_track)
                      ? "#000120"
                      : "white",
                  padding: "5px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  cell.row.final_presenting_track = option.option_value;
                  let body = {
                    body: {
                      id: cell.row.id,
                      final_presenting_track: option.option_value,
                    },
                  };
                  patchData(
                    `https://youngstartup.io/api/db-a/presenters/updateapplication`,
                    body
                  ).then((data) => {
                    if (data.success) {
                      setDisplay(option.option_display_short)
                      handleSuccessMsg();
                      getData(
                        `https://youngstartup.io/api/db/presenters/getallapplications?stage=${cell.row.rivka_stage
                        }&company_stage=${cell.row.company_stage === "Seeking Angel Round"
                          ? "seed"
                          : "top"
                        }`
                      ).then((data) => setData(data.data))
                      // ).then((data) => setData(data))
                    } else {
                      handleErrMsg();
                    }
                    handleClose();
                  });
                }}
              >
                {option.option_display}
              </div>
            );
          })}
        </Stack>
      </Popover>
    </div>
  );
};
