import { NavBar, 
  // CompanyTable
 } from "../components/index";

export const Companies = () => {
  if(!localStorage.tok){
    window.location.href='/'
    return(<div>Please log in</div>)
  }
  return (
    <div>
      <NavBar />
      {/* <CompanyTable /> */}
    </div>
  );
};
