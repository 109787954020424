import { useEffect, useState } from "react"
import { getData, postData } from "../api/apiCalls";

const operators = ["=", "not", "<", ">", "like", "not like"];

const AdvancedSearch = () => {

    const [fields, setFields] = useState([]);
    const [selectedField, setSelectedField] = useState("");
    const [selectedOperator, setSelectedOperator] = useState("");
    const [input, setInput] = useState("");
    const [query, setQuery] = useState({});
    const [queryList, setQueryList] = useState({});
    // const [query, setQuery] = useState([]);
    // const [queryList, setQueryList] = useState([]);

    useEffect(() => {
        getData("https://youngstartup.io/api/db-info/properties/all_attendees")
            .then(data => {
                setFields(data)
            })
    }, [])

    const handleSelectField = (e) => {
        // console.log(e.target.value)
        setSelectedField(e.target.value)
    }
    const handleSelectOperator = (e) => {
        // console.log(e.target.value)
        setSelectedOperator(e.target.value)
    }
    const handleInput = (e) => {
        // console.log(e.target.value)
        setInput(e.target.value)
    }
    const handleAddFilter = () => {
        if (!selectedField || !selectedOperator || !input) return;
        const random = parseInt(Math.random() * 1000000);
        const filter = { id: random, data: [selectedField, selectedOperator, input] }
        // const filter = { random, selectedField, selectedOperator, input }
        if (query.id) {
            console.log("test2", { ...query, data: [...query.data, filter] })
            setQuery({ ...query, data: [...query.data, filter] });
        } else {
            const random2 = parseInt(Math.random() * 1000000);
            console.log("test", { id: random2, data: [filter] })
            setQuery({ id: random2, data: [filter] });
        }
        // const random = Math.random().toFixed(6) * 1000000;
        // const arr = [random, selectedField, selectedOperator, input];
        // setQuery([...query, arr]);
        // console.log("arr", arr)
        // // console.log("query", query)
        // console.log("query1", [...query, arr]);
        console.log("queryList", queryList)
        setSelectedField("");
        setSelectedOperator("");
        setInput("");
    }
    const handleAddQuery = () => {
        if (!query?.data?.length) return;
        // const random = parseInt(Math.random() * 1000000);
        // const queryItem = { id: random, data: query }
        // console.log("queryItem",queryItem)
        // const filter = { random, selectedField, selectedOperator, input }
        if (queryList.id) {
            console.log("test3", { ...queryList, data: [...queryList.data, query] })
            setQueryList({ ...queryList, data: [...queryList.data, query] });
        } else {
            const random2 = Math.random().toFixed(6) * 1000000;
            console.log({ id: random2, data: [query] })
            setQueryList({ id: random2, data: [query] });
        }
        // if (queryList.id) {
        //     console.log("test3", { ...queryList, data: [...queryList.data, queryItem] })
        //     setQueryList({ ...queryList, data: [...queryList.data, queryItem] });
        // } else {
        //     const random2 = Math.random().toFixed(6) * 1000000;
        //     console.log({ id: random2, data: [queryItem] })
        //     setQueryList({ id: random2, data: [queryItem] });
        // }


        // setQueryList([...queryList, query]);
        // console.log("queryList1", [...queryList, query]);
        // console.log("queryList", queryList)
        setQuery({});
        // setQuery([]);
    }
    const handleSend = () => {
        if (!queryList?.data?.length) return;
        console.log("queryList", queryList)
        const renderedData = queryList.data.map(query => {
            return query.data.map(filter => {
                return filter.data;
            })
        })
        // const data1 = queryList.data.map(query=>query.data).map(filter=>filter.data)
        // console.log(data1)
        // const data2 = data1.map(query=>query.data)
        // const data3 = data2.map(filter=>filter.data)
        // const data1 = queryList.data;
        // const data2 = data1.map(query=>query.data)
        // const data3 = data2.map(filter=>filter.data)
        // const renderedData= 
        //queryList.map
        //query.map
        //filter.map
        const body = { "body": { "filters": renderedData } }
        // const body = { "body": { "filters": queryList } }
        postData("https://youngstartup.io/api/dbt/all_attendees/filter?page=1&per-page=100", body)
            .then(data => {
                console.log(data)
            })
        setQueryList({});
        // setQueryList([]);
    }
    const removeFilter = (id) => {
        // console.log("query",query)
        // console.log(id)
        const tempQuery = query.data.filter(item => item.id !== id)
        // console.log(tempQuery)
        setQuery({ id: query.id, data: tempQuery })
    }
    const removeQuery = (id) => {
        // console.log("query",query)
        // console.log(id)
        const tempQueries = queryList.data.filter(item => item.id !== id)
        // console.log(tempQueries)
        setQueryList({ id: queryList.id, data: tempQueries })
    }

    return (
        <div style={{
            height: "100%", minHeight: "100vh", width: "100%", background: "white", position: "relative", top: "0", bottom: "0",
            marginTop: "-20px", paddingBottom: "100px"
        }}>
            <h1>testing</h1>

            <div style={{ display: "flex", flexDirection: "column", gap: "15px", margin: "10px" }}>

                <label htmlFor="field-select">Choose a field:</label>
                <select name="fields" id="field-select" onChange={handleSelectField} value={selectedField}>
                    <option value="">--Please choose an option--</option>
                    {fields.map((field, i) => {
                        return <option value={field} key={i}>{field}</option>
                    })}
                </select>
                <br />


                <label htmlFor="operator-select">Choose an operator:</label>
                <select name="operators" id="operator-select" onChange={handleSelectOperator} value={selectedOperator}>
                    <option value="">--Please choose an option--</option>
                    {operators.map((operator, i) => {
                        return <option value={operator} key={i}>{operator}</option>
                    })}
                </select>
                <br />

                <input onChange={handleInput} value={input} />

                <button onClick={handleAddFilter}>Add to Filters</button>
                <br />
                <button onClick={handleAddQuery}>Add to Queries</button>
                <br />
                <button onClick={handleSend}>Send Queries</button>

                {queryList.data && queryList.data.map((query, i) => {
                    return <div key={query.id} style={{ border: "2px solid blue" }}>
                        <button style={{ color: "green" }} onClick={() => removeQuery(query.id)}>x</button>
                        {query.data && query.data.map((item, i) => {
                            return <div key={item.id}>
                                <div>{item.data[0]} {item.data[1]} {item.data[2]} &nbsp;&nbsp;&nbsp;
                                    {/* <button style={{ color: "orange" }}>x</button> */}
                                </div>
                            </div>
                        })}
                    </div>
                })}

                {query.data && query.data.map((item, i) => {
                    return <div key={item.id}>
                        <div>{item.data[0]} {item.data[1]} {item.data[2]} &nbsp;&nbsp;&nbsp;
                            <button style={{ color: "red" }} onClick={() => removeFilter(item.id)}>x</button>
                        </div>
                    </div>
                })}
                {/* {query.map((item, i) => {
                    // const random = Math.random().toFixed(6) * 1000000;
                    return <div key={item[0]}>
                        <div>{item[1]} {item[2]} {item[3]}</div>
                        <button>x</button>
                    </div>
                })} */}
            </div>

        </div>
    )
}
export default AdvancedSearch;